import React, { useState } from 'react';
import { Grid, Button, FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Popover, Switch, FormControlLabel, Tooltip, IconButton, InputAdornment  } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import DragAndDropFiles from "../DragAndDropFile/DragAndDropFiles";

const Filters = ({ updateAnswerFilter, updateStrictCitationMode, updateInstructions, updateSourceFilter, updateYearFilter, updateCitationCountFilter, answerFilter, strictCitationMode, instructions, sourceFilter, yearFilter, citationCountFilter }) => {
  const handleAnswerFilterChange = (event) => {
    updateAnswerFilter(event.target.value);
  };

  const handleStrictCitationModeChange = (event) => {
    updateStrictCitationMode(event.target.value);
  };

  //const handleSourceFilterChange = (event) => {
  //  updateSourceFilter(event.target.value);
  //};

  const handleYearFilterChange = (event) => {
    updateYearFilter(event.target.value);
  };

  const handleUpdateCitationFilterChange = (event) => {
    updateCitationCountFilter(event.target.value);
  };

  const handleInstructionsChange = (event) => {
    updateInstructions(event);
  };

  //const [open, setOpen] = React.useState(false);

  //const handleClickOpen = () => {
  //  setOpen(true);
  //};

  //const handleClose = () => {
  //  setOpen(false);
  //};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id = open2 ? 'simple-popover' : undefined;


  return (
    <div style={{display:"flex", flexDirection: "row"}} >
      {/*<FormControlLabel
          value="end"
          control={<Switch color="primary" size="small" sx={{marginRight: '2px'}}
          checked={answerFilter}
          onChange={handleAnswerFilterChange}/>}
          label="Custom"
          sx={{color: 'Grey', marginLeft: '10px', marginRigth: '0px', padding: '0px' }}
          labelPlacement="end"
      />*/}

      {/*{answerFilter === true && (
      <FormControlLabel
          value="end"
          control={<Switch color="secondary" size="small" sx={{marginRight: '2px'}}
          checked={strictCitationMode}
          onChange={handleStrictCitationModeChange}/>}
          label="Strict citation"
          sx={{color: 'Grey', marginLeft: '0px', marginRigth: '0px', padding: '0px' }}
          labelPlacement="end"
      />
    )}*/}

      <Tooltip title="Set filters for your search" arrow>
        <Button variant="outlined" size='small' sx={{ textTransform: 'none', fontSize: '15px', color: 'Grey', borderRadius: '10px', backgroundColor: 'transparent', borderColor: 'transparent' }} //marginLeft: '-6px'
        onClick={handleClick}>
          <TuneIcon sx={{ fontSize: '20px', marginRight: '6px' }} /> Advanced
        </Button>
      </Tooltip>

      <Popover
        id={id}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={0}
        sx={{ '& .MuiPopover-paper': { borderRadius: '10px', border: '1px solid #dddddd', padding: '12px 15px 16px 15px'} }}
      >

          <Typography color="textSecondary">Published since</Typography>
          <FormControl sx={{ margin: '3px 0px 12px 0px', borderRadius: '10px', maxHeight: '35px' }}>
            <Select value={yearFilter} onChange={handleYearFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'Grey', '& .MuiSvgIcon-root': {color: 'Grey'} }}>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2019}>2019</MenuItem>
              <MenuItem value={2015}>2015</MenuItem>
              <MenuItem value={2000}>2000</MenuItem>
              <MenuItem value={1900}>Any time</MenuItem>
            </Select>
          </FormControl>

          <Grid display="flex" flexDirection="row" alignItems="center">
            <Typography color="textSecondary">Minimum number of citations</Typography>
            <Tooltip title="Minimum number of times a research paper has been cited in other academic publications." arrow>
            <HelpOutlineOutlinedIcon sx={{ color: 'grey', fontSize: '16px', marginLeft: '4px' }}/>
            </Tooltip>
          </Grid>
          <FormControl sx={{ margin: '3px 0px 12px 0px', borderRadius: '10px', maxHeight: '35px' }}>
            <Select value={citationCountFilter} onChange={handleUpdateCitationFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'Grey', '& .MuiSvgIcon-root': {color: 'Grey'} }}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>

          <Grid display="flex" flexDirection="row" alignItems="center">
            <Typography color="textSecondary">Instructions</Typography>
            <Tooltip title="Specify a request. Examples: “Summarize with bullet points”, “Write a literature review”, “Describe applied methods”." arrow>
            <HelpOutlineOutlinedIcon sx={{ color: 'grey', fontSize: '16px', marginLeft: '4px' }}/>
            </Tooltip>
          </Grid>

          <TextField fullWidth
              name="goal" size="large" label={instructions ? '' : 'Specify instructions'} InputLabelProps={{ shrink: false }} 
              value={instructions} multiline onChange={(e) => handleInstructionsChange(e.target.value)} inputProps={{maxLength: 200}}
              sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35', 
              borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                //backgroundColor: '#02161a',
                borderRadius: '10px',
              }, marginTop: '5px'}}
              >
              </TextField>

      </Popover>

      {/*<Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { background: "#010e11", borderRadius: '10px', border: '1px solid #141414'} }}>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary">Answer type</Typography>
          <FormControl sx={{ margin: '0px 0px 15px 0px', borderRadius: '10px', maxHeight: '35px' }}>
            <Select value={answerFilter} onChange={handleAnswerFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'lightGrey', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'Grey'}, '& .MuiSvgIcon-root': {color: 'darkGrey'} }}>
              <MenuItem value="direct">Direct answer</MenuItem>
              <MenuItem value="literatureReview">Literature Review</MenuItem>
            </Select>
          </FormControl>
          <Typography color="textSecondary">Published since</Typography>
          <FormControl sx={{ margin: '0px 0px 15px 0px', borderRadius: '10px', maxHeight: '35px' }}>
            <Select value={yearFilter} onChange={handleYearFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'lightGrey', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'Grey'}, '& .MuiSvgIcon-root': {color: 'darkGrey'} }}>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2020}>2020</MenuItem>
              <MenuItem value={2018}>2018</MenuItem>
              <MenuItem value={1900}>Any time</MenuItem>
            </Select>
          </FormControl>
          <Typography color="textSecondary">Minimum of citations</Typography>
          <FormControl sx={{ margin: '0px 0px 0px 0px', borderRadius: '10px', maxHeight: '35px' }}>
            <Select value={citationCountFilter} onChange={handleUpdateCitationFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'lightGrey', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'Grey'}, '& .MuiSvgIcon-root': {color: 'darkGrey'} }}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{marginTop: '-20px'}}>
          <Button onClick={handleClose} sx={{textTransform: 'none'}}>Close</Button>
        </DialogActions>
      </Dialog>*/}

      <DragAndDropFiles/>

      {/* <FormControl sx={{ margin: '0px 0px 0px 0px', borderRadius: '10px', maxHeight: '35px', color:'darkGrey' }}>
            <Select value={answerFilter} onChange={handleAnswerFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'darkGrey', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'transparent'}, '& .MuiSvgIcon-root': {color: 'darkGrey'} }}>
              <MenuItem value="direct">Direct</MenuItem>
              <MenuItem value="detailed">Detailed</MenuItem>
              <MenuItem value="literatureReview">Literature Review</MenuItem>
            </Select>
          </FormControl>
      
      <FormControl sx={{ margin: '0px 0px 0px 0px', borderRadius: '10px', maxHeight: '35px', color:'darkGrey' }}>
        <Select value={sourceFilter} onChange={handleSourceFilterChange} sx={{ borderRadius: '10px', maxHeight: '35px', color:'darkGrey', '& .MuiOutlinedInput-notchedOutline': {borderColor: '#121212'}, '& .MuiSvgIcon-root': {color: 'darkGrey'} }}>
          <MenuItem value="papers">Papers</MenuItem>
          <MenuItem value="web">Web</MenuItem>
        </Select>
      </FormControl> */}

    {/*<Button variant="outlined"  size='small' sx={{ textTransform: 'none', fontSize: '16px', color: 'darkGrey', borderRadius: '10px', backgroundColor: 'transparent', borderColor: 'transparent' }} onClick={handleClickOpen}>
      <AddBoxOutlinedIcon sx={{ fontSize: '20px', marginRight:'5px' }} /> Upload file
    </Button>*/}

    </div>
  );
};

export default Filters;
