import axios from 'axios'; 

const API = axios.create({ baseURL: 'https://alifai-backend-82f5a43dbb58.herokuapp.com'}); // or  http://localhost:5001

// in order to make the API works, function that happens for every request
// because we have to send our token back to our backend so that the backend middleware can verify that we are actually logged-in
// - Interceptors are code blocks that you can use to preprocess or post-process HTTP calls, helping with global error handling, authentication, logging, and more
// - Axios interceptors are functions that Axios calls for every request. You can use interceptors to transform the request before Axios sends it, or transform the response before Axios returns the response to your code.
API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`; // bearer token (predominant type of access token used with OAuth 2.0. A Bearer Token is an opaque string, not intended to have any meaning to clients using it)
    }

    return req; // return the actual request so that we can make all the future requests (listed below)
});

// sign in and sign up
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const signinGoogleUser = (result) => API.post('/user/signinGoogleUser', result);

// get one specific post (id) from the database
export const fetchPost = (id) => API.get(`/c/${id}`);

// get the posts from the database
export const fetchPosts = (userId, scope, listToGet) => API.get(`/c/history?user=${userId}&scope=${scope}&listToGet=${listToGet}`);

// search- get the posts by search from the database
export const fetchPostsBySearch = (searchQuery, page, metricFilter, formatFilter) => API.get(`/c/search?searchQuery=${searchQuery || 'none'}&page=${page}&metricFilter=${metricFilter}&formatFilter=${formatFilter}`); //search query starts with "?" and then you specify a variable

// get the posts by category from the database
export const fetchPostsByCategory = (category, page, metricFilter, formatFilter) => API.get(`/c/category?category=${category}&page=${page}&metricFilter=${metricFilter}&formatFilter=${formatFilter}`);

// get user list (posts saved)
export const getSavedPapers = (userId) => API.get(`/c/saved?user=${userId}`);

// get one specific profile (id) from the database
export const fetchTopContributors = () => API.get(`/c/topcontributors`);

// get one specific profile (id) from the database
export const fetchProfile = (id) => API.get(`/profile/${id}`);

// get one specific profile (id) from the database
export const editProfile = (id, updatedProfile) => API.patch(`/profile/editprofile?user=${id}`, updatedProfile);

// store a (whole) new post in the database
export const createPost = ( userQuery ) => API.post('/c', userQuery);

//update a post in the database
export const updatePost = (id, updatedPost) => API.patch(`/c/${id}`, updatedPost);

// delete a post in the database
export const deletePost = (id) => API.delete(`/c/${id}`);

// save a paper
export const savePaper = (id, paperId, list) => API.patch(`/c/${id}/savePaper`, { paperId, list });

// save a chat
export const saveChat = (id, chatId, list) => API.patch(`/c/${id}/saveChat`, { chatId, list });

// save a paper
export const createNewList = (id, newPaperList, paperId, chatId) => API.patch(`/c/${id}/createNewList`, { newPaperList, paperId, chatId });

// save a paper
export const deleteList = (id, listToDelete) => API.patch(`/c/${id}/deleteList`, { listToDelete });

//update a post in the database
export const addFileChatMessage = (id, query) => API.post(`/c/${id}/addFileChatMessage`, {query});

//update a post in the database
export const deleteFileChatMessage = (id, fileChatId) => API.patch(`/c/${id}/deleteFileChatMessage`, {fileChatId});

//update a post in the database
export const addFollowup = (id, queryFollowup, strictCitationModeFollowup, selectedPapers) => API.post(`/c/${id}/addFollowup`, {queryFollowup, strictCitationModeFollowup, selectedPapers});

//update a post in the database
export const deleteFollowup = (id, followupId) => API.patch(`/c/${id}/deleteFollowup`, {followupId});

//update a post in the database
export const createAbstractSummary = (where, id, paper, paperList) => API.post(`/c/${id}/createAbstractSummary`, {where, paper, paperList});

//update a post in the database
export const createRecommendedQuestions = (id) => API.post(`/c/${id}/createRecommendedQuestions`, {});

// like a post in the database
export const likePost = (id) => API.patch(`/c/${id}/likePost`);

// downvote a post in the database
export const downvotePost = (id) => API.patch(`/c/${id}/downvotePost`);

// comment a post
export const comment = (comment, id) => API.post(`/c/${id}/commentPost`, { comment });

// delete a post in the database
export const deleteComment = (id, commentId) => API.patch(`/c/${id}/deleteComment`, { commentId });

// like a comment in the database
export const likeComment = (id, commentId) => API.patch(`/c/${id}/likeComment`, { commentId });

// downvote a comment in the database
export const downvoteComment = (id, commentId) => API.patch(`/c/${id}/downvoteComment`, { commentId });

// add a post to user list in the database
export const addPostToUserList = (id) => API.patch(`/c/${id}/addPostToUserList`);