import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { Drawer, AppBar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Grid, Divider, Typography, Toolbar, Button, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import ViewSidebarRoundedIcon from '@mui/icons-material/ViewSidebarRounded';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SendIcon from '@mui/icons-material/Send';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import decode from 'jwt-decode';
import MailIcon from '@mui/icons-material/Mail';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

import Navbar from '../Navbar/Navbar';
import lifa_logo from '../../images/lifa_logo.png';

const drawerWidth = 180;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const userId = user?.result?._id;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const isUrlActive = (path) => {
    return location.pathname === path;
  };

  // for menulist on desktop (options: profile or logout)
  const [anchor, setAnchor] = useState(null);
  const openMenu = (event) => {
      setAnchor(event.currentTarget);
  };  
  const closeMenu = () => {
      setAnchor(null);
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/');
    history.go(0);
    //setAnchor(null);
    //setAnchorMobile(null);
    setUser(null);
  }

  useEffect(() => {
    const token = user?.token;
    if (token) {
        const decodedToken = decode(token);

        if (decodedToken.exp * 1000 < new Date().getTime()) logout(); // log out if token expired, i.e. if logging in > 1h
    }
    setUser(JSON.parse(localStorage.getItem('profile')))
}, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div sx={{zIndex: 9999}}>
      <Toolbar>
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }} onClick={handleDrawerToggle}>
          <Grid display="flex" flexDirection="row" alignItems="center">
            <img src={lifa_logo} alt="lifa" height="25px"/>
            <Typography variant="h6" marginLeft='11px' fontSize='22px'>
              Lifa
            </Typography>
          </Grid>
        </Link>
      </Toolbar>
      <Divider />
      <Grid>
      <List>
        <ListItem key='Home' disablePadding
        sx={isUrlActive('/') ? { '& .MuiListItemIcon-root': {margin: '0px -17px 0px 10px', color: 'black' } } : { '& .MuiListItemIcon-root': { margin: '0px -17px 0px 10px' } }}
        onClick={() => {history.push(`/`); handleDrawerToggle()}}
        >
          <ListItemButton>
            <ListItemIcon>
              <ArrowForwardIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography style={{ fontWeight: isUrlActive('/') ? 'bold' : 'normal' }}>Home</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem key='Library' disablePadding
        sx={isUrlActive('/c/library') ? { '& .MuiListItemIcon-root': { margin: '0px -17px 0px 10px', color: 'black' } } : { '& .MuiListItemIcon-root': { margin: '0px -17px 0px 10px' } }}
        onClick={() => {history.push(`/c/library`); handleDrawerToggle()}}
        >
          <ListItemButton>
            <ListItemIcon>
              <AppsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography style={{ fontWeight: isUrlActive('/c/library') ? 'bold' : 'normal' }}>Library</Typography>} />
          </ListItemButton>
        </ListItem>
        {/*<ListItem key='Papers' disablePadding sx={{ '& .MuiListItemIcon-root': {margin: '0px -17px 0px 10px' }}} onClick={() => {history.push(`/c/saved`); handleDrawerToggle()}}>
          <ListItemButton>
            <ListItemIcon>
              <BookmarkBorderOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Papers' />
          </ListItemButton>
        </ListItem>
        <ListItem key='Collection' disablePadding sx={{ '& .MuiListItemIcon-root': {margin: '0px -10px 0px 10px' }}} onClick={() => {history.push(`/profile/${userId}`); handleDrawerToggle()}}>
          <ListItemButton>
            <ListItemIcon>
              <LibraryBooksOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Collection' />
          </ListItemButton>
        </ListItem>
        <ListItem key='Profile' disablePadding sx={{ '& .MuiListItemIcon-root': {margin: '0px -10px 0px 10px' }}} onClick={() => {history.push(`/profile/${userId}`); handleDrawerToggle()}}>
          <ListItemButton>
            <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Profile' />
          </ListItemButton>
        </ListItem>*/}
      </List>
      {user && (
        <div>
          <Button onClick={openMenu} size="medium" sx={{color: 'Grey', borderRadius: '10px', minWidth: '50px', maxWidth: '50px', marginLeft: '13px', position: 'fixed', bottom: '95px', left: '10px'}}>
            <AccountCircleOutlinedIcon sx={{ fontSize: 28 }} /> <KeyboardArrowDownIcon sx={{ fontSize: 16 }} />
          </Button>
          <Menu sx={{zIndex: 10000}} open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
            <Typography> &nbsp;&nbsp;&nbsp;{user.result.email}&nbsp;&nbsp;&nbsp;</Typography>
            <MenuItem onClick={logout}> <LogoutOutlinedIcon sx={{ fontSize: 22 }} />&nbsp;Log out</MenuItem>
          </Menu>
        </div>
      )}
      
      <Grid sx={{ position: 'fixed', bottom: 20, left: 0, right: 0, width: '180px' }}>
        <Divider sx={{ width:'180px', marginBottom: '20px' }}/>
        <a href="mailto:support@lifa.ai" style={{color: 'Grey', textDecoration: 'none'}}><Typography color="Grey" sx={{ fontSize: '14px', marginLeft: '30px', minWidth: '160px', maxWidth: '160px' }}>
          <EmailOutlinedIcon sx={{fontSize: '20px', marginBottom: '-5px', marginRight: '2px'}}/> Contact us
        </Typography></a>
        <Typography color="Grey" sx={{ fontSize: '14px', marginTop: '2px', marginLeft: '30px', minWidth: '160px', maxWidth: '160px' }}>
          support@lifa.ai
        </Typography>
      </Grid>
      </Grid>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {(!user || isMobile) && (
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          //background: 'rgba(0, 9, 11, 0.9)', backdropFilter:"blur(5px)",
          background: 'rgba(254, 254, 254, 0.9)', backdropFilter:"blur(5px)",
          //width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          //borderBottom: '1px solid #dddddd', //0.01px
        }}
      >
        
        <Toolbar fullWidth sx={{ justifyContent: 'center' }}>
            <Grid l={user ? 12 : 11} xl={user ? 12 : 10} container direction="row" justifyContent="space-between">

                {/* Left Group */}
                <Grid item container xs justifyContent="flex-start" alignItems="center">
                    {user && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'none' } }}
                        >
                            <MenuIcon sx={{ color: 'Grey' }} />
                        </IconButton>
                    )}
                    {!user && (
                        <Link to="/" style={{ textDecoration: 'none', color: 'black' }} onClick={handleDrawerToggle}>
                        <Grid display="flex" flexDirection="row" alignItems="center">
                          <img src={lifa_logo} alt="lifa" height="30px"/>
                          <Typography variant="h6" marginLeft='11px' fontSize='26px'>Lifa</Typography>
                        </Grid>
                      </Link>
                    )}
                </Grid>

                {/* Right Group */}
                <Grid item container xs justifyContent="flex-end" alignItems="center">
                    {!user && (
                        <Button component={Link} to="/auth"
                            sx={{ backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px', }}
                            variant="outlined" size="small"
                        >Sign in</Button>
                    )}
                    {/*{user && isMobile && (
                        <Button component={Link} to="/"
                            sx={{ backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px', }}
                            variant="outlined" size="small"
                        ><ArrowForwardIcon sx={{ fontSize: '20px', marginRight: '3px', marginLeft: '-4px' }} />New</Button>
                    )}*/}
                </Grid>

            </Grid>
        </Toolbar>

        
      </AppBar>
      )}
      {user && (
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#f8f8f8' },
            zIndex: 9999
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#f8f8f8' }, //, border:'none'
            zIndex: 9999
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />

      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;