import React, { useState, useEffect, useRef } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

function PdfViewer({ fileUrl }) {
    const viewerRef = useRef(null);

    async function fetchPDFasBlob(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const pdfBlob = await response.blob();
            return pdfBlob;
        } catch (error) {
            console.error("There was a problem fetching the PDF:", error);
        }
    }

    useEffect(() => {
        fetchPDFasBlob(`https://cors-proxy-for-etree.herokuapp.com/${fileUrl}`)
        .then(pdfBlob => {
            if (viewerRef.current) {
                WebViewer({
                    licenseKey: 'ZtDXK5TaOGXMSvTKHe9H',
                    path: '/pdftron',
            }, viewerRef.current)
                .then(instance => {
                    instance.UI.loadDocument(pdfBlob, { filename: 'myfile.pdf' });
    
                    const { documentViewer } = instance.Core;
                    documentViewer.addEventListener('documentLoaded', () => {
                        instance.UI.disableElements(['panToolButton', 'selectToolButton']);
                        //instance.UI.setTheme('dark');
                    });
                });
            }
        });
        
    }, [fileUrl]);

    //useEffect(() => {
    //    if (viewerRef.current) {
    //        WebViewer({
    //            licenseKey: 'ZtDXK5TaOGXMSvTKHe9H',
    //            path: '/pdftron',
    //            initialDoc: `https://cors-proxy-for-etree.herokuapp.com/${fileUrl}`,
    //        }, viewerRef.current)
    //        .then(instance => {
    //            instance.UI.disableElements(['panToolButton', 'selectToolButton']);
    //            instance.UI.setTheme('dark');
    //        });
    //    }
    //}, [fileUrl]);
  
    return (

          <div id="viewer" ref={viewerRef} style={{ zIndex: 999, width: "100%", height: '90vh' }}></div>
    );
  };

export default PdfViewer;