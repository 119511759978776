import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const Terms = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    sx={{ marginTop: '-10px' }}>
        
        <Grid md={8} container display="flex" flexDirection="column" justifyContent="center" padding='0 5% 0 5%'>
            {/*<Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'32px', fontWeight: 'bold'} : { fontSize:'48px', fontWeight: 'bold' }} >Building the future of research.</Typography>*/}
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Terms of Service
            </Typography>

            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Last updated: July 29, 2023
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the https://www.lifa.ai website (the “Service”) operated by Lifa AI (“us”, “we”, or “our”).
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                By accessing or using the Service you agree to be bound by these Terms. Lifa AI reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Our Services
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                Lifa AI provides a research-based text generation service. Lifa AI is available via https://www.lifa.ai and associated internet domains.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Acceptable Use
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                You agree not to use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                You agree not to use the Service for unlawful, illegal, fraudulent or harmful activities, including but not limited to hate, harassment, violence, political manipulation, spam, or malware.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                You agree not to conduct any systematic or automated data collection activities (including scraping, data mining, data extraction and data harvesting) on or in relation to the Service without our express written consent. Prohibited data collection includes, but is not limited to, using the Service as input into other services, websites, or databases. You agree not to republish any content generated by the Service without clearly citing the Service as well as the context associated with the content. Misrepresentation of the source of the content or the nature of its creation is strictly prohibited.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                User-Generated Content
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                The Service may allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                When you upload, submit, store, send or receive content to or through the Service, you give us (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with the Service), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Service, and to develop new ones. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials (“Content”), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you, and not Lifa AI, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via the Service. Lifa AI does not control the Content posted via the Service and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using the Service, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will Lifa AI be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you may not rely on any Content created by the Service or submitted to the Service.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                You acknowledge and agree that Lifa AI may preserve Content and may also disclose Content if required to do so by law.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Intellectual Property Rights
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Lifa AI acknowledges and respects the intellectual property rights of all individuals and entities, and expects all users of the Service to do the same. As a user of the Service, you are granted access for your own personal, non-commercial use only. Any attempts to reverse engineer, copy, reproduce, or otherwise steal intellectual property from the Service will not be tolerated and may result in legal action.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                Lifa AI takes all allegations of copyright infringement seriously and will respond promptly to any notices that comply with applicable laws and are properly provided to us. If you believe that your work has been used in a manner that constitutes copyright infringement, please contact us by email at <a href="mailto:support@lifa.ai" style={{color: '#1c95aa', textDecoration: 'none'}}>support@lifa.ai</a> or on <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Twitter</a>.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Limitation of Liability
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                In no event shall Lifa AI, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Disclaimer
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                Lifa AI, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; nor d) the results of using the Service will meet your requirements.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Indemnification
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                You agree to defend, indemnify and hold harmless Lifa and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Changes
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                After modification, we will post the revised Terms and update the “Last updated” date above. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please cease using the Service.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Intellectual Property Rights
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                If you have any questions about these Terms, please contact us by email at <a href="mailto:support@lifa.ai" style={{color: '#1c95aa', textDecoration: 'none'}}>support@lifa.ai</a> or on <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Twitter</a>.
            </Typography>
        </Grid>

        <Grid>
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 5}
        sx={{ margin: '40px 0px 20px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/contact" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    Contact
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '30px 10px 30px 10px' : '30px 60px 30px 60px'}>        
        </Grid>

      </Grid>

    </Grid>
  );
};

export default Terms;