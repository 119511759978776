import { FETCH_POST, FETCH_ALL, FETCH_BY_SEARCH, FETCH_BY_CATEGORY, FETCH_BY_USER_LIST, FETCH_SAVED_PAPERS, CREATE, UPDATE, DELETE, FETCH_TOP_CONTRIBUTORS, START_LOADING, END_LOADING, COMMENT, START_LOADING_CHAT, END_LOADING_CHAT } from '../constants/actionTypes';
import * as api from '../api'; //* as api means everything as api

// Action creators (functions that return actions) with redux

// get a single post
export const getPost = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.fetchPost(id);

        dispatch({ type: FETCH_POST, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

// get the posts (for a specific page)
export const getPosts = (userId, scope, listToGet) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.fetchPosts(userId, scope, listToGet);

        dispatch({ type: FETCH_ALL, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}
// async dispatch, to not load all the data because a lot of time needed, in redux logic
// try: to fetch all the data from the api
//payload = data stored

// search- get posts by search
export const getPostsBySearch = (searchQuery, page, metricFilter, formatFilter) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.fetchPostsBySearch(searchQuery, page, metricFilter, formatFilter);
        dispatch({ type: FETCH_BY_SEARCH, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }  
}
// destructure the data 2 times: one because we make an axios request, one because we put it in a new object where it has the data property

// category- get posts by category
export const getPostsByCategory = (category, page, metricFilter, formatFilter) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.fetchPostsByCategory(category, page, metricFilter, formatFilter); // here, data: { data } or just data?
        dispatch({ type: FETCH_BY_CATEGORY, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }  
}

// store a new post - post api request to backend server
export const createPost = (userQuery, history) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.createPost(userQuery);

        history.push(`/c/${data._id}`)

        dispatch({ type: CREATE, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
        throw error;
    }
}

// update an existing post
export const updatePost = (id, post) => async (dispatch) => {
    try {
        const { data } = await api.updatePost(id, post);

        dispatch ({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

// add tags to an existing post
export const addFileChatMessage = (id, query) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_CHAT });
        const { data } = await api.addFileChatMessage(id, query);

        dispatch ({ type: COMMENT, payload: data });
        dispatch({ type: END_LOADING_CHAT });
        return data.fileChat;
    } catch (error) {
        console.log(error);
    }
}

// add tags to an existing post
export const deleteFileChatMessage = (id, fileChatId) => async (dispatch) => {
    try {
        const { data } = await api.deleteFileChatMessage(id, fileChatId);

        dispatch ({ type: COMMENT, payload: data });
        return data.fileChat;
    } catch (error) {
        console.log(error);
    }
}

// add tags to an existing post
export const addFollowup = (id, queryFollowup, strictCitationModeFollowup, selectedPapers) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_CHAT });
        const { data } = await api.addFollowup(id, queryFollowup, strictCitationModeFollowup, selectedPapers);

        dispatch ({ type: COMMENT, payload: data });
        dispatch({ type: END_LOADING_CHAT });
        return data.followups;
    } catch (error) {
        console.log(error);
    }
}

// add tags to an existing post
export const deleteFollowup = (id, followupId) => async (dispatch) => {
    try {
        const { data } = await api.deleteFollowup(id, followupId);

        dispatch ({ type: COMMENT, payload: data });
        return data.followups;
    } catch (error) {
        console.log(error);
    }
}

// create abstract summary
export const createAbstractSummary = (where, id, paper, paperList) => async (dispatch) => {
    try {
        if(where === 'inChatDetails'){
            const { data } = await api.createAbstractSummary(where, id, paper, paperList);
            dispatch ({ type: COMMENT, payload: data });
            return data.answerMaterial;
        };
        if(where === 'inSavedPapers'){
            const { data } = await api.createAbstractSummary(where, id, paper, paperList);
            dispatch ({ type: FETCH_SAVED_PAPERS, payload: data });
            return data;
        };
    } catch (error) {
        console.log(error);
    }
}

// create recommended questions
export const createRecommendedQuestions = (id) => async (dispatch) => {
    try {
        const { data } = await api.createRecommendedQuestions(id);
        dispatch ({ type: COMMENT, payload: data });
        return data.recommendedQuestions;
    } catch (error) {
        console.log(error);
    }
}

// delete an existing post
export const deletePost = (id, history, where) => async (dispatch) => {
    try {
        await api.deletePost(id); // no need for const { response } because no returned data
        
        if(where === 'chatPage'){
            history.push('/')
        };
        
        dispatch ({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error);
    }
}

// save a paper
export const savePaper = (id, paperId, list) => async (dispatch) => {
    try {
        const { data } = await api.savePaper(id, paperId, list);

        dispatch({ type: UPDATE, payload: data });
        //return data.comments;
    } catch (error) {
        console.log(error);
    }
};

// save a chat
export const saveChat = (id, chatId, list) => async (dispatch) => {
    try {
        const { data } = await api.saveChat(id, chatId, list);

        dispatch({ type: UPDATE, payload: data });
        //return data.comments;
    } catch (error) {
        console.log(error);
    }
};

// retrieve saved papers
export const getSavedPapers = (userId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.getSavedPapers(userId);

        dispatch({ type: FETCH_SAVED_PAPERS, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

// create new list
export const createNewList = (id, newListTitle, paperId, chatId) => async (dispatch) => {
    try {
        const { data } = await api.createNewList(id, newListTitle, paperId, chatId);

        if (data.message && data.message === 'List with this name already exists') {
            throw new Error(data.message);
        }

        dispatch({ type: FETCH_SAVED_PAPERS, payload: data });
        
        return data.data
    } catch (error) {
        console.log(error);
        throw error;
    }  
}

// delete list
export const deleteList = (id, listToDelete) => async (dispatch) => {
    try {
        const { data } = await api.deleteList(id, listToDelete);

        dispatch({ type: FETCH_SAVED_PAPERS, payload: data });
        
        return data.data
    } catch (error) {
        console.log(error);
    }  
}

// like an existing post
export const likePost = (id) => async (dispatch) => {
    try {
        const { data } = await api.likePost(id);

        dispatch ({ type: UPDATE, payload: data });       
    } catch (error) {
        console.log(error);
    }
}

export const downvotePost = (id) => async (dispatch) => {
    try {
        const { data } = await api.downvotePost(id);

        dispatch ({ type: UPDATE, payload: data });       
    } catch (error) {
        console.log(error);
    }
}

// comment a post
export const commentPost = (comment, id) => async (dispatch) => {
    try {
        const { data } = await api.comment(comment, id);

        dispatch({ type: COMMENT, payload: data });
        return data.comments;
    } catch (error) {
        console.log(error);
    }
};

// delete an existing comment
export const deleteComment = (id, commentId) => async (dispatch) => {
    try {
        const { data } = await api.deleteComment(id, commentId); // no need for const { response } because no returned data

        dispatch ({ type: UPDATE, payload: data });
        return data.comments;

    } catch (error) {
        console.log(error);
    }
}

// like an existing comment
export const likeComment = (id, commentId) => async (dispatch) => {
    try {
        const { data } = await api.likeComment(id, commentId);

        dispatch ({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

// downvote an existing comment
export const downvoteComment = (id, commentId) => async (dispatch) => {
    try {
        const { data } = await api.downvoteComment(id, commentId);

        dispatch ({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

// add an existing post to the user list
export const addPostToUserList = (id) => async (dispatch) => {
    try {
        const { data } = await api.addPostToUserList(id);

        dispatch ({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

// get the posts (for a specific page)
//export const getPostsByUserList = (userId) => async (dispatch) => {
//    try {
//        dispatch({ type: START_LOADING }); // here starts the loading
//        const { data } = await api.fetchPostsByUserList(userId);

//        dispatch({ type: FETCH_BY_USER_LIST, payload: data }); //send data to reducer
//        dispatch({ type: END_LOADING }); // here ends the loading
//    } catch (error) {
//        console.log(error);
//    }  
//}

// get the posts (for a specific page)
export const getTopContributors = () => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.fetchTopContributors();

        dispatch({ type: FETCH_TOP_CONTRIBUTORS, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}
// Asynchronous data is data that is not synchronized when it is sent or received. In this type of transmission, signals are sent between the computers and external systems or vice versa in an asynchronous manner.