import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar, Tabs, Tab, Box, Popover, TextField, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Skeleton, Drawer } from '@mui/material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getPosts, getSavedPapers, createNewList, deleteList, createPost, savePaper, createAbstractSummary } from '../../actions/posts';
import Posts from '../Posts/Posts';
import journalScore from '../journalScore';
import PdfViewer from '../ChatDetails/PdfViewer/PdfViewerTron';
import SavePaperInListPopover from '../ChatDetails/SaveInListPopover/SavePaperInListPopover';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './libraryStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
  tabsScope: `${PREFIX}-tabsScope`,
  tabs: `${PREFIX}-tabs`,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const UserList = () => {
    const { posts, papers, isLoading } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const [valueTabScope, setValueTabScope] = useState(0);
    const [historyToGet, setHistoryToGet] = useState("searches");
    const [paperList, setPaperList] = useState(null);
    const [chatList, setChatList] = useState(null);
    const [userLists, setUserLists] = useState(papers);
    const [showSnackbarCopyCitation, setShowSnackbarCopyCitation] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [openPaperLoading, setOpenPaperLoading] = useState(false);
    const [loadingAbstractSummary, setLoadingAbstractSummary] = useState(new Set());
    const [valueTab, setValueTab] = useState(0);
    const [listToGet, setListToGet] = useState(-1);
    const [newListTitle, setNewListTitle] = useState("");
    const [loadingCreateNewList, setLoadingCreateNewList] = useState(false)
    const [citeTooltipMessage, setCiteTooltipMessage] = useState('Cite in BibTex format');
    
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true
    });

    const isXlScreen = useMediaQuery('(min-width:1920px)');
    const drawerWidth = isXlScreen ? 600 : 400;
    const dialogWidth = isXlScreen ? 600 : 400;

    useEffect(() => {
      if (userId && historyToGet !== "papers") {
        dispatch(getPosts(userId, historyToGet, listToGet));
      }
    }, [userId, valueTabScope, valueTab]); //happens whenever the id of the user changes

    function handleTabScopeChange(event, newValueTabScope) {
      setValueTabScope(newValueTabScope);
      if (newValueTabScope === 0) {
        setHistoryToGet("searches");
      } else if (newValueTabScope === 1) {
        setHistoryToGet("files");
      } else if (newValueTabScope === 2) {
        setHistoryToGet("papers");
      }
    };

    useEffect(() => {
      if (userId) {
        //dispatch(getSavedPapers(userId, listToGet));
        dispatch(getSavedPapers(userId));
      }
    }, [userId]);

    useEffect(() => {
      // Check if papers are available and not empty
      if (papers && papers.length > 0) {
          if (listToGet === -1) {
              // Flatten all paper arrays into a single array
              const allPapers = papers.flatMap(list => list.items);
              // Remove duplicates based on paperId
              const uniquePapers = Array.from(new Set(allPapers.map(p => p.paperId)))
              .map(paperId => allPapers.find(p => p.paperId === paperId));

              setPaperList(uniquePapers);
          } else {
              setPaperList(papers[listToGet].items);
          }
          //if (historyToGet === "searches" | historyToGet === "files") {
          //  setPaperList(papers[0].items);
          //} else {
          //  setPaperList(papers[listToGet].items);
          //}
          
          setUserLists(papers);
      } else {
          // nothing
      }
    }, [papers, listToGet]);

    function handleTabChange(event, newValueTab) {
      setValueTab(newValueTab);
      setListToGet(newValueTab - 1);
    };

    // Delete collection menu
    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
      setAnchor(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchor(null);
    };
    const handleDelete = async () => {
      try {
        const listToDelete = listToGet;
        setValueTab(0);
        setListToGet(0);
        const newList = await dispatch(deleteList('nan', listToDelete));
        setUserLists(newList);
        setAnchor(null);
      } 
      catch (error) {

      }
    };

    // for create collection popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpenNewListPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseNewListPopover = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCreateNewList = async (e) => {
      //e.preventDefault();
      try {
        setLoadingCreateNewList(true);
        const newList = await dispatch(createNewList("nan", newListTitle));
        setUserLists(newList);
        setLoadingCreateNewList(false);
        setAnchorEl(null);
        setNewListTitle('');
      } catch (error) {
        setLoadingCreateNewList(false);
        if (error.message === 'List with this name already exists') {
          // Display the message in whichever way is appropriate for your UI.
          // This can be an alert, a notification component, etc.
          alert('A collection with this title already exists, please choose another title.');
      }
    }
    };

    const handleKeyPress = (e) => {
      if (newListTitle && e.key === 'Enter') { //keyCode 13 is just the enter key
        e.preventDefault();
        handleCreateNewList(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (newListTitle && loadingCreateNewList === false) ? setDisable(false) : setDisable(true);  // && loading === false
    }, [newListTitle]);

    const handleCite = async (bibtex) => {
      if (bibtex) {
        try {
          await navigator.clipboard.writeText(`${bibtex}`);
          setCiteTooltipMessage("BibTex citation copied!");
          //setShowSnackbarCopyCitation(true);
          setTimeout(() => {
            setCiteTooltipMessage("Cite in BibTex format");
            //setShowSnackbarCopyCitation(false); // Optionally hide the snackbar after 2 seconds as well
          }, 2000); // 2000ms = 2s
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      } else {
        // Handle the case when 'bibtex' is not available
        console.error('No citation available to copy');
      }
    };
    const handleCloseSnackbarCopyCitation = () => {
      setShowSnackbarCopyCitation(false);
    };
    
    const openDialog = (material) => {
      setSelectedMaterial(material);
      setDialogOpen(true);
    };
    
    const closeDialog = () => {
      setDialogOpen(false);
    };

    // send pdf link to backend
    const handleSendPaperUrl = async (title, link, paperInfo) => {
        setOpenPaperLoading(true);
        dispatch(
        createPost({
            type: 'fileViaLink',
            fileName: title,
            fileLink: link,
            filePaperInfo: paperInfo
        }, history)
        );
        //setLoading(true)
    };

    const handleAbsractSummarize = async (material) => {
      // set loading for concerned material
      setLoadingAbstractSummary(prev => new Set([...prev, material.paperId]));
      
      const newPaperList = await dispatch(createAbstractSummary('inSavedPapers', 'no', material.paperId, paperList));
      setPaperList(newPaperList)
  
      // after the loading is complete
      setLoadingAbstractSummary(prev => {
        const updatedSet = new Set(prev);
        updatedSet.delete(material.paperId);
        return updatedSet;
      });
    };

    const HistoryTabLabel = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <HistoryIcon sx={{ mr: 1, fontSize: '22px' }} />
        <span>History</span>
      </Box>
    );

    const AllPapersTabLabel = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AllInclusiveOutlinedIcon sx={{ mr: 1, fontSize: '22px' }} />
        <span>All</span>
      </Box>
    );

    if(!user?.result?.email) {
      return (
        <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" align="center" marginBottom="10px">
              Sign in to see your research library.
          </Typography>
          <Button component={Link} to="/auth"
            sx={{ backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px', }}
            variant="outlined" size="small"
          >Sign in</Button>
        </Grid>
      );
    };

    if (!posts) {
      return (
        <CircularProgress/>
      );
    };

    if (openPaperLoading) {
      return (
        <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" marginBottom="5px">Downloading the paper... It can take up to 30 seconds for large files.</Typography>
          <CircularProgress/>
        </Grid>
      );
    };

    return (
      <StyledGrow>
          <Grid className={classes.gridContainer} container justifyContent="center" alignItems="stretch" spacing={5} sx={isMobile ? { padding: '0px 16px 0px 16px' } : {}} >
            
            <Grid item xs={12} sm={11} md={11} xl={11} sx={{marginTop: '-10px', marginBottom: '-30px'}}>
              <Typography className={classes.profilePostsTitle} variant="h5" elevation={1} sx={isMobile ? { fontWeight: 'bold' } : { fontWeight: 'bold', marginLeft: '-6px' }} >
                <AppsOutlinedIcon sx={{ fontSize: '29px', margin:'0px 0px -6.5px 0px' }}/> Library
              </Typography>
            </Grid>

            {isMobile && (
            <Grid item xs={12} sm={12} md={2.25} xl={2.05} sx={{borderLeft: '1px solid #dddddd', marginBottom: '-10px'}}
            >
              <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'-3px 0px 5px 0px' }}>
                  <BookmarkBorderOutlinedIcon sx={{ fontSize: '24px', margin:'0px -1px -5.5px 0px' }}/> Collections
                </Typography>
                <Grid>
                <Button sx={{textTransform: 'none', fontSize: '16px', maxHeight: '40px', minHeight: '40px', margin: '-5px 0px 0px 0px'}}
                onClick={handleOpenNewListPopover}>
                  <AddOutlinedIcon/>&nbsp;New collection&nbsp;
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseNewListPopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  elevation={0}
                  sx={{ '& .MuiPopover-paper': { borderRadius: '10px', border: '1px solid #dddddd', padding: '12px 15px 16px 15px'} }}
                >
                  <Grid container display="flex" flexDirection="column" alignItems="center" sx={{padding: '5px 10px 5px 10px'}}>
                  <Typography variant="h6" sx={{fontSize: '20px', marginBottom: '15px'}}>
                    Create a new collection
                  </Typography>
                  <TextField
                    size="large" label={newListTitle ? '' : 'Title'} InputLabelProps={{ shrink: false }}
                    value={newListTitle} multiline onChange={(e) => setNewListTitle(e.target.value)} inputProps={{maxLength: 30}}
                    sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#282828',
                    borderRadius:'10px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                      //backgroundColor: '#0e1619',
                      borderRadius: '10px',
                    },}}
                    onKeyPress={handleKeyPress}
                    >
                  </TextField>
                  <Button variant="contained" sx={{color: 'white', textTransform: 'none', fontSize: '16px', maxWidth: '80px', minWidth: '80px', margin: '20px 0px 0px 0px', borderRadius: '10px'}}
                  //onClick={handleOpenNewListPopover}
                  onClick={handleCreateNewList} disabled={disable}
                  >
                    Create
                  </Button>
                  </Grid>
                </Popover>
                </Grid>
              </Grid>
              {/*<Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                Collections
              </Typography>*/}
              <Grid container display="flex" flexDirection="column"
              sx={{//border: '1.5px solid #c8e4eb', //border: '1.5px solid #00242a',
              borderRadius: '10px', justifyContent: 'center'}}>
                <Divider sx={{marginTop: '10px'}}/>
                <Tabs value={valueTab} onChange={handleTabChange} orientation="horizontal" variant="scrollable"
                TabIndicatorProps={{style: {color: '#ffffff', background:'#1c95aa'}}}
                sx={{"& button.Mui-selected": {//background: "#011118",
                color: '#1c95aa'}, "& .MuiTab-root": {color: "#9f9f9f"}, "& .MuiTabs-indicator": { margin: '0px 0px 0px 0px' }}} >
                    {(valueTabScope !== 2) && (
                      <Tab key={'history'} className={classes.tabs} label={HistoryTabLabel} {...a11yProps('history')}
                      />
                    )}
                    {(valueTabScope === 2) && (
                      <Tab key={'all'} className={classes.tabs} label={AllPapersTabLabel} {...a11yProps('all')}
                      />
                    )}
                    {userLists?.map((list, i) => (
                      <Tab key={i} className={classes.tabs} label={list.title} {...a11yProps(0)}
                      />
                    ))}
                </Tabs>
                <Divider/>
              </Grid>
            </Grid>
            )}
          
          <Grid item xs={12} sm={7} md={7} xl={7}>
            <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              {/*<Typography variant="h6" component="h2" sx={{ fontSize: '18px', color: 'darkGrey', fontWeight: 'semi-bold', margin:'0px 0px 10px 0px' }}>
                <ChatOutlinedIcon fontSize="small" sx={{ margin:'0px 0px -4px 0px' }}/> Chats
              </Typography>*/}

              <Grid item xs={true} style={{ flexGrow: 1 }}>
                <Tabs value={valueTabScope} onChange={handleTabScopeChange} orientation={"horizontal"} variant="scrollable"
                TabIndicatorProps={{style: {color: '#ffffff', background:'#1c95aa'}}}
                style={{margin:'-5px 0px 20px 5px', maxHeight: '40px', minHeight: '40px'}}
                sx={{"& button.Mui-selected": {//background: "#011118",
                color: '#1c95aa'}, "& .MuiTab-root": {color: "#9f9f9f"}}} >
                  <Tab className={classes.tabsScope} label={
                    <Box display="flex" alignItems="center">
                      <SearchIcon fontSize="medium" style={{ marginRight: 4 }} />
                      Searches
                    </Box>
                  }
                  />
                  <Tab className={classes.tabsScope} label={
                    <Box display="flex" alignItems="center">
                      <ArticleOutlinedIcon fontSize="medium" style={{ marginRight: 8 }} />
                      PDF files
                    </Box>
                  }
                  />

                  <Tab className={classes.tabsScope} label={
                    <Box display="flex" alignItems="center">
                      <LibraryBooksOutlinedIcon fontSize="medium" style={{ marginRight: 8 }} />
                      Papers
                    </Box>
                  }
                  />
                </Tabs>
              </Grid>
              {(valueTab !== 0) && (
                <Grid>
                  <Button style={{color: 'grey', borderRadius: '20px', height: '33px', minWidth: '45px', maxWidth: '45px', marginTop: '-20px'}} size="small"
                  onClick={openMenu}>
                    <MoreHorizIcon fontSize="medium" />
                  </Button>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    <div>
                      <MenuItem onClick={handleDelete} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}>
                        <DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete this collection
                      </MenuItem>
                    </div>
                  </Menu>
                </Grid>
              )}
              </Grid>

            {(valueTabScope !== 2) && (
              <Posts posts={posts} isLoading={isLoading} papers={papers} scope={historyToGet} />
            )}

            {(valueTabScope === 2 && paperList.length === 0) && (
              <Typography>No papers have been added to this collection.</Typography>
            )}

            {(valueTabScope === 2) && (
            <Grid>
              <div>
                {paperList?.map((material, i) => (
                  <Grid key={material.paperId}>
                    <Divider sx={{margin: '0px 15px 0px 15px'}}/>
                    <Grid container display='flex' flexDirection='row' sx={{marginBottom: '0px', border: '0px solid #dddddd', borderRadius: '10px'}} >
                    <Grid item md={5.95} display='flex' flexDirection='column' justifyContent="center" sx={{borderRight: '0px solid #dddddd', padding: '20px'}}>
                    <Grid onClick={() => openDialog(material)} sx={{cursor: 'pointer', borderRadius: '10px', '&:hover': { '& .hover-color': { color: '#1c95aa' } }}}>
                      <Typography sx={{ fontWeight: 'bold', textAlign: 'justify' }}
                      className="hover-color">
                        {material.title}
                      </Typography>
                      {(material.venue.length > 0) && (material.authors.length > 0) && (
                      <Typography color="textSecondary">
                      {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} &nbsp;{material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                      </Typography>
                      )}
                      {(material.venue.length > 0) && (material.authors.length == 0) && (
                      <Typography color="textSecondary">
                      {material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                      </Typography>
                      )}
                      {(material.venue.length == 0) && (material.authors.length > 0) && (
                      <Typography color="textSecondary">
                      {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                      </Typography>
                      )}
                      {(material.venue.length == 0) && (material.authors.length == 0) && (
                      <Typography color="textSecondary">
                      {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                      </Typography>
                      )}
                    </Grid>
                      <Grid display="flex" flexDirection="row" alignItems="flex-start">
                        <Typography sx={{ fontSize: '15.5px', borderRadius: '10px'}}>
                        <Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${material.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip>
                          {material.openAccessPdf && (
                            <span> &nbsp; <Tooltip title={"Open paper PDF page"} arrow><a href={material.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></span>
                          )}
                          {/*{material.externalIds.ArXiv && (
                            <span> &nbsp; <a href={`https://arxiv.org/pdf/${material.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></span>
                            )}*/}
                        </Typography>
                        {material?.citationStyles?.bibtex && (
                        <div>
                          <Tooltip title={citeTooltipMessage} arrow>
                          <Button size="small"
                          sx={{ textTransform: 'none', paddingTop: '2.5px', marginLeft: '11px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '15.5px' }}
                          onClick={() => handleCite(material?.citationStyles?.bibtex)}>
                            <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                          </Button>
                          </Tooltip>
                          <Snackbar
                                open={showSnackbarCopyCitation}
                                autoHideDuration={2000}
                                onClose={handleCloseSnackbarCopyCitation}
                                message="BibTex citation copied to clipboard"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          />
                        </div>
                        )}
                        <SavePaperInListPopover papers={papers} material={material}/>
                      </Grid>
                    </Grid>

                    <Grid item md={0.1} display="flex" alignItems="center" justifyContent="center">
                      <Divider orientation={isMobile ? "horizontal" : "vertical"}/>
                    </Grid>

                    <Grid item md={5.95} display="flex" alignItems="center" justifyContent="center"
                    sx={{padding: '20px'}}>
                      {!material?.abstractSummary && !loadingAbstractSummary.has(material.paperId) && (
                        <Tooltip title={"Summarize paper's abstract"} arrow><Button onClick={() => handleAbsractSummarize(material)} sx={{textTransform: 'none', borderRadius: '10px', maxHeight: '40px'}}>Summarize</Button></Tooltip>
                      )}
                      {loadingAbstractSummary.has(material.paperId) && (
                        <Grid container direction="column">
                          <Grid item>
                              <Skeleton width="100%" />
                          </Grid>
                          <Grid item>
                              <Skeleton width="100%" />
                          </Grid>
                          <Grid item>
                              <Skeleton width="100%" />
                          </Grid>
                        </Grid>
                      )}
                      {material?.abstractSummary && (
                        <Typography color="textSecondary" sx={{ textAlign: 'justify' }}>
                          {material?.abstractSummary}
                        </Typography>
                      )}
                    </Grid>
                    </Grid>
                  </Grid>
                ))}
            </div>
            <Dialog disableAutoFocus
            BackdropProps={{ style: { backgroundColor: 'transparent' } }} PaperProps={{ elevation: 1 }}
            sx={isMobile ? { '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, margin: '0px', maxWidth: '100%', maxHeight: '95%'} } : { position: 'initial', '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, right: 0, margin: '0px', maxWidth: dialogWidth, maxHeight: '60%'} }}
            open={dialogOpen} onClose={closeDialog}>
              <DialogActions sx={{margin: '-3px -3px -35px 0px'}}>
                <Button onClick={closeDialog} sx={{color: 'Grey', textTransform: 'none', borderRadius: '10px', minWidth: '35px', maxWidth: '35px'}}><CloseIcon/></Button>
              </DialogActions>              {selectedMaterial && (
                <DialogTitle variant="h6" sx={{marginBottom: '-10px'}}>{selectedMaterial.title}</DialogTitle>
              )}
              <DialogContent>
                {selectedMaterial && (
                  <div>
                    <Typography color="textSecondary" variant="body1">{selectedMaterial.authors?.slice(0, 3).map(author => author.name).join(', ')} {selectedMaterial.authors.length < 3 ? "" : "et al."}</Typography>
                    {selectedMaterial.venue.length > 0 ? (
                      <Typography color="textSecondary" variant="body1">{journalScore[selectedMaterial.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''}
                      {selectedMaterial.venue}, {selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    ) : (
                      <Typography color="textSecondary" variant="body1">{selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    )}
                    <Grid container display="flex" flexDirection="row" alignItems="flex-start">
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"Open paper PDF page"} arrow><a href={selectedMaterial.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {/*{selectedMaterial.externalIds.ArXiv && (
                        <Typography variant="body1"><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Typography>
                      )}*/}
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <Tooltip title={citeTooltipMessage} arrow>
                          <Button size="small"
                          sx={{ textTransform: 'none', marginTop: '4.5px', marginLeft: '0px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '14.5px' }}
                          onClick={() => handleCite(selectedMaterial?.citationStyles?.bibtex)}>
                            <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                          </Button>
                          </Tooltip>
                      )}
                          <Snackbar
                                open={showSnackbarCopyCitation}
                                autoHideDuration={2000}
                                onClose={handleCloseSnackbarCopyCitation}
                                message="BibTex citation copied to clipboard"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          />
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <SavePaperInListPopover papers={papers} material={selectedMaterial}/>
                      )}
                    </Grid>
                    {selectedMaterial.openAccessPdf && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, selectedMaterial.openAccessPdf.url, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, `https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}*/}

                    <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>Abstract</Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'justify'}}>{selectedMaterial.abstract}</Typography>

                    {selectedMaterial.openAccessPdf && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "10px 0 0 0" : "10px 0px 0px 0px"}>
                        <PdfViewer fileUrl={selectedMaterial.openAccessPdf.url}/>
                      </Grid>
                    </Grid>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "20px 0 0 0" : "20px 10% 0px 10%"}>
                        <PdfViewer fileUrl={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`}/>
                      </Grid>
                    </Grid>
                    )}*/}
                  </div>
                )}
              </DialogContent>
            </Dialog>
          </Grid>
          )}
          </Grid>

          {!isMobile && (
          <Drawer
            sx={{
              zIndex: 0,
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                background: '#f8f8f8',
              },
            }}
            variant="permanent"
            anchor="right"
          >
            <Grid container>
              <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'0px 0px 20px 0px', padding: '65px 20px 0px 25px' }}>
                <BookmarkBorderOutlinedIcon sx={{ fontSize: '24px', margin:'0px -1px -5.5px 0px' }}/> Collections
              </Typography>
            </Grid>
            {/*<Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              Collections
            </Typography>*/}
            <Grid container display="flex" flexDirection="column"
            width="100%" sx={{//border: '1.5px solid #c8e4eb', //border: '1.5px solid #00242a',
            borderRadius: '10px', justifyContent: 'center'}}>
              <Tabs value={valueTab} onChange={handleTabChange} orientation="vertical"
              TabIndicatorProps={{style: {color: '#ffffff', background:'#1c95aa'}}}
              sx={{padding: 0, width: '100%', "& button.Mui-selected": {//background: "#011118",
               color: '#1c95aa'}, "& .MuiTab-root": {color: "#9f9f9f"}, "& .MuiTabs-indicator": { right: 'auto', paddingTop: '10px' }}} >
                  {(valueTabScope !== 2) && (
                    <Tab key={'history'} className={classes.tabs} label={HistoryTabLabel} {...a11yProps('history')}
                    />
                  )}
                  {(valueTabScope === 2) && (
                    <Tab key={'all'} className={classes.tabs} label={AllPapersTabLabel} {...a11yProps('all')}
                    />
                  )}
                  {userLists?.map((list, i) => (
                    <Tab key={i} className={classes.tabs} label={list.title} {...a11yProps(0)}
                    />
                  ))}
              </Tabs>
              <Divider/>
              <Button sx={{textTransform: 'none', fontSize: '16px', maxHeight: '40px', minHeight: '40px', margin: '8px 0px 10px 0px'}}
              onClick={handleOpenNewListPopover}>
                <AddOutlinedIcon/>&nbsp;New collection&nbsp;
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseNewListPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                elevation={0}
                sx={{ '& .MuiPopover-paper': { borderRadius: '10px', border: '1px solid #dddddd', padding: '12px 15px 16px 15px'} }}
              >
                <Grid container display="flex" flexDirection="column" alignItems="center" sx={{padding: '5px 10px 5px 10px'}}>
                <Typography variant="h6" sx={{fontSize: '20px', marginBottom: '15px'}}>
                  Create a new collection
                </Typography>
                <TextField
                  size="large" label={newListTitle ? '' : 'Title'} InputLabelProps={{ shrink: false }}
                  value={newListTitle} multiline onChange={(e) => setNewListTitle(e.target.value)} inputProps={{maxLength: 30}}
                  sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#282828',
                  borderRadius:'10px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                    //backgroundColor: '#0e1619',
                    borderRadius: '10px',
                  },}}
                  onKeyPress={handleKeyPress}
                  >
                </TextField>
                <Button variant="contained" sx={{color: 'white', textTransform: 'none', fontSize: '16px', maxWidth: '80px', minWidth: '80px', margin: '20px 0px 0px 0px', borderRadius: '10px'}}
                //onClick={handleOpenNewListPopover}
                onClick={handleCreateNewList} disabled={disable}
                >
                  Create
                </Button>
                </Grid>
              </Popover>
            </Grid>
          </Drawer>
          )}

          </Grid>
      </StyledGrow>
    );

};

export default UserList;