import React, { useState, useEffect } from "react";
import { Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';

import Pricing from "../Pricing/Pricing";
import SearchHomebar from '../SearchHomeBar/SearchHomebar';
import DragAndDropFiles from "../DragAndDropFile/DragAndDropFiles";
import LandingIntro from "../LandingIntro/LandingIntro";

import { dict } from './homeConstants';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from "./homeStyles";

const theme = createTheme();
const PREFIX = 'Home';
const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    inputField: `${PREFIX}-inputField`,
    searchButton: `${PREFIX}-searchButton`,
    categoryTitle: `${PREFIX}-categoryTitle`,
    pageTitle: `${PREFIX}-pageTitle`,
    allPostsTitle: `${PREFIX}-allPostsTitle`,
    categoryDescription: `${PREFIX}-categoryDescription`,
    image: `${PREFIX}-image`,
};

// set up url search params to know on which page are we currently on and what search are we looking for
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Home = () => {
    const[setCurrentId] = useState(null); //const[currentId, setCurrentId] = useState(null);
    const dispatch = useDispatch();
    const query = useQuery(); // where we'll be getting our page info from
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });
    
    // for menulist (options: profile or logout)
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };
        
    const closeMenu = () => {
        setAnchor(null);
    };

    const chooseFrench = () => {
        setLang("fr");
        setAnchor(null);
    }

    const chooseEnglish = () => {
        setLang("en");
        setAnchor(null);
    }

    return (
        <StyledGrow>
            <Grid>

                {/* <ExplorePage/> */}

                <Grid>

                {/* <Divider sx={isMobile ? {margin:"20px 0px 40px 0px"} : {margin:"20px 120px 40px 120px"}}/> */}
                
                {user && (
                    <Grid>
                        <SearchHomebar/>
                    </Grid>
                )}

                {!user && (
                <Grid container display="flex" justifyContent="center" alignItems="center">
                    <LandingIntro/>
                </Grid>
                )}
                {/* <Typography variant="h6" textAlign="center" sx={{ fontSize: '16px', color: '#404040', margin: '2px 0px 30px 0px' }} > or </Typography> */}

                {/* <Grid container justifyContent="center" flexDirection="row">
                    <DragAndDropFiles/>
                </Grid> */}
                {/* 
                
                <Grid container justifyContent="center" alignItems="center">
                    <Grid maxWidth="sm">
                        <Typography variant="h5" sx={{ fontWeight:'bold', color:'black', textAlign: 'center', marginBottom: '10px' }}>Our mission</Typography>
                        <Typography sx={{ fontSize: 20, color:'black', textAlign: 'center' }}>We believe that all human beings can greatly evolve in life, especially when they receive the right support and advice, and that they should all be able to benefit from the power of artificial intelligence for this purpose.</Typography>
                    </Grid>
                </Grid>

                <Divider sx={isMobile ? {margin:"40px 0px 40px 0px"} : {margin:"40px 200px 40px 200px"}}/>

                <Pricing/>

                <Divider sx={isMobile ? {margin:"50px 0px 40px 0px"} : {margin:"50px 200px 40px 200px"}}/>

                <Grid container justifyContent="center" alignItems="center" marginBottom="20px">
                    <Grid maxWidth="sm">
                        <Typography variant="h5" sx={{ fontWeight:'bold', color:'black', textAlign: 'center', marginBottom: '10px' }}>What our users say</Typography>
                        <Typography sx={{ fontSize: 18, fontWeight:'bold', color:'#3c3c3c', textAlign: 'center' }}>Sylvia, economics student</Typography>
                        <Typography sx={{ fontSize: 18, color:'#606060', textAlign: 'center' }}>"Lifa is amazing, I use it to get advice when I need it, to plan my studies, to get explanations of concepts and lots of other things. And, compared to other AIs, it's pretty cheap."</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid maxWidth="sm">
                        <Typography sx={{ fontSize: 18, fontWeight:'bold', color:'#3c3c3c', textAlign: 'center' }}>Daniel, psychologist</Typography>
                        <Typography sx={{ fontSize: 18, color:'#606060', textAlign: 'center' }}>"As a psychologist, I was skeptical about the possibility of AI advising humans on personal matters. I've tried Lifa, and I have to admit that I'm surprised by the relevance of the advice given."</Typography>
                    </Grid>
                </Grid>

                <Divider sx={isMobile ? {margin:"50px 0px 40px 0px"} : {margin:"50px 200px 40px 200px"}}/>

                <Grid container justifyContent="center" alignItems="center">
                    <Grid maxWidth="sm" container display="flex" justifyContent="space-between" flexDirection="row" marginBottom="40px">
                        <Typography sx={{ fontSize: 16, color:'darkGrey', }}>About</Typography>
                        <Typography sx={{ fontSize: 16, color:'darkGrey', }}>Contact</Typography>
                        <Typography sx={{ fontSize: 16, color:'darkGrey', }}>Privacy policy</Typography>
                        <Typography sx={{ fontSize: 16, color:'darkGrey', }}>Terms of use</Typography>
                    </Grid>
                </Grid>

            */}

                
                {/* <Grid container justifyContent="center" margin="-10px 0 10px 0">
                    <Button
                    sx={{ color: '#b0b0b0', borderColor: '#1c1c1c' }}
                    onClick={openMenu} size="medium">
                        <ArrowDropDownIcon fontSize="medium" />
                        <LanguageIcon fontSize="medium" />
                    </Button>
                    <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted MenuListProps={{ sx: {color:'black', backgroundColor: "#fafafa" }}} elevation={1}>
                        <MenuItem onClick={() => chooseEnglish()} sx={{"&:hover": {backgroundColor: "#eeeeee"}, fontSize:16, fontWeight:16}} > English </MenuItem>
                        <MenuItem onClick={() => chooseFrench()} sx={{"&:hover": {backgroundColor: "#eeeeee"}, fontSize:16, fontWeight:16}} > Français </MenuItem>
                    </Menu>
                </Grid> */}

            </Grid>
            </Grid>
            
    </StyledGrow>
    );
};

// component={Link} to="/" to point to home
// elevation property for nice shadow
// TextField is our search here

export default Home;