import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { Typography, CircularProgress, Divider, Button, Menu, MenuItem, Grid, CardActions, Card, Accordion, AccordionSummary, AccordionDetails, Snackbar, TextField, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, Box, Tooltip, Skeleton, Paper, Checkbox } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, useHistory, Link } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import useMediaQuery from '@mui/material/useMediaQuery';
import parse from 'html-react-parser';

import { getPost, updatePost, createPost, addFileChatMessage, addFollowup, deleteFollowup, createAbstractSummary, createRecommendedQuestions, deletePost, likePost, downvotePost, savePaper, getSavedPapers } from '../../../actions/posts';

import SavePaperInListPopover from '.././SaveInListPopover/SavePaperInListPopover';
import PdfViewer from '.././PdfViewer/PdfViewerTron';
import journalScore from '../../journalScore';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
const PREFIX = 'PostDetails';

const PapersSection = ({papers, materialsFollowup, handleSendPaperUrl}) => {
    const [citeTooltipMessage, setCiteTooltipMessage] = useState('Cite in BibTex format');

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const isXlScreen = useMediaQuery('(min-width:1920px)');
    const dialogWidth = isXlScreen ? 600 : 400;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    const [showSnackbarCopyCitation, setShowSnackbarCopyCitation] = useState(false);

    const handleCite = async (bibtex) => {
      if (bibtex) {
        try {
          await navigator.clipboard.writeText(`${bibtex}`);
          setCiteTooltipMessage("BibTex citation copied!");
          //setShowSnackbarCopyCitation(true);
          setTimeout(() => {
            setCiteTooltipMessage("Cite in BibTex format");
            //setShowSnackbarCopyCitation(false); // Optionally hide the snackbar after 2 seconds as well
          }, 2000); // 2000ms = 2s
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      } else {
        // Handle the case when 'bibtex' is not available
        console.error('No citation available to copy');
      }
    };
    const handleCloseSnackbarCopyCitation = () => {
      setShowSnackbarCopyCitation(false);
    };

    const openDialog = (material) => {
      setSelectedMaterial(material);
      setDialogOpen(true);
    };
    
    const closeDialog = () => {
      setDialogOpen(false);
    };

    return (
        <Grid>
        {materialsFollowup.map((material) => (
        <div key={material.paperId}>
        <Divider sx={{margin: '0px 15px 0px 15px'}}/>
          <Grid container display='flex' alignItems="flex-start" flexDirection='column' sx={{marginBottom: '0px', border: '0px solid #dddddd', borderRadius: '10px'}} >
            <Grid display='flex' flexDirection='row' justifyContent="center" alignItems="flex-start">
            <Grid item display='flex' flexDirection='column' justifyContent="center" sx={{borderRight: '0px solid #dddddd', padding: '20px'}}>
            <Grid onClick={() => openDialog(material)} sx={{cursor: 'pointer', borderRadius: '10px', '&:hover': { '& .hover-color': { color: '#1c95aa' } }}}>
              <Typography sx={{ fontWeight: 'bold', textAlign: 'justify' }}
              className="hover-color">
                {material.title}
              </Typography>
              {(material.venue.length > 0) && (material.authors.length > 0) && (
              <Typography color="textSecondary">
              {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} &nbsp;{material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
              </Typography>
              )}
              {(material.venue.length > 0) && (material.authors.length == 0) && (
              <Typography color="textSecondary">
              {material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
              </Typography>
              )}
              {(material.venue.length == 0) && (material.authors.length > 0) && (
              <Typography color="textSecondary">
              {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
              </Typography>
              )}
              {(material.venue.length == 0) && (material.authors.length == 0) && (
              <Typography color="textSecondary">
              {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
              </Typography>
              )}
              {/*{(material?.journal?.name) && (
              <Typography color="textSecondary" sx={{ fontSize: '14.5px' }}>
              {material?.journal?.name}
              </Typography>
              )}*/}
            </Grid>
              <Grid display="flex" flexDirection="row">
                <Typography sx={{ fontSize: '15.5px', borderRadius: '10px'}}>
                <Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${material.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip>
                  {material.openAccessPdf && (
                    <span> &nbsp; <Tooltip title={"Open paper PDF page"} arrow><a href={material.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></span>
                  )}
                  {/*{material.externalIds.ArXiv && (
                    <span> &nbsp; <a href={`https://arxiv.org/pdf/${material.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></span>
                    )}*/}
                </Typography>
                {material?.citationStyles?.bibtex && (
                <div>
                  <Tooltip title={citeTooltipMessage} arrow>
                  <Button size="small"
                  sx={{ textTransform: 'none', paddingTop: '2.5px', marginLeft: '11px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '15.5px' }}
                  onClick={() => handleCite(material?.citationStyles?.bibtex)}>
                    <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                  </Button>
                  </Tooltip>
                  <Snackbar
                        open={showSnackbarCopyCitation}
                        autoHideDuration={2000}
                        onClose={handleCloseSnackbarCopyCitation}
                        message="BibTex citation copied to clipboard"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  />
                </div>
                )}
              <SavePaperInListPopover papers={papers} material={material}/>
              </Grid>
            
            </Grid>
          </Grid>
        </Grid>
        <Grid>
        <Dialog disableAutoFocus
            BackdropProps={{ style: { backgroundColor: 'transparent' } }} PaperProps={{ elevation: 1 }}
            sx={isMobile ? { '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, margin: '0px', maxWidth: '100%', maxHeight: '95%'} } : { position: 'initial', '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, right: 0, margin: '0px', maxWidth: dialogWidth, maxHeight: '60%'} }}
            open={dialogOpen} onClose={closeDialog}>
              <DialogActions sx={{margin: '-3px -3px -35px 0px'}}>
                <Button onClick={closeDialog} sx={{color: 'Grey', textTransform: 'none', borderRadius: '10px', minWidth: '35px', maxWidth: '35px'}}><CloseIcon/></Button>
              </DialogActions>
              {selectedMaterial && (
                <DialogTitle variant="h6" sx={{marginBottom: '-10px', fontWeight: 'bold'}}>{selectedMaterial.title}</DialogTitle>
              )}
              <DialogContent>
                {selectedMaterial && (
                  <div>
                    <Typography color="textSecondary" variant="body1">{selectedMaterial.authors?.slice(0, 3).map(author => author.name).join(', ')} {selectedMaterial.authors.length < 3 ? "" : "et al."}</Typography>
                    {selectedMaterial.venue.length > 0 ? (
                      <Typography color="textSecondary" variant="body1">{journalScore[selectedMaterial.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''}
                      {selectedMaterial.venue}, {selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    ) : (
                      <Typography color="textSecondary" variant="body1">{selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    )}
                    <Grid container display="flex" flexDirection="row" alignItems="flex-start">
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"Open paper PDF page"} arrow><a href={selectedMaterial.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {/*{selectedMaterial.externalIds.ArXiv && (
                        <Typography variant="body1"><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Typography>
                      )}*/}
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <Tooltip title={citeTooltipMessage} arrow>
                          <Button size="small"
                          sx={{ textTransform: 'none', marginTop: '4.5px', marginLeft: '0px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '14.5px' }}
                          onClick={() => handleCite(selectedMaterial?.citationStyles?.bibtex)}>
                            <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                          </Button>
                          </Tooltip>
                      )}
                          <Snackbar
                                open={showSnackbarCopyCitation}
                                autoHideDuration={2000}
                                onClose={handleCloseSnackbarCopyCitation}
                                message="BibTex citation copied to clipboard"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          />
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <SavePaperInListPopover papers={papers} material={selectedMaterial}/>
                      )}
                    </Grid>
                    {selectedMaterial.openAccessPdf && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, selectedMaterial.openAccessPdf.url, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, `https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}*/}

                    <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>Abstract</Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'justify'}}>{selectedMaterial.abstract}</Typography>

                    {selectedMaterial.openAccessPdf && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "10px 0 0 0" : "10px 0px 0px 0px"}>
                        <PdfViewer fileUrl={selectedMaterial.openAccessPdf.url}/>
                      </Grid>
                    </Grid>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "20px 0 0 0" : "20px 10% 0px 10%"}>
                        <PdfViewer fileUrl={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`}/>
                      </Grid>
                    </Grid>
                    )}*/}
                    {/*{(selectedMaterial.openAccessPdf || selectedMaterial.externalIds.ArXiv ) && (
                    <PdfViewer fileUrl={selectedMaterial.openAccessPdf ? selectedMaterial.openAccessPdf.url : `https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`}/>
                    )}*/}
                  </div>
                )}
              </DialogContent>
            </Dialog>
            </Grid>
            </div>
            ))}
        </Grid>
    );
};
    
export default PapersSection;