import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, ButtonBase, Grid, Menu, MenuItem, Tooltip, Snackbar } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { deletePost, likePost, downvotePost, addPostToUserList } from '../../../actions/posts';

import SaveChatInListPopover from '../../ChatDetails/SaveInListPopover/SaveChatInListPopover';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledCard from './postStyles';

const theme = createTheme();
const PREFIX = 'Post';

const classes = {
    border: `${PREFIX}-border`,
    fullHeightCard: `${PREFIX}-fullHeightCard`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    mediaBook: `${PREFIX}-mediaBook`,
    overlay: `${PREFIX}-overlay`,
    overlay2: `${PREFIX}-overlay2`,
    grid: `${PREFIX}-grid`,
    title: `${PREFIX}-title`,
    details: `${PREFIX}-details`,
    content: `${PREFIX}-content`,
    moreOptions: `${PREFIX}-moreOptions`,
    categoryLink: `${PREFIX}-categoryLink`,
    body: `${PREFIX}-body`,
    tags: `${PREFIX}-tags`,
    cardActions: `${PREFIX}-cardActions`,
    cardAction: `${PREFIX}-cardAction`,
    cardButton: `${PREFIX}-cardButton`
};

const Post = ({ post, setCurrentId, papers }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [likes, setLikes] = useState(post?.likes); // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const [downvotes, setDownvotes] = useState(post?.downvotes); // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const [inList, setInList] = useState(post?.inList); // to have the save button colored directly
    const userId = user?.result?._id;
    const [showSnackbarCopyLink, setShowSnackbarCopyLink] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    // for menulist (options: edit or delete)
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    
    const closeMenu = () => {
      setAnchor(null);
    };

    const handleDelete = () => {
        dispatch(deletePost(post._id, history, 'notChatPage'));
    };
    
    const handleCopyLink = () => {
          navigator.clipboard.writeText(`https://www.circletree.io/resources/${post._id}`);
          setShowSnackbarCopyLink(true);
        };
    const handleCloseSnackbarCopyLink = () => {
        setShowSnackbarCopyLink(false);
      };

    const openPost = () => history.push(`/c/${post._id}`);

    return (
        <StyledCard className={classes.card} elevation={0}>
            
            <Link to={`/c/${post._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Grid item className={classes.content} xs={12} sm={12} md={12} lg={12} >
                        {(post?.type === 'paperSearch' && post?.answer) && (
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{'&:hover': { '& .Post-title': { color: '#1c95aa' }}}}>
                            <Typography className={classes.title}
                                sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                                variant="body1" >{post.query}
                            </Typography>
                            <Typography className={classes.body}
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                                color="textSecondary" variant="body1" >{isMobile ? post?.answer.substring(0, 80) : post?.answer.substring(0, 140)}...
                            </Typography>
                        </Grid>
                        )}
                        {(post?.type === 'file' || post?.type === 'fileViaLink') && (
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{'&:hover': { '& .Post-title': { color: '#1c95aa' }}}}>
                            <Typography className={classes.title}
                                sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                                variant="body1" >{post.fileName}
                            </Typography>
                            {(post?.type === 'file') && (
                            <Typography className={classes.body}
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                                color="textSecondary" variant="body1" >{isMobile ? post?.fileText.substring(0, 80) : post?.fileText.substring(0, 140)}...
                            </Typography>
                            )}
                            {(post?.type === 'fileViaLink') && (
                            <Typography className={classes.body}
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                                color="textSecondary" variant="body1" >{isMobile ? post?.filePaperInfo?.abstract.substring(0, 80) : post?.filePaperInfo?.abstract.substring(0, 140)}...
                            </Typography>
                            )}
                        </Grid>
                        )}
                    </Grid>
            </Link>

            <div className={classes.details}>
                <Grid container display="flex" flexDirection="row" alignItems="center">
                    {(post?.type === 'paperSearch' && !post?.instructions) && (
                    <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary" alignItems="center"><SearchIcon sx={{fontSize:'18px'}}/>&nbsp;Search</Typography>
                    )}
                    {(post?.type === 'paperSearch' && post?.instructions) && (
                    <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary" alignItems="center"><SearchIcon sx={{fontSize:'18px'}}/>&nbsp;Search</Typography>
                    )}
                    {(post?.type === 'file') && (
                    <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary" alignItems="center"><ArticleOutlinedIcon sx={{fontSize:'18px'}}/>&nbsp;PDF (Uploaded)</Typography>
                    )}
                    {(post?.type === 'fileViaLink') && (
                    <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary" alignItems="center"><ArticleOutlinedIcon sx={{fontSize:'18px'}}/>&nbsp;PDF (Paper)</Typography>
                    )}
                    <Typography sx={{ fontWeight: 'light', marginLeft: '11px' }} variant="body2" color="textSecondary">{moment(post.createdAt).fromNow()}</Typography>
                </Grid>
                <Grid display="flex" flexDirection="row" alignItems="center">
                    <SaveChatInListPopover papers={papers} post={post}/>
                    <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={openMenu}>
                        <MoreHorizIcon fontSize="medium" />
                    </Button>
                    <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                        {(userId === post?.creator) && (
                            <div>
                            <MenuItem onClick={handleDelete} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}><DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete</MenuItem>
                            </div>
                        )}
                    </Menu>
                </Grid>
            </div>

            {/*<CardActions className={classes.cardActions}>
            {user?.result && (
            <div>
                <Button className={classes.cardButton} size="small"
                sx={{ color:'#97bebe', borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleLike}>
                    <Likes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ color:'#97bebe', borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleAddToList}>
                    <AddToList />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ minWidth:'33px', maxWidth:'33px', minHeight:'33px', color:'#97bebe', borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleDownvote}>
                    <Downvotes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ minWidth:'33px', maxWidth:'33px', minHeight:'33px', color:'#97bebe', borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleCopyLink}>
                    <ShareOutlinedIcon fontSize="small" />
                </Button>
                <Snackbar
                    open={showSnackbarCopyLink}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbarCopyLink}
                    message="Link copied to clipboard"
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                />
            </div>
            )}
            </CardActions>*/}
        </StyledCard>
    );
}

//&nbsp; = space
// possibility to add like - likes if numerous likes: check tutorial 04:46:22 & github part 3
// for delete and edit features, check if the user is the creator before allowing them
// button base general button of the post/card

export default Post;