import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const PREFIX = 'EditProfile';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`,
    buttonCancel: `${PREFIX}-buttonCancel`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },

    [`&.${classes.paper}`]: {
      //backgroundColor: '#141414',
      padding: theme.spacing(2),
      borderRadius: 15,
    },

    [`& .${classes.form}`]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        fontSize: '16px',
        '& label.Mui-focused': {
            color: 'grey',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'grey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'grey',
            },
        },
    },

    [`& .${classes.fileInput}`]: {
      width: '100%',
      margin: '16px 0px 0px',
    },

    [`& .${classes.buttonSubmit}`]: {
      margin: '24px 0px 20px',
      borderRadius: 40,
      textTransform: 'none',
      fontSize: '16px',
      "&:hover": {
        boxShadow: "#000000",
        background: "#e9e9e9"
      },
    },

    [`& .${classes.buttonCancel}`]: {
      margin: '24px 10px 20px 0px',
      borderRadius: 40,
      textTransform: 'none',
      fontSize: '16px',
      "&:hover": {
        boxShadow: "#000000",
        background: "#181818"
      },
    }
}));

export default StyledPaper;