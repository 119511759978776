import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialgTitle, Typography, Grid, LinearProgress, CircularProgress, Popover, Tooltip } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { createPost } from '../../actions/posts';

const theme = createTheme();

const DragAndDropFiles = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const extractTextFromPDF = async (file) => {
    let fileText = '';
    try {
      const pdfData = new Uint8Array(await file.arrayBuffer());
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const numPages = pdf.numPages;
      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        fileText += pageText;
      }
    } catch (error) {
      console.error('Error extracting text from PDF:', error);
    }
    return fileText;
  };

  const sendDocumentToBackend = async (file) => {
    // Extract text from the PDF file
    const fileText = await extractTextFromPDF(file);
    // Send to backend
    dispatch(
      createPost({
        type: 'file',
        fileName: file.name,
        fileText: fileText,
      }, history)
    );
    setLoading(true)
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setPdfFile(file);
    sendDocumentToBackend(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  // Define a custom cursor style for the drop zone
  const dropzoneStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '250px', //100
    width: '450px', //
    border: isHovered ? '1.5px dashed #1c95aa' : '1.5px dashed transparent',//'1px dashed #aaa' : '1px dashed #888',
    //padding: '20px',
    backgroundColor: isHovered ? 'rgba(50, 50, 50, 0.04)' : 'rgba(50, 50, 50, 0)', //'#333333' : 'transparent',
    borderRadius: '10px'
  };

    // Define a custom cursor style for the drop zone
    const dropzoneStyle2 = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      height: '40px', //100
      //width: '160px', //
      border: isHovered ? '1.5px dashed #1c95aa' : '1.5px dashed transparent',//'1px dashed #aaa' : '1px dashed #888',
      padding: '9px',
      backgroundColor: isHovered ? 'rgba(50, 50, 50, 0.04)' : 'rgba(50, 50, 50, 0)', //'#333333' : 'transparent',
      borderRadius: '10px'
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

  return (
    <Grid>
          {isMobile && (
          <div {...getRootProps({ onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), style: dropzoneStyle2 })} className="dropzone">
            <input {...getInputProps()} />
            {(loading == true) && (
              <CircularProgress sx={{marginRight:'6px', minHeight: '20px', maxHeight: '20px', minWidth: '20px', maxWidth: '20px'}} />
            )}
            {(loading == false) && (
              <CloudUploadOutlinedIcon sx={{ fontSize: '20px', color: 'Grey', marginRight: '6px' }} />
            )}
            <Typography style={{ textAlign: 'center', color: 'Grey' }}>Upload PDF</Typography>
          </div>
          )}

          {!isMobile && (
          <Grid>
          <Tooltip title="Upload a PDF document to ask questions on it" arrow>
            <Button variant="outlined" size='small' sx={{ textTransform: 'none', fontSize: '15px', color: 'Grey', borderRadius: '10px', backgroundColor: 'transparent', borderColor: 'transparent' }} //marginLeft: '-6px'
            onClick={handleClickOpen}>
              <CloudUploadOutlinedIcon sx={{ fontSize: '20px', marginRight: '6px' }} /> Upload PDF
            </Button>
          </Tooltip>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            elevation={0}
            sx={{ '& .MuiPopover-paper': { borderRadius: '10px', border: '1px solid #dddddd', padding: '12px 15px 16px 15px'} }}
          >
            <div {...getRootProps({ onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), style: dropzoneStyle })} className="dropzone">
            <input {...getInputProps()} />
            {(loading == true) && (
              <CircularProgress sx={{marginRight:'6px', minHeight: '20px', maxHeight: '20px', minWidth: '20px', maxWidth: '20px'}} />
            )}
            {(loading == false) && (
              <CloudUploadOutlinedIcon sx={{ fontSize: '20px', color: 'Grey', marginRight: '6px' }} />
            )}
            <Typography style={{ textAlign: 'center', color: 'Grey' }}>Drag and drop PDF</Typography>
          </div>
          </Popover>
          </Grid>
          )}

          
            
          {/*<div className="pdf-container">
            {pdfFile && <p>Uploaded file: {pdfFile.name}</p>}
            {pdfFile && (
              <div className="pdf-viewer">
                <Document file={pdfFile}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            )}
          </div>*/}

          {/*<div className="pdf-container">
            <div {...getRootProps({ onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), style: dropzoneStyle })} className="dropzone">
              <input {...getInputProps()} />
              <NoteAddOutlinedIcon sx={{ fontSize: '22px', color: 'darkGrey', marginTop: '16px', marginBottom: '-6px' }} />
              <p style={{fontSize: '14px',textAlign: 'center', color: 'darkGrey'}}>Drop a PDF file here</p>
            </div>
            {pdfFile && <p>Uploaded file: {pdfFile.name}</p>}
            {pdfFile && (
              <div className="pdf-viewer">
                <Document file={pdfFile}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            )}
          </div>*/}

        {/*<Button variant="outlined"  size='large' sx={{ textTransform: 'none', fontSize: '16px', color: 'darkGrey', borderRadius: '10px', backgroundColor: 'transparent', borderColor: '#363636' }} onClick={handleClickOpen}>
            <NoteAddOutlinedIcon sx={{ fontSize: '20px', marginRight:'6px' }} />Upload a PDF file
        </Button>
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload a PDF file</DialogTitle>
        <DialogContent>
          <div className="pdf-container">
            <div {...getRootProps({ onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), style: dropzoneStyle })} className="dropzone">
              <input {...getInputProps()} />
              <NoteAddOutlinedIcon sx={{ fontSize: '24px', color: 'lightGrey', marginTop: '10px' }} />
              <p style={{textAlign: 'center', color: 'lightGrey'}}>Drag and drop a PDF file here</p>
            </div>
            {pdfFile && <p>Uploaded file: {pdfFile.name}</p>}
            {pdfFile && (
              <div className="pdf-viewer">
                <Document file={pdfFile}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{marginTop: '-20px'}}>
            <Button onClick={handleClose} sx={{textTransform: 'none'}}>Close</Button>
        </DialogActions>
      </Dialog>*/}
    </Grid>
  );
};

export default DragAndDropFiles;
