import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const Privacy = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    sx={{ marginTop: '-10px' }}>
        
        <Grid md={8} container display="flex" flexDirection="column" justifyContent="center" padding='0 5% 0 5%'>
            {/*<Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'32px', fontWeight: 'bold'} : { fontSize:'48px', fontWeight: 'bold' }} >Building the future of research.</Typography>*/}
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Privacy Policy
            </Typography>

            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                Last updated: October 1, 2023
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                This privacy policy (“Policy”) describes how https://www.lifa.ai (“Lifa AI” “we”, “us”, or “our”) collects, uses, shares and protects information about you when you use our website, products and services (collectively, the “Services”).
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Our Services
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                Lifa AI provides a research-based text generation service. Lifa AI is available via https://www.lifa.ai and associated internet domains.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Information Lifa AI Collects
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                We collect and store the email address used to create an account, the queries made with our service and the documents uploaded on the application. Collecting this information enables us to improve the quality and relevance of our service, by providing users a history of their activity and the ability to manage their library. If a user deletes a chat, a document or their account, it is automatically deleted from our database.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Information Lifa AI Does Not Collect
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                We do not collect any personal information other than your email address used to create your account.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Sharing of Information
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                No personally identifiable information (PII), i.e. a user's email or any document uploaded by a user, is shared inside or outside the Lifa AI platform. We only share queries with OpenAI via their professional API in order to synthesise information. OpenAI automatically deletes the data submitted after 30 days and does not use it to train its models.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Changes to our Privacy Policy
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                We may change this Policy from time to time. If we make changes, we will post the revised Policy on our website and update the “Last updated” date above. We encourage you to review the Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the choices available to you.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Contact Us
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '20px' }} >
                If you have any questions about this Policy, please contact us by email at <a href="mailto:support@lifa.ai" style={{color: '#1c95aa', textDecoration: 'none'}}>support@lifa.ai</a> or on <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Twitter</a>.
            </Typography>
        </Grid>

        <Grid>
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 5}
        sx={{ margin: '40px 0px 20px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/contact" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Contact
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '30px 10px 30px 10px' : '30px 60px 30px 60px'}>        
        </Grid>

      </Grid>

    </Grid>
  );
};

export default Privacy;