import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Box, Tooltip, IconButton, TextField, InputAdornment, Grid, LinearProgress, Paper, CircularProgress, FormControlLabel,  Switch } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import decode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';

import { addFileChatMessage } from '../../actions/posts';

import Filters from "../Filters/Filters";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledAppBar from "./bottombarFollowupsStyles";

const theme = createTheme();
const PREFIX = 'Navbar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};


const BottombarFollowups = ({ width, postType, handleSend, loading }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const userId = user?.result?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const [query, setQuery] = useState("");
    const [strictCitationModeFollowUp, setStrictCitationModeFollowUp] = useState(true);

    const [effectiveWidth, setEffectiveWidth] = useState(width ? width - 100 : '60%');

    useEffect(() => {
      // Check if width is provided and update the effective width
      if (width) {
        setEffectiveWidth(width - 100); // considering 50px padding on each side
      }
    }, [width]);
    
    //useEffect(() => {
    //  console.log(messages)
    //}, [messages]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const handleSendButtonClicked = () => {
      if (query && query.trim()) {
        // Call the handleSend function from props with the query
        handleSend(query, strictCitationModeFollowUp);
        // Clear the query input or perform any other necessary actions
        setQuery('');
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') { //keyCode 13 is just the enter key
        e.preventDefault();
        handleSendButtonClicked(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (query) ? setDisable(false) : setDisable(true);
    }, [query]);
    
    return (
      <Grid sx={isMobile ? {position: 'fixed', width: '95%', left: '2.5%', bottom: 20, zIndex: 2} : {position: 'fixed', width: effectiveWidth ? `${effectiveWidth}px` : '60%', bottom: 20, marginTop: '40px', zIndex: 2}}>
        <Grid item display='flex' flexDirection="column">
          {(loading == true) && (
            <Box margin='0px 0px 5px 0px' style={ isMobile ? { width: '94%', minWidth: '94%', maxWidth: '94%' } : { width: '59%', minWidth: '59%', maxWidth: '59%' }}>
              <LinearProgress />
            </Box>
          )}
          <TextField autoFocus fullWidth={isMobile ? true : false} //style={ isMobile ? {width: '95%', minWidth: '95%', maxWidth: '95%', marginBottom:'2.5%', marginTop:'2.5%'} : { width: '50%', minWidth: '50%', maxWidth: '50%', marginBottom:'0.75%', marginTop:'0.75%', marginLeft: '180px' }}
          name="goal" size="large" label={query ? '' : 'Ask follow-up on selected papers'} InputLabelProps={{ shrink: false }} 
          value={query} multiline onChange={(e) => setQuery(e.target.value)} inputProps={{maxLength: 150}}
          sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35',
          borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
            backgroundColor: '#fefefe', //'rgba(30, 30, 30, 1)',
            borderRadius: '20px',
            //paddingRight:'20px',
            //paddingLeft:'-20px'
          },}}
          onKeyPress={handleKeyPress}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleSendButtonClicked} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#1c95aa', color: 'white', padding:'2px', marginRight:'0px'}}>
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}>
          </TextField>

          {/* <Grid sx={ isMobile ? {marginTop: '-8px', marginBottom: '2px'} : { marginLeft: '180px', marginTop: '-8px', marginBottom: '2px' }}>
          <FormControlLabel
              value="end"
              control={<Switch color="secondary" size="small" sx={{marginRight: '2px'}}
              checked={strictCitationModeFollowUp}
              onChange={() => setStrictCitationModeFollowUp(!strictCitationModeFollowUp)}/>}
              label="Strict citation"
              sx={{color: 'Grey', marginLeft: '0px', marginRigth: '0px', padding: '0px' }}
              labelPlacement="end"
          />
          </Grid> */}

        </Grid>
      </Grid>
    );
};

export default BottombarFollowups;