import React, { useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { useState } from 'react';
import { Typography, CircularProgress, Divider, Button, Menu, MenuItem, Grid, CardActions, Card, Accordion, AccordionSummary, AccordionDetails, Snackbar, TextField, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, Box, Tooltip, Skeleton, Paper, Checkbox, Drawer } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, useHistory, Link } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import useMediaQuery from '@mui/material/useMediaQuery';
import parse from 'html-react-parser';

import { getPost, updatePost, createPost, addFileChatMessage, deleteFileChatMessage, addFollowup, deleteFollowup, createAbstractSummary, createRecommendedQuestions, deletePost, likePost, downvotePost, savePaper, getSavedPapers } from '../../actions/posts';

import Bottombar from '../Bottombar/Bottombar';
import BottombarFollowups from '../BottombarFollowups/BottombarFollowups';
import journalScore from '../journalScore';
import PdfViewer from './PdfViewer/PdfViewerTron';
import SavePaperInListPopover from './SaveInListPopover/SavePaperInListPopover';
import SaveChatInListPopover from './SaveInListPopover/SaveChatInListPopover';
import PapersSection from './PapersSection/PapersSection';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './postDetailsStyles';

import './styles.css';

const theme = createTheme();
const PREFIX = 'PostDetails';

const classes = {
  media: `${PREFIX}-media`,
  mediaBook: `${PREFIX}-mediaBook`,
  card: `${PREFIX}-card`,
  section: `${PREFIX}-section`,
  details: `${PREFIX}-details`,
  moreOptions: `${PREFIX}-moreOptions`,
  categoryLink: `${PREFIX}-categoryLink`,
  description: `${PREFIX}-description`,
  cardButton: `${PREFIX}-cardButton`,
  imageSection: `${PREFIX}-imageSection`,
  recommendedPosts: `${PREFIX}-recommendedPosts`,
  loadingPaper: `${PREFIX}-loadingPaper`,
  answerField: `${PREFIX}-answerField`
};


const PostDetails = () => {
  const { post, posts, isLoading, isLoadingChat, papers } = useSelector((state) => state?.posts); // get posts data
  const [answerMaterial, setAnswerMaterial] = useState(post?.answerMaterial);
  const [recommendedQuestions, setRecommendedQuestions] = useState(post?.recommendedQuestions);
  const [postData, setPostData] = useState({
    answer: ''
  });
  const [fileChat, setFileChat] = useState(post?.fileChat)
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [likes, setLikes] = useState(post?.likes);
  const [downvotes, setDownvotes] = useState(post?.downvotes);
  const [inList, setInList] = useState(post?.inList);
  const userId = user?.result?._id;
  const userList = user?.result?.list;
  const [paperList, setPaperList] = useState(papers);
  const hasLikedPost = likes?.find((like) => like === userId);
  const hasDownvotedPost = downvotes?.find((downvote) => downvote === userId);
  const hasPostInList = inList?.find((id) => id === userId);
  //const userInUploads = post?.users.includes(userId);
  const [anchor, setAnchor] = useState(null);
  const [noteExpanded, setNoteExpanded] = useState(false);
  const handleNoteExpanded = () => {
      setNoteExpanded(!noteExpanded);
  };
  const [showSnackbarCopyLink, setShowSnackbarCopyLink] = useState(false);
  const [showSnackbarCopyCitation, setShowSnackbarCopyCitation] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [dialogFileSourceOpen, setDialogFileSourceOpen] = useState(false);
  const [selectedFileSourceMaterial, setSelectedFileSourceMaterial] = useState(null);
  const [loading, setLoading] = useState(isLoadingChat);
  const [k, setK] = useState(10) //useState(post?.nPapersForAnswer);
  const [openPaperLoading, setOpenPaperLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [loadingAbstractSummary, setLoadingAbstractSummary] = useState(new Set());
  const [loadingRecommendedQuestions, setLoadingRecommendedQuestions] = useState(false);
  const [newSearchLoading, setNewSearchLoading] = useState(false);
  const [newSearchLoadingMessage, setNewSearchLoadingMessage] = useState('Getting relevant papers...');
  const gridRef = useRef(null);
  const followupGridRef = useRef(null);
  const [followups, setFollowups] = useState(post?.followups)
  const [loadingFollowup, setLoadingFollowup] = useState(false);
  const [activeFollowup, setActiveFollowup] = useState(null);
  const [activeFileChatMessage, setActiveFileChatMessage] = useState(null);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [copyTooltipMessage, setCopyTooltipMessage] = useState('Copy to clipboard');
  const [citeTooltipMessage, setCiteTooltipMessage] = useState('Cite in BibTex format');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const [mobileOpen, setMobileOpen] = isMobile ? useState(false) : useState(true);

  const isXlScreen = useMediaQuery('(min-width:1920px)');

  // Set drawerWidth and paper dialogWidth based on screen size
  const drawerWidth = isXlScreen ? 600 : 400;
  const dialogWidth = isXlScreen ? 600 : 400;

  const { id } = useParams();

  // get the data for a single post
  useEffect(() => {
    dispatch(getPost(id));
  }, [id]); //happens whenever the id of the post changes

  useEffect(() => {
    if (id) {
      dispatch(getSavedPapers(userId));
    }
  }, [id]);

  useEffect(() => {
    // Check if papers are available and not empty
    if (papers && papers.length > 0) {
      setPaperList(papers);
    } else {
      // nothing
    }
  }, [papers]);

  useEffect(() => {
    setAnswerMaterial(post?.answerMaterial);
  }, [post?.answerMaterial]); 

  //useEffect(() => {
  //  setK(post?.nPapersForAnswer);
  //}, [post?.nPapersForAnswer]); 

  useEffect(() => {
    setRecommendedQuestions(post?.recommendedQuestions);
  }, [post?.recommendedQuestions]); 
  
  // test openai completion
  const [value, setValue] = useState('')

  const streamAnswer = async () => {
    try {
      const cleanedText = post.prompt.replace(/"/g, "'").trim();
      const jsonObject = {
          data: cleanedText,
          chatId: id
        };
      const jsonString = JSON.stringify(jsonObject);
      const response = await fetch(
        'https://alifai-backend-82f5a43dbb58.herokuapp.com/api/chatcompletion',
        {
          method: 'POST',
          headers: {
            //'Content-Type': 'text/event-stream',
            'Content-Type': 'application/json',
          },
          body: jsonString,
        });
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader()
      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        //console.log('Received: ', value)
        setValue((prev) => prev + value)
      }
  } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Server responded with status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        console.error('Request headers:', error.config.headers);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error setting up the request:', error.message);
      }
  }};

  useEffect(() => {
    if (post && !post?.answer && (isLoading === false) && (post?.type === 'paperSearch')) {
      streamAnswer()//.then(setPostData({ answer: value })).then(dispatch(updatePost(id, { ...postData })));
    // then save it in database with new function
    }
  }, [post]);


  function RenderFormattedText({ text }) {
    // Replace your characters first
    const formattedText = text.replace(/\[(.*?)\]/g, '<span style="color: #1c95aa">($1)</span>');
  
    // Split by newline and render with line breaks
    const lines = formattedText.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {/* Using dangerouslySetInnerHTML to render the formatted HTML */}
        <span dangerouslySetInnerHTML={{ __html: line }} />
        {index !== formattedText.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  
    return <Typography sx={{ textAlign: 'justify' }}>{lines}</Typography>;
  };

  useEffect(() => {
    const fetchRecommendedQuestions = async () => {
        if (!isLoading && post && post.query && post.type === 'paperSearch' && post?.recommendedQuestions.length == 0) {
          setLoadingRecommendedQuestions(true);
          const newRecommendedQuestions = await dispatch(createRecommendedQuestions(post._id));
            // handle the result if needed
            setRecommendedQuestions(newRecommendedQuestions);
            setLoadingRecommendedQuestions(false);
        }
    };

    fetchRecommendedQuestions();
  }, [post]);


  const streamFollowup = async (followupId, followupPrompt) => {
    try {
      const cleanedText = followupPrompt.replace(/"/g, "'").trim();
      const jsonObject = {
          data: cleanedText,
          chatId: id,
          followupId: followupId
        };
      const jsonString = JSON.stringify(jsonObject);
      const response = await fetch(
        'https://alifai-backend-82f5a43dbb58.herokuapp.com/api/followupcompletion',
        {
          method: 'POST',
          headers: {
            //'Content-Type': 'text/event-stream',
            'Content-Type': 'application/json',
          },
          body: jsonString,
        });
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader()
      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        //console.log('Received: ', value)
        setFollowups(prevFollowups => {
          // Find the index of the followup with the matching ID
          const index = prevFollowups.findIndex(f => f._id === followupId);

          // If not found, just return the previous state
          if (index === -1) return prevFollowups;

          // Copy the followups array
          const newFollowups = [...prevFollowups];

          // If the followup doesn't have an "answer" field or it's null, initialize it with the value, otherwise concatenate
          const currentAnswer = newFollowups[index].answer || '';
          newFollowups[index] = {
            ...newFollowups[index],
            answer: currentAnswer + value,
          };

          return newFollowups;
        });
      }
  } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Server responded with status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        console.error('Request headers:', error.config.headers);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error setting up the request:', error.message);
      }
  }};

  // Define a function to handle sending messages in PostDetails.js
  const handleSendFollowup = async (queryFollowup, strictCitationModeFollowup) => {
    if (selectedPapers.length === 0) {
      // If no papers selected, show an alert and then return from the function
      alert("Please select at least 1 paper to ask a follow-up");
      return;
    }
    if (user.result._id !== post.creator) {
      // If no papers selected, show an alert and then return from the function
      alert("Only the creator of the chat can ask a follow-up");
      return;
    }
    if (queryFollowup !== null && loadingFollowup === false) {
      setLoadingFollowup(true);
      // first, a function creating everything in the backend and returning a prompt and followup id
      // (new followup question in db with instructions, strictCitationMode, papers)
      const newFollowups = await dispatch(addFollowup(id, queryFollowup, strictCitationModeFollowup, selectedPapers));
      setFollowups(newFollowups);
      setLoadingFollowup(false);
      // then, streamFollowup with the resulting prompt (new "value" and new function)
      const lastFollowup = newFollowups[newFollowups.length - 1];
      streamFollowup(lastFollowup._id, lastFollowup.prompt)
    }
    if (followupGridRef.current) {
      followupGridRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const handleDeleteFollowup = async (followupId) => {
    try {
        const newFollowups = await dispatch(deleteFollowup(post._id, followupId));
        setFollowups(newFollowups);
    } catch (error) {
        console.error("Failed to delete follow-up:", error);
        // Handle the error appropriately
    }
  };

  useEffect(() => {
    setFollowups(post?.followups);
  }, [post?.followups]);

  useEffect(() => {
    if (answerMaterial && answerMaterial.length >= 5) {
      const initialSelection = answerMaterial.slice(0, 5).map(paper => ({ paperId: paper.paperId, title: paper.title, authors: paper.authors, year: paper.year, abstract: paper.abstract }));
      setSelectedPapers(initialSelection);
    }
  }, [answerMaterial]);

  const handleCheckboxChange = (isChecked, paper) => {
    if (isChecked) {
      // Check if there are already 5 papers selected
      if (selectedPapers.length >= 5) {
        alert('You cannot select more than 5 papers.'); // You can replace this with a more user-friendly notification
        return;
      }

      setSelectedPapers(prevSelectedPapers => [
        ...prevSelectedPapers,
        { paperId: paper.paperId, title: paper.title, authors: paper.authors, year: paper.year, abstract: paper.abstract}
      ]);
    } else {
      // Remove the paper from the selection
      setSelectedPapers(prevSelectedPapers => prevSelectedPapers.filter(p => p.paperId !== paper.paperId));
    }
  };

  const isCheckboxDisabled = (material) => {
    return !selectedPapers.some(paper => paper.paperId === material.paperId) && selectedPapers.length >= 5;
  };

  const tooltipMessage = (material) => {
    const isChecked = selectedPapers.some(paper => paper.paperId === material.paperId);

    if (isCheckboxDisabled(material)) {
      return "Unselect one of the 5 selected papers to select a new one for the follow-up";
    } else if (isChecked) {
      return "Unselect this paper for the follow-up";
    } else {
      return "Select this paper for the follow-up";
    }
  };


  // Define a function to handle sending messages in file chat
  const handleSendInPostDetails = async (query) => {
    if (query !== null) {
      setLoadingMessage(true);
      // Use the dispatch function here
      const newMessages = await dispatch(addFileChatMessage(id, query));
      setFileChat(newMessages);
      setLoadingMessage(false);
      //window.scrollTo(0, document.body.scrollHeight);
      if (gridRef.current) {
        gridRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  const handleDeleteFileChatMessage = async (fileChatId) => {
    try {
        const newMessages = await dispatch(deleteFileChatMessage(post._id, fileChatId));
        setFileChat(newMessages);
    } catch (error) {
        console.error("Failed to delete follow-up:", error);
        // Handle the error appropriately
    }
  };

  useEffect(() => {
    setFileChat(post?.fileChat);
    //window.scrollTo(0, document.body.scrollHeight);
  }, [post?.fileChat]);

  useEffect(() => {
    setLoading(isLoadingChat);
  }, [isLoadingChat]);


  // send pdf link to backend
  const handleSendPaperUrl = async (title, link, paperInfo) => {
        setOpenPaperLoading(true);
        await dispatch(
          createPost({
            type: 'fileViaLink',
            fileName: title,
            fileLink: link,
            filePaperInfo: paperInfo
          }, history)
        );
        setOpenPaperLoading(false);
        //setLoading(true)
  };

  const handleSendRecommendedQuestion = async (recommendedQuestion) => {
    //e.preventDefault();
    
    try {
      setValue('');
      setNewSearchLoading(true);
      const timeoutId = setTimeout(() => {
        setNewSearchLoadingMessage('Analyzing collected papers...');
      }, 3500);
      
      await dispatch(
        createPost({
          type: 'paperSearch',
          query: recommendedQuestion,
          answerType: post.answerType === 'true' ? true : false,
          yearFilter: post.yearFilter,
          citationCountFilter: post.citationCountFilter,
          yearFilter: post.yearFilter,
          citationCountFilter: post.citationCountFilter,
          instructions: post.answerType === 'true' ? post.instructions : '',
          strictCitationMode: (post.answerType === 'true' && post.strictCitationMode === 'true') ? true : false
        }, history)
      );
      
      setNewSearchLoading(false);
      return () => clearTimeout(timeoutId);
      
    } catch (error) {
      // If there's an error, setLoading to false and setSendError to true (to display a message)
      setNewSearchLoading(false);
      //setSendError(true);
    }
  };

  const handleShowMorePapers = () => {
    setK(k+10);
  };

  useEffect(()=>{
    setLikes(post?.likes);
  }, [post?.likes]);

  useEffect(()=>{
    setDownvotes(post?.downvotes);
  }, [post?.downvotes]);

  useEffect(()=>{
    setInList(post?.inList);
  }, [post?.inList]);

  const goToHomepage = () => {
    history.push('/');
  };

  const [originalAnswerSourcesVisibility, setOriginalAnswerSourcesVisibility] = useState(false);

  const [followupVisibility, setFollowupVisibility] = useState(
    followups ? followups.map(() => false) : []
  );

  const toggleFollowupVisibility = (index) => {
    if (!followups) {
      return; // Handle the case where followups is not defined
    }

    const newVisibility = [...followupVisibility];
    newVisibility[index] = !newVisibility[index];
    setFollowupVisibility(newVisibility);
  };

  const renderPaperTitles = (papersFollowup) => {
    // Assuming that 'papers' is an array containing all papers
    const materialsFollowup = papersFollowup.map((paperId) => {
      return post.answerMaterial.find((material) => material.paperId === paperId);
    });
  
    // You pass the entire 'papers' array to a single 'PapersSection' component
    return (
      <div>
        <PapersSection papers={papers} materialsFollowup={materialsFollowup} handleSendPaperUrl={handleSendPaperUrl}/>
      </div>
    );
  };

  const [gridNode, setGridNode] = useState(null);
  const [gridNode2, setGridNode2] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrolling2, setIsScrolling2] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const scrollTimeoutRef2 = useRef(null);

  const handleMouseEnter = useCallback(() => {
    setIsScrolling(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsScrolling(false);
  }, []);

  const handleScroll = useCallback(() => {
    // If already scrolling, clear the previous timeout to reset the timer
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }

    setIsScrolling(true);

    // Set isScrolling to false after 1 second of inactivity
    scrollTimeoutRef.current = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  }, []);

  const handleRef = useCallback(node => {
    if (node !== null) {
      setGridNode(node);
    }
  }, []);

  useEffect(() => {
    if (gridNode) {
      // Separate handlers for scroll and mouse events
      gridNode.addEventListener('scroll', handleScroll);
      gridNode.addEventListener('mouseenter', handleMouseEnter);
      gridNode.addEventListener('mouseleave', handleMouseLeave);

      // Cleanup
      return () => {
        if (gridNode) {
          gridNode.removeEventListener('scroll', handleScroll);
          gridNode.removeEventListener('mouseenter', handleMouseEnter);
          gridNode.removeEventListener('mouseleave', handleMouseLeave);
        }

        // Clear any pending timeouts when the component unmounts
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, [gridNode, handleScroll, handleMouseEnter, handleMouseLeave]);

  const handleMouseEnter2 = useCallback(() => {
    setIsScrolling2(true);
  }, []);

  const handleMouseLeave2 = useCallback(() => {
    setIsScrolling2(false);
  }, []);

  const handleScroll2 = useCallback(() => {
    // If already scrolling, clear the previous timeout to reset the timer
    if (scrollTimeoutRef2.current !== null) {
      clearTimeout(scrollTimeoutRef2.current);
    }

    setIsScrolling2(true);

    // Set isScrolling to false after 1 second of inactivity
    scrollTimeoutRef2.current = setTimeout(() => {
      setIsScrolling2(false);
    }, 1000);
  }, []);

  const handleRef2 = useCallback(node => {
    if (node !== null) {
      setGridNode2(node);
    }
  }, []);

  useEffect(() => {
    if (gridNode2) {
      // Separate handlers for scroll and mouse events
      gridNode2.addEventListener('scroll', handleScroll2);
      gridNode2.addEventListener('mouseenter', handleMouseEnter2);
      gridNode2.addEventListener('mouseleave', handleMouseLeave2);

      // Cleanup
      return () => {
        if (gridNode2) {
          gridNode2.removeEventListener('scroll', handleScroll2);
          gridNode2.removeEventListener('mouseenter', handleMouseEnter2);
          gridNode2.removeEventListener('mouseleave', handleMouseLeave2);
        }

        // Clear any pending timeouts when the component unmounts
        if (scrollTimeoutRef2.current) {
          clearTimeout(scrollTimeoutRef2.current);
        }
      };
    }
  }, [gridNode2, handleScroll2, handleMouseEnter2, handleMouseLeave2]);

  const [parentWidth, setParentWidth] = useState('60%');

  useLayoutEffect(() => {
    if (!post) return; // if there's no data, don't try to measure anything

    const updateParentWidth = () => {
      const parentElement = document.querySelector('#parent-grid');
      if (parentElement) {
        const currentWidth = parentElement.getBoundingClientRect().width;
        setParentWidth(currentWidth);
      }
    };

    updateParentWidth();
    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, [post, loading, followups]);

  if (!post && !isLoading && !openPaperLoading && !newSearchLoading) {
    return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" marginBottom="10px">No chat with this id.</Typography>
        <Button onClick={goToHomepage}
        sx={{ backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px', }}
        variant="outlined" size="small"
        >Go to home page</Button>
    </Grid>
    )
  };

  if (!user) {
    return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" marginBottom="10px">Sign in to see your chats.</Typography>
        <Button component={Link} to="/auth"
          sx={{ backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px', }}
          variant="outlined" size="small"
        >Sign in</Button>
    </Grid>
    )
  };

  if (isLoading && !openPaperLoading && !newSearchLoading) {
    return (
      <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress/>
      </Grid>
    );
  };
  if (isLoading && newSearchLoading) {
    return (
      <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center"  sx={{padding: '0px 20px 0px 20px'}}>
        <Grid container md={10.5} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center"
        marginBottom="15px">
          <CircularProgress size={30} />
          <Typography marginLeft="10px" variant="h6">{newSearchLoadingMessage}</Typography>
        </Grid>
        <Grid container md={10.5} direction="column">
          <Grid item marginBottom="20px">
              <Skeleton width="50%" height={30}/>
          </Grid>
          <Grid item marginBottom="20px">
              <Skeleton width="12%"/>
          </Grid>
          <Grid item>
              <Skeleton width="100%" />
          </Grid>
          <Grid item marginBottom="20px">
              <Skeleton width="100%"/>
          </Grid>
          <Grid item marginBottom="20px">
              <Skeleton width="12%" />
          </Grid>
          <Grid item  marginBottom="10px">
              <Skeleton variant="rounded" width="100%" height={60}/>
          </Grid>
          <Grid item  marginBottom="10px">
              <Skeleton variant="rounded" width="100%" height={60}/>
          </Grid>
          <Grid item  marginBottom="10px">
              <Skeleton variant="rounded" width="100%" height={60}/>
          </Grid>
          <Grid item  marginBottom="10px">
              <Skeleton variant="rounded" width="100%" height={60}/>
          </Grid>
          <Grid item  marginBottom="10px">
              <Skeleton variant="rounded" width="100%" height={60}/>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (openPaperLoading) {
    return (
      <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" marginBottom="10px">Downloading and analyzing the paper... It can take up to 30 seconds for large files.</Typography>
        <CircularProgress/>
      </Grid>
    );
  };

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  
  const closeMenu = () => {
    setAnchor(null);
  };
  
  const openMenuFollowup = (followupId) => {
    setActiveFollowup(followupId);
  };
  
  const closeMenuFollowup = () => {
    setActiveFollowup(null);
  };

  const openMenuFileChatMessage = (fileChatId) => {
    setActiveFileChatMessage(fileChatId);
  };
  
  const closeMenuFileChatMessage = () => {
    setActiveFileChatMessage(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleEdit = () => {
    history.push({pathname:'/edit', data: post._id  });
  };

  const handleDelete = () => {
    dispatch(deletePost(post._id, history, 'chatPage'));
  };

  const handleReport = () => {
    dispatch(closeMenu());
  };

  const handleLike = async () => {
    dispatch(likePost(post._id));

    if(hasLikedPost) {
        setLikes(likes.filter((id) => id !== userId));
    } else {
        setLikes([...likes, userId]);
    }
  };

  const Likes = () => {
    if (likes?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
        return likes.find((like) => like === userId)
            ? (
            <><ThumbUpIcon fontSize="small" /></>
        ) : (
            <><ThumbUpOutlinedIcon fontSize="small" /></>
        );
    }

    return <><ThumbUpOutlinedIcon fontSize="small" /></>;
  };

  // to have the downvote button colored directly, not after several seconds (after having the downvote in the database), for better user experience
  const handleDownvote = async () => {
    dispatch(downvotePost(post._id));

    if(hasDownvotedPost) {
        setDownvotes(downvotes.filter((id) => id !== userId));
    } else {
        setDownvotes([...downvotes, userId]);
    }
  };

  const Downvotes = () => {
      if (downvotes?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
          return downvotes.find((downvote) => downvote === userId)
              ? (
              <><ThumbDownIcon fontSize="small" /></>
          ) : (
              <><ThumbDownOutlinedIcon fontSize="small" /></>
          );
      }

      return <><ThumbDownOutlinedIcon fontSize="small" /></>;
  };


  const handleSavePaper = async (material) => {
    dispatch(savePaper(post._id, material.paperId));

    if(paperList?.some(paper => paper.paperId === material.paperId)) {
        setPaperList(paperList.filter(paper => paper.paperId !== material.paperId));
    } else {
        setPaperList([...paperList, material]);
    }
  };

  const handleAbsractSummarize = async (material) => {
    // set loading for concerned material
    setLoadingAbstractSummary(prev => new Set([...prev, material.paperId]));
    
    const newAnswerMaterial = await dispatch(createAbstractSummary('inChatDetails', post._id, material.paperId, 'no'));
    setAnswerMaterial(newAnswerMaterial)

    // after the loading is complete
    setLoadingAbstractSummary(prev => {
      const updatedSet = new Set(prev);
      updatedSet.delete(material.paperId);
      return updatedSet;
    });
  };

  const handleCopyAnswer = async (answerSelected) => {
    //const referencesString = post?.answerMaterial.map((material, i) => (
    //  `[${i + 1}] ${material.title} | ${material.authors.length > 0 ? `${material.authors[0].name} et al.` : ''} ${material.venue.length > 0 ? `${material.venue},` : ''} ${material.year}. | ${material.citationCount} citations. | https://doi.org/${material.externalIds.DOI}`
    //));
    //if (post?.answer) {
    //  const anwerWithReferencesToCopy = `${post.query}\n\n` + post.answer + "\n\nReferences:\n" + referencesString.join("\n");
    //  navigator.clipboard.writeText(`${anwerWithReferencesToCopy}`);
    //} else {
    //  const anwerWithReferencesToCopy = `${post.query}\n\n` + value + "\n\nReferences:\n" + referencesString.join("\n");
    //  navigator.clipboard.writeText(`${anwerWithReferencesToCopy}`);
    //}
    setCopyTooltipMessage("Copied!");
    try {
      await navigator.clipboard.writeText(answerSelected);
      //setShowSnackbarCopyLink(true);
      setTimeout(() => {
        setCopyTooltipMessage("Copy to clipboard");
        //setShowSnackbarCopyLink(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  const handleCloseSnackbarCopyAnswer = () => {
    setShowSnackbarCopyLink(false);
  };

  const handleCite = async (bibtex) => {
    if (bibtex) {
      try {
        await navigator.clipboard.writeText(`${bibtex}`);
        setCiteTooltipMessage("BibTex citation copied!");
        //setShowSnackbarCopyCitation(true);
        setTimeout(() => {
          setCiteTooltipMessage("Cite in BibTex format");
          //setShowSnackbarCopyCitation(false); // Optionally hide the snackbar after 2 seconds as well
        }, 2000); // 2000ms = 2s
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      // Handle the case when 'bibtex' is not available
      console.error('No citation available to copy');
    }
  };
  const handleCloseSnackbarCopyCitation = () => {
    setShowSnackbarCopyCitation(false);
  };

  const openDialog = (material) => {
    setSelectedMaterial(material);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialogFileSource = (material) => {
    setSelectedFileSourceMaterial(material);
    setDialogFileSourceOpen(true);
  };

  const closeDialogFileSource = () => {
    setDialogFileSourceOpen(false);
  };

  return (
  <Grid container justifyContent="space-between" alignItems="stretch" spacing={5} sx={ (!isMobile && (post?.type === 'fileViaLink')) ? {overflowY: 'hidden'} : {overflowY: 'hidden'}}>
    <Grid item xs={12} sm={12} md={12} container justifyContent="center" alignItems="center" sx={isMobile ? { margin: '-50px 20px 0px 20px' } : {marginTop: '-50px'}}>
    <Grid item xs={12} sm={12} md={((post?.type === 'fileViaLink') && !isMobile) ? 5 : 12} xl={((post?.type === 'fileViaLink') && !isMobile) ? 5 : 12}
    sx={ (!isMobile && (post?.type === 'fileViaLink')) ? {height: '90vh', overflowY: 'auto', padding: '0 20px 0 20px', position: 'relative', marginTop: '15px'} : {}}
    className={(!isMobile && (post?.type === 'fileViaLink')) ? `scrollable-content ${isScrolling ? 'scrolling' : ''}` : ''}
    ref={(!isMobile && (post?.type === 'fileViaLink')) ? handleRef : {}}
    >
      <StyledPaper style={(!isMobile && (post?.type === 'file' || post?.type === 'fileViaLink')) ? { padding: '0px 20px 120px 20px', borderRadius: '15px' } : { padding: '0px 0px 0px 0px', borderRadius: '15px' }}
      elevation={0}
      >
        <div className={classes.card}>
          <div className={classes.section}>
            <div className={classes.details}>
              {/*{(post?.type === 'paperSearch' && !post?.instructions) && (
              <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary"><SearchIcon sx={{fontSize:'18px'}}/>&nbsp;Search</Typography>
              )}
              {(post?.type === 'paperSearch' && post?.instructions) && (
              <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary"><SearchIcon sx={{fontSize:'18px'}}/>&nbsp;Search</Typography>
              )}*/}
              {(post?.type === 'file') && (
              <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary"><ArticleOutlinedIcon sx={{fontSize:'18px'}}/>&nbsp;PDF (Uploaded)</Typography>
              )}
              {(post?.type === 'fileViaLink') && (
              <Typography sx={{ fontWeight: 'light', alignItems: 'center', display: 'flex' }} variant="body2" color="textSecondary"><ArticleOutlinedIcon sx={{fontSize:'18px'}}/>&nbsp;PDF (Paper)</Typography>
              )}
              {/*{(post?.type === 'paperSearch') && (
              <Typography sx={{ fontWeight: 'light' }} variant="body2" color="textSecondary">{moment(post.lastUploadedAt).fromNow()}</Typography>
              )}*/}
              {(post?.type === 'file' || post?.type === 'fileViaLink') && (
              <Grid display="flex" flexDirection="row" alignItems="center" sx={{marginLeft: '20px'}}>
                <Grid>
                  <SaveChatInListPopover papers={papers} post={post}/>
                </Grid>
                <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={openMenu}>
                  <MoreHorizIcon fontSize="medium" />
                </Button>
                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                  {(userId === post?.creator) && (
                    <div>
                      <MenuItem onClick={handleDelete} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}><DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete this page</MenuItem>
                    </div>
                  )}
                </Menu>
              </Grid>
              )}
            </div>
            {(post?.type === 'paperSearch') && (
            <Grid display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "" : "center"}>

              <Grid item md={8} xl={7} sx={isMobile ? {} : {marginRight: '0px', paddingRight: '50px', paddingLeft: '50px'}}//, overflowY: 'scroll', maxHeight: '90vh'}}
              //className={`scrollable-content ${isScrolling ? 'scrolling' : ''}`}
              //ref={handleRef}
              id="parent-grid"
              >

              <BottombarFollowups width={parentWidth} postType={post?.type} handleSend={handleSendFollowup} loading={loading} />

              <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1c95aa', padding: '0px 0px 0px 0px', marginBottom:'10px' }}>
                  {post.query}
                </Typography>
                <Grid display="flex" flexDirection="row" alignItems="center" sx={{marginLeft: '20px'}}>
                  <Grid>
                    <SaveChatInListPopover papers={papers} post={post}/>
                  </Grid>
                  <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={openMenu}>
                    <MoreHorizIcon fontSize="medium" />
                  </Button>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    {(userId === post?.creator) && (
                      <div>
                        <MenuItem onClick={handleDelete} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}><DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete this page</MenuItem>
                      </div>
                    )}
                  </Menu>
                </Grid>
              </Grid>

              {post.answerType === 'true' && post.instructions &&(
              <Grid>
                <Divider sx={{marginTop: '25px'}}/>
                <Typography variant="h6" sx={{ color: '#1c95aa', fontSize: '17px', fontWeight: 'bold', margin:'20px 0px 10px 0px' }}>
                  <PersonOutlineOutlinedIcon sx={{ fontSize: '24px', margin:'0px 2px -5px 0px' }}/> Instructions
                </Typography>
                <Typography sx={{ color: '#1c95aa', marginBottom:'10px' }}>
                  {post.instructions}
                </Typography>
              </Grid>
              )}

              <Grid container>
                <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'10px 0px 10px 0px' }}>
                  <NotesOutlinedIcon sx={{ fontSize: '24px', margin:'0px 2px -5px 0px' }}/> Answer
                </Typography>
              </Grid>
              
              {post?.answer && <RenderFormattedText text={post.answer} />}
              {!post?.answer && <RenderFormattedText text={value} />}
              {(!post?.answer && !value) && (
              <Skeleton />
              )}

              {(post?.answer || value) && (
              <div>
              <Grid display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '10px', marginBottom: '0px'}}>
                <Grid>
                  {originalAnswerSourcesVisibility === false && (
                  <Tooltip title={"Show referenced papers"} arrow>
                  <Button size="small" onClick={() => setOriginalAnswerSourcesVisibility(!originalAnswerSourcesVisibility)}
                  sx={{textTransform: 'none', color: 'grey', borderRadius: '10px'}}>
                        <>
                    Sources <AddOutlinedIcon sx={{fontSize: '16px'}}/>
                    </>
                  </Button>
                  </Tooltip>
                  )}
                  {originalAnswerSourcesVisibility === true && (
                  <Tooltip title={"Hide referenced papers"} arrow>
                  <Button size="small" onClick={() => setOriginalAnswerSourcesVisibility(!originalAnswerSourcesVisibility)}
                  sx={{textTransform: 'none', color: 'grey', borderRadius: '10px'}}>
                    <>
                    Sources <CloseIcon sx={{fontSize: '16px'}}/>
                    </>
                  </Button>
                  </Tooltip>
                  )}
                </Grid>
                <Grid display="flex" flexDirection="row" alignItems="center">
                <Tooltip title={copyTooltipMessage} arrow>
                  <Button className={classes.moreOptions} size="small"
                  sx={{ color: 'grey' }}
                  onClick={() => handleCopyAnswer(post.answer)}>
                      <ContentCopyIcon sx={{fontSize:"18px"}} />
                  </Button>
                </Tooltip>
                <Snackbar
                      open={showSnackbarCopyLink}
                      autoHideDuration={2000}
                      onClose={handleCloseSnackbarCopyAnswer}
                      message="Copied"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
                </Grid>
              </Grid>
              {originalAnswerSourcesVisibility === true && (
                <Grid sx={{marginTop: '20px'}}>
                  {renderPaperTitles(answerMaterial.slice(0, 5).map((paper) => paper.paperId))}
                </Grid>
              )}
              </div>
              )}

              {followups?.length > 0 && (
              <div>
              {followups.map((followup, index) => (
                <div key={index} ref={index === followups.length - 1 ? followupGridRef : null}>
                  <Divider sx={{marginTop: '25px'}}/>
                  <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: '17px', color: '#1c95aa', fontWeight: 'bold', margin:'30px 0px 10px 0px' }}>
                      <PersonOutlineOutlinedIcon sx={{ fontSize: '24px', margin:'0px 2px -5px 0px' }}/> Instructions
                    </Typography>
                  </Grid>
                  <Typography sx={{ color: '#1c95aa', marginBottom:'10px' }}>
                    {followup.instructionsFollowup}
                  </Typography>
                  {/* Conditional rendering in case 'answer' is not available yet */}
                  <Grid container>
                    <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'10px 0px 10px 0px' }}>
                      <NotesOutlinedIcon sx={{ fontSize: '24px', margin:'0px 2px -5px 0px' }}/> Answer
                    </Typography>
                  </Grid>
                  {followup.answer ? (
                    <RenderFormattedText text={followup.answer} />
                  ) : (
                    <Skeleton />
                  )}
                  <Grid display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '10px', marginBottom: '0px'}}>
                    <Grid>
                      {!followupVisibility[index] && (
                      <Tooltip title={"Show referenced papers"} arrow>
                      <Button size="small" onClick={() => toggleFollowupVisibility(index)}
                      sx={{textTransform: 'none', color: 'grey', borderRadius: '10px'}}>
                        <>
                        Sources <AddOutlinedIcon sx={{fontSize: '16px'}}/>
                        </>
                      </Button>
                      </Tooltip>
                      )}
                      {followupVisibility[index] && (
                      <Tooltip title={"Hide referenced papers"} arrow>
                      <Button size="small" onClick={() => toggleFollowupVisibility(index)}
                      sx={{textTransform: 'none', color: 'grey', borderRadius: '10px'}}>
                        <>
                        Sources <CloseIcon sx={{fontSize: '16px'}}/>
                        </>
                      </Button>
                      </Tooltip>
                      )}
                    </Grid>
                    <Grid display="flex" flexDirection="row">
                    <Tooltip title={copyTooltipMessage} arrow>
                      <Button className={classes.moreOptions} size="small"
                      sx={{ color: 'grey' }}
                      onClick={() => handleCopyAnswer(followup.answer)}>
                          <ContentCopyIcon sx={{fontSize:"18px"}} />
                      </Button>
                    </Tooltip>
                    <Snackbar
                          open={showSnackbarCopyLink}
                          autoHideDuration={2000}
                          onClose={handleCloseSnackbarCopyAnswer}
                          message="Copied"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    />
                    <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={() => openMenuFollowup(followup._id)}
                    id={`followup-${followup._id}`}>
                      <MoreHorizIcon fontSize="medium" />
                    </Button>
                    <Menu open={activeFollowup === followup._id} anchorEl={activeFollowup ? document.querySelector(`#followup-${followup._id}`) : null}
                    onClose={closeMenuFollowup} keepMounted >
                      {(userId === post?.creator) && (
                        <div>
                          <MenuItem onClick={() => handleDeleteFollowup(followup._id)} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}><DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete</MenuItem>
                        </div>
                      )}
                    </Menu>
                    </Grid>
                  </Grid>
                  {followupVisibility[index] && (
                    <Grid sx={{marginTop: '20px'}}>
                      {renderPaperTitles(followup.papers)}
                    </Grid>
                  )}
                </div>
              ))}
              <Divider sx={{marginTop: '25px'}}/>
              </div>
              )}
              
              {!isMobile && (
              <Grid>
              <Grid container>
                <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'30px 0px 15px 0px' }}>
                  <ReadMoreOutlinedIcon sx={{ fontSize: '26px', margin:'0px 2px -6px 0px' }}/> Related
                </Typography>
              </Grid>

              {recommendedQuestions && !loadingRecommendedQuestions && (
                <Grid sx={{marginBottom: '110px'}}>
                  {recommendedQuestions.map((recommendedQuestion, i) => (
                  <Tooltip key={recommendedQuestion} title="Open in a new page" arrow>
                    <Grid display="flex" flexDirection="row" alignItems="center"
                    onClick={() => handleSendRecommendedQuestion(recommendedQuestion)}
                    sx={{cursor: 'pointer', borderRadius: '10px', marginBottom: '10px', '&:hover': { '& .hover-color': { color: '#1c95aa' } }}}>
                      <ArrowForwardIcon sx={{color: '#1c95aa', fontSize: '20px', marginRight: '10px'}}/>
                      <Typography sx={{ fontSize: '16.5px', fontWeight: 'bold', textAlign: 'justify' }}
                      className="hover-color">
                        {recommendedQuestion}
                      </Typography>
                    </Grid>
                  </Tooltip>
                  ))}
                </Grid>
              )}
              {loadingRecommendedQuestions && (
              <Grid sx={{marginBottom: '110px'}}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Grid>
              )}
              </Grid>
              )}

              </Grid>

            {/*<CardActions>*/}
              {/*<Button className={classes.cardButton} size="small"
              sx={{ minWidth:'33px', maxWidth:'33px', minHeight:'33px', borderColor: 'white' }}
              disabled={!user?.result} onClick={handleLike}>
                <Likes />
              </Button>
              <Button className={classes.cardButton} size="small"
              sx={{ minWidth:'33px', maxWidth:'33px', minHeight:'33px', borderColor: 'white' }}
              disabled={!user?.result} onClick={handleDownvote}>
                <Downvotes />
              </Button>*/}
            {/*</CardActions>*/}

            {/*<Grid item md={0.1} display="flex" alignItems="center" justifyContent="center" sx={{marginLeft: '30px', marginRight: '20px'}}>
              <Divider orientation={isMobile ? "horizontal" : "vertical"}/>
            </Grid>*/}

            {isMobile && (
              <Button onClick={handleDrawerToggle} size="small"
              sx={{ position: 'fixed', top: 10, right: 4, zIndex: 9999, color: 'Grey',
              backgroundColor: 'transparent', borderRadius: '10px', textTransform: 'none', fontSize: '16px'}}>
                {mobileOpen ? (
                  <>
                  Papers <CloseIcon sx={{fontSize: '22px'}}/>
                  </>
                ) : (
                  <>
                  Papers <AddOutlinedIcon sx={{fontSize: '22px'}}/>
                  </>
                )}
                
              </Button>
            )}

            <Drawer
              sx={{
                zIndex: isMobile ? 9997 : 0,
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  background: '#f8f8f8',
                },
              }}
              //variant="permanent"
              anchor="right"
              variant={isMobile ? "temporary" : "permanent"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >

            {/*<Grid item md={5} display="flex" flexDirection="column" sx={isMobile ? {marginTop: '30px'} : {overflowY: 'scroll', maxHeight: '90vh', paddingLeft: '45px', paddingRight: '30px'}}
            className={`scrollable-content ${isScrolling2 ? 'scrolling' : ''}`}
            ref={handleRef2}>*/}
            <Grid className={`scrollable-content ${isScrolling2 ? 'scrolling' : ''}`}
            ref={handleRef2}
            marginTop={isMobile ? "15px" : ""}>
            <Grid container flexDirection="column"
            sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f8f8f8', padding: '65px 20px 0px 30px'}}>
              <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'0px 0px 5px 0px' }}>
                <LibraryBooksOutlinedIcon sx={{ fontSize: '22px', margin:'0px 2px -4px 0px' }}/> Papers
              </Typography>
              <Typography color="textSecondary" alignItems="center" sx={{ fontSize: '14px', margin: '0px 0px 20px 0px' }}>
                Published {post.yearFilter === 1900 ? "at any time" : `after ${post.yearFilter}`} with a minimum of {post.citationCountFilter} citations
              </Typography>
            </Grid>

            <Grid sx={{padding: '0px 20px 0px 30px'}}
            >
                {answerMaterial?.slice(0, k).map((material, i) => (
                <Grid key={material.paperId}>
                  <Divider sx={{margin: '0px 15px 0px 8px'}}/>
                    <Grid container display='flex' flexDirection='column' alignItems="flex-start" sx={{marginBottom: '0px', border: '0px solid #dddddd', borderRadius: '10px'}} >
                      <Grid display='flex' flexDirection='row' justifyContent="center" alignItems="flex-start" sx={{width: '100%'}}>
                      <Tooltip title={tooltipMessage(material)} arrow>
                        <span>
                          <Checkbox
                            checked={selectedPapers.some(paper => paper.paperId === material.paperId)}
                            onChange={(e) => handleCheckboxChange(e.target.checked, material)}
                            inputProps={{ 'aria-label': 'select paper' }}
                            disabled={isCheckboxDisabled(material)}
                            sx={{marginTop: '20px', marginRight: '-10px'}}
                          />
                        </span>
                      </Tooltip>
                      <Grid item display='flex' flexDirection='column' justifyContent="center" sx={{borderRight: '0px solid #dddddd', padding: '20px', width: '100%'}}>
                      <Grid onClick={() => openDialog(material)} sx={{cursor: 'pointer', borderRadius: '10px', '&:hover': { '& .hover-color': { color: '#1c95aa' } }}}>
                        <Typography sx={{ fontWeight: 'bold', textAlign: 'justify' }}
                        className="hover-color">
                          {material.title}
                        </Typography>
                        {(material.venue.length > 0) && (material.authors.length > 0) && (
                        <Typography color="textSecondary">
                        {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} &nbsp;{material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                        </Typography>
                        )}
                        {(material.venue.length > 0) && (material.authors.length == 0) && (
                        <Typography color="textSecondary">
                        {material.venue.length > 0 ? '|' : ''}&nbsp; {material.venue.length > 0 ? `${material.venue}` : ''} {journalScore[material.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                        </Typography>
                        )}
                        {(material.venue.length == 0) && (material.authors.length > 0) && (
                        <Typography color="textSecondary">
                        {material.authors.length > 1 ? `${material.authors[0].name.split(" ").pop()} et al.` : `${material.authors[0].name.split(" ").pop()}`} {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                        </Typography>
                        )}
                        {(material.venue.length == 0) && (material.authors.length == 0) && (
                        <Typography color="textSecondary">
                        {material.venue.length > 0 ? `${material.venue}` : ''} &nbsp;|&nbsp; {material.year} &nbsp;|&nbsp; {material.citationCount} citations
                        </Typography>
                        )}
                        {/*{(material?.journal?.name) && (
                        <Typography color="textSecondary" sx={{ fontSize: '14.5px' }}>
                        {material?.journal?.name}
                        </Typography>
                        )}*/}
                      </Grid>
                        <Grid display="flex" flexDirection="row">
                          <Typography sx={{ fontSize: '15.5px', borderRadius: '10px'}}>
                          <Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${material.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip>
                            {material.openAccessPdf && (
                              <span> &nbsp; <Tooltip title={"Open paper PDF page"} arrow><a href={material.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></span>
                            )}
                            {/*{material.externalIds.ArXiv && (
                              <span> &nbsp; <a href={`https://arxiv.org/pdf/${material.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></span>
                              )}*/}
                          </Typography>
                          {material?.citationStyles?.bibtex && (
                          <div>
                            <Tooltip title={citeTooltipMessage} arrow>
                            <Button size="small"
                            sx={{ textTransform: 'none', marginTop: '1px', paddingTop: '2.5px', marginLeft: '11px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '15.5px' }}
                            onClick={() => handleCite(material?.citationStyles?.bibtex)}>
                              <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                            </Button>
                            </Tooltip>
                            <Snackbar
                                  open={showSnackbarCopyCitation}
                                  autoHideDuration={2000}
                                  onClose={handleCloseSnackbarCopyCitation}
                                  message="BibTex citation copied to clipboard"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            />
                          </div>
                          )}
                        <SavePaperInListPopover papers={papers} material={material}/>
                        </Grid>
                      
                      <Grid item display="flex" alignItems="center" justifyContent="center">
                        <Divider orientation={isMobile ? "horizontal" : "vertical"}/>
                      </Grid>

                      <Grid item display="flex" alignItems="center" justifyContent="center"
                      //sx={{borderLeft: '1px solid #00161a', padding: '10px'}}
                      //sx={{padding: '20px'}}
                      fullWidth
                      sx={{marginTop: '10px', width: '100%'}}
                      >
                        {!material?.abstractSummary && !loadingAbstractSummary.has(material.paperId) && (
                          <Tooltip title={"Summarize paper's abstract"} arrow><Button onClick={() => handleAbsractSummarize(material)} sx={{textTransform: 'none', borderRadius: '10px', maxHeight: '40px'}}>Summarize</Button></Tooltip>
                        )}
                        {loadingAbstractSummary.has(material.paperId) && (
                          <Grid container direction="column">
                            <Grid item>
                                <Skeleton width="100%" />
                            </Grid>
                            <Grid item>
                                <Skeleton width="100%" />
                            </Grid>
                            <Grid item>
                                <Skeleton width="100%" />
                            </Grid>
                          </Grid>
                        )}
                        {material?.abstractSummary && (
                          <Typography color="textSecondary" sx={{ textAlign: 'justify' }}>
                            {material?.abstractSummary}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
                  </Grid>
                ))}
            <Divider sx={{margin: '0px 15px 20px 15px'}}/>

            {(k < 31) && (
            <Grid container justifyContent="center">
              <Button variant='outlined' onClick={() => handleShowMorePapers()} sx={{textTransform: 'none', borderRadius: '10px', marginTop:'15px', marginBottom: '30px'}}>Show more papers</Button>
            </Grid>
            )}
            </Grid>
            </Grid>

            {/*</Grid>*/}
            </Drawer>

            </Grid>
            )}

            {isMobile && post?.type === 'paperSearch' && (
            <Grid>
            <Grid container>
              <Typography variant="h6" color="textSecondary" sx={{ fontSize: '17px', fontWeight: 'bold', margin:'30px 0px 15px 0px' }}>
                <ReadMoreOutlinedIcon sx={{ fontSize: '26px', margin:'0px 2px -6px 0px' }}/> Related
              </Typography>
            </Grid>

            {recommendedQuestions && !loadingRecommendedQuestions && (
              <Grid sx={{marginBottom: '100px'}}>
                {recommendedQuestions.map((recommendedQuestion, i) => (
                <Tooltip key={recommendedQuestion} title="Open in a new page" arrow>
                  <Grid display="flex" flexDirection="row" alignItems="center"
                  onClick={() => handleSendRecommendedQuestion(recommendedQuestion)}
                  sx={{cursor: 'pointer', borderRadius: '10px', marginBottom: '10px', '&:hover': { '& .hover-color': { color: '#1c95aa' } }}}>
                    <ArrowForwardIcon sx={{color: '#1c95aa', fontSize: '20px', marginRight: '10px'}}/>
                    <Typography sx={{ fontSize: '16.5px', fontWeight: 'bold', textAlign: 'justify' }}
                    className="hover-color">
                      {recommendedQuestion}
                    </Typography>
                  </Grid>
                </Tooltip>
                ))}
              </Grid>
            )}
            {loadingRecommendedQuestions && (
            <Grid sx={{marginBottom: '100px'}}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Grid>
            )}
            </Grid>
            )}


            {(post?.type === 'paperSearch') && (
            <Dialog disableAutoFocus
            BackdropProps={{ style: { backgroundColor: 'transparent' } }} PaperProps={{ elevation: 1 }}
            sx={isMobile ? { zIndex: 9997, '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, margin: '0px', maxWidth: '100%', maxHeight: '95%'} } : { position: 'initial', '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, right: 0, margin: '0px', maxWidth: dialogWidth, maxHeight: '60%'} }}
            open={dialogOpen} onClose={closeDialog}>
              <DialogActions sx={{margin: '-3px -3px -35px 0px'}}>
                <Button onClick={closeDialog} sx={{color: 'Grey', textTransform: 'none', borderRadius: '10px', minWidth: '35px', maxWidth: '35px'}}><CloseIcon/></Button>
              </DialogActions>
              {selectedMaterial && (
                <DialogTitle variant="h6" sx={{marginBottom: '-10px', fontWeight: 'bold'}}>{selectedMaterial.title}</DialogTitle>
              )}
              <DialogContent>
                {selectedMaterial && (
                  <div>
                    <Typography color="textSecondary" variant="body1">{selectedMaterial.authors?.slice(0, 3).map(author => author.name).join(', ')} {selectedMaterial.authors.length < 3 ? "" : "et al."}</Typography>
                    {selectedMaterial.venue.length > 0 ? (
                      <Typography color="textSecondary" variant="body1">{journalScore[selectedMaterial.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''}
                      {selectedMaterial.venue}, {selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    ) : (
                      <Typography color="textSecondary" variant="body1">{selectedMaterial.year}. {selectedMaterial.citationCount} citations</Typography>
                    )}
                    <Grid container display="flex" flexDirection="row" alignItems="flex-start">
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"Open paper PDF page"} arrow><a href={selectedMaterial.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {!selectedMaterial.openAccessPdf && (
                        <Typography variant="body1" sx={{marginRight: '12px'}}><Tooltip title={"See paper on Semantic Scholar"} arrow><a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                      )}
                      {/*{selectedMaterial.externalIds.ArXiv && (
                        <Typography variant="body1"><a href={`https://doi.org/${selectedMaterial.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={selectedMaterial.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Typography>
                      )}*/}
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <Tooltip title={citeTooltipMessage} arrow>
                          <Button size="small"
                          sx={{ textTransform: 'none', marginTop: '4.5px', marginLeft: '0px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', borderColor: 'white', fontWeight: 'light', fontSize: '14.5px' }}
                          onClick={() => handleCite(selectedMaterial?.citationStyles?.bibtex)}>
                            <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                          </Button>
                          </Tooltip>
                      )}
                          <Snackbar
                                open={showSnackbarCopyCitation}
                                autoHideDuration={2000}
                                onClose={handleCloseSnackbarCopyCitation}
                                message="BibTex citation copied to clipboard"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          />
                      {selectedMaterial?.citationStyles?.bibtex && (
                          <SavePaperInListPopover papers={papers} material={selectedMaterial}/>
                      )}
                    </Grid>
                    {selectedMaterial.openAccessPdf && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, selectedMaterial.openAccessPdf.url, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                      <Button onClick={() => handleSendPaperUrl(selectedMaterial.title, `https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`, selectedMaterial)} variant='outlined' size="small" sx={{textTransform: 'none', fontSize: '16px', borderRadius: '10px', marginTop: '8px', marginBottom: '4px'}}>Ask questions on this paper (Beta)</Button>
                    )}*/}

                    <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>Abstract</Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'justify'}}>{selectedMaterial.abstract}</Typography>

                    {selectedMaterial.openAccessPdf && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "10px 0 0 0" : "10px 0px 0px 0px"}>
                        <PdfViewer fileUrl={selectedMaterial.openAccessPdf.url}/>
                      </Grid>
                    </Grid>
                    )}
                    {/*{selectedMaterial.externalIds.ArXiv && (
                    <Grid>
                      <Typography variant="h6" marginTop='10px' marginBottom='4px' fontSize='18px'>PDF</Typography>
                      <Grid margin={isMobile ? "20px 0 0 0" : "20px 10% 0px 10%"}>
                        <PdfViewer fileUrl={`https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`}/>
                      </Grid>
                    </Grid>
                    )}*/}
                    {/*{(selectedMaterial.openAccessPdf || selectedMaterial.externalIds.ArXiv ) && (
                    <PdfViewer fileUrl={selectedMaterial.openAccessPdf ? selectedMaterial.openAccessPdf.url : `https://arxiv.org/pdf/${selectedMaterial.externalIds.ArXiv}`}/>
                    )}*/}
                  </div>
                )}
              </DialogContent>
            </Dialog>
            )}




            
            {(post?.type === 'file' || post?.type === 'fileViaLink') && (
            <div>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', padding: '0px 0px 0px 0px', marginBottom:'4px' }}>
                {post.fileName}
              </Typography>
            </div>
            )}
            {(post?.type === 'fileViaLink') && (
            <Grid>
              <Typography color="textSecondary" variant="body1">{post?.filePaperInfo.authors?.slice(0, 3).map(author => author.name).join(', ')} {post?.filePaperInfo.authors?.length < 3 ? "" : "et al."}</Typography>
              {post?.filePaperInfo.venue.length > 0 ? (
                <Typography variant="body1" color="textSecondary">{journalScore[post?.filePaperInfo.venue] ? <Tooltip title="Influential journal (Scimago Journal Rank score > 1). The Scimago Journal Rank indicator is a measure of the prestige of scholarly journals that accounts for both the number of citations received by a journal and the prestige of the journals where the citations come from." sx={{fontSize: '14px'}}><StarOutlineIcon sx={{color: '#1c95aa', fontSize: '18px', marginBottom: '-3px'}}/></Tooltip> : ''} {post?.filePaperInfo.venue}, {post?.filePaperInfo.year}. {post?.filePaperInfo.citationCount} citations</Typography>
              ) : (
                <Typography variant="body1" color="textSecondary">{post?.filePaperInfo.year}. {post?.filePaperInfo.citationCount} citations</Typography>
              )}
              <Grid display="flex" flexDirection="row" alignItems="flex-start">
                {post?.filePaperInfo.openAccessPdf && (
                  <Typography variant="body1"><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${post?.filePaperInfo.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip> | <Tooltip title={"See paper on Semantic Scholar"} arrow><a href={post?.filePaperInfo.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip> | <Tooltip title={"Open paper PDF page"} arrow><a href={post?.filePaperInfo.openAccessPdf.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                )}
                {!post?.filePaperInfo.openAccessPdf && (
                  <Typography variant="body1"><Tooltip title={"See paper on publisher’s website"} arrow><a href={`https://doi.org/${post?.filePaperInfo.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip> | <Tooltip title={"See paper on Semantic Scholar"} arrow><a href={post?.filePaperInfo.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Tooltip></Typography>
                )}
                {/*{post?.filePaperInfo.externalIds.ArXiv && (
                  <Typography variant="body1"><a href={`https://doi.org/${post?.filePaperInfo.externalIds.DOI}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>DOI <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={post?.filePaperInfo.url} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Semantic Scholar <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a> | <a href={`https://arxiv.org/pdf/${post?.filePaperInfo.externalIds.ArXiv}`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>PDF <OpenInNewIcon sx={{fontSize: '14px', marginBottom:"-2px", marginLeft:"-2px"}}/></a></Typography>
                )}*/}
                {post?.filePaperInfo?.citationStyles?.bibtex && (
                  <Grid display="flex" flexDirection="row" alignItems="flex-start">
                    <Typography variant="body1" sx={{marginLeft: '5px'}}>|</Typography>
                    <Tooltip title={citeTooltipMessage} arrow>
                    <Button size="small"
                    sx={{ textTransform: 'none', marginTop: '4.5px', marginLeft: '4px', minWidth:'50px', maxWidth:'50px', minHeight:'15px', maxHeight:'15px', fontWeight: 'light', fontSize: '14.5px' }}
                    onClick={() => handleCite(post?.filePaperInfo?.citationStyles?.bibtex)}>
                      <FormatQuoteOutlinedIcon sx={{fontSize:"18px", marginRight: '2px'}} /> Cite
                    </Button>
                    </Tooltip>
                    <Snackbar
                          open={showSnackbarCopyCitation}
                          autoHideDuration={2000}
                          onClose={handleCloseSnackbarCopyCitation}
                          message="BibTex citation copied to clipboard"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    />
                    <Typography variant="body1" sx={{marginLeft: '5px', marginRight: '-5px'}}>|</Typography>
                    <SavePaperInListPopover papers={papers} material={post?.filePaperInfo}/>
                  </Grid>
                )}
              </Grid>

              <Accordion disableGutters elevation={0} sx={{background:'transparent', padding:'0', '&:before': {display: 'none'}}}>
                <AccordionSummary
                  elevation={0}
                  sx={{background:'transparent', padding:'0', minHeight: '40px', maxHeight: '40px', maxWidth: '95px'}}
                  expandIcon={<ExpandMoreIcon sx={{color:'#1c95aa'}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="#1c95aa">Abstract</Typography>
                </AccordionSummary>
                <AccordionDetails elevation={0} sx={{background:'transparent', padding:'0'}}>
                  <Typography sx={{ textAlign: 'justify'}}>
                    {post?.filePaperInfo.abstract}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Grid>
            )}

            {(post?.type === 'file' || post?.type === 'fileViaLink') && fileChat && (
            <Grid container sx={{minHeight: '50vh'}}>
              {/*<Grid item xs={12} sm={12} md={8}>
                <TextField value={post?.fileText} multiline fullWidth></TextField>
              </Grid>*/}
            <Grid item xs={12} sm={12} md={12} style={isMobile ? {} : { margin: '5px 0px 70px 0px' }}>
              {fileChat.map((item, index) => (
                <div key={index} ref={index === fileChat.length - 1 ? gridRef : null}> {/*ref={(el) => el && index === fileChat.length - 1 && el.scrollIntoView()}*/}
                  <Grid container display="flex" flexDirection="column">
                    <Typography sx={{ color: '#1c95aa', margin: '7px 0px 10px 0px' }}>
                      {item.query}
                    </Typography>
                    <Typography sx={{ textAlign: 'justify' }}>
                      {item.answer}
                    </Typography>
                    <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '10px'}}>
                      <Grid>
                      <Tooltip title={"Show file extracts"} arrow>
                      <Button size="small" onClick={() => openDialogFileSource(item)}
                      sx={{textTransform: 'none', color: 'grey', borderRadius: '10px'}}>
                        Sources <AddOutlinedIcon sx={{fontSize: '16px'}}/>
                      </Button>
                      </Tooltip>
                      </Grid>
                      <Grid display="flex" flexDirection="row" alignItems="center">
                      <Tooltip title={copyTooltipMessage} arrow>
                        <Button className={classes.moreOptions} size="small"
                        sx={{ color: 'grey' }}
                        onClick={() => handleCopyAnswer(item.answer)}>
                            <ContentCopyIcon sx={{fontSize:"18px"}} />
                        </Button>
                      </Tooltip>
                      <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={() => openMenuFileChatMessage(item._id)}
                        id={`fileChatMessage-${item._id}`}>
                          <MoreHorizIcon fontSize="medium" />
                        </Button>
                        <Menu open={activeFileChatMessage === item._id} anchorEl={activeFileChatMessage ? document.querySelector(`#fileChatMessage-${item._id}`) : null}
                        onClose={closeMenuFileChatMessage} keepMounted >
                          {(userId === post?.creator) && (
                            <div>
                              <MenuItem onClick={() => handleDeleteFileChatMessage(item._id)} sx={{padding: '5px', margin: '-5px 3px -5px 3px'}}><DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete</MenuItem>
                            </div>
                          )}
                        </Menu>
                      <Snackbar
                            open={showSnackbarCopyLink}
                            autoHideDuration={2000}
                            onClose={handleCloseSnackbarCopyAnswer}
                            message="Copied"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Dialog disableAutoFocus style={isMobile ? {marginLeft: '0px'}: {marginLeft: '0px'}}
                  BackdropProps={{ style: { backgroundColor: 'transparent' } }} PaperProps={{ elevation: 1 }}
                  sx={isMobile ? { '& .MuiDialog-paper': { borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, margin: '0px', maxWidth: '100%', maxHeight: '95%'} } : { '& .MuiDialog-paper': { position: 'initial', borderRadius: '10px', border: '1px solid #dddddd', position: 'fixed', bottom: 0, right: 0, margin: '0px', maxWidth: dialogWidth, maxHeight: '60%'} }}
                  open={dialogFileSourceOpen} onClose={closeDialogFileSource}>
                  <DialogActions sx={{margin: '-3px -3px -35px 0px'}}>
                    <Button onClick={closeDialogFileSource} sx={{color: 'Grey', textTransform: 'none', borderRadius: '10px', minWidth: '35px', maxWidth: '35px'}}><CloseIcon/></Button>
                  </DialogActions>
                  <DialogTitle variant="h6" sx={{marginBottom: '-10px'}}>Extracts used to generate the answer</DialogTitle>
                  <DialogContent>
                    {(post?.type === 'fileViaLink') && (
                      <div>
                        <Typography variant="body1">Abstract:</Typography>
                        <Typography variant="body1" marginBottom="16px" sx={{ textAlign: 'justify'}}>"{post?.filePaperInfo.abstract}"</Typography>
                      </div>
                    )}
                    {selectedFileSourceMaterial?.answerMaterial.map((material, i) => (
                    <div key={i}>
                      <Typography variant="body1">Extract {i+1}:</Typography>
                      <Typography variant="body1" marginBottom="16px" sx={{ textAlign: 'justify'}}>"{material.pageContent}"</Typography>
                    </div>
                    ))}
                  </DialogContent>
                </Dialog>
                <Divider style={{ margin: '16px 0px 10px 0px' }}  //style={{ margin: '20px 0' }} 
                  />
                </div>
              ))}

              {loadingMessage && (
              <Grid>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Grid>
              )}

            </Grid>
            </Grid>
            )}
            
            {/*<CommentSection post={post} />
           <Divider style={{ margin: '20px 0' }} /> */}
          </div>

        </div>

        {/*<div>
            <Button
              sx={{minWidth:'36px', maxWidth:'36px', background: 'rgba(15, 15, 15, 0.8)', position: 'fixed', bottom: theme.spacing(15), right: '2.5%'}}
              size="small"
              variant="outlined"
              color="primary"
              onClick={scrollToTop}
            >
              <KeyboardArrowUpIcon />
            </Button>
          
          <Button
            sx={{minWidth:'36px', maxWidth:'36px', background: 'rgba(15, 15, 15, 0.8)', position: 'fixed', bottom: theme.spacing(10), right: '2.5%'}}
            size="small"
            variant="outlined"
            color="primary"
            onClick={scrollToBottom}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </div>*/}

      </StyledPaper>

      {(post?.type === 'file' || post?.type === 'fileViaLink') && (
      <Bottombar postType={post?.type} handleSend={handleSendInPostDetails} loading={loading} />
      )}

    </Grid>
    
    {(post?.type === 'fileViaLink') && !isMobile && (
    <Grid item xs={12} sm={12} md={7} sx={{marginTop: '20px'}}>
      <PdfViewer fileUrl={post?.filePaperInfo.openAccessPdf ? post?.filePaperInfo.openAccessPdf.url : `https://arxiv.org/pdf/${post?.filePaperInfo.externalIds.ArXiv}`}/>
    </Grid>
    )}

    </Grid>
  </Grid>
  );
};

export default PostDetails;
