import { styled } from '@mui/material/styles';
import { Grow } from '@mui/material';

const PREFIX = 'UserProfile';

const classes = {
    profileHead: `${PREFIX}-profileHead`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    profilePostsTitle: `${PREFIX}-profilePostsTitle`,
    tabs: `${PREFIX}-tabs`,
};

const StyledGrow = styled(Grow)((
    {
        theme
    }
) => ({

    [`& .${classes.profileHead}`]: {
      borderRadius: 15,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px',
    },

    [`& .${classes.pagination}`]: {
      borderRadius: 15,
      marginTop: '24px',
      padding: '16px',
    },

    [`& .${classes.gridContainer}`]: {
      //padding: '0px 12px 0px 16px',
      //margin: '0px 12px 0px 16px',
      //[theme.breakpoints.down('xs', 'xl')]: {
        //flexDirection: 'column-reverse',
      //},
    },

    [`& .${classes.profilePostsTitle}`]: {
        padding: '0px 12px 16px 0px',
        marginTop: '-30px'
    },

    [`& .${classes.tabs}`]: {
      textTransform:'none',
      fontSize: 16,
      fontWeight: 16,
      maxHeight: '40px',
      minHeight: '40px',
      margin:'10px 0px 0px 0px',
      borderRadius: 10,
      padding: '0px 15px 0px 15px',
      minWidth: '0px',
      [theme.breakpoints.down('md')]: {
        margin:'10px 0px 0px 0px',
        //maxWidth: '130px',
        //minWidth: '130px',
      },
    },
  
}));

export default StyledGrow;