import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Box, Tooltip, IconButton, TextField, InputAdornment, Grid, Paper, LinearProgress, CircularProgress, FormControlLabel, Switch, Skeleton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import decode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';

import { createPost } from '../../actions/posts';

import Filters from "../Filters/Filters";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledAppBar from "./searchHomebarStyles";

const theme = createTheme();
const PREFIX = 'Navbar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};


const Bottombar = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const userId = user?.result?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [query, setQuery] = useState("");
    const [instructions, setInstructions] = useState("");
    const [answerFilter, setAnswerFilter] = useState(false);
    const [strictCitationMode, setStrictCitationMode] = useState(true);
    const [sourceFilter, setSourceFilter] = useState('papers');
    var [yearFilter, setYearFilter] = useState(1900);
    var [citationCountFilter, setCitationCountFilter] = useState(5);
    const [loading, setLoading] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Getting relevant papers...');
    const [labelSearchBar, setLabelSearchBar] = useState('Enter a research topic');

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    useEffect(() => {
      if (answerFilter === false) {
        setLabelSearchBar('Enter a research topic')
      } else if (answerFilter === true) {
        setLabelSearchBar('Enter a topic')
      }
    }, [answerFilter]);

    const updateAnswerFilter = (newAnswerFilter) => {
      setAnswerFilter(!answerFilter);
    };

    const updateStrictCitationMode = (newStrictCitationMode) => {
      setStrictCitationMode(!strictCitationMode);
    };

    const updateSourceFilter = (newSourceFilter) => {
      setSourceFilter(newSourceFilter);
    };

    const updateYearFilter = (newYearFilter) => {
      setYearFilter(newYearFilter);
    };

    const updateCitationCountFilter = (newCitationCountFilter) => {
      setCitationCountFilter(newCitationCountFilter);
    };

    const updateInstructions = (newInstructions) => {
      setInstructions(newInstructions);
    };

    // for menulist on desktop (options: profile or logout)
    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };  
    const closeMenu = () => {
        setAnchor(null);
    };

    // for menu on mobile
    const [anchorMobile, setAnchorMobile] = useState(null);
    const openMenuMobile = (event) => {
        setAnchorMobile(event.currentTarget);
    }; 
    const closeMenuMobile = () => {
        setAnchorMobile(null);
    };

    const handleSend = async (e) => {
      //e.preventDefault();
      
      try {
        setLoading(true);
        setSendError(false);
        const timeoutId = setTimeout(() => {
          setLoadingMessage('Analyzing collected papers...');
        }, 3500);
        
        await dispatch(
          createPost({
            type: 'paperSearch',
            query: query,
            answerType: instructions ? true : false, //answerFilter,
            yearFilter: yearFilter,
            citationCountFilter: citationCountFilter,
            instructions : instructions,
            strictCitationMode: strictCitationMode
          }, history)
        );
        return () => clearTimeout(timeoutId);
        
      } catch (error) {
        // If there's an error, setLoading to false and setSendError to true (to display a message)
        setLoading(false);
        setSendError(true);
      }
    };

    const handleKeyPress = (e) => {
      if (((answerFilter === false && query && loading === false) || (answerFilter === true && query && loading === false))
        && e.key === 'Enter') {
        e.preventDefault();
        handleSend(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (query && loading === false) ? setDisable(false) : setDisable(true);  // && loading === false
    }, [query]);
    
    return (
      <Box sx={{ width: '100%', maxWidth: '100%' }}>
        <Grid container sx={{width: '100%'}} display='flex' flexDirection="column" alignItems='center'>
          {(loading == true) && (
            <Box sx={{ width: '100%', maxWidth: '100%' }}>
              <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"  sx={{padding: '0px 20px 0px 20px', width: '100%'}}>
                <Grid container item md={10.5} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center"
                marginBottom="15px">
                  <CircularProgress size={30} />
                  <Typography marginLeft="10px" variant="h6">{loadingMessage}</Typography>
                </Grid>
                <Grid container item md={10.5} direction="column">
                  <Grid item marginBottom="20px">
                      <Skeleton width="50%" height={30}/>
                  </Grid>
                  <Grid item marginBottom="20px">
                      <Skeleton width="12%"/>
                  </Grid>
                  <Grid item>
                      <Skeleton width="100%" />
                  </Grid>
                  <Grid item marginBottom="20px">
                      <Skeleton width="100%"/>
                  </Grid>
                  <Grid item marginBottom="20px">
                      <Skeleton width="12%" />
                  </Grid>
                  <Grid item  marginBottom="10px">
                      <Skeleton variant="rounded" width="100%" height={60}/>
                  </Grid>
                  <Grid item  marginBottom="10px">
                      <Skeleton variant="rounded" width="100%" height={60}/>
                  </Grid>
                  <Grid item  marginBottom="10px">
                      <Skeleton variant="rounded" width="100%" height={60}/>
                  </Grid>
                  <Grid item  marginBottom="10px">
                      <Skeleton variant="rounded" width="100%" height={60}/>
                  </Grid>
                  <Grid item  marginBottom="10px">
                      <Skeleton variant="rounded" width="100%" height={60}/>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          
          {(loading == false) && (
          <Grid item xl={9} display='flex' flexDirection="column" alignItems='center' justifyContent='center' sx={{width: '100%'}}>
            <Typography variant="h4" textAlign={isMobile ? "center" : "center"} margin={isMobile ? "10px 0px 40px 0px" : "60px 0px 40px 0px"} sx={isMobile ? {fontSize:'28px', fontWeight: 'bold'} : { fontSize:'32px', fontWeight: 'bold' }} >
              Explore the scientific literature
            </Typography>
            <TextField fullWidth={isMobile ? true : false} style={ isMobile ? {width: '95%', minWidth: '95%', maxWidth: '95%', marginBottom:'5px'} : { width: '60%', minWidth: '60%', maxWidth: '60%', marginBottom:'5px' }}
            name="goal" size="large" label={query ? '' : labelSearchBar} InputLabelProps={{ shrink: false }} 
            value={query} multiline onChange={(e) => setQuery(e.target.value)} inputProps={{maxLength: 150}}
            sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35',
            borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
              //backgroundColor: '#02161a',
              borderRadius: '10px',
            },}}
            onKeyPress={answerFilter === true ? {} : handleKeyPress}
            InputProps={ answerFilter === true ? {} : {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleSend} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#1c95aa', color: 'white', padding:'2px', marginRight:'0px'}}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}>
            </TextField>
            {answerFilter === true && (
              <TextField fullWidth={isMobile ? true : false} style={ isMobile ? {width: '95%', minWidth: '95%', maxWidth: '95%', marginBottom:'5px'} : { width: '60%', minWidth: '60%', maxWidth: '60%', marginBottom:'5px' }}
              name="goal" size="large" label={instructions ? '' : 'Specify instructions'} InputLabelProps={{ shrink: false }} 
              value={instructions} multiline onChange={(e) => setInstructions(e.target.value)} inputProps={{maxLength: 200}}
              sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35', 
              borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                //backgroundColor: '#02161a',
                borderRadius: '10px',
              }, marginTop: '5px'}}
              onKeyPress={handleKeyPress}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleSend} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#1c95aa', color: 'white', padding:'2px', marginRight:'0px'}}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}>
              </TextField>
              )}

            <Box marginBottom='10px' display='flex' justifyContent={isMobile ? '' : ''} style={ isMobile ? { width: '95%', minWidth: '95%', maxWidth: '95%' } : { width: '60%', minWidth: '60%', maxWidth: '60%' }}>
              {/*<FormControlLabel control={<Switch defaultChecked size="small"/>} label="Custom" color="textSecondary" />*/}
              <Filters answerFilter={answerFilter} strictCitationMode={strictCitationMode} instructions={instructions} sourceFilter={sourceFilter} yearFilter={yearFilter} citationCountFilter={citationCountFilter} updateAnswerFilter={updateAnswerFilter} updateStrictCitationMode={updateStrictCitationMode} updateInstructions={updateInstructions} updateSourceFilter={updateSourceFilter} updateYearFilter={updateYearFilter} updateCitationCountFilter={updateCitationCountFilter}/>
              {/* <Grid display="flex" flexDirection="row" sx={{marginTop: '6.5px', marginLeft:'12px'}}>
              <CloudUploadOutlinedIcon fontSize="small" sx={{color:'darkGrey', marginRight:'8px', marginTop:'1px'}}/>
              <Typography sx={{color:'darkGrey'}}>Upload File</Typography>
              </Grid> */}
            </Box>

            {/*{answerFilter === false && (
            <Box marginTop='15px' justifyContent={isMobile ? '' : ''} style={ isMobile ? { width: '94%', minWidth: '94%', maxWidth: '94%' } : { width: '59%', minWidth: '59%', maxWidth: '59%' }}>
              <Typography color="textSecondary" sx={{ fontSize: '12px', textAlign: 'justify' }}>
                With the Default mode, you can enter a question or a research topic and you will get a short and concise answer. Use the Custom mode to specify your instructions.
              </Typography>
            </Box>
            )}*/}

            {/*{answerFilter === true && (
            <Box marginTop='15px' justifyContent={isMobile ? '' : ''} style={ isMobile ? { width: '94%', minWidth: '94%', maxWidth: '94%' } : { width: '59%', minWidth: '59%', maxWidth: '59%' }}>
              <Typography color="textSecondary" sx={{ fontSize: '12px', textAlign: 'justify' }}>
                With the Custom mode, you can enter a topic and specify what you want from the model (“What methods are used?”, “Summarize and give me research question ideas”, “Write a research proposal”…).
              </Typography>
              <Typography marginTop='4px' color="textSecondary" sx={{ fontSize: '12px', textAlign: 'justify' }}>
                Unselect Strict citation to give the model more creativity.
              </Typography>
            </Box>
            )}*/}

            {(sendError == true) && (
              <Box>
                <Typography color="#c33535">Error. No papers found for your query. Please try another query and filters or try again later.</Typography>
              </Box>
            )}

          </Grid>
          )}
      
        </Grid>

      </Box>
    );
};

export default Bottombar;