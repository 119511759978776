import React, { useState, useEffect } from 'react';
import { Popover, Typography, CircularProgress, Button, IconButton, Grid, Tooltip, TextField } from '@mui/material';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useDispatch, useSelector } from 'react-redux';

import { saveChat, createNewList } from '../../../actions/posts';

const SaveChatInListPopover = ({ papers, post }) => {
    const dispatch = useDispatch();
    const [userLists, setUserLists] = useState(papers);
    const [newListTitle, setNewListTitle] = useState("");
    const [loadingCreateNewList, setLoadingCreateNewList] = useState(false)
    const [showCreateNewList, setShowCreateNewList] = useState(false)
    
    useEffect(() => {
        // Check if papers are available and not empty
        if (papers && papers.length > 0) {
            setUserLists(papers);
        } else {
            // nothing
        }
    }, [papers]);

    const handleSaveChat = async (post, list) => {
        
        dispatch(saveChat("nan", post._id, list));
    
        // find the index of the target list
        const listTitle = list.title;
        const listIndex = userLists.findIndex(list => list.title === listTitle);

        if (listIndex === -1) {
            console.error("List not found");
            return;
        }

        const targetList = userLists[listIndex];
        // check if the chat is already in that list
        if (targetList.chats.some(chat => chat === post._id)) {
            // Remove the chat from the list
            targetList.chats = targetList.chats.filter(chat => chat !== post._id);
        } else {
            // Add the chat to the list
            targetList.chats.push(post._id);
        }
        // update the userLists state with the modified list
        const updatedUserLists = [...userLists];
        updatedUserLists[listIndex] = targetList;
        setUserLists(updatedUserLists);
        setAnchorEl(null);
      };
    
    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpenSaveChatPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseSaveChatPopover = () => {
      setAnchorEl(null);
      setShowCreateNewList(false);
    };
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const handleCreateNewList = async (e) => {
        //e.preventDefault();
        try {
          setLoadingCreateNewList(true);
          const newList = await dispatch(createNewList("nan", newListTitle, "", post._id));
          setUserLists(newList);
          setLoadingCreateNewList(false);
          setAnchorEl(null);
          setShowCreateNewList(false)
        } catch (error) {
          setLoadingCreateNewList(false);
          if (error.message === 'List with this name already exists') {
            // Display the message in whichever way is appropriate for your UI.
            // This can be an alert, a notification component, etc.
            alert('A collection with this title already exists, please choose another title.');
        }
        }
      };
  
    const handleKeyPress = (e) => {
      if (newListTitle && e.key === 'Enter') { //keyCode 13 is just the enter key
        e.preventDefault();
        handleCreateNewList(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (newListTitle && loadingCreateNewList === false) ? setDisable(false) : setDisable(true);  // && loading === false
    }, [newListTitle]);

    const handleShowCreateNewList = () => {
        setShowCreateNewList(!showCreateNewList);
      };

    return (
        <Grid>
            <Tooltip title={"Save in collection"} arrow>
            <Button size="small"
            sx={{ textTransform: 'none', marginLeft: '10px', padding: '0px', minWidth:'35px', maxWidth:'35px', borderRadius: '10px' }}
            onClick={handleOpenSaveChatPopover}
            >
                {userLists?.some(list => list.chats.some(chat => chat === post._id)) ? (
                    <>
                    <BookmarkOutlinedIcon sx={{ fontSize: '22px' }} />
                    </>
                ) : (
                    <>
                    <BookmarkBorderOutlinedIcon sx={{ fontSize: '22px' }} />
                    </>
                )}
            </Button>
            </Tooltip>
            <Popover
                id={idPopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseSaveChatPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                elevation={0}
                sx={{ '& .MuiPopover-paper': { borderRadius: '10px', border: '1px solid #dddddd', padding: '12px 15px 16px 15px'} }}
              >
                <Grid container display="flex" flexDirection="column" alignItems="center" sx={{padding: '5px 10px 5px 10px'}}>
                
                {!showCreateNewList && (
                    <Grid container display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6" sx={{fontSize: '18px', marginBottom: '5px'}}>
                    Choose collection
                    </Typography>
                    {userLists?.map((list, i) => (
                        <Button key={i} sx={{textTransform: 'none', fontSize: '16px', margin: '0px 0px 5px 0px', borderRadius: '10px',
                        color: list.chats.some(chat => chat === post._id) ? 'primary' : 'Grey'}}
                        onClick={() => handleSaveChat(post, list)}
                        >
                            {list.chats.some(chat => chat === post._id)
                            ? <RadioButtonCheckedOutlinedIcon sx={{fontSize: '20px'}} />
                            : null}&nbsp;
                            {list.title}&nbsp;
                        </Button>
                    ))}

                    <Button sx={{textTransform: 'none', fontSize: '16px', margin: '0px 0px -10px 0px', borderRadius: '10px'}}
                    onClick={handleShowCreateNewList}>
                        <AddOutlinedIcon/>&nbsp;New collection&nbsp;
                    </Button>
                    </Grid>
                )}

                {showCreateNewList && (
                <Grid container display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6" sx={{fontSize: '20px', marginBottom: '15px'}}>
                        Save in a new collection
                    </Typography>
                    <TextField
                    size="large" label={newListTitle ? '' : 'Title'} InputLabelProps={{ shrink: false }}
                    value={newListTitle} multiline onChange={(e) => setNewListTitle(e.target.value)} inputProps={{maxLength: 30}}
                    sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#282828',
                    borderRadius:'10px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                        //backgroundColor: '#0e1619',
                        borderRadius: '10px',
                    },}}
                    onKeyPress={handleKeyPress}
                    >
                    </TextField>
                    <Button variant="contained" sx={{color: 'white', textTransform: 'none', fontSize: '16px', margin: '20px 0px 5px 0px', borderRadius: '10px'}}
                    onClick={handleCreateNewList} disabled={disable}
                    >
                        Create and save
                    </Button>
                    <Button sx={{textTransform: 'none', color: 'Grey', fontSize: '16px', margin: '5px 0px -10px 0px', borderRadius: '10px'}}
                    onClick={handleShowCreateNewList}>
                        Cancel
                    </Button>
                </Grid>
                )}
                </Grid>
              </Popover>
        </Grid>
    );
  };
  
  export default SaveChatInListPopover;