import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #36d2f5 30%, #40f1b0 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const About = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center"
    sx={{ marginTop: '-10px' }}>
        
        <Grid md={8} container display="flex" flexDirection="column" justifyContent="center" padding='0 5% 0 5%'>
            {/*<Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'32px', fontWeight: 'bold'} : { fontSize:'48px', fontWeight: 'bold' }} >Building the future of research.</Typography>*/}
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                About
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                We are a small interdisciplinary team based in Berlin and Paris.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                We believe in the importance of research. Our mission is to help researchers, whether professionals or enthusiasts, build the world of tomorrow.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ marginBottom: '15px' }} >
                As such, we created Lifa, a research-based, AI-powered text generation engine that facilitates access to scientific knowledge.
            </Typography>
            <Typography color="textSecondary" textAlign={"justify"} sx={{ }} >
                If you see any potential improvements or would like to participate in any way in this mission, please don't hesitate to reach out to us by email at <a href="mailto:support@lifa.ai" style={{color: '#1c95aa', textDecoration: 'none'}}>support@lifa.ai</a> or on <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: '#1c95aa', "textDecoration": "none"}}>Twitter</a>.
            </Typography>
        </Grid>

        <Grid>
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 5}
        sx={isMobile ? { margin: '40px 0px 20px 0px' } : { position: 'fixed', bottom: 0, left: 0, margin: '40px 0px 30px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey'}}>
                <Link 
                    to="/contact" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    Contact
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '30px 10px 30px 10px' : '30px 60px 30px 60px'}>        
        </Grid>

      </Grid>

    </Grid>
  );
};

export default About;