import React from 'react';
import { Container, Grid } from '@mui/material';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '@fontsource/poppins';
import '@fontsource/inter/300.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleOAuthProvider } from '@react-oauth/google';

const theme = createTheme({typography: {
    fontFamily: 'poppins, sans-serif',
  },});
const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {main: '#1c95aa',}, //#41b2c6 #a187ff #1c95aa
    background: {
        //default: "#00090b",
        //paper: '#00090b',
      },
  },
  typography: {
    fontFamily: 'poppins, sans-serif',
    fontSize: 13,
  },
});

import Navbar from './components/Navbar/Navbar';
import Bottombar from './components/Bottombar/Bottombar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import UserProfile from './components/UserProfile/UserProfile';
import Library from './components/Library/Library';
import History from './components/History/History';
import SavedPapers from './components/SavedPapers/SavedPapers';
import EditProfile from './components/EditProfile/EditProfile';
import Drawer from './components/Drawer/Drawer';
import PostDetails from './components/ChatDetails/PostDetails';
import About from './components/Docs/About';
import Terms from './components/Docs/Terms';
import Privacy from './components/Docs/Privacy';
import Contact from './components/Docs/Contact';

const App = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });
    
    return(
        <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <GoogleOAuthProvider clientId="986648263517-nf3sgp261luhetvatnntce9mokco5de3.apps.googleusercontent.com">
                {/*<Navbar/>*/}
                <Drawer/>
                <Grid marginLeft={(isMobile || !user) ? '0px': '180px'}
                marginTop={((isMobile) || (!isMobile && !user)) ? '0px': '-50px'}
                >
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/c/library" component={Library} />
                    <Route path="/c/:id" component={PostDetails} />
                    <Route path="/profile/editprofile" component={EditProfile} />
                    <Route path="/profile/:id" component={UserProfile} />
                    <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/" />)} />
                    <Route path="/about" component={About} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/contact" component={Contact} />
                </Switch></Grid>
                {/* <Bottombar/> */}
        </GoogleOAuthProvider>
        </ThemeProvider>
        </BrowserRouter>
    )
};
// BrowserRouter: router implementation for HTML5 browser (use different url for different...)
// Switch: either show the home either show the auth
// first check if the user is logged in before showing Auth page

export default App;