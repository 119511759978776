import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar, Tabs, Tab, Box, Popover, TextField } from '@mui/material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getPosts, getSavedPapers, createNewList, deleteList } from '../../actions/posts';
import Posts from '../Posts/Posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './historyStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
  tabsScope: `${PREFIX}-tabsScope`,
  tabs: `${PREFIX}-tabs`,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const UserList = () => {
    const { posts, papers, isLoading } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const [valueTabScope, setValueTabScope] = useState(0);
    const [historyToGet, setHistoryToGet] = useState("history");
    const [chatList, setChatList] = useState(null);
    const [userLists, setUserLists] = useState(papers);
    const [valueTab, setValueTab] = useState(0);
    const [listToGet, setListToGet] = useState(0);
    const [newListTitle, setNewListTitle] = useState("");
    const [loadingCreateNewList, setLoadingCreateNewList] = useState(false)
    
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true
    });

    useEffect(() => {
      if (userId) {
        dispatch(getPosts(userId, historyToGet, listToGet));
      }
    }, [userId, valueTabScope, valueTab]); //happens whenever the id of the user changes

    function handleTabScopeChange(event, newValueTabScope) {
      setValueTabScope(newValueTabScope);
      if (newValueTabScope === 0) {
        setHistoryToGet("history");
      } else if (newValueTabScope === 1) {
        setHistoryToGet("searches");
      } else if (newValueTabScope === 2) {
        setHistoryToGet("files");
      }
    };

    useEffect(() => {
      if (userId) {
        //dispatch(getSavedPapers(userId, listToGet));
        dispatch(getSavedPapers(userId));
      }
    }, [userId]);

    useEffect(() => {
      // Check if papers are available and not empty
      if (papers && papers.length > 0) {
          if (listToGet === 0) {
              // Flatten all paper arrays into a single array
              const allPapers = papers.flatMap(list => list.items);
              // Remove duplicates based on paperId
              const uniquePapers = Array.from(new Set(allPapers.map(p => p.paperId)))
              .map(paperId => allPapers.find(p => p.paperId === paperId));

              setChatList(uniquePapers);
          } else {
              setChatList(papers[listToGet].items);
          }
          setUserLists(papers);
      } else {
          // nothing
      }
    }, [papers, listToGet]);

    function handleTabChange(event, newValueTab) {
      setValueTab(newValueTab);
      setListToGet(newValueTab);
    };

    // Delete collection menu
    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
      setAnchor(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchor(null);
    };
    const handleDelete = async () => {
      try {
        const listToDelete = listToGet;
        setValueTab(0);
        setListToGet(0);
        const newList = await dispatch(deleteList('nan', listToDelete));
        setUserLists(newList);
        setAnchor(null);
      } 
      catch (error) {

      }
    };

    // for create collection popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpenNewListPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseNewListPopover = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCreateNewList = async (e) => {
      //e.preventDefault();
      try {
        setLoadingCreateNewList(true);
        const newList = await dispatch(createNewList("nan", newListTitle));
        setUserLists(newList);
        setLoadingCreateNewList(false);
        setAnchorEl(null);
        setNewListTitle('');
      } catch (error) {
        setLoadingCreateNewList(false);
        if (error.message === 'List with this name already exists') {
          // Display the message in whichever way is appropriate for your UI.
          // This can be an alert, a notification component, etc.
          alert('A collection with this title already exists, please choose another title.');
      }
    }
    };

    const handleKeyPress = (e) => {
      if (newListTitle && e.key === 'Enter') { //keyCode 13 is just the enter key
        e.preventDefault();
        handleCreateNewList(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (newListTitle && loadingCreateNewList === false) ? setDisable(false) : setDisable(true);  // && loading === false
    }, [newListTitle]);

    if(!user?.result?.email) {
      return (
          <Paper style={{padding: theme.spacing(2), borderRadius: 15}} elevation={0}>
              <Typography variant="h6" align="center">
                  Sign in to see your history.
              </Typography>
          </Paper>
      );
    };

    if (!posts) {
      return (
        <CircularProgress/>
      );
    };

    return (
      <StyledGrow in>
          <Grid className={classes.gridContainer} container justifyContent="center" alignItems="stretch" spacing={4} sx={{ padding: '0px 16px 0px 16px' }} >
            
            <Grid item xs={12} sm={12} md={11.25} sx={{marginBottom: '-35px'}}>
              <Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontSize: '22px', fontWeight: 'bold' }} >
                Chat history
              </Typography>
            </Grid>

          <Grid item xs={12} sm={12} md={2.25}>
            <Grid container>
              <Typography variant="h6" component="h2" sx={{ fontSize: '20px', color: 'darkGrey', fontWeight: 'semi-bold', margin:'0px 0px 12px 0px' }}>
                <AppsOutlinedIcon sx={{ fontSize: '22px', margin:'0px 1px -4px 0px' }}/> Collections
              </Typography>
            </Grid>
            {/*<Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              Collections
            </Typography>*/}
            <Grid container display="flex" flexDirection="column" justifyContent="center"
            width="100%" sx={{border: '1.5px solid #00242a', borderRadius: '10px', justifyContent: 'center'}}>
              <Tabs value={valueTab} onChange={handleTabChange} orientation="vertical"
              TabIndicatorProps={{style: {color: '#ffffff', background:'#1c95aa'}}}
              sx={{"& button.Mui-selected": {background: "#011118", color: '#1c95aa'}, "& .MuiTab-root": {color: "#9f9f9f"}}} >
                  {userLists?.map((list, i) => (
                    <Tab key={i} className={classes.tabs} label={list.title} {...a11yProps(0)}
                    />
                  ))}
              </Tabs>
              <Button sx={{textTransform: 'none', color: 'darkGrey', fontSize: '16px', maxHeight: '40px', minHeight: '40px', margin: '10px 0px 10px 0px'}}
              onClick={handleOpenNewListPopover}>
                <AddOutlinedIcon/>&nbsp;New&nbsp;
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseNewListPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{ '& .MuiPopover-paper': { background: "#010e11", borderRadius: '10px', border: '1px solid #141414', padding: '12px 15px 16px 15px'} }}
              >
                <Grid container display="flex" flexDirection="column" alignItems="center" sx={{padding: '5px 10px 5px 10px'}}>
                <Typography variant="h6" sx={{fontSize: '20px', marginBottom: '15px'}}>
                  Create a new collection
                </Typography>
                <TextField
                  size="large" label={newListTitle ? '' : 'Title'} InputLabelProps={{ shrink: false }}
                  value={newListTitle} multiline onChange={(e) => setNewListTitle(e.target.value)} inputProps={{maxLength: 30}}
                  sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { borderColor: '#282828', borderRadius:'10px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
                    backgroundColor: '#0e1619',
                    borderRadius: '10px',
                  },}}
                  onKeyPress={handleKeyPress}
                  >
                </TextField>
                <Button variant="contained" sx={{color: 'white', textTransform: 'none', fontSize: '16px', maxWidth: '80px', minWidth: '80px', margin: '20px 0px 0px 0px', borderRadius: '10px'}}
                //onClick={handleOpenNewListPopover}
                onClick={handleCreateNewList} disabled={disable}
                >
                  Create
                </Button>
                </Grid>
              </Popover>
            </Grid>
          </Grid>


          <Grid item xs={12} sm={12} md={9}>
            <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              {/*<Typography variant="h6" component="h2" sx={{ fontSize: '18px', color: 'darkGrey', fontWeight: 'semi-bold', margin:'0px 0px 10px 0px' }}>
                <ChatOutlinedIcon fontSize="small" sx={{ margin:'0px 0px -4px 0px' }}/> Chats
              </Typography>*/}

              <Grid item xs={true} style={{ flexGrow: 1 }}>
                <Tabs value={valueTabScope} onChange={handleTabScopeChange} orientation={"horizontal"} variant="scrollable"
                TabIndicatorProps={{style: {color: '#ffffff', background:'#1c95aa'}}} style={{margin:'-5px 0px 20px 16px', maxHeight: '40px', minHeight: '40px'}}
                sx={{"& button.Mui-selected": {background: "#011118", color: '#1c95aa'}, "& .MuiTab-root": {color: "#9f9f9f"}}} >
                  <Tab className={classes.tabsScope} label={"All"}
                  />
                  <Tab className={classes.tabsScope} label={
                    <Box display="flex" alignItems="center">
                      <SearchIcon fontSize="medium" style={{ marginRight: 8 }} />
                      Searches
                    </Box>
                  }
                  />
                  <Tab className={classes.tabsScope} label={
                    <Box display="flex" alignItems="center">
                      <ArticleOutlinedIcon fontSize="medium" style={{ marginRight: 8 }} />
                      PDFs
                    </Box>
                  }
                  />
                </Tabs>
              </Grid>
              {(valueTab !== 0) && (
                <Grid>
                  <Button style={{color: 'grey', borderRadius: '20px', height: '33px', minWidth: '45px', maxWidth: '45px'}} size="small"
                  onClick={openMenu}>
                    <MoreHorizIcon fontSize="medium" />
                  </Button>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    <div>
                      <MenuItem onClick={handleDelete} sx={{color: '#c33535'}}>
                        <DeleteOutlinedIcon sx={{ fontSize: 22 }}/>&nbsp;Delete this collection
                      </MenuItem>
                    </div>
                  </Menu>
                </Grid>
              )}
              </Grid>
            <Posts posts={posts} isLoading={isLoading} papers={papers} />
          </Grid>
          </Grid>
      </StyledGrow>
    );

};

export default UserList;