import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

const PREFIX = 'Navbar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};

const StyledAppBar = styled(AppBar)((
  {
    theme
  }
) => ({
  [`&.${classes.appBar}`]: {
    borderRadius: 2,
    //minHeight: '60px',
    margin: '0px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', //space-between
    alignItems: 'center',
    padding: '0px 0% 0px 200px',
    [theme.breakpoints.down('md')]: {
      //flexDirection: 'column',
      //maxHeight: '50px',
      padding: '0px 0% 0px 0%',
    },
  },

  [`& .${classes.heading}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '2em',
    fontWeight: 300,
  },

  [`& .${classes.image}`]: {
    marginLeft: '0px',
    marginRight: '0px',
    //marginBottom:'5px',
    height:"28px",
    borderRadius:'3px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginRight: '0px',
      //height:"32px",
    },
  },

  [`& .${classes.toolbar}`]: {
    //display: 'flex',
    //justifyContent: 'flex-end',
    //width: '400px',
    //marginLeft:'-30px',
    [theme.breakpoints.down('md')]: {
      //width: 'auto',
      //marginLeft:'-15px',
      //padding: '0px 1% 0px 0%',
    },
  },

  [`& .${classes.profile}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '240px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: 20,
      justifyContent: 'center',
    },
  },

  [`& .${classes.logout}`]: {
    borderRadius: 15,
    color: 'lightGrey', //#424242
    //backgroundColor: 'white',
    borderColor: 'white',
    //marginLeft: '20px',
    minWidth:'45px',
    maxWidth:'45px',
    minHeight:'45px',
    maxHeight:'45px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'light',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#F0EFEF"
    },
    [theme.breakpoints.down('md')]: {
      minWidth:'45px',
      maxWidth:'45px',
    },
  },

  [`& .${classes.userName}`]: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },

  [`& .${classes.brandContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.purple}`]: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  [`& .${classes.buttonLogin}`]: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      background: "#39796d"
    },
    [theme.breakpoints.down('md')]: {
      //minWidth:'80px',
      //maxWidth:'80px',
    },
  },

  [`& .${classes.buttonCreate}`]: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      background: "#39796d"
    },
    [theme.breakpoints.down('md')]: {
      minWidth:'50px',
      maxWidth:'50px',
    },
  }
}));

export default StyledAppBar;