import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 requests / month',
      'To try Lifa',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    buttonColor: '#df7427', buttonBackgroundColor: 'white', buttonHoverBackground:"#f7d3b9"
  },
  {
    title: 'Premium',
    price: '4.99',
    description: [
      '300 messages / month',
      'Suitable for most users',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    buttonColor: 'white', buttonBackgroundColor: '#df7427', buttonHoverBackground:"#cf6a22"
  },
  {
    title: 'Pro',
    price: '9.99',
    description: [
      '800 messages / month',
      'Ideal for heavy users',
    ],
    buttonText: 'Get pro',
    buttonVariant: 'outlined',
    buttonColor: '#df7427', buttonBackgroundColor: 'white', buttonHoverBackground:"#f7d3b9"
  },
];

const theme = createTheme();

export default function Pricing() {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

  return (
    <Grid container alignItems="center" >
      
      <Container disableGutters maxWidth="md" component="main" sx={isMobile ? {} : { paddingLeft: '90px', paddingRight: '90px' }}>
        <Typography marginBottom="20px"
          component="h1"
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
          fontWeight="bold"
        >
          Pricing
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={2} sx={isMobile ? {} : { paddingLeft: '90px', paddingRight: '90px' }}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              md={4}
            >
              <Card elevation={0} sx={{borderRadius:"10px", border: '0.1px solid lightGrey'}}>
                <CardHeader padding='0px'
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', fontSize:'20px' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box marginBottom="8px"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                    }}
                  >
                    <Typography component="h2" variant="h6" fontSize='20px' color="text.primary">
                      €{tier.price}
                    </Typography>
                    <Typography variant="h6" fontSize='18px' color="text.secondary">
                      /month
                    </Typography>
                  </Box>
                    {tier.description.map((line) => (
                      <Typography
                        variant="subtitle2"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} sx={{margin:"-8px 0 10px 0", textTransform:'none', color: tier.buttonColor, backgroundColor: tier.buttonBackgroundColor, borderColor:'#df7427', borderRadius: '20px', fontSize: "16px",fontWeight:'bold', "&:hover": { background: tier.buttonHoverBackground, borderColor:'#df7427'} }}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </Grid>
)}