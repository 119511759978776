const journalScore = {
    'Genome Biology': 9.249,
    'Annual Review of Pathology: Mechanisms of Disease': 8.628,
    'Annual Review of Plant Biology': 8.131,
    'Nature Sustainability': 6.568,
    'Molecular Systems Biology': 6.22,
    'Fungal Diversity': 5.784,
    'Annual Review of Entomology': 5.655,
    'Annual Review of Ecology, Evolution, and Systematics': 5.409,
    'Nature Ecology and Evolution': 5.374,
    'Molecular Plant': 5.209,
    'Nature Plants': 5.205,
    'Studies in Mycology': 5.202,
    'Trends in Ecology and Evolution': 5.127,
    'Ecology Letters': 4.426,
    'Biological Reviews': 4.388,
    'Remote Sensing of Environment': 4.057,
    'PLoS Biology': 4.005,
    'Mycosphere': 3.918,
    'Annual Review of Animal Biosciences': 3.696,
    'Plant Cell': 3.646,
    'ISME Journal': 3.619,
    'Biometrika': 3.566,
    'Journal of Bioresources and Bioproducts': 3.549,
    'Nature Food': 3.525,
    'Frontiers in Ecology and the Environment': 3.51,
    'Molecular Biology and Evolution': 3.423,
    'Annual Review of Phytopathology': 3.379,
    'Evolution Letters': 3.297,
    'Ecological Monographs': 3.273,
    'Persoonia: Molecular Phylogeny and Evolution of Fungi': 3.273,
    'Trends in Plant Science': 3.213,
    'Current Opinion in Plant Biology': 3.206,
    'Systematic Biology': 3.185,
    'Conservation Letters': 3.083,
    'New Phytologist': 3.047,
    'Plant Communications': 2.985,
    'Soil Biology and Biochemistry': 2.934,
    'Limnology And Oceanography Letters': 2.848,
    'Current Biology': 2.806,
    'Methods in Ecology and Evolution': 2.805,
    'Plant Biotechnology Journal': 2.776,
    'Journal of Integrative Plant Biology': 2.729,
    'Molecular Ecology Resources': 2.594,
    'Food Hydrocolloids': 2.578,
    'Comprehensive Reviews in Food Science and Food Safety': 2.566,
    'Trends in Food Science and Technology': 2.522,
    'Global Ecology and Biogeography': 2.447,
    'BioScience': 2.42,
    'Ecography': 2.372,
    'PLoS Genetics': 2.363,
    'Advances in Nutrition': 2.331,
    'BMC Biology': 2.324,
    'Communications Biology': 2.251,
    'Wiley Interdisciplinary Reviews: Water': 2.245,
    'Global Food Security': 2.173,
    'Plant Physiology': 2.166,
    'Biological Conservation': 2.146,
    'Current Forestry Reports': 2.143,
    'Plant Journal': 2.118,
    'Reviews in Aquaculture': 2.117,
    'Philosophical Transactions of the Royal Society B: Biological Sciences': 2.084,
    'American Journal of Agricultural Economics': 2.075,
    'Functional Ecology': 2.073,
    'Conservation Biology': 2.071,
    'Journal of Ecology': 2.07,
    'Reviews in Fisheries Science and Aquaculture': 2.064,
    'Quarterly Review of Biology': 2.063,
    'Annual review of food science and technology': 2.061,
    'Plant, Cell and Environment': 2.029,
    'Biochar': 1.966,
    'Ecology': 1.958,
    'Ecosystem Services': 1.956,
    'Molecular Ecology': 1.937,
    'Geoderma': 1.933,
    'Journal of Animal Ecology': 1.913,
    'People and Nature': 1.909,
    'Proceedings of the Royal Society B: Biological Sciences': 1.898,
    'Food Policy': 1.896,
    'SOIL': 1.894,
    'Life Science Alliance': 1.884,
    'PLoS Computational Biology': 1.872,
    'Fish and Fisheries': 1.868,
    'Critical Reviews in Food Science and Nutrition': 1.862,
    'Physics of Life Reviews': 1.848,
    'NJAS - Wageningen Journal of Life Sciences': 1.841,
    'Journal of Experimental Botany': 1.823,
    'Environmental DNA': 1.809,
    'Horticulture Research': 1.805,
    'Soil and Tillage Research': 1.796,
    'Evolution and Human Behavior': 1.795,
    'Journal of Virology': 1.795,
    'Database : the journal of biological databases and curation': 1.786,
    'Food Security': 1.764,
    'International Journal of Biological Sciences': 1.75,
    'Diversity and Distributions': 1.731,
    'Quaternary Science Reviews': 1.701,
    'International Soil and Water Conservation Research': 1.696,
    'mSystems': 1.691,
    'Mammal Review': 1.672,
    'Land Use Policy': 1.656,
    'European Review of Agricultural Economics': 1.645,
    'Agriculture, Ecosystems and Environment': 1.639,
    'Food Chemistry': 1.624,
    'Agricultural and Forest Meteorology': 1.623,
    'IMA Fungus': 1.616,
    'Plants People Planet': 1.611,
    'Biogeosciences': 1.598,
    'Biology and Fertility of Soils': 1.591,
    'Current Opinion in Insect Science': 1.589,
    'Computers and Electronics in Agriculture': 1.587,
    'Agricultural Systems': 1.574,
    'Systematic Entomology': 1.572,
    'Reviews in Fish Biology and Fisheries': 1.56,
    'Agricultural Water Management': 1.524,
    'Agronomy for Sustainable Development': 1.509,
    'Evolution; international journal of organic evolution': 1.503,
    'American Naturalist': 1.49,
    'Marine Pollution Bulletin': 1.49,
    'Oikos': 1.471,
    'Limnology and Oceanography': 1.466,
    'Journal of Contextual Behavioral Science': 1.465,
    'Science China Life Sciences': 1.449,
    'Geobiology': 1.441,
    'Harmful Algae': 1.44,
    'Journal of Agricultural Economics': 1.438,
    'Myrmecological News': 1.436,
    'Urban Forestry and Urban Greening': 1.434,
    'Current Opinion in Food Science': 1.431,
    'Movement Ecology': 1.428,
    'Ecosystems': 1.427,
    'Journal of Animal Science and Biotechnology': 1.423,
    'Molecular Phylogenetics and Evolution': 1.414,
    'Food Science and Human Wellness': 1.408,
    'Theoretical And Applied Genetics': 1.403,
    'Molecular Plant Pathology': 1.398,
    'Ecological Indicators': 1.396,
    'Field Crops Research': 1.396,
    'Environmental Microbiology': 1.391,
    'Fungal Systematics and Evolution': 1.376,
    'Agricultural Economics (United Kingdom)': 1.375,
    'Evolutionary Applications': 1.374,
    'Genome Biology and Evolution': 1.374,
    'Meat Science': 1.373,
    'Journal of Biogeography': 1.371,
    'Plant and Cell Physiology': 1.37,
    'Remote Sensing in Ecology and Conservation': 1.366,
    'Food Research International': 1.36,
    'Solid Earth': 1.35,
    'Biometrics': 1.348,
    'Cladistics': 1.347,
    'Plant Phenomics': 1.341,
    'Food Packaging and Shelf Life': 1.338,
    'Journal of Human Evolution': 1.338,
    'Rice': 1.335,
    'Canadian Journal of Agricultural Economics': 1.333,
    'Entomologia Generalis': 1.324,
    'Journal of Rural Studies': 1.316,
    'Journal of Pest Science': 1.31,
    'Critical Reviews in Plant Sciences': 1.299,
    'Biological Research': 1.294,
    'Insect Systematics and Diversity': 1.294,
    'Biology Letters': 1.293,
    'Nutrients': 1.291,
    'Journal of Geophysical Research G: Biogeosciences': 1.285,
    'Postharvest Biology and Technology': 1.282,
    'Ecosystems and People': 1.28,
    'PaleoAmerica': 1.28,
    'Innovative Food Science and Emerging Technologies': 1.269,
    'Soil Dynamics and Earthquake Engineering': 1.251,
    'Plant Science': 1.249,
    'Restoration Ecology': 1.238,
    'Frontiers in Plant Science': 1.231,
    'Fire Ecology': 1.224,
    'Preslia': 1.223,
    'Annals of Botany': 1.213,
    'NeoBiota': 1.213,
    'Crop Journal': 1.209,
    'Precision Agriculture': 1.209,
    'Artificial Intelligence in Agriculture': 1.202,
    'Horticultural Plant Journal': 1.199,
    'Progress in Oceanography': 1.198,
    'Forest Ecology and Management': 1.184,
    'Phytochemistry Reviews': 1.184,
    'Journal of Dairy Science': 1.179,
    'Plant Cell Reports': 1.178,
    'LWT': 1.173,
    'EvoDevo': 1.172,
    'Applied Soil Ecology': 1.164,
    'Animal Frontiers': 1.163,
    'International Journal of Geomechanics': 1.163,
    'Pedosphere': 1.163,
    'Journal of the Academy of Nutrition and Dietetics': 1.162,
    'Food Quality and Preference': 1.161,
    'Journal of Food Engineering': 1.161,
    'BMC Plant Biology': 1.159,
    'Marine Life Science and Technology': 1.158,
    'Soil Ecology Letters': 1.158,
    'Environmental Technology and Innovation': 1.157,
    'Wildlife Monographs': 1.157,
    'Bulletin of the American Museum of Natural History': 1.155,
    'Plant and Soil': 1.153,
    'Aquatic Toxicology': 1.152,
    'Land Degradation and Development': 1.145,
    'Physiologia Plantarum': 1.141,
    'Plant Physiology and Biochemistry': 1.141,
    'Applied Geography': 1.138,
    'European Journal of Agronomy': 1.135,
    'Plant Molecular Biology': 1.132,
    'Environmental and Experimental Botany': 1.129,
    'Fungal Biology Reviews': 1.126,
    'Ocean and Coastal Management': 1.126,
    'Forest Policy and Economics': 1.124,
    'Plant Reproduction': 1.123,
    'Frontiers in Marine Science': 1.122,
    'GCB Bioenergy': 1.121,
    'Plant Methods': 1.121,
    'Animal Nutrition': 1.12,
    'Biology Direct': 1.117,
    'Aquaculture, Economics and Management': 1.109,
    'Fish and Shellfish Immunology': 1.108,
    'Insect Biochemistry and Molecular Biology': 1.106,
    'Insect Conservation and Diversity': 1.106,
    'Food and Function': 1.103,
    'Poultry Science': 1.1,
    'Journal of Agricultural and Food Chemistry': 1.099,
    'Palaeogeography, Palaeoclimatology, Palaeoecology': 1.097,
    'Frontiers in Ecology and Evolution': 1.095,
    'Basic and Applied Ecology': 1.094,
    'Forest Ecosystems': 1.093,
    'Antioxidants': 1.084,
    'Aquaculture': 1.082,
    'Coral Reefs': 1.081,
    'Perspectives in Plant Ecology, Evolution and Systematics': 1.076,
    'Microbial Ecology': 1.072,
    'Evolution: Education and Outreach': 1.07,
    'Ecosphere': 1.067,
    'Oceanography and Marine Biology': 1.064,
    'Paleobiology': 1.063,
    'Biosystems Engineering': 1.061,
    'Palaeontology': 1.058,
    'Food Control': 1.057,
    'Zoological Journal of the Linnean Society': 1.055,
    'Astrobiology': 1.05,
    'Food and Energy Security': 1.049,
    'Mycorrhiza': 1.048,
    'Biodiversity and Conservation': 1.045,
    'Biomass and Bioenergy': 1.045,
    'Global Ecology and Conservation': 1.045,
    'Phytobiomes Journal': 1.044,
    'Animal Behaviour': 1.043,
    'Journal of the International Society of Sports Nutrition': 1.041,
    'Molecular Nutrition and Food Research': 1.038,
    'Food Microbiology': 1.036,
    'Agriculture and Human Values': 1.035,
    'European Journal of Soil Science': 1.035,
    'Marine Policy': 1.034,
    'Plant Direct': 1.034,
    'Applied and Environmental Microbiology': 1.032,
    'Molecular Plant-Microbe Interactions': 1.032,
    'Journal of Systematics and Evolution': 1.029,
    'Genetics Selection Evolution': 1.027,
    'MycoKeys': 1.025,
    'Ecosystem Health and Sustainability': 1.023,
    'Frontiers in Forests and Global Change': 1.022,
    'Pest Management Science': 1.022,
    'Tree Physiology': 1.019,
    'Journal of Experimental Biology': 1.018,
    'Proceedings of the Japan Academy Series B: Physical and Biological Sciences': 1.015,
    'Vegetation History and Archaeobotany': 1.015,
    'Boreas': 1.013,
    'Journal of Vegetation Science': 1.008,
    'Oecologia': 1.007,
    'Zoologica Scripta': 1.002,
    'Administrative Science Quarterly': 16.555,
    'Science': 13.328,
    'Psychological Bulletin': 7.635,
    'Journal of Consumer Research': 6.024,
    'Radiocarbon': 4.271,
    'Psychological Methods': 4.247,
    'IEEE Signal Processing Magazine': 3.939,
    'Human Relations': 3.508,
    'Philosophical Review, The': 3.428,
    'Explorations in Economic History': 3.017,
    'Psychological Review': 2.801,
    'Behavior Research Methods': 2.753,
    'Nous': 2.738,
    'Political Psychology': 2.61,
    'Journal of Business Ethics': 2.59,
    'Computers in Human Behavior': 2.464,
    'Postdigital Science and Education': 2.453,
    'Mind': 2.433,
    'Journal of Marriage and Family': 2.386,
    'Journal of Economic History': 2.331,
    'Applied Psychology': 2.279,
    'Journal of Peasant Studies': 2.277,
    'Social Forces': 2.132,
    'Public Opinion Quarterly': 2.129,
    'Science Education': 2.115,
    'Emotion Review': 2.113,
    'Decision Support Systems': 2.076,
    'Social Science and Medicine': 1.978,
    'Business Ethics Quarterly': 1.947,
    'Journal of Archaeological Research': 1.907,
    'Gender and Society': 1.866,
    'European Child and Adolescent Psychiatry': 1.799,
    'Evolution and Human Behavior': 1.795,
    'Group and Organization Management': 1.784,
    'Psychonomic Bulletin and Review': 1.783,
    'Criminology and Public Policy': 1.737,
    'Television and New Media': 1.708,
    'Quaternary Science Reviews': 1.701,
    'Economy and Society': 1.69,
    'Theory and Society': 1.673,
    'Sociology of Religion': 1.651,
    'Health Education and Behavior': 1.65,
    'Group Processes and Intergroup Relations': 1.631,
    'Annals of the New York Academy of Sciences': 1.626,
    'International Migration Review': 1.626,
    'Journal of Philosophy': 1.614,
    'Multivariate Behavioral Research': 1.606,
    'Neural Computation': 1.544,
    'Feminist Economics': 1.534,
    'Philosophy and Phenomenological Research': 1.534,
    'Journalism': 1.511,
    'Qualitative Research': 1.511,
    'Economic History Review': 1.496,
    'British Journal of Mathematical and Statistical Psychology': 1.49,
    'British Journal for the Philosophy of Science': 1.477,
    'Clinical Neuropsychologist': 1.477,
    'Journal of Ethnic and Migration Studies': 1.435,
    'Natural Language Semantics': 1.426,
    'Psychology of Women Quarterly': 1.417,
    'Public Understanding of Science': 1.408,
    'Men and Masculinities': 1.397,
    'Psychology of Aesthetics, Creativity, and the Arts': 1.397,
    'Cliometrica': 1.383,
    'School Leadership and Management': 1.379,
    'Political Geography': 1.354,
    'Plant Phenomics': 1.341,
    'Anxiety, Stress and Coping': 1.336,
    'European Review of Economic History': 1.331,
    'Philosophy and Technology': 1.299,
    'Human Resource Development Quarterly': 1.293,
    'Annual Review of Anthropology': 1.284,
    'Psychology and Psychotherapy: Theory, Research and Practice': 1.283,
    'American Antiquity': 1.278,
    'Journal of Personality Assessment': 1.271,
    'European Psychologist': 1.259,
    'Journal of Sex Research': 1.25,
    'Philosophical Studies': 1.242,
    'Minds and Machines': 1.232,
    'Design Studies': 1.231,
    'Australasian Journal of Philosophy': 1.225,
    'Journal of World Prehistory': 1.225,
    'Philosophers Imprint': 1.22,
    'Journal of Archaeological Science': 1.218,
    'Anthropological Theory': 1.208,
    'International Journal of Social Robotics': 1.177,
    'Eye': 1.176,
    'Mind and Language': 1.174,
    'Poetics': 1.173,
    'Journal of Research in Music Education': 1.171,
    'Science Technology and Human Values': 1.17,
    'Teachers and Teaching: Theory and Practice': 1.167,
    'Journal of Linguistics': 1.153,
    'International Theory': 1.133,
    'Signs': 1.128,
    'Thinking and Reasoning': 1.128,
    'Linguistics and Philosophy': 1.127,
    'Journal of Applied Behavior Analysis': 1.123,
    'Journal of Medical Ethics': 1.111,
    'Journal of Experimental Psychology: Human Perception and Performance': 1.108,
    'American Journal of Orthopsychiatry': 1.107,
    'Philosophy Compass': 1.106,
    'Aggressive Behavior': 1.103,
    'Palgrave Communications': 1.086,
    'Journal of Archaeological Method and Theory': 1.084,
    'Journal of Agrarian Change': 1.077,
    'European Accounting Review': 1.072,
    'Journal of Semantics': 1.069,
    'Perspectives: Studies in Translation Theory and Practice': 1.066,
    'Games and Culture': 1.065,
    'Intelligence': 1.059,
    'Journal of Writing Research': 1.059,
    'Convergence': 1.056,
    'Ethnicity and Health': 1.045,
    'European Journal of Cultural Studies': 1.04,
    'Archives of Sexual Behavior': 1.038,
    'Historical Methods': 1.036,
    'Holocene': 1.025,
    'Memory and Cognition': 1.025,
    'Past and Present': 1.016,
    'Vegetation History and Archaeobotany': 1.015,
    'Journal of Clinical Psychology': 1.014,
    'Boreas': 1.013,
    'Nature Reviews Molecular Cell Biology': 34.201,
    'Cell': 26.494,
    'Nature Medicine': 24.687,
    'Nature Biotechnology': 22.781,
    'Nature Reviews Cancer': 20.977,
    'Nature Reviews Genetics': 19.454,
    'Nature Genetics': 16.732,
    'Nature Methods': 14.358,
    'Physiological Reviews': 13.693,
    'Nature Catalysis': 13.187,
    'Cancer Cell': 12.578,
    'Annual Review of Biochemistry': 11.154,
    'Current Protocols in Bioinformatics': 10.408,
    'Cell Host and Microbe': 10.227,
    'Journal of Clinical Oncology': 10.163,
    'Cell Metabolism': 10.037,
    'Nature Cancer': 9.817,
    'Nature Cell Biology': 9.568,
    'Molecular Cell': 9.541,
    'Nature Reviews Methods Primers': 9.476,
    'Genome Biology': 9.249,
    'Nature Microbiology': 9.187,
    'Cell Stem Cell': 9.156,
    'Molecular Cancer': 8.703,
    'Nucleic Acids Research': 8.234,
    'Lancet Diabetes and Endocrinology,The': 8.217,
    'Annual Review of Plant Biology': 8.131,
    'Nature Reviews Endocrinology': 8.124,
    'JAMA Oncology': 8.103,
    'Cell Research': 8.06,
    'Nature Structural and Molecular Biology': 7.549,
    'Annual Review of Genetics': 7.34,
    'Endocrine Reviews': 7.205,
    'Cell Systems': 7.112,
    'Nature Metabolism': 7.045,
    'Annual Review of Biophysics': 6.926,
    'Chem': 6.8,
    'Nature Protocols': 6.732,
    'Signal Transduction and Targeted Therapy': 6.63,
    'Annual Review of Cell and Developmental Biology': 6.627,
    'Nature Biomedical Engineering': 6.625,
    'Annals of the Rheumatic Diseases': 6.489,
    'Annual Review of Physiology': 6.429,
    'Molecular Systems Biology': 6.22,
    'Journal of Hematology and Oncology': 6.046,
    'Trends in Cell Biology': 5.987,
    'Journal of the American Chemical Society': 5.945,
    'Cell Reports Medicine': 5.841,
    'Genes and Development': 5.769,
    'Nature Chemical Biology': 5.509,
    'EMBO Journal': 5.484,
    'Journal of the National Cancer Institute': 5.409,
    'Developmental Cell': 5.385,
    'Pharmacological Reviews': 5.338,
    'Molecular Plant': 5.209,
    'Annual Review of Genomics and Human Genetics': 5.196,
    'Molecular Neurodegeneration': 5.158,
    'Nature Communications': 5.116,
    'American Journal of Human Genetics': 4.943,
    'Blood': 4.927,
    'Genome Research': 4.906,
    'Cell Genomics': 4.863,
    'Genome Medicine': 4.851,
    'Neuro-Oncology': 4.833,
    'Clinical Cancer Research': 4.603,
    'Annual Review of Cancer Biology': 4.599,
    'Cell Reports': 4.574,
    'Circulation Research': 4.531,
    'Drug Resistance Updates': 4.391,
    'Biological Reviews': 4.388,
    'Nature Aging': 4.336,
    'Trends in Biochemical Sciences': 4.265,
    'eLife': 4.251,
    'Annual Review of Medicine': 4.233,
    'PLoS Medicine': 4.223,
    'Cell Discovery': 4.179,
    'Advanced Science': 4.086,
    'Protein Science': 4.007,
    'PLoS Biology': 4.005,
    'EMBO Molecular Medicine': 3.932,
    'Acta Crystallographica Section D: Structural Biology': 3.87,
    'Nano Today': 3.868,
    'Cold Spring Harbor perspectives in biology': 3.863,
    'Microbiology and Molecular Biology Reviews': 3.719,
    'Current Opinion in Cell Biology': 3.703,
    'Annual Review of Animal Biosciences': 3.696,
    'Journal of Cell Biology': 3.664,
    'Plant Cell': 3.646,
    'Trends in Genetics': 3.583,
    'Trends in Cancer': 3.581,
    'Cell Death and Differentiation': 3.558,
    'Cancer Immunology Research': 3.552,
    'Journal of Bioresources and Bioproducts': 3.549,
    'Molecular Psychiatry': 3.541,
    'Journal of Extracellular Vesicles': 3.525,
    'Autophagy': 3.456,
    'Molecular Therapy': 3.425,
    'Molecular Biology and Evolution': 3.423,
    'Journal for ImmunoTherapy of Cancer': 3.403,
    'Small': 3.395,
    'Experimental and Molecular Medicine': 3.38,
    'Leukemia': 3.376,
    'Protein and Cell': 3.367,
    'Biofuel Research Journal': 3.313,
    'Evolution Letters': 3.297,
    'EMBO Reports': 3.273,
    'Molecular Aspects of Medicine': 3.244,
    'Current Opinion in Structural Biology': 3.212,
    'Systematic Biology': 3.185,
    'Progress in Lipid Research': 3.158,
    'Seminars in Cancer Biology': 3.115,
    'Green Synthesis and Catalysis': 3.094,
    'IUCrJ': 3.072,
    'New Phytologist': 3.047,
    'Ageing Research Reviews': 3.007,
    'Plant Communications': 2.985,
    'Cancer Research': 2.984,
    'Trends in Molecular Medicine': 2.974,
    'Circulation. Genomic and precision medicine': 2.966,
    'Bioinformatics': 2.94,
    'Wiley interdisciplinary reviews. RNA': 2.907,
    'eBioMedicine': 2.9,
    'European Journal of Cancer': 2.898,
    'Wiley Interdisciplinary Reviews: Computational Molecular Science': 2.889,
    'Metabolism: Clinical and Experimental': 2.886,
    'Genomics, Proteomics and Bioinformatics': 2.869,
    'British Journal of Cancer': 2.867,
    'Soft Robotics': 2.859,
    'Critical Reviews in Biochemistry and Molecular Biology': 2.852,
    'Science Signaling': 2.83,
    'Molecular Metabolism': 2.809,
    'Current Biology': 2.806,
    'Plant Biotechnology Journal': 2.776,
    'Wiley Interdisciplinary Reviews: Developmental Biology': 2.768,
    'Medicinal Research Reviews': 2.748,
    'Aging Cell': 2.738,
    'Bioactive Materials': 2.738,
    'Cancer': 2.736,
    'Quarterly Reviews of Biophysics': 2.73,
    'Journal of Integrative Plant Biology': 2.729,
    'Trends in Biotechnology': 2.721,
    'Biomaterials': 2.716,
    'Cancer Communications': 2.677,
    'Biochimica et Biophysica Acta - Reviews on Cancer': 2.657,
    'Journal of the National Cancer Center': 2.649,
    'Biotechnology Advances': 2.629,
    'Cytokine and Growth Factor Reviews': 2.621,
    'Molecular Ecology Resources': 2.594,
    'Epigenetics and Chromatin': 2.586,
    'Bone Research': 2.575,
    'Physiology': 2.552,
    'Cell Chemical Biology': 2.541,
    'Structure': 2.529,
    'Trends in Food Science and Technology': 2.522,
    'Journal of Biomedical Science': 2.52,
    'npj Precision Oncology': 2.52,
    'Redox Biology': 2.519,
    'Current Opinion in Chemical Biology': 2.514,
    'PLoS Pathogens': 2.49,
    'Cardiovascular Research': 2.481,
    'Development (Cambridge)': 2.472,
    'Stem Cell Reports': 2.464,
    'Cold Spring Harbor Symposia on Quantitative Biology': 2.462,
    'Traffic': 2.462,
    'Progress in Nuclear Magnetic Resonance Spectroscopy': 2.423,
    'Journal of Molecular Biology': 2.421,
    'Journal of Experimental and Clinical Cancer Research': 2.413,
    'Breast Cancer Research': 2.408,
    'Immunity and Ageing': 2.387,
    'Biomarker Research': 2.379,
    'JNCI Cancer Spectrum': 2.377,
    'Diabetes Technology and Therapeutics': 2.374,
    'Cellular and Molecular Life Sciences': 2.371,
    'Mobile DNA': 2.363,
    'PLoS Genetics': 2.363,
    'BMC Biology': 2.324,
    'Oncogene': 2.311,
    'RNA': 2.309,
    'NAR Genomics and Bioinformatics': 2.304,
    'Trends in Endocrinology and Metabolism': 2.304,
    'Natural Product Reports': 2.284,
    'Current Opinion in Genetics and Development': 2.264,
    'International Journal of Cancer': 2.259,
    'Communications Biology': 2.251,
    'Angiogenesis': 2.227,
    'JCO Precision Oncology': 2.208,
    'Seminars in Cell and Developmental Biology': 2.197,
    'ESMO Open': 2.196,
    'Biosensors and Bioelectronics': 2.194,
    'Cell Death and Disease': 2.188,
    'Brain Stimulation': 2.184,
    'Plant Physiology': 2.166,
    'Sub-Cellular Biochemistry': 2.165,
    'Briefings in Bioinformatics': 2.163,
    'Journal of Pineal Research': 2.158,
    'Cell Reports Methods': 2.145,
    'Open Biology': 2.143,
    'Comprehensive Physiology': 2.14,
    'Molecular and Cellular Proteomics': 2.14,
    'Gastric Cancer': 2.118,
    'Plant Journal': 2.118,
    'ACS Photonics': 2.113,
    'Cold Spring Harbor Perspectives in Medicine': 2.109,
    'Matrix Biology': 2.109,
    'Cancer and Metastasis Reviews': 2.107,
    'npj Genomic Medicine': 2.093,
    'International Journal of Radiation Oncology Biology Physics': 2.09,
    'Cancer Letters': 2.088,
    'Philosophical Transactions of the Royal Society B: Biological Sciences': 2.084,
    'Current Opinion in Biotechnology': 2.055,
    'Diabetes, Obesity and Metabolism': 2.048,
    'Plant, Cell and Environment': 2.029,
    'Basic Research in Cardiology': 2.022,
    'Clinical and Molecular Hepatology': 2.014,
    'Clinical Epigenetics': 2.014,
    'Nanophotonics': 2.013,
    'International Review of Cell and Molecular Biology': 2.001,
    'Human Genetics': 1.98,
    'npj Biofilms and Microbiomes': 1.978,
    'Current Protocols in Human Genetics': 1.975,
    'Biomolecular Detection and Quantification': 1.967,
    'npj Regenerative Medicine': 1.967,
    'Biology of Sex Differences': 1.961,
    'Essays in Biochemistry': 1.958,
    'Molecular Therapy - Nucleic Acids': 1.947,
    'Inflammation and Regeneration': 1.938,
    'Molecular Ecology': 1.937,
    'Journal of Lipid Research': 1.934,
    'Human Genetics and Genomics Advances': 1.92,
    'Journal of Membrane Science': 1.91,
    'Experimental Hematology and Oncology': 1.908,
    'Journal of Molecular Cell Biology': 1.904,
    'Proceedings of the Royal Society B: Biological Sciences': 1.898,
    'Molecular Cancer Therapeutics': 1.89,
    'Life Science Alliance': 1.884,
    'Structural Health Monitoring': 1.877,
    'PLoS Computational Biology': 1.872,
    'Cell Communication and Signaling': 1.868,
    'Transcription': 1.867,
    'Diabetes/Metabolism Research and Reviews': 1.863,
    'Oncologist': 1.859,
    'Critical Reviews in Clinical Laboratory Sciences': 1.851,
    'Diabetes and Metabolism': 1.849,
    'Genetics': 1.848,
    'Journal of Cell Science': 1.842,
    'Reviews in Endocrine and Metabolic Disorders': 1.835,
    'Human Molecular Genetics': 1.831,
    'Obesity': 1.826,
    'Molecular Therapy - Methods and Clinical Development': 1.825,
    'Journal of Experimental Botany': 1.823,
    'Journal of Medical Genetics': 1.816,
    'Transplantation and Cellular Therapy': 1.814,
    'Environmental DNA': 1.809,
    'Metabolic Engineering': 1.808,
    'American Journal of Respiratory Cell and Molecular Biology': 1.805,
    'Horticulture Research': 1.805,
    'Journal of Biological Chemistry': 1.805,
    'Journal of Medicinal Chemistry': 1.805,
    'Biochimica et Biophysica Acta - Gene Regulatory Mechanisms': 1.803,
    'Database : the journal of biological databases and curation': 1.786,
    'Molecular Oncology': 1.778,
    'Journal of Clinical Endocrinology and Metabolism': 1.776,
    'DNA Repair': 1.764,
    'European Journal of Human Genetics': 1.764,
    'Cell and Bioscience': 1.751,
    'International Journal of Biological Sciences': 1.75,
    'Age and Ageing': 1.738,
    'Current Protocols in Molecular Biology': 1.738,
    'FEBS Journal': 1.711,
    'Wiley Interdisciplinary Reviews: Systems Biology and Medicine': 1.71,
    'Antioxidants and Redox Signaling': 1.706,
    'Clinical and Translational Medicine': 1.706,
    'Journals of Gerontology - Series A Biological Sciences and Medical Sciences': 1.703,
    'mSystems': 1.691,
    'Aging and Disease': 1.682,
    'Cell Proliferation': 1.681,
    'Prostate Cancer and Prostatic Diseases': 1.676,
    'IEEE Journal of Biomedical and Health Informatics': 1.672,
    'Molecular Cancer Research': 1.668,
    'Cancer Immunology, Immunotherapy': 1.663,
    'Molecular Autism': 1.647,
    'BioDrugs': 1.646,
    'Cellular and Molecular Biology Letters': 1.643,
    'Acta Biomaterialia': 1.638,
    'Critical Reviews in Biotechnology': 1.637,
    'Neurobiology of Stress': 1.637,
    'Environmental Research': 1.635,
    'Diabetes Research and Clinical Practice': 1.634,
    'Annals of the New York Academy of Sciences': 1.626,
    'Free Radical Biology and Medicine': 1.625,
    'Journal of Leukocyte Biology': 1.622,
    'Communications Chemistry': 1.62,
    'Journal of Genetics and Genomics': 1.62,
    'Oncogenesis': 1.61,
    'Lung Cancer': 1.609,
    'Thyroid': 1.609,
    'JCO clinical cancer informatics': 1.602,
    'Journal of Structural Biology': 1.582,
    'Expert Opinion on Therapeutic Targets': 1.581,
    'Journal of Structural Biology: X': 1.581,
    'Molecular Biology of the Cell': 1.58,
    'Computational and Structural Biotechnology Journal': 1.579,
    'Frontiers of Hormone Research': 1.574,
    'American Journal of Physiology - Endocrinology and Metabolism': 1.572,
    'Molecular and Cellular Biology': 1.571,
    'CBE Life Sciences Education': 1.57,
    'GeroScience': 1.57,
    'Organic Letters': 1.564,
    'Journal of Physiology': 1.557,
    'Biochimica et Biophysica Acta - Molecular Cell Research': 1.546,
    'Cell Death Discovery': 1.545,
    'Journal of Translational Medicine': 1.544,
    'Journal of the National Cancer Institute. Monographs': 1.541,
    'Environmental Microbiomes': 1.538,
    'Human Mutation': 1.538,
    'Cells': 1.537,
    'Clinical Chemistry': 1.536,
    'Biofabrication': 1.534,
    'Cancer Science': 1.533,
    'npj Aging and Mechanisms of Disease': 1.531,
    'Skeletal Muscle': 1.53,
    'Genes and Diseases': 1.522,
    'Neurobiology of Aging': 1.521,
    'Nucleus': 1.516,
    'Vaccine: X': 1.509,
    'Biochimica et Biophysica Acta - Molecular Basis of Disease': 1.507,
    'Evolution; international journal of organic evolution': 1.503,
    'European Journal of Endocrinology': 1.502,
    'Proteins: Structure, Function and Bioinformatics': 1.5,
    'Stem Cell Research and Therapy': 1.498,
    'Acta Physiologica': 1.497,
    'Frontiers in Molecular Neuroscience': 1.495,
    'Vaccine': 1.493,
    'Nucleic Acid Therapeutics': 1.49,
    'DMM Disease Models and Mechanisms': 1.489,
    'Pathogens and Immunity': 1.489,
    'Non-coding RNA': 1.486,
    'Bioengineering and Translational Medicine': 1.484,
    'Journal of Neurochemistry': 1.475,
    'Biochemical Society Transactions': 1.47,
    'American Journal of Physiology - Cell Physiology': 1.468,
    'JCO Global Oncology': 1.467,
    'American Journal of Physiology - Lung Cellular and Molecular Physiology': 1.466,
    'Cancer Biology and Medicine': 1.466,
    'Targeted Oncology': 1.46,
    'Journal of Inherited Metabolic Disease': 1.459,
    'Neoplasia': 1.451,
    'Neurogastroenterology and Motility': 1.451,
    'Science China Life Sciences': 1.449,
    'Apoptosis : an international journal on programmed cell death': 1.447,
    'Materials Today Bio': 1.444,
    'Breast Cancer Research and Treatment': 1.442,
    'Journal of Investigative Dermatology': 1.435,
    'Cell Stress': 1.434,
    'Best Practice and Research in Clinical Endocrinology and Metabolism': 1.432,
    'Journal of Animal Science and Biotechnology': 1.423,
    'Journal of Nanobiotechnology': 1.421,
    'Frontiers in Cell and Developmental Biology': 1.418,
    'Molecular Phylogenetics and Evolution': 1.414,
    'Tissue Engineering - Part B: Reviews': 1.414,
    'American Journal of Physiology - Renal Physiology': 1.413,
    'Immunology and Cell Biology': 1.411,
    'Cellular oncology (Dordrecht)': 1.408,
    'Epigenetics': 1.408,
    'ACS Synthetic Biology': 1.406,
    'Theoretical And Applied Genetics': 1.403,
    'Journal of General Physiology': 1.398,
    'Molecular Plant Pathology': 1.398,
    'Journal of Immunotherapy': 1.397,
    'Mass Spectrometry Reviews': 1.395,
    'Biophysical Reviews': 1.392,
    'Forensic Science International: Genetics': 1.392,
    'Current Opinion in Systems Biology': 1.391,
    'Psychoneuroendocrinology': 1.391,
    'Stem cells translational medicine': 1.39,
    'Seminars in Radiation Oncology': 1.389,
    'npj Systems Biology and Applications': 1.388,
    'FASEB Journal': 1.386,
    'Current Protocols in Cytometry': 1.385,
    'Cancer Gene Therapy': 1.384,
    'American Journal of Physiology - Gastrointestinal and Liver Physiology': 1.383,
    'Mechanisms of Ageing and Development': 1.38,
    'Chemical Record': 1.379,
    'Advances in Microbial Physiology': 1.377,
    'Fungal Systematics and Evolution': 1.376,
    'Evolutionary Applications': 1.374,
    'Genome Biology and Evolution': 1.374,
    'Plant and Cell Physiology': 1.37,
    'ACS Chemical Biology': 1.369,
    'Gene Therapy': 1.366,
    'RNA Biology': 1.365,
    'Genes Chromosomes and Cancer': 1.362,
    'Methods': 1.357,
    'BioEssays': 1.355,
    'Biometrics': 1.348,
    'Psychology and Aging': 1.348,
    'Wellcome Open Research': 1.348,
    'Expert Review of Vaccines': 1.346,
    'Endocrinology': 1.345,
    'Psychophysiology': 1.345,
    'Cancer Research and Treatment': 1.341,
    'Drug Discovery Today: Technologies': 1.34,
    'Journal of Proteome Research': 1.34,
    'Molecular Therapy - Oncolytics': 1.339,
    'Stem Cells': 1.339,
    'BMC Structural Biology': 1.334,
    'Biochemical Pharmacology': 1.329,
    'European Journal of Cell Biology': 1.327,
    'Journal of Applied Crystallography': 1.326,
    'Oxidative Medicine and Cellular Longevity': 1.324,
    'Lab on a Chip': 1.314,
    'Cancers': 1.312,
    'Journal of Molecular and Cellular Cardiology': 1.312,
    'Interdisciplinary Topics in Gerontology and Geriatrics': 1.311,
    'Biochimica et Biophysica Acta - Molecular and Cell Biology of Lipids': 1.308,
    'Human Genomics': 1.308,
    'European Journal of Clinical Investigation': 1.3,
    'Molecular and Cellular Neurosciences': 1.295,
    'Biological Research': 1.294,
    'Insect Systematics and Diversity': 1.294,
    'Journal of Cellular Physiology': 1.294,
    'World Journal of Oncology': 1.294,
    'mSphere': 1.288,
    'American Journal of Physiology - Heart and Circulatory Physiology': 1.283,
    'Biochemical Journal': 1.283,
    'Molecular Microbiology': 1.281,
    'Journal of Endocrinology': 1.278,
    'Advances in Biological Regulation': 1.276,
    'FEBS Letters': 1.276,
    'Journal of Molecular Medicine': 1.276,
    'Glycobiology': 1.273,
    'Molecular Brain': 1.273,
    'Oral Oncology': 1.271,
    'APL Bioengineering': 1.269,
    'Breast': 1.269,
    'Clinical Chemistry and Laboratory Medicine': 1.266,
    'Clinical Biochemist Reviews': 1.264,
    'Journal of Inflammation': 1.259,
    'Biochimica et Biophysica Acta - Bioenergetics': 1.257,
    'Microbial genomics': 1.257,
    'Endocrine-Related Cancer': 1.255,
    'Cytometry. Part A : the journal of the International Society for Analytical Cytology': 1.254,
    'Plant Science': 1.249,
    'Journal of Biomolecular NMR': 1.247,
    'Diabetic Medicine': 1.246,
    'Journal of Molecular Diagnostics': 1.245,
    'Molecular Medicine': 1.245,
    'Mutation Research - Reviews in Mutation Research': 1.245,
    'Current Protocols': 1.244,
    'Clinical Genetics': 1.241,
    'Frontiers in Synaptic Neuroscience': 1.238,
    'Frontiers in Molecular Biosciences': 1.233,
    'Cancer cytopathology': 1.231,
    'Cell Calcium': 1.222,
    'Cilia': 1.221,
    'Journal of Molecular Endocrinology': 1.221,
    'Gerontology': 1.22,
    'Laboratory Investigation': 1.218,
    'Biophysical Journal': 1.216,
    'Frontiers in Aging Neuroscience': 1.211,
    'Life Sciences': 1.207,
    'Bioconjugate Chemistry': 1.204,
    'Aging': 1.2,
    'Horticultural Plant Journal': 1.199,
    'Journal of the Royal Society Interface': 1.196,
    'Biological Chemistry': 1.195,
    'Journal of Hypertension': 1.194,
    'Endocrinology and Metabolism Clinics of North America': 1.19,
    'Cancer Medicine': 1.188,
    'Mitochondrion': 1.188,
    'International Journal of Biological Macromolecules': 1.187,
    'Cytotherapy': 1.186,
    'Phytochemistry Reviews': 1.184,
    'Temperature': 1.183,
    'Biofilm': 1.182,
    'Experimental Hematology': 1.182,
    'Molecules and Cells': 1.182,
    'Journal of Dairy Science': 1.179,
    'Transfusion Medicine Reviews': 1.179,
    'Journal of Neuro-Oncology': 1.178,
    'Diagnosis': 1.172,
    'EvoDevo': 1.172,
    'Journal of Cellular and Molecular Medicine': 1.165,
    'Cell Surface': 1.164,
    'Archives of Pharmacal Research': 1.163,
    'Maturitas': 1.16,
    'Pancreatology': 1.159,
    'Marine Life Science and Technology': 1.158,
    'Progress in Biophysics and Molecular Biology': 1.158,
    'Developmental Biology': 1.155,
    'International Journal of Molecular Sciences': 1.154,
    'Zeitschrift fur Medizinische Physik': 1.154,
    'CRISPR Journal': 1.151,
    'Translational Oncology': 1.15,
    'Cancer Cell International': 1.145,
    'Journal of Water Process Engineering': 1.144,
    'Biological Procedures Online': 1.142,
    'Interface Focus': 1.141,
    'Physiologia Plantarum': 1.141,
    'Plant Physiology and Biochemistry': 1.141,
    'BioFactors': 1.139,
    'Frontiers in Oncology': 1.138,
    'BMC Cancer': 1.137,
    'Clinical Lung Cancer': 1.137,
    'Journal of Nutritional Biochemistry': 1.136,
    'Biochemistry': 1.134,
    'Plant Molecular Biology': 1.132,
    'RSC Chemical Biology': 1.132,
    'Acta Diabetologica': 1.131,
    'Bioinformatics and Biology Insights': 1.128,
    'Cellular Signalling': 1.128,
    'Bone': 1.127,
    'International Journal of Tryptophan Research': 1.127,
    'Pflugers Archiv European Journal of Physiology': 1.127,
    'Phytomedicine': 1.126,
    'Cancer Prevention Research': 1.125,
    'Clinical and Experimental Metastasis': 1.125,
    'Plant Reproduction': 1.123,
    'Genes and Immunity': 1.121,
    'Plant Methods': 1.121,
    'Expert Opinion on Biological Therapy': 1.12,
    'Journal of Dermatological Science': 1.118,
    'Biology Direct': 1.117,
    'International Journal of Oncology': 1.116,
    'Virchows Archiv': 1.114,
    'Cancer Epidemiology': 1.113,
    'Proteomics': 1.113,
    'International Journal of Nanomedicine': 1.111,
    'Microbial Biotechnology': 1.108,
    'BMC Genomics': 1.107,
    'Insect Biochemistry and Molecular Biology': 1.106,
    'Journal of Bacteriology': 1.101,
    'Microbial Cell': 1.101,
    'BMC Bioinformatics': 1.1,
    'Neonatology': 1.098,
    'Tumour Virus Research': 1.097,
    'Microbiology spectrum': 1.095,
    'Hormones and Behavior': 1.091,
    'Microbial Cell Factories': 1.091,
    'Briefings in Functional Genomics': 1.089,
    'Journal of Human Genetics': 1.089,
    'Clinica Chimica Acta': 1.085,
    'Current Protocols in Cell Biology': 1.085,
    'Antioxidants': 1.084,
    'Clinical and Translational Science': 1.084,
    'Matrix Biology Plus': 1.083,
    'Physiological Genomics': 1.082,
    'Neuroendocrinology': 1.079,
    'BMB Reports': 1.078,
    'Journal of Heredity': 1.076,
    'Biomolecules': 1.074,
    'Heredity': 1.074,
    'Medical Physics': 1.072,
    'Carcinogenesis': 1.071,
    'Neuroscience Bulletin': 1.071,
    'NeuroMolecular Medicine': 1.069,
    'NMR in Biomedicine': 1.069,
    'Advances in Protein Chemistry and Structural Biology': 1.068,
    'Andrology': 1.068,
    'Journal of Neurophysiology': 1.067,
    'Stem Cell Reviews and Reports': 1.067,
    'Pathobiology': 1.063,
    'Molecular Pharmacology': 1.061,
    'Expert Reviews in Molecular Medicine': 1.06,
    'Reproductive Biology and Endocrinology': 1.059,
    'Food Control': 1.057,
    'Digestive Diseases and Sciences': 1.054,
    'Pigment Cell and Melanoma Research': 1.053,
    'Hematological Oncology': 1.051,
    'Journal of Muscle Research and Cell Motility': 1.051,
    'Mycorrhiza': 1.048,
    'ACS Chemical Neuroscience': 1.045,
    'Human Gene Therapy': 1.045,
    'Phytobiomes Journal': 1.044,
    'Analytica Chimica Acta': 1.042,
    'Lipids in Health and Disease': 1.042,
    'SLAS Discovery': 1.042,
    'IUBMB Life': 1.041,
    'Molecular Human Reproduction': 1.039,
    'Molecular Nutrition and Food Research': 1.038,
    'Plant Direct': 1.034,
    'Virus Research': 1.034,
    'Cytoskeleton': 1.033,
    'Sexual Medicine Reviews': 1.033,
    'Applied and Environmental Microbiology': 1.032,
    'Current Opinion in Lipidology': 1.032,
    'Molecular Plant-Microbe Interactions': 1.032,
    'Familial Cancer': 1.031,
    'Cytokine: X': 1.03,
    'Molecular Diagnosis and Therapy': 1.03,
    "International Journal of Alzheimer's Disease": 1.029,
    'International Journal of Molecular Medicine': 1.029,
    'Frontiers in Physiology': 1.028,
    'Genetics Selection Evolution': 1.027,
    'Journal of Cell Communication and Signaling': 1.025,
    'Molecular Biomedicine': 1.023,
    'Biotechnology for Biofuels': 1.022,
    'Molecular Carcinogenesis': 1.022,
    'Cell Biology and Toxicology': 1.019,
    'Molecular Genetics and Metabolism': 1.019,
    'Tree Physiology': 1.019,
    'Chromosome Research': 1.018,
    'Journal of Experimental Biology': 1.018,
    'Journal of Cancer Research and Clinical Oncology': 1.017,
    'Journal of Biological Rhythms': 1.014,
    'Polymer Chemistry': 1.014,
    'Current Genetics': 1.013,
    'Cancer Microenvironment': 1.01,
    'Cell Regeneration': 1.01,
    'Molecular and Cellular Endocrinology': 1.009,
    'Archives of Gerontology and Geriatrics': 1.008,
    'Cancer Causes and Control': 1.008,
    'OpenNano': 1.005,
    'Endocrine Practice': 1.003,
    'Zoologica Scripta': 1.002,
    'Academy of Management Annals': 15.633,
    'Journal of Finance': 15.515,
    'Review of Financial Studies': 12.239,
    'Academy of Management Journal': 10.91,
    'Journal of Marketing': 10.802,
    'Academy of Management Review': 10.687,
    'Journal of Financial Economics': 10.601,
    'Strategic Management Journal': 8.497,
    'Marketing Science': 7.593,
    'Journal of Marketing Research': 7.415,
    'Journal of Management': 7.213,
    'Manufacturing and Service Operations Management': 7.126,
    'Annual Review of Organizational Psychology and Organizational Behavior': 7.036,
    'Organization Science': 6.541,
    'Journal of the Academy of Marketing Science': 6.248,
    'Journal of Accounting and Economics': 6.1,
    'Journal of Consumer Research': 6.024,
    'Review of Finance': 5.68,
    'Review of Corporate Finance Studies': 5.448,
    'Management Science': 5.322,
    'Journal of Accounting Research': 5.165,
    'Journal of International Business Studies': 5.093,
    'Journal of Business Venturing': 5.029,
    'Journal of Service Research': 4.981,
    'International Journal of Information Management': 4.906,
    'Innovation Policy and the Economy': 4.903,
    'Academy of Management Perspectives': 4.84,
    'International Organization': 4.798,
    'Journal of Labor Economics': 4.727,
    'Organizational Research Methods': 4.626,
    'Organization Studies': 4.537,
    'Journal of Management Studies': 4.492,
    'Accounting Review': 4.446,
    'Leadership Quarterly': 4.331,
    'Entrepreneurship Theory and Practice': 4.323,
    'Strategic Organization': 4.284,
    'Strategy Science': 4.232,
    'Review of Accounting Studies': 4.212,
    'California Management Review': 4.17,
    'Journal of Interactive Marketing': 4.092,
    'Brookings Papers on Economic Activity': 4.021,
    'Journal of Strategic Information Systems': 4.011,
    'Journal of Supply Chain Management': 4.009,
    'International Journal of Research in Marketing': 3.896,
    'Journal of Organizational Behavior': 3.804,
    'MIS Quarterly: Management Information Systems': 3.793,
    'Journal of Retailing': 3.787,
    'Personnel Psychology': 3.749,
    'IMF Economic Review': 3.734,
    'Journal of Financial and Quantitative Analysis': 3.7,
    'Journal of Operations Management': 3.679,
    'European Business Review': 3.667,
    'Research Policy': 3.634,
    'Tourism Management': 3.561,
    'Human Relations': 3.508,
    'Global Strategy Journal': 3.471,
    'Annals of Tourism Research': 3.426,
    'Organizational Behavior and Human Decision Processes': 3.408,
    'ILR Review': 3.37,
    'Journal of Public Administration Research and Theory': 3.337,
    'Journal of Human Resources': 3.335,
    'Public Administration Review': 3.311,
    'Journal of Public Policy and Marketing': 3.263,
    'Information Systems Research': 3.257,
    'Journal of Travel Research': 3.249,
    'Journal of World Business': 3.245,
    'Journal of Product Innovation Management': 3.222,
    'Strategic Entrepreneurship Journal': 3.175,
    'Journal of Business Logistics': 3.135,
    'Production and Operations Management': 3.12,
    'Human Resource Management Review': 3.106,
    'Journal of Management Information Systems': 3.064,
    'International Journal of Production Economics': 3.028,
    'Contemporary Accounting Research': 3.012,
    'Transportation Research, Part E: Logistics and Transportation Review': 3.005,
    'Journal of Consumer Psychology': 2.978,
    'International Journal of Production Research': 2.976,
    'Organizational Psychology Review': 2.97,
    'Journal of Sustainable Tourism': 2.966,
    'Business and Society': 2.965,
    'International Journal of Hospitality Management': 2.928,
    'Journal of Vocational Behavior': 2.926,
    'Supply Chain Management': 2.896,
    'Journal of Business Research': 2.895,
    'Journal of Service Management': 2.875,
    'Business Strategy and the Environment': 2.87,
    'International Journal of Management Reviews': 2.857,
    'Long Range Planning': 2.852,
    'Journal of Research in Interactive Marketing': 2.833,
    'Small Business Economics': 2.732,
    'Information and Management': 2.688,
    'Omega': 2.679,
    'Industrial Marketing Management': 2.658,
    'Journal of Innovation and Knowledge': 2.649,
    'Technological Forecasting and Social Change': 2.644,
    'International Journal of Operations and Production Management': 2.618,
    'Journal of Advertising': 2.606,
    'Tourism Geographies': 2.596,
    'Journal of Business Ethics': 2.59,
    'Journal of Risk and Uncertainty': 2.545,
    'Journal of Retailing and Consumer Services': 2.543,
    'Journal of Hospitality Marketing and Management': 2.52,
    'Journal of Business and Psychology': 2.517,
    'Journal of Interactive Advertising': 2.505,
    'International Journal of Contemporary Hospitality Management': 2.5,
    'Jahrbucher fur Nationalokonomie und Statistik': 2.494,
    'European Journal of Information Systems': 2.481,
    'International Journal of Information Management Data Insights': 2.479,
    'Business Horizons': 2.475,
    'International Business Review': 2.455,
    'Accounting, Organizations and Society': 2.411,
    'Technovation': 2.41,
    'Human Resource Management': 2.338,
    'International Journal of Project Management': 2.333,
    'Research Methodology in Strategy and Management': 2.326,
    'Human Resource Management Journal': 2.321,
    'IEEE Communications Standards Magazine': 2.306,
    'Review of Public Personnel Administration': 2.303,
    'Journal of Corporate Finance': 2.268,
    'International Small Business Journal': 2.257,
    'Gender, Work and Organization': 2.237,
    'Journal of Knowledge Management': 2.22,
    'Journal of Conflict Resolution': 2.164,
    'British Journal of Management': 2.153,
    'Corporate Social Responsibility and Environmental Management': 2.134,
    'Journal of Travel and Tourism Marketing': 2.126,
    'Decision Sciences': 2.122,
    'Journal of Policy Analysis and Management': 2.111,
    'Fiscal Studies': 2.103,
    'Journal of Purchasing and Supply Management': 2.092,
    'Journal of International Marketing': 2.088,
    'Journal of Manufacturing Technology Management': 2.083,
    'Psychology and Marketing': 2.083,
    'International Journal of Advertising': 2.081,
    'Decision Support Systems': 2.076,
    'Knowledge-Based Systems': 2.065,
    'Current Issues in Tourism': 2.062,
    'Journal of Occupational and Organizational Psychology': 2.062,
    'Family Business Review': 2.058,
    'Journal of Management Inquiry': 2.043,
    'Transportation Research, Part A: Policy and Practice': 2.029,
    'Review of International Organizations': 2.017,
    'Information and Organization': 1.997,
    'Service Industries Journal': 1.996,
    'Journal of Family Business Strategy': 1.992,
    'Work, Employment and Society': 1.988,
    'Journal of Cleaner Production': 1.981,
    'International Journal of Forecasting': 1.972,
    'European Journal of Work and Organizational Psychology': 1.966,
    'Organization': 1.96,
    'Business Ethics Quarterly': 1.947,
    'Academy of Management Learning and Education': 1.94,
    'Journal of Destination Marketing and Management': 1.936,
    'Tourism Management Perspectives': 1.92,
    'Tourism Review': 1.878,
    'Organization and Environment': 1.877,
    'ACM Transactions on Information Systems': 1.864,
    'Mathematical Finance': 1.837,
    'Australasian Marketing Journal': 1.811,
    'International Journal of Physical Distribution and Logistics Management': 1.795,
    'Group and Organization Management': 1.784,
    'Journal of Business Venturing Insights': 1.783,
    'Entrepreneurship and Regional Development': 1.774,
    'Journal of Hospitality and Tourism Management': 1.773,
    'Journal of International Management': 1.769,
    'Journal of International Business Policy': 1.768,
    'International Journal of Consumer Studies': 1.753,
    'American Review of Public Administration': 1.745,
    'Human Resource Development Review': 1.739,
    'Financial Management': 1.735,
    'Accounting, Auditing and Accountability Journal': 1.727,
    'Production Planning and Control': 1.719,
    'Cities': 1.718,
    'Journal of Technology Transfer': 1.704,
    'Work, Aging and Retirement': 1.699,
    'Marketing Theory': 1.693,
    'Economy and Society': 1.69,
    'AMS Review': 1.687,
    'Marketing Letters': 1.672,
    'New Technology, Work and Employment': 1.669,
    'Journal of Marketing Management': 1.664,
    'Auditing': 1.655,
    'Journal of Management in Engineering - ASCE': 1.645,
    'Real Estate Economics': 1.634,
    'European Journal of Marketing': 1.63,
    'European Management Journal': 1.625,
    'Journal of Intellectual Capital': 1.582,
    'Journal of Services Marketing': 1.577,
    'Management Learning': 1.574,
    'International Journal of Human Resource Management': 1.571,
    'Journal of Small Business Management': 1.556,
    'Electronic Markets': 1.545,
    'Feminist Economics': 1.534,
    'International Entrepreneurship and Management Journal': 1.524,
    'Sport Management Review': 1.513,
    'Business Research': 1.51,
    'Journal of Money, Credit and Banking': 1.501,
    'R and D Management': 1.497,
    'Technology in Society': 1.491,
    'Critical Perspectives on Accounting': 1.483,
    'Management International Review': 1.483,
    'Labour Economics': 1.479,
    'Internet of Things (Netherlands)': 1.474,
    'International Journal of Logistics Management': 1.472,
    'Project Management Journal': 1.469,
    'International Journal of Electronic Commerce': 1.468,
    'Journal of Contextual Behavioral Science': 1.465,
    'Journal of Hospitality and Tourism Technology': 1.46,
    'Quantitative Marketing and Economics': 1.437,
    'British Journal of Industrial Relations': 1.433,
    'Public Relations Review': 1.432,
    'Journal of Product and Brand Management': 1.427,
    'Asia Pacific Journal of Management': 1.41,
    'World Bank Economic Review': 1.405,
    'Journal of Accounting Literature': 1.402,
    'Journal of Air Transport Management': 1.402,
    'Journal of Economics and Management Strategy': 1.4,
    'Career Development and Transition for Exceptional Individuals': 1.395,
    'International Journal of Industrial Organization': 1.388,
    'Work and Occupations': 1.388,
    'Journal of Management Science and Engineering': 1.384,
    'School Leadership and Management': 1.379,
    'Journal of Information Technology': 1.375,
    'Journal of Personal Selling and Sales Management': 1.372,
    'Journal of Hospitality and Tourism Research': 1.357,
    'Journal of Risk Research': 1.354,
    'Corporate Governance (Bingley)': 1.349,
    'Journal of Manufacturing Processes': 1.349,
    'Journal of Law, Economics, and Organization': 1.345,
    'Management Decision': 1.345,
    'Competition and Change': 1.343,
    'Information Technology and Tourism': 1.342,
    'International Journal of Stress Management': 1.336,
    'Cleaner Logistics and Supply Chain': 1.329,
    'Management Review Quarterly': 1.327,
    'Industry and Innovation': 1.323,
    'Review of Managerial Science': 1.312,
    'International Marketing Review': 1.309,
    'Electronic Commerce Research and Applications': 1.307,
    'Journal of Managerial Psychology': 1.306,
    'Governance': 1.305,
    'International Journal of Tourism Research': 1.298,
    'Human Resource Development International': 1.297,
    'Human Resource Development Quarterly': 1.293,
    'International Journal of Entrepreneurial Behaviour and Research': 1.287,
    'Consumption Markets and Culture': 1.281,
    'Journal of Management Analytics': 1.281,
    'British Accounting Review': 1.275,
    'Research in International Business and Finance': 1.273,
    'Journal of Fashion Marketing and Management': 1.271,
    'Journal of Accounting and Public Policy': 1.26,
    'Financial Analysts Journal': 1.259,
    'Journal of Industrial Economics': 1.259,
    'Business and Politics': 1.248,
    'Journal of Industrial Integration and Management': 1.244,
    'Journal of Enterprise Information Management': 1.242,
    'Administration and Society': 1.238,
    'ASTIN Bulletin': 1.237,
    'Venture Capital': 1.234,
    'Corporate Governance: An International Review': 1.219,
    'Industrial Management and Data Systems': 1.219,
    'Journal of Sport Management': 1.219,
    'International Conference on Information and Knowledge Management, Proceedings': 1.214,
    'Educational Assessment, Evaluation and Accountability': 1.211,
    'American Health and Drug Benefits': 1.206,
    'International Public Management Journal': 1.203,
    'Journal of the Association for Consumer Research': 1.2,
    'Telecommunications Policy': 1.192,
    'Journal of Internet Commerce': 1.187,
    'Scandinavian Journal of Hospitality and Tourism': 1.186,
    'Benchmarking': 1.185,
    'Journal of Economic Behavior and Organization': 1.184,
    'Scandinavian Journal of Management': 1.18,
    'Cornell Hospitality Quarterly': 1.178,
    'Financial Innovation': 1.171,
    'Journal of Business Finance and Accounting': 1.17,
    'Management Accounting Research': 1.167,
    'Journal of Industrial Relations': 1.165,
    'International Journal of Accounting Information Systems': 1.159,
    'Journal of Construction Engineering and Management - ASCE': 1.152,
    'Marketing Intelligence and Planning': 1.15,
    'European Journal of Industrial Relations': 1.146,
    'European Journal of Innovation Management': 1.142,
    'International Journal of Health Geographics': 1.142,
    'Journal of Current Issues and Research in Advertising': 1.141,
    'Applied Geography': 1.138,
    'Journal of Vacation Marketing': 1.135,
    'Foundations and Trends in Accounting': 1.134,
    'Australian Journal of Management': 1.132,
    'Quarterly Journal of Finance': 1.132,
    'Socio-Economic Planning Sciences': 1.131,
    'International Journal of Logistics Research and Applications': 1.12,
    'Administrative Theory and Praxis': 1.112,
    'Journal of Economic Inequality': 1.109,
    'Public Performance &amp; Management Review': 1.109,
    'Journal of the American Taxation Association': 1.108,
    'Emerging Markets Review': 1.104,
    'National Tax Journal': 1.1,
    'European Management Review': 1.098,
    'Annals of Tourism Research Empirical Insights': 1.096,
    'Journal of Leadership and Organizational Studies': 1.096,
    'European Research on Management and Business Economics': 1.095,
    'Journal of Common Market Studies': 1.095,
    'Journal of Information Systems': 1.092,
    'Tourism Economics': 1.092,
    'Journal of Brand Management': 1.09,
    'International Journal of Bank Marketing': 1.088,
    'Journal of Healthcare Leadership': 1.082,
    'Educational Management Administration and Leadership': 1.079,
    'Personnel Review': 1.078,
    'European Accounting Review': 1.072,
    'Global Journal of Flexible Systems Management': 1.072,
    'Journal of Service Theory and Practice': 1.066,
    'Tourism Recreation Research': 1.066,
    'Management and Organization Review': 1.061,
    'Multinational Business Review': 1.06,
    'International Transactions in Operational Research': 1.059,
    'International Journal of Management Education': 1.056,
    'Public Personnel Management': 1.051,
    'Journal of Advertising Research': 1.041,
    'Journal of Industrial and Business Economics': 1.029,
    'Journal of Leisure Research': 1.021,
    'Service Science': 1.016,
    'Fuel Cells Bulletin': 1.014,
    'Journal of Marketing Communications': 1.012,
    'Leadership and Organization Development Journal': 1.009,
    'Review of Industrial Organization': 1.009,
    'Journal of Responsible Innovation': 1.007,
    'Journal of Organization Design': 1.004,
    'IEEE Transactions on Engineering Management': 1.002,
    'Nature Biotechnology': 22.781,
    'Nature Catalysis': 13.187,
    'Nature Nanotechnology': 13.141,
    'Nature Reviews Chemistry': 10.967,
    'Electrochemical Energy Reviews': 8.905,
    'Nature Chemistry': 8.14,
    'Annual Review of Biophysics': 6.926,
    'Chem': 6.8,
    'Nature Biomedical Engineering': 6.625,
    'Journal of the American Chemical Society': 5.945,
    'Progress in Energy and Combustion Science': 5.679,
    'Angewandte Chemie - International Edition': 5.573,
    'Applied Catalysis B: Environmental': 4.887,
    'Accounts of Materials Research': 4.788,
    'ACS Catalysis': 4.195,
    'ACS Central Science': 4.171,
    'Advanced Science': 4.086,
    'Materials Horizons': 3.88,
    'Nano Today': 3.868,
    'Nano Letters': 3.54,
    'ACS Materials Letters': 3.421,
    'Biofuel Research Journal': 3.313,
    'Green Synthesis and Catalysis': 3.094,
    'Chemistry of Materials': 2.869,
    'Journal of Photochemistry and Photobiology C: Photochemistry Reviews': 2.837,
    'Chemical Engineering Journal': 2.803,
    'Trends in Biotechnology': 2.721,
    'Biomaterials': 2.716,
    'Biotechnology Advances': 2.629,
    'Food Hydrocolloids': 2.578,
    'Chinese Journal of Catalysis': 2.551,
    'Bioresource Technology': 2.473,
    'Annual Review of Chemical and Biomolecular Engineering': 2.294,
    'Advances in Colloid and Interface Science': 2.241,
    'ChemSusChem': 2.064,
    'Current Opinion in Biotechnology': 2.055,
    'Nano Materials Science': 1.917,
    'Journal of Membrane Science': 1.91,
    'ACS Sensors': 1.875,
    'Corrosion Science': 1.84,
    'Metabolic Engineering': 1.808,
    'Catalysis Reviews - Science and Engineering': 1.789,
    'Journal of Catalysis': 1.776,
    'Engineering': 1.75,
    'ACS Sustainable Chemistry and Engineering': 1.744,
    'International Journal of Thermofluids': 1.643,
    'Critical Reviews in Solid State and Materials Sciences': 1.627,
    'Smart Materials in Medicine': 1.612,
    'Journal of Colloid and Interface Science': 1.604,
    'ACS Applied Energy Materials': 1.588,
    'Wiley Interdisciplinary Reviews: Nanomedicine and Nanobiotechnology': 1.588,
    'Applied Thermal Engineering': 1.559,
    'Biofabrication': 1.534,
    'Advanced Industrial and Engineering Polymer Research': 1.528,
    'Shiyou Xuebao/Acta Petrolei Sinica': 1.527,
    'Mechanism and Machine Theory': 1.517,
    'Current Opinion in Green and Sustainable Chemistry': 1.478,
    'Desalination': 1.471,
    'Materials Science for Energy Technologies': 1.463,
    'Materials Today Bio': 1.444,
    'Journal of CO2 Utilization': 1.433,
    'Journal of Nanobiotechnology': 1.421,
    'Tissue Engineering - Part B: Reviews': 1.414,
    'International Journal of Robust and Nonlinear Control': 1.403,
    'Fuel Processing Technology': 1.393,
    'Fuel': 1.38,
    'Chemical Record': 1.379,
    'International Journal of Heat and Mass Transfer': 1.369,
    'Journal of Chemical Information and Modeling': 1.357,
    'Ultrasonics Sonochemistry': 1.35,
    'Chemical Communications': 1.344,
    'Separation and Purification Technology': 1.339,
    'Process Safety and Environmental Protection': 1.338,
    'Current Opinion in Colloid and Interface Science': 1.314,
    'Lab on a Chip': 1.314,
    'Advanced Synthesis and Catalysis': 1.291,
    'APL Bioengineering': 1.269,
    'Electrochimica Acta': 1.264,
    'Case Studies in Chemical and Environmental Engineering': 1.256,
    'Biomacromolecules': 1.244,
    'Extreme Mechanics Letters': 1.235,
    'Chemistry - A European Journal': 1.229,
    'Materials Today Chemistry': 1.229,
    'Bioconjugate Chemistry': 1.204,
    'Advanced Structural and Chemical Imaging': 1.199,
    'Journal of Environmental Chemical Engineering': 1.198,
    'Journal of the Royal Society Interface': 1.196,
    'ChemCatChem': 1.188,
    'Catalysis Science and Technology': 1.183,
    'Indonesian Journal of Science and Technology': 1.176,
    'International Journal of Molecular Sciences': 1.154,
    'Journal of Water Process Engineering': 1.144,
    'Interface Focus': 1.141,
    'International Journal of Nanomedicine': 1.111,
    'Microbial Biotechnology': 1.108,
    'Microbial Cell Factories': 1.091,
    'Materials Science and Engineering C': 1.086,
    'Physics of Fluids': 1.083,
    'Journal of diabetes science and technology': 1.067,
    'Nanoscale Advances': 1.063,
    'Catalysis Today': 1.053,
    'Carbon Resources Conversion': 1.051,
    'Reviews in Chemical Engineering': 1.049,
    'Separation and Purification Reviews': 1.038,
    'Engineering Science and Technology, an International Journal': 1.036,
    'ChemElectroChem': 1.024,
    'Progress in Organic Coatings': 1.015,
    'Polymer Chemistry': 1.014,
    'Green Chemical Engineering': 1.006,
    'Journal of Industrial and Engineering Chemistry': 1.005,
    'Powder Technology': 1.005,
    'Chemical Reviews': 18.911,
    'Chemical Society Reviews': 15.109,
    'Nature Materials': 13.874,
    'Nature Reviews Chemistry': 10.967,
    'Electrochemical Energy Reviews': 8.905,
    'Nature Chemistry': 8.14,
    'ACS Energy Letters': 7.855,
    'Chem': 6.8,
    'Accounts of Chemical Research': 6.382,
    'EnergyChem': 6.26,
    'Journal of the American Chemical Society': 5.945,
    'Progress in Polymer Science': 5.676,
    'Angewandte Chemie - International Edition': 5.573,
    'Advanced Functional Materials': 5.565,
    'Nature Communications': 5.116,
    'Trends in Chemistry': 4.435,
    'Annual Review of Physical Chemistry': 4.232,
    'ACS Catalysis': 4.195,
    'ACS Central Science': 4.171,
    'Coordination Chemistry Reviews': 4.121,
    'Small Methods': 3.629,
    'Chem Catalysis': 3.565,
    'EcoMat': 3.557,
    'Journal of Bioresources and Bioproducts': 3.549,
    'Nano Letters': 3.54,
    'AVS Quantum Science': 3.413,
    'Small': 3.395,
    'Surface Science Reports': 3.204,
    'Journal of Materials Chemistry A': 3.156,
    'Environmental Science &amp; Technology': 3.123,
    'IUCrJ': 3.072,
    'CCS Chemistry': 3.03,
    'Wiley Interdisciplinary Reviews: Computational Molecular Science': 2.889,
    'Chemistry of Materials': 2.869,
    'Journal of Photochemistry and Photobiology C: Photochemistry Reviews': 2.837,
    'Chemical Engineering Journal': 2.803,
    'Chemical Science': 2.762,
    'Journal of Energy Chemistry': 2.754,
    'npj 2D Materials and Applications': 2.665,
    'Cell Reports Physical Science': 2.598,
    'Food Hydrocolloids': 2.578,
    'Chinese Journal of Catalysis': 2.551,
    'Redox Biology': 2.519,
    'Current Opinion in Chemical Biology': 2.514,
    'Polymer Reviews': 2.493,
    'Progress in Nuclear Magnetic Resonance Spectroscopy': 2.423,
    'Annual Review of Chemical and Biomolecular Engineering': 2.294,
    'Natural Product Reports': 2.284,
    'Annual Review of Analytical Chemistry': 2.263,
    'Advances in Colloid and Interface Science': 2.241,
    'Science China Chemistry': 2.241,
    'TrAC - Trends in Analytical Chemistry': 2.218,
    'Biosensors and Bioelectronics': 2.194,
    'Molecular and Cellular Proteomics': 2.14,
    'Carbon': 2.1,
    'Giant': 1.948,
    'Journal of Membrane Science': 1.91,
    'Current Opinion in Electrochemistry': 1.854,
    'Journal of Power Sources': 1.854,
    'Journal of Physical Chemistry Letters': 1.85,
    'Corrosion Science': 1.84,
    'Catalysis Reviews - Science and Engineering': 1.789,
    'Trends in Environmental Analytical Chemistry': 1.78,
    'Journal of Catalysis': 1.776,
    'ACS Sustainable Chemistry and Engineering': 1.744,
    'Analytical Chemistry': 1.733,
    'Chemosphere': 1.727,
    'Carbohydrate Polymers': 1.726,
    'Progress in Surface Science': 1.677,
    'Journal of Chemical Theory and Computation': 1.665,
    'ACS Macro Letters': 1.655,
    'Progress in Solid State Chemistry': 1.633,
    '2D Materials': 1.631,
    'Journal of Cheminformatics': 1.63,
    'Critical Reviews in Solid State and Materials Sciences': 1.627,
    'Food Chemistry': 1.624,
    'Communications Chemistry': 1.62,
    'ACS Applied Energy Materials': 1.588,
    'Organic Letters': 1.564,
    'Journal of Materials Chemistry C': 1.534,
    'Batteries and Supercaps': 1.502,
    'Current Opinion in Green and Sustainable Chemistry': 1.478,
    'Desalination': 1.471,
    'Macromolecules': 1.461,
    'Aldrichimica Acta': 1.406,
    'Mass Spectrometry Reviews': 1.395,
    'Chinese Chemical Letters': 1.389,
    'Engineered Science': 1.386,
    'Fuel': 1.38,
    'Chemical Record': 1.379,
    'Wuli Huaxue Xuebao/ Acta Physico - Chimica Sinica': 1.358,
    'Journal of Chemical Information and Modeling': 1.357,
    'Ultrasonics Sonochemistry': 1.35,
    'Chemical Communications': 1.344,
    'Journal of Proteome Research': 1.34,
    'Separation and Purification Technology': 1.339,
    'Inorganic Chemistry Frontiers': 1.331,
    'Rare Metals': 1.326,
    'Topics in Current Chemistry': 1.323,
    'Current Opinion in Colloid and Interface Science': 1.314,
    'Lab on a Chip': 1.314,
    'Journal of Nanostructure in Chemistry': 1.294,
    'Advanced Synthesis and Catalysis': 1.291,
    'Organic Reactions': 1.28,
    'Organic Chemistry Frontiers': 1.279,
    'Innovative Food Science and Emerging Technologies': 1.269,
    'Electrochimica Acta': 1.264,
    'Chinese Journal of Chemistry': 1.262,
    'Journal of Biomolecular NMR': 1.247,
    'Journal of Pharmaceutical Analysis': 1.242,
    'Chemistry - A European Journal': 1.229,
    'Journal of Materials Chemistry B': 1.211,
    'Bioconjugate Chemistry': 1.204,
    'Advanced Structural and Chemical Imaging': 1.199,
    'Journal of Chemical Physics': 1.196,
    'ChemCatChem': 1.188,
    'Applied Surface Science': 1.187,
    'Archives of Pharmacal Research': 1.163,
    'International Journal of Molecular Sciences': 1.154,
    'Journal of Analytical and Applied Pyrolysis': 1.145,
    'European Journal of Medicinal Chemistry': 1.143,
    'RSC Chemical Biology': 1.132,
    'MRS Bulletin': 1.128,
    'Electrochemistry Communications': 1.126,
    'International Journal of Nanomedicine': 1.111,
    'ACS Applied Electronic Materials': 1.101,
    'Journal of Agricultural and Food Chemistry': 1.099,
    'Russian Chemical Reviews': 1.085,
    'Materials Today Sustainability': 1.079,
    'Marine Chemistry': 1.076,
    'NMR in Biomedicine': 1.069,
    'Nanoscale Advances': 1.063,
    'Materials Advances': 1.061,
    'Catalysis Today': 1.053,
    'Analytica Chimica Acta': 1.042,
    'SLAS Discovery': 1.042,
    'Separation and Purification Reviews': 1.038,
    'Structural Dynamics': 1.037,
    'Macromolecular Rapid Communications': 1.036,
    'Comments on Inorganic Chemistry': 1.034,
    'Israel Journal of Chemistry': 1.032,
    'Journal of Physical Chemistry C': 1.028,
    'ChemElectroChem': 1.024,
    'Minerals Engineering': 1.018,
    'Progress in Organic Coatings': 1.015,
    'Polymer Chemistry': 1.014,
    'Foundations and Trends in Machine Learning': 13.775,
    'Advances in Computers': 9.308,
    'IEEE Journal on Selected Areas in Communications': 7.656,
    'Foundations and Trends in Signal Processing': 6.676,
    'Nature Biomedical Engineering': 6.625,
    'IEEE Wireless Communications': 6.556,
    'Science Robotics': 6.479,
    'Molecular Systems Biology': 6.22,
    'Nature Machine Intelligence': 6.21,
    'Proceedings of the IEEE Computer Society Conference on Computer Vision and Pattern Recognition': 5.952,
    'IEEE Transactions on Evolutionary Computation': 5.767,
    'IEEE Transactions on Wireless Communications': 5.662,
    'Proceedings of the IEEE International Conference on Computer Vision': 5.662,
    'Proceedings of the IEEE': 5.564,
    'IEEE Transactions on Cybernetics': 5.365,
    'Foundations and Trends in Computer Graphics and Vision': 5.329,
    'IEEE Communications Magazine': 5.285,
    'IEEE Transactions on Smart Grid': 5.118,
    'Journal of Service Research': 4.981,
    'International Journal of Information Management': 4.906,
    'Synthesis Lectures on Communication Networks': 4.854,
    'Information Fusion': 4.756,
    'AI Open': 4.726,
    'ACM Computing Surveys': 4.457,
    'IEEE Transactions on Pattern Analysis and Machine Intelligence': 4.447,
    'PRX Quantum': 4.415,
    'Mathematical Programming Computation': 4.377,
    'IEEE Transactions on Automatic Control': 4.334,
    'IEEE Network': 4.266,
    'Journal of Strategic Information Systems': 4.011,
    'Journal of Supply Chain Management': 4.009,
    'IEEE Transactions on Industrial Informatics': 4.002,
    'IEEE Signal Processing Magazine': 3.939,
    'Annual Review of Control, Robotics, and Autonomous Systems': 3.891,
    'Synthesis Lectures on Artificial Intelligence and Machine Learning': 3.886,
    'MIS Quarterly: Management Information Systems': 3.793,
    'IEEE Internet of Things Journal': 3.747,
    'ACM Transactions on Graphics': 3.697,
    'GigaScience': 3.697,
    'IEEE Transactions on Systems, Man, and Cybernetics: Systems': 3.688,
    'Computers and Education': 3.682,
    'Journal of Operations Management': 3.679,
    'Operations Research': 3.579,
    'npj Digital Medicine': 3.552,
    'IEEE Transactions on Fuzzy Systems': 3.533,
    'IEEE Transactions on Neural Networks and Learning Systems': 3.447,
    'IEEE Transactions on Cognitive Communications and Networking': 3.419,
    'Foundations and Trends in Communications and Information Theory': 3.415,
    'AVS Quantum Science': 3.413,
    'International Journal of Computer Vision': 3.369,
    'Annual Reviews in Control': 3.343,
    'Internet and Higher Education': 3.327,
    'ISPRS Journal of Photogrammetry and Remote Sensing': 3.308,
    'npj Quantum Information': 3.305,
    'Proceedings of the Annual ACM Symposium on Theory of Computing': 3.26,
    'Information Systems Research': 3.257,
    'IEEE/CAA Journal of Automatica Sinica': 3.218,
    'Medical Image Analysis': 3.195,
    'IEEE Transactions on Industrial Electronics': 3.194,
    'Foundations and Trends in Information Retrieval': 3.128,
    'Journal of Management Information Systems': 3.064,
    'npj Computational Materials': 2.977,
    'Computer-Aided Civil and Infrastructure Engineering': 2.962,
    'Journal of the ACM': 2.953,
    'IEEE Open Journal of the Communications Society': 2.95,
    'Bioinformatics': 2.94,
    'Wiley Interdisciplinary Reviews: Computational Molecular Science': 2.889,
    'Transportation Research Part C: Emerging Technologies': 2.882,
    'Robotics and Computer-Integrated Manufacturing': 2.881,
    'Array': 2.88,
    'Proceedings - IEEE INFOCOM': 2.877,
    'IEEE Transactions on Medical Imaging': 2.872,
    'Soft Robotics': 2.859,
    'IEEE Transactions on Vehicular Technology': 2.802,
    'Information Systems Journal': 2.748,
    'Journal of Manufacturing Systems': 2.742,
    'International Journal of Robotics Research': 2.729,
    'Journal of Statistical Software': 2.722,
    'IEEE Transactions on Robotics': 2.716,
    'Journal of Big Data': 2.714,
    'Information and Management': 2.688,
    'Computer Science Review': 2.685,
    'IEEE Transactions on Intelligent Transportation Systems': 2.674,
    'IEEE Transactions on Information Forensics and Security': 2.652,
    'International Conference on Architectural Support for Programming Languages and Operating Systems - ASPLOS': 2.649,
    'Computers in Industry': 2.646,
    'Swarm and Evolutionary Computation': 2.646,
    'IEEE Journal on Selected Topics in Signal Processing': 2.631,
    'Proceedings of the Annual ACM-SIAM Symposium on Discrete Algorithms': 2.626,
    'ACM Transactions on Intelligent Systems and Technology': 2.603,
    'IEEE Transactions on Mobile Computing': 2.541,
    'IEEE Geoscience and Remote Sensing Magazine': 2.539,
    'Big Data Mining and Analytics': 2.533,
    'IEEE Transactions on Signal Processing': 2.503,
    'Artificial Intelligence Review': 2.49,
    'European Journal of Information Systems': 2.481,
    'International Journal of Information Management Data Insights': 2.479,
    'Mechanical Systems and Signal Processing': 2.475,
    'SIAM Journal on Computing': 2.471,
    'Computers in Human Behavior': 2.464,
    'IEEE Computational Intelligence Magazine': 2.437,
    'Radiology: Artificial Intelligence': 2.432,
    'Scientific data': 2.41,
    'Big Data and Society': 2.389,
    'Journal of Network and Computer Applications': 2.384,
    'IEEE Transactions on Control of Network Systems': 2.376,
    'European Journal of Operational Research': 2.371,
    'Nature Computational Science': 2.36,
    'IEEE Transactions on Image Processing': 2.359,
    'IEEE Transactions on Knowledge and Data Engineering': 2.358,
    'Wiley Interdisciplinary Reviews: Data Mining and Knowledge Discovery': 2.33,
    'IEEE Communications Standards Magazine': 2.306,
    'NAR Genomics and Bioinformatics': 2.304,
    'Information Sciences': 2.285,
    'SIAM Journal on Optimization': 2.282,
    'Journal of Machine Learning Research': 2.281,
    'Mathematical Programming': 2.281,
    'Neural Networks': 2.221,
    'Computer Methods in Applied Mechanics and Engineering': 2.218,
    'Briefings in Bioinformatics': 2.163,
    'Journal of Intelligent Manufacturing': 2.16,
    'International Journal of Computer-Supported Collaborative Learning': 2.158,
    'Cell Reports Methods': 2.145,
    'Human Behavior and Emerging Technologies': 2.126,
    'Proceedings of the ACM SIGMOD International Conference on Management of Data': 2.116,
    'IEEE Transactions on Services Computing': 2.111,
    'Information Processing and Management': 2.106,
    'Journal of Memory and Language': 2.104,
    'Proceedings of the Annual ACM Symposium on Principles of Distributed Computing': 2.101,
    'Pattern Recognition': 2.085,
    'Journal of Manufacturing Technology Management': 2.083,
    'Decision Support Systems': 2.076,
    'IEEE/ASME Transactions on Mechatronics': 2.069,
    'Knowledge-Based Systems': 2.065,
    'International Journal of Educational Technology in Higher Education': 2.051,
    'Future Generation Computer Systems': 2.043,
    'IEEE/ACM Transactions on Networking': 2.025,
    'Journal of Field Robotics': 2.019,
    'Proceedings of the VLDB Endowment': 2.012,
    'Information and Organization': 1.997,
    'Transactions of the Association for Computational Linguistics': 1.985,
    'Journal of Computer-Mediated Communication': 1.954,
    'Social Media and Society': 1.95,
    'APL Photonics': 1.949,
    'High-Confidence Computing': 1.926,
    'IEEE Transactions on Affective Computing': 1.905,
    'Energy and AI': 1.891,
    'IEEE Transactions on Parallel and Distributed Systems': 1.89,
    'Applied Soft Computing Journal': 1.882,
    'Telematics and Informatics': 1.878,
    'Expert Systems with Applications': 1.873,
    'IEEE Intelligent Systems': 1.872,
    'PLoS Computational Biology': 1.872,
    'Virtual and Physical Prototyping': 1.871,
    'IEEE Transactions on Information Theory': 1.87,
    'Journal of Combinatorial Theory. Series B': 1.869,
    'ACM Transactions on Information Systems': 1.864,
    'Network Neuroscience': 1.853,
    'Physics of Life Reviews': 1.848,
    'IEEE Transactions on Dependable and Secure Computing': 1.828,
    'VLDB Journal': 1.825,
    'Artificial Intelligence': 1.819,
    'Digital Communications and Networks': 1.796,
    'IEEE Communications Letters': 1.795,
    'Computers and Geotechnics': 1.787,
    'Database : the journal of biological databases and curation': 1.786,
    'Proceedings of the ACM on Programming Languages': 1.77,
    'Science China Information Sciences': 1.767,
    'IEEE Transactions on Emerging Topics in Computational Intelligence': 1.764,
    'Computers and Industrial Engineering': 1.76,
    'Computer Assisted Language Learning': 1.754,
    'Journal of Computational Physics': 1.753,
    'Engineering': 1.75,
    'Journal of the Association for Information Systems': 1.741,
    'Engineering Applications of Artificial Intelligence': 1.729,
    'IACR Transactions on Cryptographic Hardware and Embedded Systems': 1.72,
    'Production Planning and Control': 1.719,
    'Computers and Operations Research': 1.716,
    'IEEE Transactions on Software Engineering': 1.713,
    'ACM Transactions on Database Systems': 1.709,
    'Advanced Engineering Informatics': 1.709,
    'Computers and Education: Artificial Intelligence': 1.7,
    'IEEE Robotics and Automation Letters': 1.693,
    'IEEE Transactions on Network and Service Management': 1.693,
    'mSystems': 1.691,
    'Journal of Environmental Informatics': 1.681,
    'IEEE Transactions on Green Communications and Networking': 1.679,
    'Machine Learning': 1.679,
    'Journal of Educational Computing Research': 1.673,
    'IEEE Journal of Biomedical and Health Informatics': 1.672,
    'Journal of Chemical Theory and Computation': 1.665,
    'Social Science Computer Review': 1.662,
    'Foundations of Computational Mathematics': 1.652,
    'IEEE Transactions on Network Science and Engineering': 1.647,
    'Journal of Communications and Networks': 1.644,
    'IEEE Transactions on Multimedia': 1.638,
    'Proceedings of the ACM Conference on Computer and Communications Security': 1.638,
    'Data Mining and Knowledge Discovery': 1.635,
    'Journal of Cheminformatics': 1.63,
    'Journal of Computer Assisted Learning': 1.629,
    'Computer Networks': 1.625,
    'Nonlinear Analysis: Hybrid Systems': 1.617,
    'Computers and Security': 1.605,
    'Foundations and Trends in Databases': 1.597,
    'ISA Transactions': 1.588,
    'Computers and Electronics in Agriculture': 1.587,
    'Journal of Communications and Information Networks': 1.586,
    'Synthesis Lectures on Image, Video, and Multimedia Processing': 1.584,
    'IEEE Transactions on Intelligent Vehicles': 1.583,
    'Archives of Computational Methods in Engineering': 1.581,
    'Computational and Structural Biotechnology Journal': 1.579,
    'Computing and Software for Big Science': 1.578,
    'Advanced Quantum Technologies': 1.577,
    'Proceedings of the ACM SIGPLAN Conference on Programming Language Design and Implementation (PLDI)': 1.562,
    'Cognitive Psychology': 1.56,
    'Developments in the Built Environment': 1.556,
    'Electronic Markets': 1.545,
    'Artificial Intelligence and Law': 1.544,
    'IEEE Transactions on Circuits and Systems I: Regular Papers': 1.542,
    'Business and Information Systems Engineering': 1.535,
    'Systems and Control Letters': 1.519,
    'Mechanism and Machine Theory': 1.517,
    'IEEE Transactions on Visualization and Computer Graphics': 1.515,
    'Communications of the ACM': 1.514,
    'Human-centric Computing and Information Sciences': 1.501,
    'International Journal of Neural Systems': 1.491,
    'Computational Visual Media': 1.488,
    'Journal of Materials Processing Technology': 1.485,
    'Neurocomputing': 1.481,
    'Internet of Things (Netherlands)': 1.474,
    'Internet Policy Review': 1.473,
    'Cyberpsychology, Behavior, and Social Networking': 1.466,
    'Blockchain: Research and Applications': 1.461,
    'Journal of Hospitality and Tourism Technology': 1.46,
    'Data Science and Engineering': 1.458,
    'Digital Biomarkers': 1.458,
    'IEEE Systems Journal': 1.448,
    'Artificial Intelligence in Medicine': 1.443,
    'Journal of Computers in Education': 1.442,
    'INFORMS Journal on Computing': 1.441,
    'IEEE Transactions on Cloud Computing': 1.439,
    'International Journal of Intelligent Systems': 1.438,
    'Mathematics of Operations Research': 1.435,
    'Information Systems Frontiers': 1.424,
    'Online Learning Journal': 1.417,
    'Theory of Computing': 1.406,
    'Computerized Medical Imaging and Graphics': 1.402,
    'Computer Communications': 1.395,
    'Current Opinion in Systems Biology': 1.391,
    'npj Systems Biology and Applications': 1.388,
    'Control Engineering Practice': 1.386,
    'Engineered Science': 1.386,
    'IEEE Transactions on Artificial Intelligence': 1.381,
    'Journal of Information Technology': 1.375,
    'Journal of Learning Analytics': 1.369,
    'Journal of Chemical Information and Modeling': 1.357,
    'IEEE Transactions on Emerging Topics in Computing': 1.353,
    'Journal of Research on Technology in Education': 1.352,
    'IEEE Transactions on Computational Social Systems': 1.351,
    'Machine Learning: Science and Technology': 1.351,
    'Environmental Modelling and Software': 1.35,
    'Journal of Computing in Civil Engineering': 1.349,
    'IEEE/ACM Transactions on Audio Speech and Language Processing': 1.348,
    'IEEE Transactions on Sustainable Computing': 1.346,
    'Information Technology and Tourism': 1.342,
    'China Communications': 1.328,
    'Computational Mechanics': 1.321,
    'International Journal of Geographical Information Science': 1.315,
    'Journal of Computational Physics: X': 1.314,
    'Computer Physics Communications': 1.312,
    'Electronic Commerce Research and Applications': 1.307,
    'Mobile Media and Communication': 1.303,
    'Pattern Recognition Letters': 1.302,
    'Ad Hoc Networks': 1.301,
    'IEEE Circuits and Systems Magazine': 1.293,
    'IEEE Transactions on Signal and Information Processing over Networks': 1.291,
    'Empirical Software Engineering': 1.287,
    'Journal of Optical Communications and Networking': 1.287,
    'Random Structures and Algorithms': 1.281,
    'Journal of Information Security and Applications': 1.279,
    'Journal of Systems Architecture': 1.276,
    'ACM Transactions on Knowledge Discovery from Data': 1.272,
    'Journal of Informetrics': 1.269,
    'International Journal of Human Computer Studies': 1.268,
    'Cybersecurity': 1.266,
    'Technology, Pedagogy and Education': 1.261,
    'IEEE Transactions on Neural Systems and Rehabilitation Engineering': 1.26,
    'Computers and Structures': 1.259,
    'IEEE Transactions on Biometrics, Behavior, and Identity Science': 1.255,
    'Proceedings of the ACM SIGACT-SIGMOD-SIGART Symposium on Principles of Database Systems': 1.249,
    'Robotics and Autonomous Systems': 1.245,
    'Journal of Enterprise Information Management': 1.242,
    'Topics in Cognitive Science': 1.242,
    'Policy and Internet': 1.24,
    'Computational Linguistics': 1.238,
    'Proceedings - International Conference on Software Engineering': 1.235,
    'Minds and Machines': 1.232,
    'Design Studies': 1.231,
    'Signal Processing': 1.231,
    'User Modeling and User-Adapted Interaction': 1.231,
    'ACM Transactions on Sensor Networks': 1.228,
    'Computers in Biology and Medicine': 1.222,
    'Industrial Management and Data Systems': 1.219,
    'Computer Vision and Image Understanding': 1.213,
    'Fuzzy Sets and Systems': 1.212,
    'Proceedings - International Conference on Distributed Computing Systems': 1.211,
    'ACM Transactions on Internet Technology': 1.21,
    'European Journal of Combinatorics': 1.209,
    'Railway Engineering Science': 1.209,
    'ICT Express': 1.204,
    'Artificial Intelligence in Agriculture': 1.202,
    'Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies': 1.202,
    'Computer Standards and Interfaces': 1.199,
    'ACM Transactions on Software Engineering and Methodology': 1.198,
    'Information Technology for Development': 1.196,
    'Information and Inference': 1.193,
    'Distributed Computing': 1.192,
    'Journal of Combinatorial Theory - Series A': 1.192,
    'Telecommunications Policy': 1.192,
    'Journal of Internet Commerce': 1.187,
    'ACM Transactions on Mathematical Software': 1.186,
    'Proceedings - IEEE International Conference on Robotics and Automation': 1.186,
    'Interactive Technology and Smart Education': 1.184,
    'IEEE Transactions on Big Data': 1.182,
    'Information and Software Technology': 1.182,
    'Computers and Geosciences': 1.18,
    'Human-Computer Interaction': 1.178,
    'ACM Transactions on the Web': 1.177,
    'International Journal of Social Robotics': 1.177,
    'Indonesian Journal of Science and Technology': 1.176,
    'IEEE Transactions on Cognitive and Developmental Systems': 1.173,
    'ReCALL': 1.173,
    'IEEE Robotics and Automation Magazine': 1.172,
    'Intelligence-Based Medicine': 1.171,
    'Journal of Scientific Computing': 1.171,
    'Interactive Learning Environments': 1.17,
    'Science Technology and Human Values': 1.17,
    'Neural Computing and Applications': 1.169,
    'Autonomous Robots': 1.165,
    'Journal of Healthcare Informatics Research': 1.163,
    'Information Systems Management': 1.16,
    'Journal of the Franklin Institute': 1.159,
    'Journal of Parallel and Distributed Computing': 1.158,
    'Engineering Applications of Computational Fluid Mechanics': 1.155,
    'International Journal of Molecular Sciences': 1.154,
    'Proceedings - International Conference on Data Engineering': 1.153,
    'Fuzzy Optimization and Decision Making': 1.152,
    'IEEE Transactions on Computers': 1.152,
    'Methods, Data, Analyses': 1.148,
    'Applied Intelligence': 1.145,
    'IACR Transactions on Symmetric Cryptology': 1.144,
    'International Journal of Health Geographics': 1.142,
    'American Journal of Distance Education': 1.14,
    'IEEE Transactions on Learning Technologies': 1.14,
    'Complex and Intelligent Systems': 1.138,
    'Theory and Practice of Logic Programming': 1.138,
    'ACM Transactions on Internet of Things': 1.129,
    'Bioinformatics and Biology Insights': 1.128,
    'Intelligent Systems with Applications': 1.126,
    'Journal of Membrane Computing': 1.126,
    'Journal of Systems and Software': 1.126,
    'Language Learning and Technology': 1.125,
    'Journal of Artificial Intelligence Research': 1.124,
    'IEEE Signal Processing Letters': 1.123,
    'Proceedings - Symposium on Logic in Computer Science': 1.122,
    'International Journal of Logistics Research and Applications': 1.12,
    'Computer Methods and Programs in Biomedicine': 1.118,
    'Structural and Multidisciplinary Optimization': 1.116,
    'CAAI Transactions on Intelligence Technology': 1.115,
    'Inverse Problems': 1.115,
    'International Journal of Artificial Intelligence in Education': 1.11,
    'Technology, Knowledge and Learning': 1.108,
    'IEEE Transactions on Computational Imaging': 1.105,
    'International Journal of Systems Science': 1.105,
    'Ocean Modelling': 1.104,
    'Journal of Grid Computing': 1.103,
    'Journal of Intelligent Transportation Systems: Technology, Planning, and Operations': 1.101,
    'BMC Bioinformatics': 1.1,
    'Swarm Intelligence': 1.098,
    'Engineering with Computers': 1.096,
    'Journal of Information Systems': 1.092,
    'IEEE Transactions on Quantum Engineering': 1.088,
    'Journal of Pragmatics': 1.085,
    'Journal of Biomedical Informatics': 1.083,
    'Virtual Reality': 1.081,
    'Information Technology and People': 1.08,
    'Pervasive and Mobile Computing': 1.08,
    'Optimization Methods and Software': 1.079,
    'Biomedical Signal Processing and Control': 1.071,
    'International Journal of Child-Computer Interaction': 1.071,
    'Proceedings - Real-Time Systems Symposium': 1.07,
    'Journal of Semantics': 1.069,
    'Cognitive Systems Research': 1.068,
    'EPJ Data Science': 1.066,
    'ACM Transactions on Multimedia Computing, Communications and Applications': 1.065,
    'Games and Culture': 1.065,
    'Granular Computing': 1.059,
    'International Transactions in Operational Research': 1.059,
    'Cognitive Science': 1.057,
    'International Journal of Computer Integrated Manufacturing': 1.056,
    'Journal of Digital Imaging': 1.054,
    'Knowledge Engineering Review': 1.053,
    'International Journal of Digital Earth': 1.052,
    'IEEE Transactions on Human-Machine Systems': 1.051,
    'Online Social Networks and Media': 1.051,
    'Quantum Machine Intelligence': 1.051,
    'Journal of Artificial Intelligence and Soft Computing Research': 1.047,
    'Journal of Manufacturing Science and Engineering': 1.046,
    'Memetic Computing': 1.042,
    'Enterprise Information Systems': 1.039,
    'IEEE Intelligent Transportation Systems Magazine': 1.038,
    'Cognitive Computation': 1.037,
    'Engineering Science and Technology, an International Journal': 1.036,
    'Designs, Codes, and Cryptography': 1.033,
    'Data Science Journal': 1.026,
    'IPSJ Transactions on Computer Vision and Applications': 1.025,
    'Journal of Cryptology': 1.025,
    'IEEE Internet Computing': 1.023,
    'International Journal of Artificial Intelligence': 1.023,
    'Scientometrics': 1.019,
    'International Journal of Cognitive Computing in Engineering': 1.015,
    'Journal of the Association for Information Science and Technology': 1.015,
    'Smart Cities': 1.015,
    'World Wide Web': 1.013,
    'Computability': 1.008,
    'Algorithmica': 1.007,
    'Computational Social Networks': 1.003,
    'International Journal of Machine Learning and Cybernetics': 1.002,
    'Manufacturing and Service Operations Management': 7.126,
    'Annals of Mathematics': 6.76,
    'Lancet Digital Health, The': 6.433,
    'Journal of Business and Economic Statistics': 6.15,
    'Management Science': 5.322,
    'Annals of Statistics': 5.219,
    'International Journal of Information Management': 4.906,
    'Organizational Research Methods': 4.626,
    'Strategy Science': 4.232,
    'Journal of Strategic Information Systems': 4.011,
    'Journal of the Royal Statistical Society. Series B: Statistical Methodology': 3.976,
    'Annual Review of Statistics and Its Application': 3.896,
    'MIS Quarterly: Management Information Systems': 3.793,
    'IEEE Internet of Things Journal': 3.747,
    'Journal of Operations Management': 3.679,
    'Research Policy': 3.634,
    'Operations Research': 3.579,
    'Biometrika': 3.566,
    'Journal of the American Statistical Association': 3.397,
    'Information Systems Research': 3.257,
    'Transportation Research Part B: Methodological': 3.205,
    'Communications in Transportation Research': 3.188,
    'Journal of Business Logistics': 3.135,
    'Production and Operations Management': 3.12,
    'Annals of Probability': 3.099,
    'Journal of Management Information Systems': 3.064,
    'International Journal of Production Economics': 3.028,
    'Structural Equation Modeling': 3.014,
    'Transportation Research, Part E: Logistics and Transportation Review': 3.005,
    'International Journal of Production Research': 2.976,
    'Transportation Research Part C: Emerging Technologies': 2.882,
    'International Journal of Management Reviews': 2.857,
    'Journal of Industrial Information Integration': 2.723,
    'Journal of Statistical Software': 2.722,
    'Journal of Big Data': 2.714,
    'Information and Management': 2.688,
    'Omega': 2.679,
    'International Journal of Operations and Production Management': 2.618,
    'Journal of Global Economic Analysis': 2.615,
    'Probability Theory and Related Fields': 2.515,
    'European Journal of Information Systems': 2.481,
    'International Journal of Information Management Data Insights': 2.479,
    'Accounting, Organizations and Society': 2.411,
    'Scientific data': 2.41,
    'Big Data and Society': 2.389,
    'American Statistician': 2.373,
    'European Journal of Operational Research': 2.371,
    'Information Sciences': 2.285,
    'Biostatistics': 2.248,
    'Decision Sciences': 2.122,
    'IEEE Transactions on Services Computing': 2.111,
    'Information Processing and Management': 2.106,
    'Decision Support Systems': 2.076,
    'Knowledge-Based Systems': 2.065,
    'Transportation Research, Part A: Policy and Practice': 2.029,
    'Statistical Science': 1.978,
    'Annals of Applied Probability': 1.971,
    "Annales de l'institut Henri Poincare (B) Probability and Statistics": 1.816,
    'Oxford Bulletin of Economics and Statistics': 1.743,
    'Journal of Mixed Methods Research': 1.736,
    'Journal of Computational and Graphical Statistics': 1.72,
    'Production Planning and Control': 1.719,
    'Computers and Operations Research': 1.716,
    'Journal of Environmental Informatics': 1.681,
    'Journal of Management in Engineering - ASCE': 1.645,
    'Transportation Research Interdisciplinary Perspectives': 1.629,
    'Decision Making: Applications in Management and Engineering': 1.579,
    'Management Learning': 1.574,
    'Sport Management Review': 1.513,
    'Critical Perspectives on Accounting': 1.483,
    'INFORMS Journal on Computing': 1.441,
    'Mathematics of Operations Research': 1.435,
    'Ecological Indicators': 1.396,
    'Journal of Management Science and Engineering': 1.384,
    'Finance and Stochastics': 1.349,
    'Journal of Manufacturing Processes': 1.349,
    'Management Decision': 1.345,
    'Comparative Migration Studies': 1.34,
    'Patterns': 1.34,
    'Journal of Managerial Psychology': 1.306,
    'Journal of Management Analytics': 1.281,
    'Journal of Informetrics': 1.269,
    'Journal of Enterprise Information Management': 1.242,
    'Electronic Journal of Probability': 1.235,
    'Journal of Sport Management': 1.219,
    'International Conference on Information and Knowledge Management, Proceedings': 1.214,
    'EURO Journal on Transportation and Logistics': 1.213,
    'Journal of Choice Modelling': 1.186,
    'IEEE Transactions on Big Data': 1.182,
    'Stochastic Systems': 1.179,
    'Statistica Sinica': 1.169,
    'Journal of Multivariate Analysis': 1.167,
    'Management Accounting Research': 1.167,
    'Annals of Applied Statistics': 1.159,
    'International Journal of Accounting Information Systems': 1.159,
    'Methods, Data, Analyses': 1.148,
    'Socio-Economic Planning Sciences': 1.131,
    'International Journal of Logistics Research and Applications': 1.12,
    'Journal of Leadership and Organizational Studies': 1.096,
    'Journal of Information Systems': 1.092,
    'Electronic Journal of Statistics': 1.067,
    'International Transactions in Operational Research': 1.059,
    'Annals of Operations Research': 1.046,
    'Judgment and Decision Making': 1.046,
    'Enterprise Information Systems': 1.039,
    'Service Science': 1.016,
    'International Journal of Cognitive Computing in Engineering': 1.015,
    'Journal of the Association for Information Science and Technology': 1.015,
    'Econometrics and Statistics': 1.01,
    'Journal of Responsible Innovation': 1.007,
    'Periodontology 2000': 3.457,
    'International journal of oral science': 2.603,
    'Journal of Clinical Periodontology': 2.407,
    'Journal of Dental Research': 1.872,
    'Clinical Oral Implants Research': 1.691,
    'International Endodontic Journal': 1.506,
    'Journal of Endodontics': 1.399,
    'Clinical Implant Dentistry and Related Research': 1.382,
    'Dental Materials': 1.283,
    'European Journal of Orthodontics': 1.272,
    'Oral Oncology': 1.271,
    'Journal of Periodontology': 1.27,
    'Journal of Cranio-Maxillo-Facial Surgery': 1.263,
    'Journal of Prosthodontics': 1.225,
    'Journal of Dentistry': 1.189,
    'Angle Orthodontist': 1.167,
    'Journal of Prosthetic Dentistry': 1.154,
    'Dental Traumatology': 1.103,
    'American Journal of Orthodontics and Dentofacial Orthopedics': 1.062,
    'Annual Review of Astronomy and Astrophysics': 14.486,
    'Astronomy and Astrophysics Review': 9.937,
    'Reviews of Geophysics': 9.061,
    'Living Reviews in Solar Physics': 7.235,
    'Nature Reviews Earth and Environment': 6.193,
    'Nature Geoscience': 6.11,
    'Annual Review of Earth and Planetary Sciences': 6.083,
    'Annual Review of Marine Science': 5.097,
    'Radiocarbon': 4.271,
    'Earth System Science Data': 4.24,
    'Remote Sensing of Environment': 4.057,
    'Current Climate Change Reports': 4.042,
    'Earth-Science Reviews': 3.807,
    'Journal of Advances in Modeling Earth Systems': 3.605,
    'ISPRS Journal of Photogrammetry and Remote Sensing': 3.308,
    'Quarterly Journal of the Royal Meteorological Society': 3.26,
    'Astrophysical Journal, Supplement Series': 3.086,
    'Wiley Interdisciplinary Reviews: Climate Change': 2.972,
    'npj Climate and Atmospheric Science': 2.87,
    'Limnology And Oceanography Letters': 2.848,
    'New Astronomy Reviews': 2.825,
    'Space Science Reviews': 2.768,
    'Astrophysical Journal Letters': 2.726,
    'One Earth': 2.617,
    'Shiyou Kantan Yu Kaifa/Petroleum Exploration and Development': 2.565,
    'IEEE Geoscience and Remote Sensing Magazine': 2.539,
    'China Petroleum Exploration': 2.523,
    "Earth's Future": 2.51,
    'Antipode': 2.504,
    'Atmospheric Chemistry and Physics': 2.463,
    'Communications Earth and Environment': 2.457,
    'Bulletin of the American Meteorological Society': 2.418,
    'Earth and Planetary Science Letters': 2.416,
    'Climate Policy': 2.412,
    'IEEE Transactions on Geoscience and Remote Sensing': 2.4,
    'Geology': 2.397,
    'Engineering Geology': 2.342,
    'Earth System Dynamics': 2.298,
    'American Journal of Science': 2.283,
    'Gondwana Research': 2.283,
    'Wiley Interdisciplinary Reviews: Water': 2.245,
    'Journal of Climate': 2.243,
    'Journal of Petrology': 2.243,
    'Geoscientific Model Development': 2.225,
    'Cryosphere': 2.222,
    'Global Biogeochemical Cycles': 2.19,
    'Bulletin of the Geological Society of America': 2.17,
    'Geochimica et Cosmochimica Acta': 2.165,
    'Geochemical Perspectives Letters': 2.152,
    'Publications of the Astronomical Society of the Pacific': 2.151,
    'Economic Geology': 2.146,
    'Tunnelling and Underground Space Technology': 2.125,
    'Geochronology': 2.107,
    'Tectonics': 2.068,
    'Acta Geotechnica': 2.067,
    'Geotechnique': 2.042,
    'Mineralium Deposita': 2.039,
    'Astronomical Journal': 2.025,
    'Climate of the Past': 2.025,
    'Journals of the Atmospheric Sciences': 2.016,
    'Astronomy and Astrophysics': 1.999,
    'International Journal of Mining Science and Technology': 1.991,
    'Journal of Geophysical Research E: Planets': 1.989,
    'Journal of Metamorphic Geology': 1.983,
    'International Journal of Rock Mechanics and Minings Sciences': 1.965,
    'Weather and Climate Extremes': 1.941,
    'Geoscience Frontiers': 1.93,
    'Landslides': 1.928,
    'Astrophysical Journal': 1.925,
    'Geography and Sustainability': 1.898,
    'Monthly Weather Review': 1.89,
    'Fish and Fisheries': 1.868,
    'Geotextiles and Geomembranes': 1.864,
    'Journal of Geotechnical and Geoenvironmental Engineering - ASCE': 1.861,
    'Rock Mechanics and Rock Engineering': 1.849,
    'Climate Dynamics': 1.847,
    'Geophysical Research Letters': 1.837,
    'Contributions to Mineralogy and Petrology': 1.835,
    'Geochemistry, Geophysics, Geosystems': 1.834,
    'Journal of Geophysical Research D: Atmospheres': 1.798,
    'Soil and Tillage Research': 1.796,
    'Computers and Geotechnics': 1.787,
    'Hydrology and Earth System Sciences': 1.777,
    'Journal of Rock Mechanics and Geotechnical Engineering': 1.762,
    'Journal of Geophysical Research: Solid Earth': 1.742,
    'Monthly Notices of the Royal Astronomical Society': 1.734,
    'Paleoceanography and Paleoclimatology': 1.721,
    'Earthquake Engineering and Structural Dynamics': 1.714,
    'Science China Earth Sciences': 1.705,
    'Quaternary Science Reviews': 1.701,
    'Precambrian Research': 1.698,
    'Monthly Notices of the Royal Astronomical Society: Letters': 1.696,
    'Surveys in Geophysics': 1.681,
    'Journal of Geodesy': 1.663,
    'Annals of the American Association of Geographers': 1.654,
    'Lithos': 1.653,
    'Geography Compass': 1.65,
    'International Journal of Coal Geology': 1.644,
    'Geophysics': 1.638,
    'International Journal of Applied Earth Observation and Geoinformation': 1.628,
    'Permafrost and Periglacial Processes': 1.627,
    'AAPG Bulletin': 1.623,
    'Agricultural and Forest Meteorology': 1.623,
    'Seismological Research Letters': 1.612,
    'Canadian Geotechnical Journal': 1.607,
    'Advances in Geo-Energy Research': 1.599,
    'Biogeosciences': 1.598,
    'Journal of Physical Oceanography': 1.585,
    'Reviews in Mineralogy and Geochemistry': 1.562,
    'Elements': 1.551,
    'Earthquake Spectra': 1.55,
    'Transactions of the Institute of British Geographers': 1.544,
    'Advances in Atmospheric Sciences': 1.54,
    'Publications of the Astronomical Society of Australia': 1.536,
    'Oil and Gas Geology': 1.529,
    'Agricultural Water Management': 1.524,
    'Earth Systems and Environment': 1.516,
    'Journal of Geophysical Research F: Earth Surface': 1.516,
    'Weather and Climate Dynamics': 1.515,
    'Marine and Petroleum Geology': 1.513,
    'Marine Pollution Bulletin': 1.49,
    'Catena': 1.472,
    'Limnology and Oceanography': 1.466,
    'Tianranqi Gongye/Natural Gas Industry': 1.465,
    'Journal of the Meteorological Society of Japan': 1.464,
    'Chemical Geology': 1.46,
    'Global and Planetary Change': 1.459,
    'Geobiology': 1.441,
    'Petroleum Exploration and Development': 1.435,
    'Physics of the Dark Universe': 1.431,
    'International Journal for Numerical and Analytical Methods in Geomechanics': 1.429,
    'Atmospheric Research': 1.425,
    'Sedimentology': 1.421,
    'Journal of Geophysical Research: Oceans': 1.42,
    'Geosphere': 1.414,
    'Organic Geochemistry': 1.395,
    'Atmospheric Measurement Techniques': 1.391,
    'Advances in Climate Change Research': 1.383,
    'Boundary-Layer Meteorology': 1.381,
    'Journal of Hydrometeorology': 1.374,
    'Remote Sensing in Ecology and Conservation': 1.366,
    'Geomechanics for Energy and the Environment': 1.362,
    'Earth Surface Dynamics': 1.359,
    'Weather and Forecasting': 1.356,
    'Solid Earth': 1.35,
    'Atmospheric Environment': 1.347,
    'Geophysical Journal International': 1.347,
    'GIScience and Remote Sensing': 1.34,
    'Climatic Change': 1.335,
    'Basin Research': 1.329,
    'Geosynthetics International': 1.319,
    'Tectonophysics': 1.309,
    'Terra Nova': 1.309,
    'GPS Solutions': 1.302,
    'Biogeochemistry': 1.293,
    'Bulletin of the Seismological Society of America': 1.286,
    'Journal of Geophysical Research G: Biogeosciences': 1.285,
    'IEEE Geoscience and Remote Sensing Letters': 1.284,
    'PaleoAmerica': 1.28,
    'Ocean Science': 1.278,
    'IEEE Journal of Selected Topics in Applied Earth Observations and Remote Sensing': 1.264,
    'American Mineralogist': 1.262,
    'Soil Dynamics and Earthquake Engineering': 1.251,
    'Icarus': 1.241,
    'Journal of Guidance, Control, and Dynamics': 1.239,
    'Bulletin of Earthquake Engineering': 1.233,
    'Transportation Geotechnics': 1.229,
    'Soils and Foundations': 1.227,
    'Journal of Structural Geology': 1.225,
    'Geoenvironmental Disasters': 1.221,
    'Geomorphology': 1.207,
    'Georisk': 1.207,
    'Progress in Oceanography': 1.198,
    'Progress in Disaster Science': 1.194,
    'Underground Space (China)': 1.189,
    'Urban Climate': 1.185,
    'Quaternary Geochronology': 1.182,
    'Computers and Geosciences': 1.18,
    'International Journal of Climatology': 1.178,
    'Earth Surface Processes and Landforms': 1.177,
    'Indonesian Journal of Science and Technology': 1.176,
    'International Journal of Coal Science and Technology': 1.175,
    'Ore Geology Reviews': 1.172,
    'International Journal of Geomechanics': 1.163,
    'Geoscience Letters': 1.158,
    'Marine Life Science and Technology': 1.158,
    'SPE Journal': 1.144,
    'Progress in Earth and Planetary Science': 1.143,
    'Atmospheric Science Letters': 1.139,
    'Remote Sensing': 1.136,
    'Cold Regions Science and Technology': 1.135,
    'International Journal of Disaster Risk Reduction': 1.132,
    'Geostandards and Geoanalytical Research': 1.127,
    'Ocean and Coastal Management': 1.126,
    'Frontiers in Marine Science': 1.122,
    'Journal of the Geological Society': 1.122,
    'Publication of the Astronomical Society of Japan': 1.119,
    'Journal of Natural Gas Science and Engineering': 1.116,
    'Journal of Petroleum Science and Engineering': 1.111,
    'Journal of Volcanology and Geothermal Research': 1.107,
    'Astrodynamics': 1.106,
    'Carbon Balance and Management': 1.106,
    'Ocean Modelling': 1.104,
    'Palaeogeography, Palaeoclimatology, Palaeoecology': 1.097,
    'Geographical Analysis': 1.096,
    'Natural Hazards and Earth System Sciences': 1.094,
    'Journal of Geographical Sciences': 1.09,
    'Journal of Glaciology': 1.086,
    'Journal of Hydrology: Regional Studies': 1.085,
    'Atmospheric Environment: X': 1.084,
    'Space Weather': 1.084,
    'Physics of the Earth and Planetary Interiors': 1.082,
    'Journal of Applied Meteorology and Climatology': 1.079,
    'Marine Chemistry': 1.076,
    'GSA Today': 1.071,
    'International Geology Review': 1.067,
    'Oceanography and Marine Biology': 1.064,
    'Paleobiology': 1.063,
    'Journal of High Energy Astrophysics': 1.06,
    'Palaeontology': 1.058,
    'Bulletin of Volcanology': 1.056,
    'Structure and Infrastructure Engineering': 1.053,
    'International Journal of Digital Earth': 1.052,
    'Astrobiology': 1.05,
    'Climate Risk Management': 1.037,
    'Elementa': 1.037,
    'Journal of Asian Earth Sciences': 1.034,
    'Holocene': 1.025,
    'Bulletin of Engineering Geology and the Environment': 1.02,
    'Minerals Engineering': 1.018,
    'Vegetation History and Archaeobotany': 1.015,
    'Boreas': 1.013,
    'Journal of Southern Hemisphere Earth Systems Science': 1.011,
    'Marine Geology': 1.006,
    'Petroleum Science': 1.005,
    'Quarterly Journal of Economics': 36.73,
    'American Economic Review': 21.833,
    'Journal of Political Economy': 20.643,
    'Journal of Finance': 15.515,
    'Review of Economic Studies': 14.432,
    'Econometrica': 12.979,
    'Journal of Economic Literature': 12.966,
    'American Economic Journal: Applied Economics': 12.694,
    'Review of Financial Studies': 12.239,
    'American Economic Journal: Macroeconomics': 10.871,
    'Journal of Marketing': 10.802,
    'Journal of Financial Economics': 10.601,
    'Annual Review of Economics': 10.247,
    'Journal of Economic Perspectives': 8.59,
    'Review of Economics and Statistics': 8.371,
    'American Economic Journal: Economic Policy': 8.175,
    'Marketing Science': 7.593,
    'Journal of Marketing Research': 7.415,
    'Journal of Management': 7.213,
    'Journal of the Academy of Marketing Science': 6.248,
    'Journal of Business and Economic Statistics': 6.15,
    'Journal of Accounting and Economics': 6.1,
    'Journal of the European Economic Association': 6.087,
    'Journal of Consumer Research': 6.024,
    'Journal of Monetary Economics': 5.944,
    'Journal of Econometrics': 5.744,
    'Review of Finance': 5.68,
    'Review of Corporate Finance Studies': 5.448,
    'Journal of Financial Intermediation': 5.384,
    'Journal of Accounting Research': 5.165,
    'American Economic Journal: Microeconomics': 5.156,
    'Journal of Public Economics': 5.1,
    'Journal of International Business Studies': 5.093,
    'Handbook of Econometrics': 5.036,
    'Innovation Policy and the Economy': 4.903,
    'Journal of Labor Economics': 4.727,
    'Review of Asset Pricing Studies': 4.537,
    'Accounting Review': 4.446,
    'Economic Journal': 4.334,
    'Entrepreneurship Theory and Practice': 4.323,
    'Brookings Papers on Economic Activity': 4.021,
    'Journal of Supply Chain Management': 4.009,
    'IMF Economic Review': 3.734,
    'Journal of Financial and Quantitative Analysis': 3.7,
    'Journal of International Economics': 3.391,
    'Journal of Human Resources': 3.335,
    'Quantitative Economics': 3.313,
    'Journal of Public Policy and Marketing': 3.263,
    'Journal of World Business': 3.245,
    'Journal of Economic Growth': 3.234,
    'RAND Journal of Economics': 3.202,
    'Strategic Entrepreneurship Journal': 3.175,
    'Journal of Development Economics': 3.139,
    'Review of Environmental Economics and Policy': 3.117,
    'Journal of the Association of Environmental and Resource Economists': 3.078,
    'Journal of Urban Economics': 3.076,
    'Energy Economics': 3.039,
    'International Journal of Production Economics': 3.028,
    'Explorations in Economic History': 3.017,
    'Structural Equation Modeling': 3.014,
    'Contemporary Accounting Research': 3.012,
    'Journal of Population Economics': 2.986,
    'Journal of Economic Theory': 2.873,
    'Long Range Planning': 2.852,
    'Foundations and Trends in Finance': 2.823,
    'Small Business Economics': 2.732,
    'Resources, Conservation and Recycling': 2.682,
    'Journal of Innovation and Knowledge': 2.649,
    'Journal of Global Economic Analysis': 2.615,
    'Annual Review of Resource Economics': 2.602,
    'Journal of Business Ethics': 2.59,
    'Review of Economic Dynamics': 2.588,
    'World Bank Research Observer': 2.553,
    'Journal of Risk and Uncertainty': 2.545,
    'Journal of Environmental Economics and Management': 2.522,
    'World Development': 2.504,
    'Journal of Applied Econometrics': 2.495,
    'Jahrbucher fur Nationalokonomie und Statistik': 2.494,
    'International Business Review': 2.455,
    'Critical Finance Review': 2.451,
    'MIS Quarterly Executive': 2.436,
    'Theoretical Economics': 2.428,
    'Socio-Economic Review': 2.346,
    'Journal of Economic History': 2.331,
    'Review of International Political Economy': 2.331,
    'Experimental Economics': 2.293,
    'Journal of Corporate Finance': 2.268,
    'Oxford Review of Economic Policy': 2.241,
    'Finance Research Letters': 2.231,
    'Journal of Economic Surveys': 2.199,
    'Resources, Conservation and Recycling Advances': 2.168,
    'International Economic Review': 2.115,
    'Fiscal Studies': 2.103,
    'Journal of International Marketing': 2.088,
    'American Journal of Agricultural Economics': 2.075,
    'Family Business Review': 2.058,
    'Annual Review of Financial Economics': 2.053,
    'Applied Economic Perspectives and Policy': 2.051,
    'Review of International Organizations': 2.017,
    'Journal of Family Business Strategy': 1.992,
    'Work, Employment and Society': 1.988,
    'European Economic Review': 1.963,
    'Journal of Economic Geography': 1.953,
    'Business Ethics Quarterly': 1.947,
    'Ecological Economics': 1.899,
    'Food Policy': 1.896,
    'Economic Policy': 1.886,
    'International Review of Financial Analysis': 1.881,
    'Resources Policy': 1.869,
    'Journal of Industrial Ecology': 1.843,
    'Mathematical Finance': 1.837,
    'Economic Geography': 1.833,
    'Australasian Marketing Journal': 1.811,
    'Economic Theory': 1.785,
    'Entrepreneurship and Regional Development': 1.774,
    'Journal of International Management': 1.769,
    'Review of Economics of the Household': 1.762,
    'International Journal of Consumer Studies': 1.753,
    'Oxford Bulletin of Economics and Statistics': 1.743,
    'Financial Management': 1.735,
    'Accounting, Auditing and Accountability Journal': 1.727,
    'Journal of Banking and Finance': 1.716,
    'Foundations and Trends in Econometrics': 1.7,
    'Work, Aging and Retirement': 1.699,
    'Economy and Society': 1.69,
    'Marketing Letters': 1.672,
    'Auditing': 1.655,
    'Internet Research': 1.65,
    'European Review of Agricultural Economics': 1.645,
    'Journal of Financial Econometrics': 1.645,
    'Econometric Theory': 1.638,
    'Information Economics and Policy': 1.637,
    'Real Estate Economics': 1.634,
    'Journal of Financial Stability': 1.629,
    'Journal of Law and Economics': 1.612,
    'China Economic Review': 1.603,
    'Journal of Economic Psychology': 1.601,
    'Environmental and Resource Economics': 1.592,
    'Games and Economic Behavior': 1.558,
    'Electronic Markets': 1.545,
    'American Journal of Health Economics': 1.541,
    'Feminist Economics': 1.534,
    'Journal of Financial Markets': 1.518,
    'Econometrics Journal': 1.506,
    'Journal of Money, Credit and Banking': 1.501,
    'Economic History Review': 1.496,
    'China Finance Review International': 1.494,
    'Critical Perspectives on Accounting': 1.483,
    'Labour Economics': 1.479,
    'International Journal of Electronic Commerce': 1.468,
    'Journal of Agricultural Economics': 1.438,
    'Quantitative Marketing and Economics': 1.437,
    'Industrial and Corporate Change': 1.436,
    'International Environmental Agreements: Politics, Law and Economics': 1.418,
    'Asia Pacific Journal of Management': 1.41,
    'World Bank Economic Review': 1.405,
    'Journal of Economics and Management Strategy': 1.4,
    'International Journal of Industrial Organization': 1.388,
    'Cliometrica': 1.383,
    'Agricultural Economics (United Kingdom)': 1.375,
    'Finance and Stochastics': 1.349,
    'Journal of Law, Economics, and Organization': 1.345,
    'Review of Black Political Economy': 1.345,
    'Canadian Journal of Agricultural Economics': 1.333,
    'European Review of Economic History': 1.331,
    'Structural Change and Economic Dynamics': 1.321,
    'Energy Journal': 1.309,
    'International Marketing Review': 1.309,
    'Journal of International Money and Finance': 1.308,
    'Economic Modelling': 1.303,
    'Consumption Markets and Culture': 1.281,
    'Journal of Empirical Finance': 1.281,
    'Research in International Business and Finance': 1.273,
    'Economics of Education Review': 1.266,
    'Financial Analysts Journal': 1.259,
    'Journal of Industrial Economics': 1.259,
    'Nonlinear Analysis: Real World Applications': 1.256,
    'Regional Science and Urban Economics': 1.247,
    'Journal of Behavioral and Experimental Finance': 1.242,
    'ASTIN Bulletin': 1.237,
    'Venture Capital': 1.234,
    'Economica': 1.23,
    'Cambridge Journal of Regions, Economy and Society': 1.216,
    'Journal of the Association for Consumer Research': 1.2,
    'Journal of Comparative Economics': 1.199,
    'Telecommunications Policy': 1.192,
    'Tijdschrift Voor Economische en Sociale Geografie': 1.192,
    'Journal of Economic Behavior and Organization': 1.184,
    'Economic Development and Cultural Change': 1.177,
    'Financial Innovation': 1.171,
    'Journal of Business Finance and Accounting': 1.17,
    'Science Technology and Human Values': 1.17,
    'Management Accounting Research': 1.167,
    'International Journal of Accounting Information Systems': 1.159,
    'Economics of Transportation': 1.158,
    'Cambridge Journal of Economics': 1.156,
    'Foundations and Trends in Accounting': 1.134,
    'Journal of Economic Dynamics and Control': 1.134,
    'Quarterly Journal of Finance': 1.132,
    'Socio-Economic Planning Sciences': 1.131,
    'Eurasian Geography and Economics': 1.125,
    'Forest Policy and Economics': 1.124,
    'Conflict Management and Peace Science': 1.112,
    'Pacific Basin Finance Journal': 1.112,
    'Journal of Economic Inequality': 1.109,
    'Journal of the American Taxation Association': 1.108,
    'Emerging Markets Review': 1.104,
    'Resources and Energy Economics': 1.101,
    'National Tax Journal': 1.1,
    'Economic Analysis and Policy': 1.098,
    'European Research on Management and Business Economics': 1.095,
    'Journal of Common Market Studies': 1.095,
    'Palgrave Communications': 1.086,
    'International Journal of Central Banking': 1.082,
    'European Accounting Review': 1.072,
    'Journal of Benefit-Cost Analysis': 1.072,
    'Econometric Reviews': 1.052,
    'Judgment and Decision Making': 1.046,
    'European Journal of Health Economics': 1.042,
    'Economics and Human Biology': 1.041,
    'International Journal of Wellbeing': 1.034,
    'Marine Policy': 1.034,
    'Journal of International Financial Markets, Institutions and Money': 1.031,
    'Research in Transportation Economics': 1.03,
    'European Journal of Political Economy': 1.029,
    'Journal of Industrial and Business Economics': 1.029,
    'Financial Review': 1.027,
    'Journal of International Economic Law': 1.019,
    'Applied Health Economics and Health Policy': 1.017,
    'Econometrics and Statistics': 1.01,
    'Review of Industrial Organization': 1.009,
    'Journal of Policy Modeling': 1.007,
    'Nature Reviews Materials': 21.927,
    'Nature Energy': 19.588,
    'Energy and Environmental Science': 12.306,
    'Joule': 12.282,
    'Advanced Energy Materials': 9.044,
    'Electrochemical Energy Reviews': 8.905,
    'ACS Energy Letters': 7.855,
    'Nature Sustainability': 6.568,
    'EnergyChem': 6.26,
    'Progress in Energy and Combustion Science': 5.679,
    'Advances in Applied Energy': 5.458,
    'Energy Storage Materials': 5.179,
    'Nano Energy': 5.03,
    'Carbon Energy': 4.571,
    'Energy and Environmental Materials': 4.338,
    'IEEE Transactions on Sustainable Energy': 3.746,
    'IEEE Transactions on Power Systems': 3.726,
    'Biofuel Research Journal': 3.313,
    'Renewable and Sustainable Energy Reviews': 3.232,
    'Journal of Materials Chemistry A': 3.156,
    'Energy Economics': 3.039,
    'Applied Energy': 2.907,
    'Materials Today Physics': 2.767,
    'Journal of Energy Chemistry': 2.754,
    'IEEE Journal of Emerging and Selected Topics in Power Electronics': 2.641,
    'Protection and Control of Modern Power Systems': 2.618,
    'Cell Reports Physical Science': 2.598,
    'Shiyou Kantan Yu Kaifa/Petroleum Exploration and Development': 2.565,
    'eTransportation': 2.563,
    'China Petroleum Exploration': 2.523,
    'Energy Conversion and Management': 2.514,
    'Polymer Reviews': 2.493,
    'IEEE Transactions on Transportation Electrification': 2.487,
    'Bioresource Technology': 2.473,
    'Environmental Innovation and Societal Transitions': 2.418,
    'Sustainable Cities and Society': 2.305,
    'Annual Review of Chemical and Biomolecular Engineering': 2.294,
    'Energy Policy': 2.292,
    'Solar RRL': 2.24,
    'Energy Research and Social Science': 2.171,
    'Progress in Photovoltaics: Research and Applications': 2.132,
    'Environmental Research Letters': 2.119,
    'Energy Strategy Reviews': 2.068,
    'ChemSusChem': 2.064,
    'Materials Today Energy': 2.046,
    'IEEE Transactions on Energy Conversion': 2.038,
    'Sustainable Development': 2.035,
    'Sustainable Production and Consumption': 2.029,
    'International Journal of Mining Science and Technology': 1.991,
    'Energy': 1.989,
    'Journal of Cleaner Production': 1.981,
    'Global Transitions': 1.953,
    'IEEE Transactions on Power Delivery': 1.92,
    'Journal of Modern Power Systems and Clean Energy': 1.912,
    'Green Energy and Environment': 1.909,
    'Energy and AI': 1.891,
    'Journal of Power Sources': 1.854,
    'Renewable Energy': 1.815,
    'JPhys Energy': 1.806,
    'Energy and Built Environment': 1.782,
    'Engineering': 1.75,
    'ACS Sustainable Chemistry and Engineering': 1.744,
    'Smart Energy': 1.741,
    'Chinese Journal of Electrical Engineering': 1.713,
    'Global Environmental Politics': 1.68,
    'IEEE Transactions on Green Communications and Networking': 1.679,
    'International Journal of Coal Geology': 1.644,
    'Geophysics': 1.638,
    'AAPG Bulletin': 1.623,
    'Sustainable Materials and Technologies': 1.605,
    'Advances in Geo-Energy Research': 1.599,
    'ACS Applied Energy Materials': 1.588,
    'Wind Energy Science': 1.587,
    'Applied Thermal Engineering': 1.559,
    'IEEE Power and Energy Magazine': 1.558,
    'International Journal of Electrical Power and Energy Systems': 1.533,
    'Shiyou Xuebao/Acta Petrolei Sinica': 1.527,
    'Solar Energy Materials and Solar Cells': 1.503,
    'Batteries and Supercaps': 1.502,
    'Wiley Interdisciplinary Reviews: Energy and Environment': 1.491,
    'Tianranqi Gongye/Natural Gas Industry': 1.465,
    'Materials Science for Energy Technologies': 1.463,
    'Journal of Energy Storage': 1.456,
    'Petroleum Exploration and Development': 1.435,
    'IEEE Open Access Journal of Power and Energy': 1.434,
    'Advanced Sustainable Systems': 1.393,
    'Fuel Processing Technology': 1.393,
    'Engineered Science': 1.386,
    'Fuel': 1.38,
    'Sustainable Energy Technologies and Assessments': 1.375,
    'Solar Energy': 1.373,
    'Energy Conversion and Management: X': 1.365,
    'IEEE Transactions on Sustainable Computing': 1.346,
    'International Journal of Hydrogen Energy': 1.318,
    'Energy Journal': 1.309,
    'Matter and Radiation at Extremes': 1.273,
    'Sustainable Energy and Fuels': 1.257,
    'IEEE Electrification Magazine': 1.233,
    'Sustainable Energy, Grids and Networks': 1.225,
    'Journal of Wind Engineering and Industrial Aerodynamics': 1.207,
    'Energy for Sustainable Development': 1.199,
    'Horticultural Plant Journal': 1.199,
    'Nuclear Materials and Energy': 1.184,
    'International Journal of Coal Science and Technology': 1.175,
    'Building Simulation': 1.171,
    'International Journal of Sustainable Transportation': 1.146,
    'Journal of Analytical and Applied Pyrolysis': 1.145,
    'SPE Journal': 1.144,
    'Journal of the Energy Institute': 1.138,
    'GCB Bioenergy': 1.121,
    'CSEE Journal of Power and Energy Systems': 1.118,
    'Journal of Natural Gas Science and Engineering': 1.116,
    'Journal of Petroleum Science and Engineering': 1.111,
    'Current Opinion in Chemical Engineering': 1.108,
    'Electric Power Systems Research': 1.099,
    'Materials Today Sustainability': 1.079,
    'Wind Energy': 1.076,
    'Renewable Energy Focus': 1.06,
    'High Voltage': 1.058,
    'Carbon Resources Conversion': 1.051,
    'Food and Energy Security': 1.049,
    'Biomass and Bioenergy': 1.045,
    'Journal of Physical Chemistry C': 1.028,
    'Biotechnology for Biofuels': 1.022,
    'Fuel Cells Bulletin': 1.014,
    'High Power Laser Science and Engineering': 1.014,
    'Petroleum Science': 1.005,
    'Journal of Nuclear Materials': 1.002,
    'Nature Biotechnology': 22.781,
    'IEEE Communications Surveys and Tutorials': 14.248,
    'Nature Materials': 13.874,
    'Nature Nanotechnology': 13.141,
    'Nature Electronics': 10.927,
    'Advanced Materials': 9.538,
    'IEEE Journal on Selected Areas in Communications': 7.656,
    'Materials Science and Engineering: R: Reports': 6.842,
    'Nature Biomedical Engineering': 6.625,
    'IEEE Wireless Communications': 6.556,
    'Science Robotics': 6.479,
    'Materials Today': 6.194,
    'IEEE Transactions on Wireless Communications': 5.662,
    'Proceedings of the IEEE': 5.564,
    'IEEE Transactions on Cybernetics': 5.365,
    'Cement and Concrete Research': 5.294,
    'IEEE Communications Magazine': 5.285,
    'Nano-Micro Letters': 5.276,
    'Nano Energy': 5.03,
    'Foundations and Trends in Systems and Control': 4.816,
    'Advanced Photonics': 4.787,
    'ACS Nano': 4.728,
    'IEEE Vehicular Technology Magazine': 4.469,
    'PhotoniX': 4.445,
    'PRX Quantum': 4.415,
    'IEEE Transactions on Automatic Control': 4.334,
    'Advanced Science': 4.086,
    'IEEE Transactions on Industrial Informatics': 4.002,
    'IEEE Signal Processing Magazine': 3.939,
    'Annual Review of Control, Robotics, and Autonomous Systems': 3.891,
    'IEEE Transactions on Communications': 3.889,
    'Materials Horizons': 3.88,
    'Nano Today': 3.868,
    'IEEE Transactions on Power Systems': 3.726,
    'IEEE Transactions on Systems, Man, and Cybernetics: Systems': 3.688,
    'Journal of Operations Management': 3.679,
    'Automatica': 3.657,
    'Research Policy': 3.634,
    'International Journal of Machine Tools and Manufacture': 3.579,
    'Nano Letters': 3.54,
    'Digest of Technical Papers - IEEE International Solid-State Circuits Conference': 3.537,
    'IEEE Transactions on Fuzzy Systems': 3.533,
    'ACS Materials Letters': 3.421,
    'AVS Quantum Science': 3.413,
    'Small': 3.395,
    'Annual Reviews in Control': 3.343,
    'IEEE Transactions on Power Electronics': 3.341,
    'Cement and Concrete Composites': 3.339,
    'Water Research': 3.338,
    'ISPRS Journal of Photogrammetry and Remote Sensing': 3.308,
    'Engineered Regeneration': 3.25,
    'Journal of Product Innovation Management': 3.222,
    'IEEE/CAA Journal of Automatica Sinica': 3.218,
    'International Materials Reviews': 3.206,
    'Transportation Research Part B: Methodological': 3.205,
    'npj Flexible Electronics': 3.197,
    'IEEE Transactions on Industrial Electronics': 3.194,
    'Communications in Transportation Research': 3.188,
    'Production and Operations Management': 3.12,
    'IEEE Wireless Communications Letters': 3.092,
    'IEEE Journal of Solid-State Circuits': 3.043,
    'Archive for Rational Mechanics and Analysis': 3.041,
    'International Journal of Production Economics': 3.028,
    'Transportation Research, Part E: Logistics and Transportation Review': 3.005,
    'npj Computational Materials': 2.977,
    'International Journal of Production Research': 2.976,
    'Computer-Aided Civil and Infrastructure Engineering': 2.962,
    'Journal of the ACM': 2.953,
    'Annual Review of Biomedical Engineering': 2.927,
    'Opto-Electronic Advances': 2.913,
    'Applied Energy': 2.907,
    'Transportation Research Part C: Emerging Technologies': 2.882,
    'Progress in Aerospace Sciences': 2.881,
    'Robotics and Computer-Integrated Manufacturing': 2.881,
    'Proceedings - IEEE INFOCOM': 2.877,
    'IEEE Transactions on Medical Imaging': 2.872,
    'Soft Robotics': 2.859,
    'International Journal of Plasticity': 2.84,
    'Transportation Science': 2.827,
    'Chemical Engineering Journal': 2.803,
    'IEEE Transactions on Vehicular Technology': 2.802,
    'Journal of Manufacturing Systems': 2.742,
    'Bioactive Materials': 2.738,
    'International Journal of Robotics Research': 2.729,
    'Journal of Industrial Information Integration': 2.723,
    'Biomaterials': 2.716,
    'IEEE Transactions on Robotics': 2.716,
    'IEEE Transactions on Intelligent Transportation Systems': 2.674,
    'npj 2D Materials and Applications': 2.665,
    'IEEE Transactions on Information Forensics and Security': 2.652,
    'Computers in Industry': 2.646,
    'IEEE Journal of Emerging and Selected Topics in Power Electronics': 2.641,
    'Additive Manufacturing': 2.633,
    'IEEE Journal on Selected Topics in Signal Processing': 2.631,
    'Protection and Control of Modern Power Systems': 2.618,
    'Cell Reports Physical Science': 2.598,
    'eTransportation': 2.563,
    'IEEE Reviews in Biomedical Engineering': 2.556,
    'IEEE Transactions on Mobile Computing': 2.541,
    'IEEE Geoscience and Remote Sensing Magazine': 2.539,
    'Progress in Quantum Electronics': 2.513,
    'IEEE Transactions on Signal Processing': 2.503,
    'Polymer Reviews': 2.493,
    'IEEE Transactions on Transportation Electrification': 2.487,
    'Nano Research': 2.486,
    'International Journal of Information Management Data Insights': 2.479,
    'Mechanical Systems and Signal Processing': 2.475,
    'Automation in Construction': 2.443,
    'Journal of Magnesium and Alloys': 2.441,
    'Technovation': 2.41,
    'IEEE Transactions on Geoscience and Remote Sensing': 2.4,
    'IEEE Transactions on Control of Network Systems': 2.376,
    'European Journal of Operational Research': 2.371,
    'Quantum Science and Technology': 2.341,
    'IEEE Transactions on Antennas and Propagation': 2.317,
    'IEEE Communications Standards Magazine': 2.306,
    'Sustainable Cities and Society': 2.305,
    'International Journal of Extreme Manufacturing': 2.304,
    'Composites Part B: Engineering': 2.3,
    'Applied Mechanics Reviews': 2.293,
    'Information Sciences': 2.285,
    'Journal of Machine Learning Research': 2.281,
    'Wiley Interdisciplinary Reviews: Water': 2.245,
    'Solar RRL': 2.24,
    'Computer Methods in Applied Mechanics and Engineering': 2.218,
    'Biosensors and Bioelectronics': 2.194,
    'Global Food Security': 2.173,
    'Journal of Intelligent Manufacturing': 2.16,
    'Progress in Photovoltaics: Research and Applications': 2.132,
    'Journal of Materials Science and Technology': 2.13,
    'Tunnelling and Underground Space Technology': 2.125,
    'IEEE Open Journal of Vehicular Technology': 2.116,
    'Advanced healthcare materials': 2.114,
    'ACS Photonics': 2.113,
    'Information Processing and Management': 2.106,
    'Journal of Manufacturing Technology Management': 2.083,
    'International Journal of Engineering Science': 2.082,
    'IEEE/ASME Transactions on Mechatronics': 2.069,
    'Current Opinion in Biotechnology': 2.055,
    'Materials Today Advances': 2.04,
    'IEEE Industrial Electronics Magazine': 2.039,
    'IEEE Transactions on Energy Conversion': 2.038,
    'Sustainable Production and Consumption': 2.029,
    'Transportation Research, Part A: Policy and Practice': 2.029,
    'IEEE/ACM Transactions on Networking': 2.025,
    'Transportation Research, Part D: Transport and Environment': 2.024,
    'IEEE Antennas and Wireless Propagation Letters': 2.02,
    'Journal of Field Robotics': 2.019,
    'Nanophotonics': 2.013,
    'Energy': 1.989,
    'IEEE Transactions on Control Systems Technology': 1.985,
    'Journal of Cleaner Production': 1.981,
    'IEEE Transactions on Automation Science and Engineering': 1.968,
    'npj Regenerative Medicine': 1.967,
    'Accident Analysis and Prevention': 1.956,
    'Advanced Materials Technologies': 1.924,
    'IEEE Transactions on Power Delivery': 1.92,
    'Nano Materials Science': 1.917,
    'Communications Materials': 1.911,
    'IEEE Transactions on Microwave Theory and Techniques': 1.892,
    'Energy and AI': 1.891,
    'Nano Convergence': 1.891,
    'Construction and Building Materials': 1.888,
    'Learning, Media and Technology': 1.879,
    'Telematics and Informatics': 1.878,
    'Structural Health Monitoring': 1.877,
    'Expert Systems with Applications': 1.873,
    'Virtual and Physical Prototyping': 1.871,
    'Geotextiles and Geomembranes': 1.864,
    'Osteoarthritis and Cartilage': 1.863,
    'Critical Reviews in Food Science and Nutrition': 1.862,
    'Journal of Power Sources': 1.854,
    'Rock Mechanics and Rock Engineering': 1.849,
    'Vehicular Communications': 1.846,
    'IEEE Transactions on Dependable and Secure Computing': 1.828,
    'Biomaterials Research': 1.796,
    'IEEE Communications Letters': 1.795,
    'Energy and Built Environment': 1.782,
    'IEEE Transactions on Industry Applications': 1.777,
    'IEEE Transactions on Broadcasting': 1.775,
    'Proceedings of the ACM on Programming Languages': 1.77,
    'Composites Science and Technology': 1.766,
    'Computers and Industrial Engineering': 1.76,
    'Reliability Engineering and System Safety': 1.76,
    'Scripta Materialia': 1.756,
    'Structural Control and Health Monitoring': 1.755,
    'Engineering': 1.75,
    'Materials and Design': 1.741,
    'Smart Energy': 1.741,
    'Engineering Applications of Artificial Intelligence': 1.729,
    'Production Planning and Control': 1.719,
    'Earthquake Engineering and Structural Dynamics': 1.714,
    'Chinese Journal of Electrical Engineering': 1.713,
    'Journal of Technology Transfer': 1.704,
    'IEEE Robotics and Automation Letters': 1.693,
    'IEEE Transactions on Network and Service Management': 1.693,
    'Journal of the Mechanics and Physics of Solids': 1.691,
    'IEEE Control Systems Letters': 1.687,
    'IEEE Open Journal of Power Electronics': 1.674,
    'IEEE Journal of Biomedical and Health Informatics': 1.672,
    'Materials Science &amp; Engineering A: Structural Materials: Properties, Microstructure and Processing': 1.667,
    'APL Materials': 1.662,
    'CIRP Annals - Manufacturing Technology': 1.661,
    'Composites - Part A: Applied Science and Manufacturing': 1.661,
    'Coastal Engineering': 1.648,
    'IEEE Transactions on Network Science and Engineering': 1.647,
    'Journal of Management in Engineering - ASCE': 1.645,
    'International Journal of Thermofluids': 1.643,
    'Acta Biomaterialia': 1.638,
    'IEEE Transactions on Multimedia': 1.638,
    '2D Materials': 1.631,
    'Transportation Research Interdisciplinary Perspectives': 1.629,
    'Critical Reviews in Solid State and Materials Sciences': 1.627,
    'Nonlinear Analysis: Hybrid Systems': 1.617,
    'IEEE Transactions on Biomedical Circuits and Systems': 1.616,
    'Water Cycle': 1.613,
    'Smart Materials in Medicine': 1.612,
    'Energy and Buildings': 1.608,
    'Canadian Geotechnical Journal': 1.607,
    'Engineering Structures': 1.607,
    'Sustainable Materials and Technologies': 1.605,
    'Advances in Geo-Energy Research': 1.599,
    'ACS Applied Energy Materials': 1.588,
    'ISA Transactions': 1.588,
    'Wiley Interdisciplinary Reviews: Nanomedicine and Nanobiotechnology': 1.588,
    'Journal of Communications and Information Networks': 1.586,
    'Building and Environment': 1.584,
    'Synthesis Lectures on Image, Video, and Multimedia Processing': 1.584,
    'IEEE Transactions on Intelligent Vehicles': 1.583,
    'Advanced Quantum Technologies': 1.577,
    'IEEE Radio Frequency Integrated Circuits Symposium, RFIC, Digest of Technical Papers': 1.576,
    'IEEE Journal on Emerging and Selected Topics in Circuits and Systems': 1.562,
    'Applied Thermal Engineering': 1.559,
    'IEEE Power and Energy Magazine': 1.558,
    'IEEE Open Journal of the Industrial Electronics Society': 1.557,
    'Developments in the Built Environment': 1.556,
    'IEEE Transactions on Circuits and Systems I: Regular Papers': 1.542,
    'Biofabrication': 1.534,
    'International Journal of Electrical Power and Energy Systems': 1.533,
    'International Journal of Mechanical Sciences': 1.532,
    'IEEE Symposium on VLSI Circuits, Digest of Technical Papers': 1.529,
    'Advanced Industrial and Engineering Polymer Research': 1.528,
    'European Physical Journal C': 1.526,
    'Systems and Control Letters': 1.519,
    'Aerospace Science and Technology': 1.517,
    'Mechanism and Machine Theory': 1.517,
    'Microsystems and Nanoengineering': 1.515,
    'International Journal of Fatigue': 1.504,
    'Batteries and Supercaps': 1.502,
    'International Journal of Impact Engineering': 1.502,
    'IEEE Transactions on Circuits and Systems for Video Technology': 1.491,
    'Journal of Materials Processing Technology': 1.485,
    'Bioengineering and Translational Medicine': 1.484,
    'Sensors and Actuators B: Chemical': 1.482,
    'Unmanned Systems': 1.476,
    'Transportation': 1.475,
    'Internet of Things (Netherlands)': 1.474,
    'Desalination': 1.471,
    'Structural Safety': 1.47,
    'IEEE Transactions on Consumer Electronics': 1.463,
    'Data Science and Engineering': 1.458,
    'Journal of Energy Storage': 1.456,
    'Composite Structures': 1.455,
    'Journal of Composites for Construction': 1.45,
    'IEEE Systems Journal': 1.448,
    'Materials Today Bio': 1.444,
    'IEEE Open Access Journal of Power and Energy': 1.434,
    'Thin-Walled Structures': 1.434,
    'International Journal for Numerical and Analytical Methods in Geomechanics': 1.429,
    'Safety Science': 1.429,
    'Journal of Nanobiotechnology': 1.421,
    'Tissue Engineering - Part B: Reviews': 1.414,
    'Journal of Fluid Mechanics': 1.409,
    'ACS Synthetic Biology': 1.406,
    'International Journal of Robust and Nonlinear Control': 1.403,
    'IEEE Transactions on Instrumentation and Measurement': 1.397,
    'International Journal of Industrial Organization': 1.388,
    'Composites Communications': 1.387,
    'Control Engineering Practice': 1.386,
    'Engineered Science': 1.386,
    'Journal of Management Science and Engineering': 1.384,
    'Friction': 1.376,
    'IEEE Journal of Oceanic Engineering': 1.376,
    'Journal of Sound and Vibration': 1.376,
    'International Journal of Heat and Mass Transfer': 1.369,
    'Geomechanics for Energy and the Environment': 1.362,
    'Journal of Structural Engineering': 1.36,
    'Journal of Risk Research': 1.354,
    'Transportation Research Part F: Traffic Psychology and Behaviour': 1.352,
    'Journal of Nonlinear Science': 1.35,
    'Journal of Computing in Civil Engineering': 1.349,
    'Journal of Manufacturing Processes': 1.349,
    'IEEE/ACM Transactions on Audio Speech and Language Processing': 1.348,
    'Tribology International': 1.348,
    'Food Packaging and Shelf Life': 1.338,
    'IEEE Transactions on Aerospace and Electronic Systems': 1.338,
    'Process Safety and Environmental Protection': 1.338,
    'Cleaner Logistics and Supply Chain': 1.329,
    'China Communications': 1.328,
    'Ocean Engineering': 1.326,
    'Computational Mechanics': 1.321,
    'Geosynthetics International': 1.319,
    'Journal of Constructional Steel Research': 1.319,
    'IEEE Electron Device Letters': 1.316,
    'Advanced Materials Interfaces': 1.315,
    'Lab on a Chip': 1.314,
    'Mobile Media and Communication': 1.303,
    'IEEE Transactions on Reliability': 1.296,
    'IEEE Circuits and Systems Magazine': 1.293,
    'Vehicle System Dynamics': 1.287,
    'Nonlinear Dynamics': 1.285,
    'IEEE Geoscience and Remote Sensing Letters': 1.284,
    'Dental Materials': 1.283,
    'Engineering Fracture Mechanics': 1.281,
    'Ecosystems and People': 1.28,
    'Journal of Science Education and Technology': 1.28,
    'Journal of Information Security and Applications': 1.279,
    'Matter and Radiation at Extremes': 1.273,
    'Journal of Bridge Engineering': 1.271,
    'APL Bioengineering': 1.269,
    'Innovative Food Science and Emerging Technologies': 1.269,
    'International Journal of Human Computer Studies': 1.268,
    'IEEE Transactions on Circuits and Systems II: Express Briefs': 1.266,
    'IEEE Transactions on Neural Systems and Rehabilitation Engineering': 1.26,
    'Computers and Structures': 1.259,
    'Case Studies in Chemical and Environmental Engineering': 1.256,
    'Nonlinear Analysis: Real World Applications': 1.256,
    'Soil Dynamics and Earthquake Engineering': 1.251,
    'Robotics and Autonomous Systems': 1.245,
    'Journal of Industrial Integration and Management': 1.244,
    'NDT and E International': 1.24,
    'Journal of Guidance, Control, and Dynamics': 1.239,
    'Journal of Traffic and Transportation Engineering (English Edition)': 1.236,
    'Extreme Mechanics Letters': 1.235,
    'Bulletin of Earthquake Engineering': 1.233,
    'IEEE Electrification Magazine': 1.233,
    'Journal of Building Engineering': 1.232,
    'Design Studies': 1.231,
    'Signal Processing': 1.231,
    'Transportation Geotechnics': 1.229,
    'Marine Structures': 1.228,
    'Soils and Foundations': 1.227,
    'Chinese Journal of Aeronautics': 1.225,
    'Sustainable Energy, Grids and Networks': 1.225,
    'IEEE Microwave and Wireless Components Letters': 1.222,
    'Geoenvironmental Disasters': 1.221,
    'IEEE Journal of Selected Topics in Quantum Electronics': 1.221,
    'Industrial Management and Data Systems': 1.219,
    'Road Materials and Pavement Design': 1.218,
    'Indoor Air': 1.211,
    'Journal of Materials Chemistry B': 1.211,
    'Railway Engineering Science': 1.209,
    'Georisk': 1.207,
    'Journal of Wind Engineering and Industrial Aerodynamics': 1.207,
    'Bioconjugate Chemistry': 1.204,
    'Artificial Intelligence in Agriculture': 1.202,
    'Applied Ocean Research': 1.198,
    'Journal of the Royal Society Interface': 1.196,
    'Telecommunications Policy': 1.192,
    'Superconductor Science and Technology': 1.191,
    'Underground Space (China)': 1.189,
    'Proceedings - IEEE International Conference on Robotics and Automation': 1.186,
    'International Journal of Social Robotics': 1.177,
    'Indonesian Journal of Science and Technology': 1.176,
    'EJNMMI Physics': 1.174,
    'IEEE Robotics and Automation Magazine': 1.172,
    'Biomaterials Science': 1.171,
    'Building Simulation': 1.171,
    'Journal of Scientific Computing': 1.171,
    'Science Technology and Human Values': 1.17,
    'IEEE Transactions on Biomedical Engineering': 1.167,
    'Journal of the Franklin Institute': 1.159,
    'International Journal of Refrigeration': 1.155,
    'Journal of Construction Engineering and Management - ASCE': 1.152,
    'Sustainable and Resilient Infrastructure': 1.15,
    'Journal of Engineering Education': 1.149,
    'International Journal of Sustainable Transportation': 1.146,
    'Journal of Water Process Engineering': 1.144,
    'Theoretical and Applied Fracture Mechanics': 1.142,
    'Interface Focus': 1.141,
    'IEEE Transactions on Learning Technologies': 1.14,
    'Complex and Intelligent Systems': 1.138,
    'Journal of the Energy Institute': 1.138,
    'Journal of Neural Engineering': 1.135,
    'Proceedings of the Custom Integrated Circuits Conference': 1.133,
    'IEEE Transactions on Semiconductor Manufacturing': 1.129,
    'IISE Transactions': 1.129,
    'IEEE Signal Processing Letters': 1.123,
    'Frontiers in Marine Science': 1.122,
    'International Journal of Logistics Research and Applications': 1.12,
    'Transportmetrica A: Transport Science': 1.12,
    'CSEE Journal of Power and Energy Systems': 1.118,
    'IEEE Open Journal of Antennas and Propagation': 1.118,
    'Structural and Multidisciplinary Optimization': 1.116,
    'Food Engineering Reviews': 1.11,
    'Journal of Safety Research': 1.109,
    'Materials Characterization': 1.109,
    'Technology, Knowledge and Learning': 1.108,
    'Astrodynamics': 1.106,
    'Measurement: Journal of the International Measurement Confederation': 1.106,
    'International Journal of Systems Science': 1.105,
    'EPJ Quantum Technology': 1.101,
    'Journal of Intelligent Transportation Systems: Technology, Planning, and Operations': 1.101,
    'Electric Power Systems Research': 1.099,
    'IEEE Control Systems': 1.097,
    'Engineering with Computers': 1.096,
    'IEEE Transactions on Quantum Engineering': 1.088,
    'Materials Science and Engineering C': 1.086,
    'Progress in Biomedical Engineering': 1.084,
    'Journal of Intelligent and Connected Vehicles': 1.083,
    'Physics of Fluids': 1.083,
    'European Journal of Mechanics, A/Solids': 1.082,
    'Journal of Alloys and Compounds': 1.079,
    'Biocybernetics and Biomedical Engineering': 1.073,
    'European Accounting Review': 1.072,
    'Biomedical Signal Processing and Control': 1.071,
    'Health Policy and Technology': 1.071,
    'Journal of diabetes science and technology': 1.067,
    'CIRP Journal of Manufacturing Science and Technology': 1.065,
    'Nanoscale Advances': 1.063,
    'Progress in Electromagnetics Research': 1.062,
    'Biosystems Engineering': 1.061,
    'Materials and Manufacturing Processes': 1.059,
    'High Voltage': 1.058,
    'International Journal of Computer Integrated Manufacturing': 1.056,
    'JPhys Photonics': 1.056,
    'International Journal of Pavement Engineering': 1.053,
    'Structure and Infrastructure Engineering': 1.053,
    'Journal of Fluids and Structures': 1.052,
    'Coastal Engineering Journal': 1.051,
    'IEEE Transactions on Human-Machine Systems': 1.051,
    'IEEE Journal of Photovoltaics': 1.05,
    'Journal of Transport and Health': 1.05,
    'Educational Technology and Society': 1.049,
    'Journal of Flood Risk Management': 1.049,
    'Optics and Lasers in Engineering': 1.047,
    'International Journal of Solids and Structures': 1.046,
    'Journal of Manufacturing Science and Engineering': 1.046,
    'International Journal of Thermal Sciences': 1.044,
    'International Journal for Numerical Methods in Engineering': 1.043,
    'Wear': 1.043,
    'Frontiers of Optoelectronics': 1.04,
    'IEEE Intelligent Transportation Systems Magazine': 1.038,
    'Engineering Science and Technology, an International Journal': 1.036,
    'Finite Fields and Their Applications': 1.034,
    'IEEE Open Journal of Engineering in Medicine and Biology': 1.032,
    'European Transport Research Review': 1.029,
    'International Journal of Damage Mechanics': 1.027,
    'Environment and Planning B: Urban Analytics and City Science': 1.026,
    'Journal of Materials in Civil Engineering': 1.026,
    'Materials and Structures/Materiaux et Constructions': 1.024,
    'IEEE Solid-State Circuits Letters': 1.019,
    'Minerals Engineering': 1.018,
    'Technical Digest - International Electron Devices Meeting': 1.017,
    'Acta Astronautica': 1.015,
    'International Journal of Cognitive Computing in Engineering': 1.015,
    'Journal of Sandwich Structures and Materials': 1.015,
    'Smart Cities': 1.015,
    'Polymer Chemistry': 1.014,
    'Hydrometallurgy': 1.012,
    'European Journal of Control': 1.007,
    'AIAA Journal': 1.006,
    'IEEE Transactions on Engineering Management': 1.002,
    'Journal of School Violence': 1.001,
    'MMWR Recommendations and Reports': 23.962,
    'Morbidity and Mortality Weekly Report': 16.786,
    'MMWR Surveillance Summaries': 15.178,
    'Energy and Environmental Science': 12.306,
    'Advances in Optics and Photonics': 8.332,
    'Nature Climate Change': 6.849,
    'Chem': 6.8,
    'Nature Sustainability': 6.568,
    'Nature Reviews Earth and Environment': 6.193,
    'Fungal Diversity': 5.784,
    'Annual Review of Ecology, Evolution, and Systematics': 5.409,
    'Nature Ecology and Evolution': 5.374,
    'Applied Catalysis B: Environmental': 4.887,
    'Annual Review of Environment and Resources': 4.784,
    'Energy and Environmental Materials': 4.338,
    'Global Change Biology': 4.069,
    'Current Climate Change Reports': 4.042,
    'Journal of Advances in Modeling Earth Systems': 3.605,
    'Frontiers in Ecology and the Environment': 3.51,
    'Water Research': 3.338,
    'Biofuel Research Journal': 3.313,
    'Environmental Science &amp; Technology': 3.123,
    'Review of Environmental Economics and Policy': 3.117,
    'Conservation Letters': 3.083,
    'Journal of the Association of Environmental and Resource Economists': 3.078,
    'Environment international': 3.075,
    'Wiley Interdisciplinary Reviews: Climate Change': 2.972,
    'Global Environmental Change': 2.945,
    'Environmental Science and Technology Letters': 2.909,
    'Applied Energy': 2.907,
    'Critical Reviews in Environmental Science and Technology': 2.899,
    'Business Strategy and the Environment': 2.87,
    'npj Climate and Atmospheric Science': 2.87,
    'Methods in Ecology and Evolution': 2.805,
    'Chemical Engineering Journal': 2.803,
    'Resources, Conservation and Recycling': 2.682,
    'One Earth': 2.617,
    'Journal of Hazardous Materials': 2.57,
    'Journal of Environmental Economics and Management': 2.522,
    "Earth's Future": 2.51,
    'Bioresource Technology': 2.473,
    'Communications Earth and Environment': 2.457,
    'Global Ecology and Biogeography': 2.447,
    'Environmental Innovation and Societal Transitions': 2.418,
    'Climate Policy': 2.412,
    'Reviews in Environmental Science and Biotechnology': 2.41,
    'International Journal of Project Management': 2.333,
    'Resources, Environment and Sustainability': 2.306,
    'Environmental Chemistry Letters': 2.295,
    'Energy Policy': 2.292,
    'Environmental Health Perspectives': 2.251,
    'Environmental Science and Ecotechnology': 2.247,
    'Wiley Interdisciplinary Reviews: Water': 2.245,
    'Oxford Review of Economic Policy': 2.241,
    'Cryosphere': 2.222,
    'TrAC - Trends in Analytical Chemistry': 2.218,
    'Global Biogeochemical Cycles': 2.19,
    'Global Food Security': 2.173,
    'Resources, Conservation and Recycling Advances': 2.168,
    'Geochemical Perspectives Letters': 2.152,
    'Biological Conservation': 2.146,
    'Current Forestry Reports': 2.143,
    'Corporate Social Responsibility and Environmental Management': 2.134,
    'Environmental Research Letters': 2.119,
    'Reviews in Aquaculture': 2.117,
    'Environmental Pollution': 2.11,
    'Current Opinion in Environmental Sustainability': 2.1,
    'Urban Studies': 2.1,
    'Conservation Biology': 2.071,
    'Journal of Ecology': 2.07,
    'Landscape and Urban Planning': 2.065,
    'ChemSusChem': 2.064,
    'Reviews in Fisheries Science and Aquaculture': 2.064,
    'Water Research X': 2.056,
    'Sustainable Production and Consumption': 2.029,
    'Climate of the Past': 2.025,
    'Transportation Research, Part D: Transport and Environment': 2.024,
    'Environment and Planning D: Society and Space': 2.015,
    'Energy': 1.989,
    'Journal of Cleaner Production': 1.981,
    'Environment and Behavior': 1.979,
    'Policy Studies Journal': 1.97,
    'Biochar': 1.966,
    'Journal of Applied Ecology': 1.964,
    'Green Chemistry': 1.959,
    'Ecosystem Services': 1.956,
    'Science of the Total Environment': 1.946,
    'Weather and Climate Extremes': 1.941,
    'Ecological Economics': 1.899,
    'Geography and Sustainability': 1.898,
    'Proceedings of the Royal Society B: Biological Sciences': 1.898,
    'Food Policy': 1.896,
    'npj Clean Water': 1.888,
    'Economic Policy': 1.886,
    'Life Science Alliance': 1.884,
    'Organization and Environment': 1.877,
    'PLoS Computational Biology': 1.872,
    'Resources Policy': 1.869,
    'Fish and Fisheries': 1.868,
    'Journal of Geotechnical and Geoenvironmental Engineering - ASCE': 1.861,
    'Sustainability: Science, Practice, and Policy': 1.856,
    'Journal of Transport Geography': 1.852,
    'Environmental Politics': 1.851,
    'Journal of Industrial Ecology': 1.843,
    'Environmental DNA': 1.809,
    'Particle and Fibre Toxicology': 1.805,
    'Current Environmental Health Reports': 1.791,
    'Trends in Environmental Analytical Chemistry': 1.78,
    'Hydrology and Earth System Sciences': 1.777,
    'Ambio': 1.769,
    'Regional Studies': 1.757,
    'Engineering': 1.75,
    'Waste Management': 1.745,
    'ACS Sustainable Chemistry and Engineering': 1.744,
    'Smart Energy': 1.741,
    'Chemosphere': 1.727,
    'Ecological Applications': 1.726,
    'Water Resources Research': 1.715,
    'Quaternary Science Reviews': 1.701,
    'International Soil and Water Conservation Research': 1.696,
    'Journal of Environmental Informatics': 1.681,
    'Global Environmental Politics': 1.68,
    'Environmental Science and Policy': 1.678,
    'Journal of Environmental Management': 1.678,
    'Journal of Hydrology': 1.67,
    'Land Use Policy': 1.656,
    'Geography Compass': 1.65,
    'Coastal Engineering': 1.648,
    'Agriculture, Ecosystems and Environment': 1.639,
    'Information Economics and Policy': 1.637,
    'Environmental Research': 1.635,
    'Transportation Research Interdisciplinary Perspectives': 1.629,
    'International Journal of Applied Earth Observation and Geoinformation': 1.628,
    'Agricultural and Forest Meteorology': 1.623,
    'Communications Chemistry': 1.62,
    'Current Pollution Reports': 1.619,
    'Water Cycle': 1.613,
    'Environmental Science: Nano': 1.607,
    'Sustainable Materials and Technologies': 1.605,
    'Environmental and Resource Economics': 1.592,
    'Global Sustainability': 1.589,
    'Environment and Planning A': 1.588,
    'Building and Environment': 1.584,
    'Environment and Urbanization': 1.57,
    'Computers, Environment and Urban Systems': 1.567,
    'Perspectives in Ecology and Conservation': 1.54,
    'Sustainability Science': 1.535,
    'Agricultural Water Management': 1.524,
    'Earth Systems and Environment': 1.516,
    'Journal of Sustainable Cement-Based Materials': 1.51,
    'Agronomy for Sustainable Development': 1.509,
    'Current Opinion in Environmental Science and Health': 1.508,
    'Wiley Interdisciplinary Reviews: Energy and Environment': 1.491,
    'Marine Pollution Bulletin': 1.49,
    'Current Opinion in Green and Sustainable Chemistry': 1.478,
    'Emerging Contaminants': 1.478,
    'Internet Policy Review': 1.473,
    'Desalination': 1.471,
    'Landscape Ecology': 1.471,
    'Exposure and Health': 1.467,
    'Global and Planetary Change': 1.459,
    'Science China Life Sciences': 1.449,
    'Geobiology': 1.441,
    'Urban Forestry and Urban Greening': 1.434,
    'Journal of CO2 Utilization': 1.433,
    'Ecosystems': 1.427,
    'Journal of Exposure Science and Environmental Epidemiology': 1.415,
    'Habitat International': 1.403,
    'Journal of Air Transport Management': 1.402,
    'Ecological Indicators': 1.396,
    'Advanced Sustainable Systems': 1.393,
    'Journal of Environmental Sciences': 1.392,
    'Advances in Climate Change Research': 1.383,
    'Journal of Toxicology and Environmental Health - Part B: Critical Reviews': 1.383,
    'Earth System Governance': 1.376,
    'Journal of Biogeography': 1.371,
    'Remote Sensing in Ecology and Conservation': 1.366,
    'Environmental Impact Assessment Review': 1.362,
    'European Urban and Regional Studies': 1.356,
    'Environmental Modelling and Software': 1.35,
    'Journal of Hazardous Materials Letters': 1.35,
    'Ultrasonics Sonochemistry': 1.35,
    'Ecotoxicology and Environmental Safety': 1.348,
    'Atmospheric Environment': 1.347,
    'Frontiers of Environmental Science and Engineering': 1.344,
    'Process Safety and Environmental Protection': 1.338,
    'Climatic Change': 1.335,
    'Canadian Journal of Agricultural Economics': 1.333,
    'Cleaner Logistics and Supply Chain': 1.329,
    'Ocean Engineering': 1.326,
    'Climate and Development': 1.318,
    'Environmental Health: A Global Access Science Source': 1.313,
    'Journal of Pest Science': 1.31,
    'Journal of European Social Policy': 1.309,
    'Environmental Sciences: Processes and Impacts': 1.299,
    'International Journal of Tourism Research': 1.298,
    'Policy and Politics': 1.295,
    'Biogeochemistry': 1.293,
    'Journal of Geophysical Research G: Biogeosciences': 1.285,
    'Ecosystems and People': 1.28,
    'Journal of Personality Assessment': 1.271,
    'Advances in Water Resources': 1.263,
    'Case Studies in Chemical and Environmental Engineering': 1.256,
    'Policy Sciences': 1.256,
    'Environment and Planning C: Politics and Space': 1.253,
    'Mutation Research - Reviews in Mutation Research': 1.245,
    'Restoration Ecology': 1.238,
    'Environmental Sciences Europe': 1.227,
    'GeoHealth': 1.227,
    'Journal of Hydrology X': 1.227,
    'Fire Ecology': 1.224,
    'Preslia': 1.223,
    'Geoenvironmental Disasters': 1.221,
    'Animal Conservation': 1.213,
    'Issues in Ecology': 1.213,
    'NeoBiota': 1.213,
    'Indoor Air': 1.211,
    'Energy for Sustainable Development': 1.199,
    'Horticultural Plant Journal': 1.199,
    'Journal of Regional Science': 1.199,
    'Journal of Environmental Chemical Engineering': 1.198,
    'Progress in Disaster Science': 1.194,
    'Telecommunications Policy': 1.192,
    'Ecology and Society': 1.188,
    'Urban Climate': 1.185,
    'Forest Ecology and Management': 1.184,
    'Applied Soil Ecology': 1.164,
    'Archives of Toxicology': 1.162,
    'Soil Ecology Letters': 1.158,
    'Environmental Technology and Innovation': 1.157,
    'Wildlife Monographs': 1.157,
    'Bulletin of the American Museum of Natural History': 1.155,
    'International Journal of Health Policy and Management': 1.155,
    'Aquatic Toxicology': 1.152,
    'Environmental Reviews': 1.15,
    'International Journal of Sustainable Transportation': 1.146,
    'Land Degradation and Development': 1.145,
    'Journal of Water Process Engineering': 1.144,
    'Applied Geography': 1.138,
    'International Journal of Life Cycle Assessment': 1.135,
    'Ocean and Coastal Management': 1.126,
    'Forest Policy and Economics': 1.124,
    'Frontiers in Marine Science': 1.122,
    'Environmental Science: Water Research and Technology': 1.121,
    'GCB Bioenergy': 1.121,
    'Environmental Development': 1.117,
    'Aquaculture, Economics and Management': 1.109,
    'Fish and Shellfish Immunology': 1.108,
    'Carbon Balance and Management': 1.106,
    'Population and Environment': 1.102,
    'Frontiers in Ecology and Evolution': 1.095,
    'Microbiology spectrum': 1.095,
    'Regional Environmental Change': 1.095,
    'Forest Ecosystems': 1.093,
    'Environment and Planning E: Nature and Space': 1.089,
    'Journal of Hydrology: Regional Studies': 1.085,
    'Atmospheric Environment: X': 1.084,
    'Groundwater for Sustainable Development': 1.08,
    'Journal of Agrarian Change': 1.077,
    'Marine Chemistry': 1.076,
    'Microbial Ecology': 1.072,
    'Ecological Processes': 1.07,
    'Ecosphere': 1.067,
    'Tourism Recreation Research': 1.066,
    'Journal of Environmental Assessment Policy and Management': 1.064,
    'Journal of Environmental Policy and Planning': 1.064,
    'Motivation Science': 1.063,
    'Paleobiology': 1.063,
    'Journal of Transport and Health': 1.05,
    'Journal of Flood Risk Management': 1.049,
    'Biodiversity and Conservation': 1.045,
    'Biomass and Bioenergy': 1.045,
    'Global Ecology and Conservation': 1.045,
    'Phytobiomes Journal': 1.044,
    'Housing Studies': 1.043,
    'Analytica Chimica Acta': 1.042,
    'Climate Risk Management': 1.037,
    'Elementa': 1.037,
    'Marine Policy': 1.034,
    'Plant Direct': 1.034,
    'Applied and Environmental Microbiology': 1.032,
    'Environment and Planning B: Urban Analytics and City Science': 1.026,
    'Environmental Toxicology and Chemistry': 1.025,
    'FEMS Microbiology Ecology': 1.025,
    'Holocene': 1.025,
    'Ecosystem Health and Sustainability': 1.023,
    'Biotechnology for Biofuels': 1.022,
    'Frontiers in Forests and Global Change': 1.022,
    'International Biodeterioration and Biodegradation': 1.021,
    'Journal of Leisure Research': 1.021,
    'Cell Biology and Toxicology': 1.019,
    'Journal of Environment and Development': 1.017,
    'International Journal of Disaster Risk Science': 1.015,
    'Aerosol Science and Technology': 1.014,
    'Journal of Southern Hemisphere Earth Systems Science': 1.011,
    'Journal of Vegetation Science': 1.008,
    'Frontiers in Environmental Science': 1.005,
    'MMWR Recommendations and Reports': 23.962,
    'Morbidity and Mortality Weekly Report': 16.786,
    'MMWR Surveillance Summaries': 15.178,
    'Lancet Digital Health, The': 6.433,
    'British Journal of Sports Medicine': 4.764,
    'Qualitative Research in Sport, Exercise and Health': 4.045,
    'npj Digital Medicine': 3.552,
    'Sports Medicine': 3.292,
    'Medical Image Analysis': 3.195,
    'IEEE Transactions on Medical Imaging': 2.872,
    'Vital and health statistics. Series 2, Data evaluation and methods research': 2.723,
    'International Journal of Behavioral Nutrition and Physical Activity': 2.709,
    'Ultrasound in Obstetrics and Gynecology': 2.572,
    'American Journal of Sports Medicine': 2.477,
    'Radiology: Artificial Intelligence': 2.432,
    'Diabetes Technology and Therapeutics': 2.374,
    'Journal of Cardiovascular Magnetic Resonance': 2.233,
    'Bone and Joint Journal': 2.149,
    'Journal of Sport and Health Science': 2.041,
    'Arthroscopy - Journal of Arthroscopic and Related Surgery': 2.006,
    'Journal of Bone and Joint Surgery': 1.996,
    'Knee Surgery, Sports Traumatology, Arthroscopy': 1.789,
    'Medicine and Science in Sports and Exercise': 1.734,
    'International Review of Sport and Exercise Psychology': 1.711,
    'Human Brain Mapping': 1.688,
    'IEEE Journal of Biomedical and Health Informatics': 1.672,
    'Journal of Shoulder and Elbow Surgery': 1.666,
    'Exercise Immunology Review': 1.659,
    'Exercise and Sport Sciences Reviews': 1.613,
    'Journal of Physiotherapy': 1.57,
    'Journal of Physiology': 1.557,
    'Sports Medicine - Open': 1.538,
    'Sport Management Review': 1.513,
    'Journal of Learning Disabilities': 1.476,
    'Biology of Sport': 1.475,
    'Journal of Athletic Training': 1.427,
    'Computerized Medical Imaging and Graphics': 1.402,
    'Journal of Orthopaedic and Sports Physical Therapy': 1.396,
    'Current Protocols in Cytometry': 1.385,
    'Psychology of Sport and Exercise': 1.351,
    'IEEE/ACM Transactions on Audio Speech and Language Processing': 1.348,
    'International Journal of Sports Physiology and Performance': 1.333,
    'Scandinavian Journal of Medicine and Science in Sports': 1.331,
    'Sports Health': 1.328,
    'Journal of Science and Medicine in Sport': 1.324,
    'Journal of Strength and Conditioning Research': 1.3,
    'Statistical Methods in Medical Research': 1.3,
    'Diagnostic and interventional imaging': 1.296,
    'Journal of Fluency Disorders': 1.296,
    'Ear and Hearing': 1.273,
    'Archives of Pathology and Laboratory Medicine': 1.268,
    'BMJ Open Sport and Exercise Medicine': 1.249,
    'Current Protocols': 1.244,
    'Journal of Pharmaceutical Analysis': 1.242,
    'Telemedicine Journal and e-Health': 1.237,
    'Science and Medicine in Football': 1.233,
    'Cancer Imaging': 1.231,
    'Eye and Vision': 1.223,
    'Journal of Sport Management': 1.219,
    'Spine': 1.197,
    'Innovation in Aging': 1.191,
    'Reading and Writing': 1.19,
    'Clinical Orthopaedics and Related Research': 1.186,
    'International Journal of Health Policy and Management': 1.155,
    'Zeitschrift fur Medizinische Physik': 1.154,
    'Physical Education and Sport Pedagogy': 1.15,
    'Brain Topography': 1.149,
    'Journal of Sports Sciences': 1.141,
    'Sport, Education and Society': 1.129,
    'Health Equity': 1.12,
    'Health systems and reform': 1.119,
    'European Physical Education Review': 1.103,
    'European Journal of Sport Science': 1.092,
    'Archives of Physical Medicine and Rehabilitation': 1.062,
    'Journal of Digital Imaging': 1.054,
    'Journal of Orthopaedic Trauma': 1.043,
    'Journal of the International Society of Sports Nutrition': 1.041,
    'Research and Practice for Persons with Severe Disabilities': 1.039,
    'International Review for the Sociology of Sport': 1.035,
    'Journal of Sports Science and Medicine': 1.027,
    'European Journal of Physical and Rehabilitation Medicine': 1.019,
    'Physical Therapy': 1.005,
    'Nature Biotechnology': 22.781,
    'Nature Reviews Immunology': 18.141,
    'Annual Review of Immunology': 15.262,
    'Immunity': 14.795,
    'Science immunology': 11.191,
    'Nature Immunology': 10.921,
    'Nature Reviews Microbiology': 10.396,
    'Cell Host and Microbe': 10.227,
    'Lancet Microbe, The': 9.419,
    'Nature Microbiology': 9.187,
    'Clinical Microbiology Reviews': 7.584,
    'Annals of the Rheumatic Diseases': 6.489,
    'Journal of Experimental Medicine': 6.237,
    'Molecular Systems Biology': 6.22,
    'EMBO Journal': 5.484,
    'Annual Review of Microbiology': 5.436,
    'Blood': 4.927,
    'Trends in Immunology': 4.689,
    'Annual Review of Virology': 4.654,
    'Lancet Rheumatology, The': 4.586,
    'eLife': 4.251,
    'Eurosurveillance': 4.145,
    'PLoS Biology': 4.005,
    'Lancet HIV,The': 3.989,
    'Arthritis and Rheumatology': 3.788,
    'Journal of Allergy and Clinical Immunology': 3.742,
    'Immunological Reviews': 3.729,
    'Microbiology and Molecular Biology Reviews': 3.719,
    'Microbiome': 3.709,
    'ISME Journal': 3.619,
    'Cancer Immunology Research': 3.552,
    'Cellular and Molecular Immunology': 3.545,
    'Trends in Microbiology': 3.468,
    'Journal for ImmunoTherapy of Cancer': 3.403,
    'FEMS Microbiology Reviews': 3.245,
    'npj Vaccines': 3.197,
    'Brain, Behavior, and Immunity': 3.033,
    'Soil Biology and Biochemistry': 2.934,
    'Emerging Microbes and Infections': 2.815,
    'Mucosal Immunology': 2.792,
    'Allergy: European Journal of Allergy and Clinical Immunology': 2.734,
    'Current Opinion in Immunology': 2.698,
    'Seminars in Immunology': 2.652,
    'Biotechnology Advances': 2.629,
    'Cytokine and Growth Factor Reviews': 2.621,
    'Reviews in Medical Virology': 2.618,
    'Current Protocols in Microbiology': 2.602,
    'Gut Microbes': 2.6,
    'Journal of Autoimmunity': 2.594,
    'Autoimmunity Reviews': 2.552,
    'PLoS Pathogens': 2.49,
    'Seminars in Immunopathology': 2.462,
    'Journal of Neuroinflammation': 2.439,
    'Reviews in Environmental Science and Biotechnology': 2.41,
    'Immunity and Ageing': 2.387,
    'Current Protocols in Immunology': 2.34,
    'Virus Evolution': 2.337,
    'mBio': 2.283,
    'Current Opinion in Microbiology': 2.195,
    'Cell Death and Disease': 2.188,
    'Journal of Clinical Immunology': 2.151,
    'Open Biology': 2.143,
    'Virology: Research and Treatment': 2.13,
    'Current Opinion in Virology': 2.091,
    'RMD Open': 2.024,
    'Frontiers in Immunology': 2.022,
    'Journal of Clinical Virology': 2.006,
    'OncoImmunology': 2.003,
    'npj Biofilms and Microbiomes': 1.978,
    'Journal of Medical Virology': 1.957,
    'Inflammation and Regeneration': 1.938,
    'Clinical and Translational Immunology': 1.908,
    'Proceedings of the Royal Society B: Biological Sciences': 1.898,
    'EcoSal Plus': 1.828,
    'Metabolic Engineering': 1.808,
    'Journal of Virology': 1.795,
    'European Journal of Immunology': 1.766,
    'International Journal of Biological Sciences': 1.75,
    'mAbs': 1.748,
    'Current HIV/AIDS Reports': 1.726,
    'Trends in Parasitology': 1.713,
    'Antiviral Research': 1.701,
    'Antibodies': 1.697,
    'mSystems': 1.691,
    'ImmunoTargets and Therapy': 1.681,
    'Cancer Immunology, Immunotherapy': 1.663,
    'Exercise Immunology Review': 1.659,
    'Vaccines': 1.655,
    'Antibody Therapeutics': 1.648,
    'Critical Reviews in Biotechnology': 1.637,
    'Journal of Immunology': 1.636,
    'Journal of Leukocyte Biology': 1.622,
    'Immunology': 1.601,
    'Arthritis Research and Therapy': 1.592,
    'Biology and Fertility of Soils': 1.591,
    'Clinical Immunology': 1.563,
    'Cell Death Discovery': 1.545,
    'Environmental Microbiomes': 1.538,
    'Vaccine: X': 1.509,
    'Vaccine': 1.493,
    'DMM Disease Models and Mechanisms': 1.489,
    'Pathogens and Immunity': 1.489,
    'Journal of Microbiology, Immunology and Infection': 1.45,
    'Epidemics': 1.448,
    'Current Opinion in Food Science': 1.431,
    'Journal of Nanobiotechnology': 1.421,
    'Immunology and Cell Biology': 1.411,
    'Journal of Immunotherapy': 1.397,
    'Environmental Microbiology': 1.391,
    'Critical Reviews in Microbiology': 1.388,
    'International Immunology': 1.383,
    'Advances in Microbial Physiology': 1.377,
    'Fungal Systematics and Evolution': 1.376,
    'Immune Network': 1.369,
    'Xenotransplantation': 1.351,
    'Biometrics': 1.348,
    'Expert Review of Vaccines': 1.346,
    'Inflammation Research': 1.339,
    'Frontiers in Cellular and Infection Microbiology': 1.308,
    'Journal of NeuroImmune Pharmacology': 1.308,
    'Viruses': 1.29,
    'mSphere': 1.288,
    'Molecular Microbiology': 1.281,
    'Expert Review of Anti-Infective Therapy': 1.27,
    'AIDS': 1.26,
    'Microbial genomics': 1.257,
    'Current Protocols': 1.244,
    'Malaria Journal': 1.237,
    'Current Opinion in HIV and AIDS': 1.236,
    'Journal of Rheumatology': 1.232,
    'Clinical and Experimental Immunology': 1.2,
    'Frontiers in Microbiology': 1.19,
    'Cytotherapy': 1.186,
    'Biofilm': 1.182,
    'International Journal for Parasitology': 1.179,
    'Current Allergy and Asthma Reports': 1.169,
    'Microbiological Research': 1.168,
    'Cellular Immunology': 1.167,
    'Clinical and Experimental Allergy': 1.166,
    'Cell Surface': 1.164,
    'Infectious Diseases': 1.146,
    'Expert Review of Clinical Immunology': 1.136,
    'Fungal Biology Reviews': 1.126,
    'Genes and Immunity': 1.121,
    'Biology Direct': 1.117,
    'Journal of General Virology': 1.113,
    'Fish and Shellfish Immunology': 1.108,
    'Microbial Biotechnology': 1.108,
    'Virulence': 1.104,
    'Journal of Bacteriology': 1.101,
    'Microbial Cell': 1.101,
    'International Immunopharmacology': 1.1,
    'Tumour Virus Research': 1.097,
    'World Allergy Organization Journal': 1.097,
    'Cellular Microbiology': 1.096,
    'Microbiology spectrum': 1.095,
    'Virology Journal': 1.092,
    'Microbial Cell Factories': 1.091,
    'Pediatric Allergy and Immunology': 1.075,
    'Microbes and Infection': 1.059,
    'Medical Microbiology and Immunology': 1.045,
    'American Journal of Tropical Medicine and Hygiene': 1.042,
    'Human Vaccines and Immunotherapeutics': 1.041,
    'International Journal for Parasitology: Drugs and Drug Resistance': 1.038,
    'Food Microbiology': 1.036,
    'Lupus Science and Medicine': 1.035,
    'Rheumatology International': 1.034,
    'Virus Research': 1.034,
    'Applied and Environmental Microbiology': 1.032,
    'Cytokine: X': 1.03,
    'FEMS Microbiology Ecology': 1.025,
    'Biotechnology for Biofuels': 1.022,
    'International Biodeterioration and Biodegradation': 1.021,
    'Clinical and Translational Allergy': 1.018,
    'Journal of Virus Eradication': 1.017,
    'Nature Reviews Materials': 21.927,
    'Nature Energy': 19.588,
    'Nature Materials': 13.874,
    'Nature Nanotechnology': 13.141,
    'Nature Photonics': 11.774,
    'Nature Electronics': 10.927,
    'Annual Review of Condensed Matter Physics': 10.644,
    'Advanced Materials': 9.538,
    'Advanced Energy Materials': 9.044,
    'Electrochemical Energy Reviews': 8.905,
    'Progress in Materials Science': 8.515,
    'ACS Energy Letters': 7.855,
    'Materials Science and Engineering: R: Reports': 6.842,
    'Chem': 6.8,
    'EnergyChem': 6.26,
    'Materials Today': 6.194,
    'InfoMat': 6.153,
    'Progress in Polymer Science': 5.676,
    'Advanced Functional Materials': 5.565,
    'Light: Science and Applications': 5.406,
    'Cement and Concrete Research': 5.294,
    'Nano-Micro Letters': 5.276,
    'Energy Storage Materials': 5.179,
    'Nano Energy': 5.03,
    'Matter': 4.896,
    'Accounts of Materials Research': 4.788,
    'Advanced Photonics': 4.787,
    'ACS Nano': 4.728,
    'Carbon Energy': 4.571,
    'PRX Quantum': 4.415,
    'Energy and Environmental Materials': 4.338,
    'Coordination Chemistry Reviews': 4.121,
    'Optica': 4.096,
    'Advanced Science': 4.086,
    'Materials Horizons': 3.88,
    'Nano Today': 3.868,
    'Small Methods': 3.629,
    'EcoMat': 3.557,
    'Journal of Bioresources and Bioproducts': 3.549,
    'Nano Letters': 3.54,
    'Digest of Technical Papers - IEEE International Solid-State Circuits Conference': 3.537,
    'Annual Review of Materials Research': 3.443,
    'ACS Materials Letters': 3.421,
    'AVS Quantum Science': 3.413,
    'Small': 3.395,
    'Cement and Concrete Composites': 3.339,
    'Engineered Regeneration': 3.25,
    'International Materials Reviews': 3.206,
    'Surface Science Reports': 3.204,
    'npj Flexible Electronics': 3.197,
    'Journal of Materials Chemistry A': 3.156,
    'Laser and Photonics Reviews': 3.147,
    'IUCrJ': 3.072,
    'Journal of Advanced Ceramics': 3.058,
    'npj Computational Materials': 2.977,
    'Acta Materialia': 2.926,
    'Opto-Electronic Advances': 2.913,
    'Wiley Interdisciplinary Reviews: Computational Molecular Science': 2.889,
    'Chemistry of Materials': 2.869,
    'International Journal of Plasticity': 2.84,
    'Materials Today Physics': 2.767,
    'Bioactive Materials': 2.738,
    'Biomaterials': 2.716,
    'npj 2D Materials and Applications': 2.665,
    'Advanced Fiber Materials': 2.653,
    'Additive Manufacturing': 2.633,
    'Cell Reports Physical Science': 2.598,
    'Advanced Composites and Hybrid Materials': 2.533,
    'Materials Research Letters': 2.529,
    'Advanced Optical Materials': 2.524,
    'Progress in Quantum Electronics': 2.513,
    'NPG Asia Materials': 2.501,
    'Polymer Reviews': 2.493,
    'Nano Research': 2.486,
    'Nanoscale Horizons': 2.486,
    'Current Opinion in Solid State and Materials Science': 2.473,
    'Journal of Magnesium and Alloys': 2.441,
    'Progress in Nuclear Magnetic Resonance Spectroscopy': 2.423,
    'Quantum Science and Technology': 2.341,
    'Materials Today Nano': 2.338,
    'Composites Part B: Engineering': 2.3,
    'Solar RRL': 2.24,
    'npj Quantum Materials': 2.221,
    'Biosensors and Bioelectronics': 2.194,
    'ACS applied materials &amp; interfaces': 2.178,
    'Progress in Photovoltaics: Research and Applications': 2.132,
    'Journal of Materials Science and Technology': 2.13,
    'Photonics Research': 2.124,
    'Advanced healthcare materials': 2.114,
    'ACS Photonics': 2.113,
    'Carbon': 2.1,
    'International Journal of Engineering Science': 2.082,
    'ChemSusChem': 2.064,
    'Materials Today Energy': 2.046,
    'Materials Today Advances': 2.04,
    'Nanophotonics': 2.013,
    'Biochar': 1.966,
    'Giant': 1.948,
    'Advanced Materials Technologies': 1.924,
    'Nano Materials Science': 1.917,
    'Communications Materials': 1.911,
    'Journal of Membrane Science': 1.91,
    'Advanced Electronic Materials': 1.891,
    'Nano Convergence': 1.891,
    'Construction and Building Materials': 1.888,
    'Geotextiles and Geomembranes': 1.864,
    'Journal of Physical Chemistry Letters': 1.85,
    'Corrosion Science': 1.84,
    'JPhys Energy': 1.806,
    'Journal of Materiomics': 1.8,
    'Science China Materials': 1.8,
    'Biomaterials Research': 1.796,
    'Composites Science and Technology': 1.766,
    'Scripta Materialia': 1.756,
    'Engineering': 1.75,
    'Materials and Design': 1.741,
    'Carbohydrate Polymers': 1.726,
    'Progress in Surface Science': 1.677,
    'Materials Science &amp; Engineering A: Structural Materials: Properties, Microstructure and Processing': 1.667,
    'APL Materials': 1.662,
    'Composites - Part A: Applied Science and Manufacturing': 1.661,
    'ACS Macro Letters': 1.655,
    'Acta Biomaterialia': 1.638,
    'Progress in Solid State Chemistry': 1.633,
    '2D Materials': 1.631,
    'Applied Materials Today': 1.627,
    'Critical Reviews in Solid State and Materials Sciences': 1.627,
    'Communications Chemistry': 1.62,
    'Nanoscale': 1.62,
    'Smart Materials in Medicine': 1.612,
    'Environmental Science: Nano': 1.607,
    'Sustainable Materials and Technologies': 1.605,
    'Journal of Colloid and Interface Science': 1.604,
    'ACS Applied Energy Materials': 1.588,
    'Wiley Interdisciplinary Reviews: Nanomedicine and Nanobiotechnology': 1.588,
    'Advanced Quantum Technologies': 1.577,
    'Materials Chemistry Frontiers': 1.575,
    'Developments in the Built Environment': 1.556,
    'Biofabrication': 1.534,
    'Journal of Materials Chemistry C': 1.534,
    'International Journal of Mechanical Sciences': 1.532,
    'IEEE Symposium on VLSI Circuits, Digest of Technical Papers': 1.529,
    'Advanced Industrial and Engineering Polymer Research': 1.528,
    'Microsystems and Nanoengineering': 1.515,
    'Journal of Sustainable Cement-Based Materials': 1.51,
    'International Journal of Fatigue': 1.504,
    'Solar Energy Materials and Solar Cells': 1.503,
    'Journal of Materials Processing Technology': 1.485,
    'Sensors and Actuators B: Chemical': 1.482,
    'Desalination': 1.471,
    'Physical Review B': 1.468,
    'Materials Science for Energy Technologies': 1.463,
    'Macromolecules': 1.461,
    'Composite Structures': 1.455,
    'Journal of Composites for Construction': 1.45,
    'Materials Today Bio': 1.444,
    'International Journal for Numerical and Analytical Methods in Geomechanics': 1.429,
    'JPhys Materials': 1.423,
    'Journal of Nanobiotechnology': 1.421,
    'Tissue Engineering - Part B: Reviews': 1.414,
    'Composites Communications': 1.387,
    'Engineered Science': 1.386,
    'Chemical Record': 1.379,
    'Friction': 1.376,
    'Solar Energy': 1.373,
    'Journal of Structural Engineering': 1.36,
    'Tribology International': 1.348,
    'Chemical Communications': 1.344,
    'Food Packaging and Shelf Life': 1.338,
    'Rare Metals': 1.326,
    'Journal of Constructional Steel Research': 1.319,
    'IEEE Electron Device Letters': 1.316,
    'Current Opinion in Colloid and Interface Science': 1.314,
    'Lab on a Chip': 1.314,
    'Dental Materials': 1.283,
    'Engineering Fracture Mechanics': 1.281,
    'APL Bioengineering': 1.269,
    'Computers and Structures': 1.259,
    'Journal of the European Ceramic Society': 1.257,
    'Biomacromolecules': 1.244,
    'NDT and E International': 1.24,
    'Materials Today Chemistry': 1.229,
    'Marine Structures': 1.228,
    'Journal of Materials Chemistry B': 1.211,
    'Journal of the Royal Society Interface': 1.196,
    'ACS Applied Nano Materials': 1.193,
    'Superconductor Science and Technology': 1.191,
    'Applied Surface Science': 1.187,
    'Nuclear Materials and Energy': 1.184,
    'Biomaterials Science': 1.171,
    'Science and Technology of Advanced Materials': 1.169,
    'Theoretical and Applied Fracture Mechanics': 1.142,
    'Interface Focus': 1.141,
    'Journal of Science: Advanced Materials and Devices': 1.13,
    'IEEE Transactions on Semiconductor Manufacturing': 1.129,
    'MRS Bulletin': 1.128,
    'CSEE Journal of Power and Energy Systems': 1.118,
    'International Journal of Nanomedicine': 1.111,
    'Tungsten': 1.11,
    'Materials Characterization': 1.109,
    'Physical Review Materials': 1.105,
    'Case Studies in Construction Materials': 1.102,
    'ACS Applied Electronic Materials': 1.101,
    'Materials Science and Engineering C': 1.086,
    'European Journal of Mechanics, A/Solids': 1.082,
    'Journal of Alloys and Compounds': 1.079,
    'Materials Today Sustainability': 1.079,
    'ACS Materials Au': 1.069,
    'Multifunctional Materials': 1.064,
    'Nanoscale Advances': 1.063,
    'Materials Advances': 1.061,
    'Materials and Manufacturing Processes': 1.059,
    'JPhys Photonics': 1.056,
    'Carbon Resources Conversion': 1.051,
    'IEEE Journal of Photovoltaics': 1.05,
    'Journal of Materials Research and Technology': 1.05,
    'Optics and Lasers in Engineering': 1.047,
    'International Journal of Solids and Structures': 1.046,
    'Wear': 1.043,
    'Frontiers of Optoelectronics': 1.04,
    'Engineering Science and Technology, an International Journal': 1.036,
    'Macromolecular Rapid Communications': 1.036,
    'Journal of Physical Chemistry C': 1.028,
    'International Journal of Damage Mechanics': 1.027,
    'Journal of Materials in Civil Engineering': 1.026,
    'Materials and Structures/Materiaux et Constructions': 1.024,
    'International Biodeterioration and Biodegradation': 1.021,
    'Technical Digest - International Electron Devices Meeting': 1.017,
    'Journal of Sandwich Structures and Materials': 1.015,
    'Progress in Organic Coatings': 1.015,
    'Aerosol Science and Technology': 1.014,
    'High Power Laser Science and Engineering': 1.014,
    'Polymer Chemistry': 1.014,
    'Hydrometallurgy': 1.012,
    'Cellulose': 1.011,
    'Journal of Nuclear Materials': 1.002,
    'Annals of Mathematics': 6.76,
    "Publications Mathematiques de l'Institut des Hautes Etudes Scientifiques": 6.582,
    'Science Robotics': 6.479,
    'Acta Numerica': 6.341,
    'Molecular Systems Biology': 6.22,
    'Journal of Business and Economic Statistics': 6.15,
    'Journal of the American Mathematical Society': 5.848,
    'IEEE Transactions on Evolutionary Computation': 5.767,
    'IEEE Transactions on Wireless Communications': 5.662,
    'Annals of Statistics': 5.219,
    'Foundations and Trends in Systems and Control': 4.816,
    'Inventiones Mathematicae': 4.811,
    'ACM Computing Surveys': 4.457,
    'IEEE Transactions on Pattern Analysis and Machine Intelligence': 4.447,
    'Annals of PDE': 4.425,
    'PRX Quantum': 4.415,
    'Mathematical Programming Computation': 4.377,
    'Communications on Pure and Applied Mathematics': 4.044,
    'Acta Mathematica': 4.01,
    'Journal of the Royal Statistical Society. Series B: Statistical Methodology': 3.976,
    'IEEE Signal Processing Magazine': 3.939,
    'Annual Review of Statistics and Its Application': 3.896,
    'Biometrika': 3.566,
    'IEEE Transactions on Fuzzy Systems': 3.533,
    'Foundations and Trends in Communications and Information Theory': 3.415,
    'Journal of the American Statistical Association': 3.397,
    'Journal of the European Mathematical Society': 3.358,
    'Foundations and Trends in Theoretical Computer Science': 3.286,
    'EMS Surveys in Mathematical Sciences': 3.245,
    'IEEE/CAA Journal of Automatica Sinica': 3.218,
    'Duke Mathematical Journal': 3.217,
    'Stata Journal': 3.115,
    'Annals of Probability': 3.099,
    'Archive for Rational Mechanics and Analysis': 3.041,
    'Geometric and Functional Analysis': 3.024,
    'Structural Equation Modeling': 3.014,
    'SIAM Review': 3.002,
    'npj Computational Materials': 2.977,
    'Journal of Differential Geometry': 2.952,
    'Bioinformatics': 2.94,
    "Annales Scientifiques de l'Ecole Normale Superieure": 2.932,
    'Wiley Interdisciplinary Reviews: Computational Molecular Science': 2.889,
    'Robotics and Computer-Integrated Manufacturing': 2.881,
    'Genomics, Proteomics and Bioinformatics': 2.869,
    'Memoirs of the American Mathematical Society': 2.863,
    'Analysis and PDE': 2.818,
    'IEEE Transactions on Vehicular Technology': 2.802,
    'Journal des Mathematiques Pures et Appliquees': 2.801,
    'International Journal of Robotics Research': 2.729,
    'Vital and health statistics. Series 2, Data evaluation and methods research': 2.723,
    'Journal of Statistical Software': 2.722,
    'Computer Science Review': 2.685,
    'Swarm and Evolutionary Computation': 2.646,
    'Proceedings of the Annual ACM-SIAM Symposium on Discrete Algorithms': 2.626,
    'Journal of Global Economic Analysis': 2.615,
    'ACM Transactions on Intelligent Systems and Technology': 2.603,
    'Algebraic Geometry': 2.552,
    'Communications in Partial Differential Equations': 2.536,
    'Probability Theory and Related Fields': 2.515,
    'NPG Asia Materials': 2.501,
    'Geometry and Topology': 2.478,
    "Annales de l'Institut Henri Poincare (C) Analyse Non Lineaire": 2.473,
    'SIAM Journal on Computing': 2.471,
    'IEEE Computational Intelligence Magazine': 2.437,
    'Forum of Mathematics, Pi': 2.436,
    'Scientific data': 2.41,
    'Calculus of Variations and Partial Differential Equations': 2.382,
    'IEEE Transactions on Control of Network Systems': 2.376,
    'American Statistician': 2.373,
    'European Journal of Operational Research': 2.371,
    'Compositio Mathematica': 2.36,
    'Journal fur die Reine und Angewandte Mathematik': 2.31,
    'NAR Genomics and Bioinformatics': 2.304,
    'Information Sciences': 2.285,
    'SIAM Journal on Optimization': 2.282,
    'Journal of Machine Learning Research': 2.281,
    'Mathematical Programming': 2.281,
    'Biostatistics': 2.248,
    'Geoscientific Model Development': 2.225,
    'Advances in Nonlinear Analysis': 2.222,
    'Mathematische Annalen': 2.107,
    'American Journal of Mathematics': 2.105,
    'Journal of Algebraic Geometry': 2.078,
    'ACM Transactions on Algorithms': 2.065,
    'Advances in Mathematics': 1.995,
    'SIAM Journal on Mathematical Analysis': 1.994,
    'Bayesian Analysis': 1.99,
    'Energy': 1.989,
    'Mathematical Models and Methods in Applied Sciences': 1.988,
    'Journal of Differential Equations': 1.983,
    'Statistical Science': 1.978,
    'Annals of Applied Probability': 1.971,
    'Journal of Functional Analysis': 1.959,
    'Educational and Psychological Measurement': 1.949,
    'Handbook of Numerical Analysis': 1.933,
    'Proceedings of the London Mathematical Society': 1.912,
    'SIAM Journal on Numerical Analysis': 1.899,
    'PLoS Computational Biology': 1.872,
    'Virtual and Physical Prototyping': 1.871,
    'Journal of Combinatorial Theory. Series B': 1.869,
    'Network Neuroscience': 1.853,
    'Mathematical Finance': 1.837,
    "Annales de l'institut Henri Poincare (B) Probability and Statistics": 1.816,
    'IEEE Communications Letters': 1.795,
    'Japanese Journal of Mathematics': 1.791,
    'Selecta Mathematica, New Series': 1.784,
    'IEEE Transactions on Emerging Topics in Computational Intelligence': 1.764,
    'Reliability Engineering and System Safety': 1.76,
    'Journal of Computational Physics': 1.753,
    'Oxford Bulletin of Economics and Statistics': 1.743,
    'Journal of Computational and Graphical Statistics': 1.72,
    'Computers and Operations Research': 1.716,
    'IEEE Robotics and Automation Letters': 1.693,
    'mSystems': 1.691,
    "Journal de l'Ecole Polytechnique - Mathematiques": 1.688,
    'IEEE Control Systems Letters': 1.687,
    'Advanced Nonlinear Studies': 1.676,
    'Foundations of Computational Mathematics': 1.652,
    'Educational Studies in Mathematics': 1.636,
    'Stochastics and Partial Differential Equations: Analysis and Computations': 1.635,
    'Journal of the Institute of Mathematics of Jussieu': 1.623,
    'Nonlinear Analysis: Hybrid Systems': 1.617,
    'Quantitative Science Studies': 1.612,
    'Multivariate Behavioral Research': 1.606,
    'SIAM Journal of Scientific Computing': 1.606,
    'Transactions of the American Mathematical Society': 1.59,
    'ISA Transactions': 1.588,
    'Quantum Topology': 1.585,
    'IEEE Transactions on Intelligent Vehicles': 1.583,
    'Archives of Computational Methods in Engineering': 1.581,
    'Advanced Quantum Technologies': 1.577,
    'Journal of Topology': 1.575,
    'Journal of Numerical Mathematics': 1.56,
    'International Journal of Mechanical Sciences': 1.532,
    'Algebra and Number Theory': 1.523,
    'International Journal of Fatigue': 1.504,
    'Advances in Combinatorics': 1.498,
    'Advances in Calculus of Variations': 1.492,
    'Nonlinearity': 1.491,
    'British Journal of Mathematical and Statistical Psychology': 1.49,
    'Journal of Materials Processing Technology': 1.485,
    'Numerische Mathematik': 1.484,
    'Unmanned Systems': 1.476,
    'Statistics in Medicine': 1.471,
    'Bernoulli': 1.45,
    'Combinatorica': 1.442,
    'International Journal of Intelligent Systems': 1.438,
    'Mathematics of Operations Research': 1.435,
    'International Mathematics Research Notices': 1.429,
    'Annali della Scuola normale superiore di Pisa - Classe di scienze': 1.425,
    'Information Systems Frontiers': 1.424,
    'Communications in Mathematical Physics': 1.413,
    'Journal of Fluid Mechanics': 1.409,
    'Theory of Computing': 1.406,
    'Revista Matematica Iberoamericana': 1.405,
    'ZDM - International Journal on Mathematics Education': 1.403,
    'Forum of Mathematics, Sigma': 1.402,
    'Chaos, Solitons and Fractals': 1.393,
    'Current Opinion in Systems Biology': 1.391,
    'Communications in Contemporary Mathematics': 1.39,
    'npj Systems Biology and Applications': 1.388,
    'Control Engineering Practice': 1.386,
    'Engineered Science': 1.386,
    'Epijournal de Geometrie Algebrique': 1.386,
    'IMA Journal of Numerical Analysis': 1.383,
    'Infectious Disease Modelling': 1.383,
    'Wiley Interdisciplinary Reviews: Computational Statistics': 1.36,
    'Commentarii Mathematici Helvetici': 1.359,
    'IEEE Transactions on Computational Social Systems': 1.351,
    'Journal of Nonlinear Science': 1.35,
    'Finance and Stochastics': 1.349,
    'Biometrics': 1.348,
    'IEEE/ACM Transactions on Audio Speech and Language Processing': 1.348,
    'Nonlinear Analysis, Theory, Methods and Applications': 1.347,
    'IEEE Transactions on Sustainable Computing': 1.346,
    'Journal for Research in Mathematics Education': 1.337,
    'Annals of Mathematics Studies': 1.334,
    'Asterisque': 1.329,
    'Computational Mechanics': 1.321,
    'Psychometrika': 1.308,
    'Mathematics of Computation': 1.302,
    'Statistical Methods in Medical Research': 1.3,
    'SMAI Journal of Computational Mathematics': 1.286,
    'Nonlinear Dynamics': 1.285,
    'SIAM Journal on Control and Optimization': 1.282,
    'Journal of Management Analytics': 1.281,
    'Random Structures and Algorithms': 1.281,
    'Journal of Informetrics': 1.269,
    'Probability Surveys': 1.26,
    'Computers and Structures': 1.259,
    'Nonlinear Analysis: Real World Applications': 1.256,
    'Robotics and Autonomous Systems': 1.245,
    "Journal d'Analyse Mathematique": 1.239,
    'Journal of Guidance, Control, and Dynamics': 1.239,
    'Electronic Journal of Probability': 1.235,
    "Annales de l'Institut Fourier": 1.227,
    'Applied Mathematics Letters': 1.224,
    'Indiana University Mathematics Journal': 1.223,
    'EURO Journal on Transportation and Logistics': 1.213,
    'Fuzzy Sets and Systems': 1.212,
    'European Journal of Combinatorics': 1.209,
    'Tunisian Journal of Mathematics': 1.209,
    'Computational Optimization and Applications': 1.208,
    'Journal of the London Mathematical Society': 1.206,
    'Information and Inference': 1.193,
    'Distributed Computing': 1.192,
    'Journal of Combinatorial Theory - Series A': 1.192,
    'Applied and Computational Mathematics': 1.191,
    'Journal of Evolution Equations': 1.19,
    'Milan Journal of Mathematics': 1.19,
    'ACM Transactions on Mathematical Software': 1.186,
    'Journal of Choice Modelling': 1.186,
    'Stochastic Processes and their Applications': 1.184,
    'Stochastic Systems': 1.179,
    'Journal of Scientific Computing': 1.171,
    'Statistica Sinica': 1.169,
    'Journal of Multivariate Analysis': 1.167,
    'Jahresbericht der Deutschen Mathematiker-Vereinigung': 1.16,
    'Annals of Applied Statistics': 1.159,
    'Journal of the Franklin Institute': 1.159,
    'Journal of Dynamics and Differential Equations': 1.158,
    'Journal of Parallel and Distributed Computing': 1.158,
    'Engineering Applications of Computational Fluid Mechanics': 1.155,
    'Fuzzy Optimization and Decision Making': 1.152,
    'IEEE Transactions on Computers': 1.152,
    'Journal of Applied and Computational Topology': 1.149,
    'Methods, Data, Analyses': 1.148,
    'Evolutionary Computation': 1.146,
    'IACR Transactions on Symmetric Cryptology': 1.144,
    'Theoretical and Applied Fracture Mechanics': 1.142,
    'Complex and Intelligent Systems': 1.138,
    'Journal of Combinatorial Designs': 1.138,
    'Mathematische Zeitschrift': 1.138,
    'Theory and Practice of Logic Programming': 1.138,
    'Journal of Economic Dynamics and Control': 1.134,
    'Discrete and Continuous Dynamical Systems': 1.13,
    'Bioinformatics and Biology Insights': 1.128,
    'Journal of Membrane Computing': 1.126,
    'IEEE Signal Processing Letters': 1.123,
    'Proceedings - Symposium on Logic in Computer Science': 1.122,
    'CPT: Pharmacometrics and Systems Pharmacology': 1.119,
    'Journal of Mathematics Teacher Education': 1.119,
    'Biology Direct': 1.117,
    'Structural and Multidisciplinary Optimization': 1.116,
    'Inverse Problems': 1.115,
    'Technology, Knowledge and Learning': 1.108,
    'Mathematical Research Letters': 1.107,
    'Measurement: Journal of the International Measurement Confederation': 1.106,
    'IEEE Transactions on Computational Imaging': 1.105,
    'International Journal of Systems Science': 1.105,
    'Journal of Intelligent Transportation Systems: Technology, Planning, and Operations': 1.101,
    'BMC Bioinformatics': 1.1,
    'IEEE Control Systems': 1.097,
    'Publications of the Research Institute for Mathematical Sciences': 1.097,
    'Engineering with Computers': 1.096,
    'ESAIM: Mathematical Modelling and Numerical Analysis': 1.096,
    'Technometrics': 1.092,
    'Statistics in Biopharmaceutical Research': 1.091,
    'Internet Mathematics': 1.085,
    'Nonlinear Differential Equations and Applications': 1.085,
    'European Journal of Mechanics, A/Solids': 1.082,
    'Journal of Mathematical Behavior': 1.081,
    'Applied Mathematical Modelling': 1.08,
    'Pervasive and Mobile Computing': 1.08,
    'Optimization Methods and Software': 1.079,
    'Electronic Journal of Statistics': 1.067,
    'Journal of Modern Dynamics': 1.067,
    'EPJ Data Science': 1.066,
    'Documenta Mathematica': 1.06,
    'SIAM Journal on Applied Algebra and Geometry': 1.059,
    'Journal of Graph Theory': 1.057,
    'Proceedings of the Royal Society of Edinburgh Section A: Mathematics': 1.057,
    'International Journal of Science and Mathematics Education': 1.055,
    'Bulletin of the American Mathematical Society': 1.051,
    'Coastal Engineering Journal': 1.051,
    'Quantum Machine Intelligence': 1.051,
    'Canadian Journal of Mathematics': 1.048,
    'Journal of Artificial Intelligence and Soft Computing Research': 1.047,
    'International Journal of Solids and Structures': 1.046,
    'International Journal of Research in Undergraduate Mathematics Education': 1.044,
    'Physica D: Nonlinear Phenomena': 1.044,
    'International Journal for Numerical Methods in Engineering': 1.043,
    'Memetic Computing': 1.042,
    'Potential Analysis': 1.041,
    'Journal of Mathematical Fluid Mechanics': 1.037,
    'Finite Fields and Their Applications': 1.034,
    'Designs, Codes, and Cryptography': 1.033,
    'Ergodic Theory and Dynamical Systems': 1.027,
    'Journal of Cryptology': 1.025,
    'Zeitschrift fur Angewandte Mathematik und Physik': 1.023,
    'SIAM Journal on Applied Dynamical Systems': 1.022,
    'Annals of K-Theory': 1.021,
    'Journal of Algebra': 1.016,
    'Service Science': 1.016,
    'Studies in Applied Mathematics': 1.016,
    'Transformation Groups': 1.012,
    'Econometrics and Statistics': 1.01,
    'Computability': 1.008,
    'Algorithmica': 1.007,
    'Annales Henri Poincare': 1.007,
    'Computational Social Networks': 1.003,
    'Ca-A Cancer Journal for Clinicians': 86.091,
    'New England Journal of Medicine': 26.015,
    'Nature Medicine': 24.687,
    'MMWR Recommendations and Reports': 23.962,
    'Nature Reviews Cancer': 20.977,
    'Nature Reviews Genetics': 19.454,
    'Nature Reviews Drug Discovery': 19.423,
    'Nature Reviews Immunology': 18.141,
    'Morbidity and Mortality Weekly Report': 16.786,
    'Annual Review of Immunology': 15.262,
    'MMWR Surveillance Summaries': 15.178,
    'Nature Reviews Clinical Oncology': 14.809,
    'Immunity': 14.795,
    'Lancet, The': 14.607,
    'World Psychiatry': 14.306,
    'Physiological Reviews': 13.693,
    'MMWR supplements': 13.467,
    'Cancer Cell': 12.578,
    'Lancet Oncology, The': 12.27,
    'Annals of Oncology': 11.945,
    'Nature Reviews Disease Primers': 11.388,
    'Lancet Respiratory Medicine,The': 11.204,
    'Science immunology': 11.191,
    'Nature Immunology': 10.921,
    'Lancet Public Health, The': 10.591,
    'Current Protocols in Bioinformatics': 10.408,
    'Nature Reviews Microbiology': 10.396,
    'Nature Reviews Gastroenterology and Hepatology': 10.178,
    'Journal of Clinical Oncology': 10.163,
    'Annual Review of Public Health': 9.963,
    'Lancet Neurology, The': 9.819,
    'Nature Cancer': 9.817,
    'Nature Reviews Methods Primers': 9.476,
    'Lancet Microbe, The': 9.419,
    'Nature Microbiology': 9.187,
    'Molecular Cancer': 8.703,
    'Annual Review of Pathology: Mechanisms of Disease': 8.628,
    'Gut': 8.588,
    'Journal of the American College of Cardiology': 8.343,
    'Lancet Diabetes and Endocrinology,The': 8.217,
    'Lancet Psychiatry,The': 8.167,
    'Lancet Infectious Diseases, The': 8.14,
    'Nature Reviews Endocrinology': 8.124,
    'JAMA Oncology': 8.103,
    'Circulation': 7.8,
    'Gastroenterology': 7.645,
    'Clinical Microbiology Reviews': 7.584,
    'Journal of Hepatology': 7.398,
    'JAMA Cardiology': 7.371,
    'Lancet Global Health,The': 7.37,
    'Cancer Discovery': 7.268,
    'Endocrine Reviews': 7.205,
    'Lancet Regional Health - Europe, The': 7.133,
    'Cell Systems': 7.112,
    'Nature Metabolism': 7.045,
    'European Urology': 6.963,
    'Nature Reviews Cardiology': 6.932,
    'Chem': 6.8,
    'Annual Review of Clinical Psychology': 6.744,
    'JAMA Neurology': 6.697,
    'JAMA - Journal of the American Medical Association': 6.695,
    'Nature Biomedical Engineering': 6.625,
    'JAMA Psychiatry': 6.578,
    'Nature Reviews Neurology': 6.536,
    'Nature Reviews Nephrology': 6.494,
    'Annals of the Rheumatic Diseases': 6.489,
    'Lancet Digital Health, The': 6.433,
    'Accounts of Chemical Research': 6.382,
    'Science Translational Medicine': 6.361,
    'American Journal of Respiratory and Critical Care Medicine': 6.237,
    'Journal of Experimental Medicine': 6.237,
    'Intensive Care Medicine': 6.229,
    'Molecular Systems Biology': 6.22,
    'Lancet Gastroenterology and Hepatology, The': 6.174,
    'NCHS data brief': 6.085,
    'Journal of Hematology and Oncology': 6.046,
    'Diabetes Care': 6.008,
    'Journal of Thoracic Oncology': 5.866,
    'European Journal of Heart Failure': 5.601,
    'EMBO Journal': 5.484,
    'Annual Review of Microbiology': 5.436,
    'Journal of the National Cancer Institute': 5.409,
    'JACC: Heart Failure': 5.352,
    'Annual Review of Genomics and Human Genetics': 5.196,
    'Molecular Neurodegeneration': 5.158,
    'Journal of Clinical Investigation': 5.117,
    'JAMA Pediatrics': 5.109,
    'Evidence-Based Mental Health': 4.969,
    'American Journal of Human Genetics': 4.943,
    'Progress in Retinal and Eye Research': 4.939,
    'Blood': 4.927,
    'JAMA Internal Medicine': 4.922,
    'Genome Research': 4.906,
    'Acta Neuropathologica': 4.897,
    'Genome Medicine': 4.851,
    'Neuro-Oncology': 4.833,
    'Lancet Haematology,The': 4.793,
    'British Journal of Sports Medicine': 4.764,
    'Psychotherapy and Psychosomatics': 4.738,
    'European Respiratory Journal': 4.722,
    'Trends in Immunology': 4.689,
    'Clinical Cancer Research': 4.603,
    'Annual Review of Cancer Biology': 4.599,
    'Lancet Rheumatology, The': 4.586,
    'eClinicalMedicine': 4.553,
    'Clinical Psychology Review': 4.541,
    'Circulation Research': 4.531,
    'Reports on Progress in Physics': 4.467,
    'Journal of the American Society of Nephrology : JASN': 4.45,
    'Brain': 4.437,
    'JACC: Cardiovascular Imaging': 4.435,
    'Drug Resistance Updates': 4.391,
    'Nature Aging': 4.336,
    'eLife': 4.251,
    'Annual Review of Medicine': 4.233,
    'Annual Review of Physical Chemistry': 4.232,
    'American Journal of Psychiatry': 4.231,
    'PLoS Medicine': 4.223,
    'Lancet Child and Adolescent Health, The': 4.179,
    'Hepatology': 4.152,
    'Eurosurveillance': 4.145,
    'JAMA network open': 4.108,
    'Journal of the National Comprehensive Cancer Network : JNCCN': 4.098,
    'Advanced Science': 4.086,
    'Radiology': 4.073,
    'Human Reproduction Update': 4.021,
    'European Journal of Epidemiology': 4.007,
    'Protein Science': 4.007,
    'Clinical Infectious Diseases': 3.995,
    'Lancet HIV,The': 3.989,
    'Annals of Neurology': 3.977,
    'Ophthalmology': 3.913,
    'Journal of Infection': 3.904,
    'Kidney International': 3.868,
    'Nano Today': 3.868,
    'Annals of Internal Medicine': 3.845,
    'Arthritis and Rheumatology': 3.788,
    'Journal of Allergy and Clinical Immunology': 3.742,
    'Immunological Reviews': 3.729,
    'Microbiology and Molecular Biology Reviews': 3.719,
    'Microbiome': 3.709,
    'Blood Cancer Journal': 3.699,
    'GigaScience': 3.697,
    'Journal of Cell Biology': 3.664,
    'Journal of the American Academy of Child and Adolescent Psychiatry': 3.663,
    'JAMA Surgery': 3.623,
    'Lancet Healthy Longevity, The': 3.619,
    'Cancer Treatment Reviews': 3.589,
    'Clinical Microbiology and Infection': 3.587,
    'Lancet Planetary Health, The': 3.586,
    'Trends in Cancer': 3.581,
    'Critical Care': 3.577,
    'npj Digital Medicine': 3.552,
    'Cellular and Molecular Immunology': 3.545,
    'Molecular Psychiatry': 3.541,
    'Journal of Extracellular Vesicles': 3.525,
    'Health Affairs': 3.501,
    'Trends in Microbiology': 3.468,
    'Med': 3.459,
    'Nature reviews. Rheumatology': 3.459,
    'European Heart Journal': 3.45,
    'BMC Medicine': 3.447,
    'Molecular Therapy': 3.425,
    'Journal for ImmunoTherapy of Cancer': 3.403,
    'Small': 3.395,
    'Experimental and Molecular Medicine': 3.38,
    'Annual Review of Phytopathology': 3.379,
    'Leukemia': 3.376,
    'Protein and Cell': 3.367,
    'American Psychologist': 3.363,
    'Journal of Clinical Epidemiology': 3.362,
    'Diabetologia': 3.349,
    'Sports Medicine': 3.292,
    "Alzheimer's and Dementia": 3.288,
    'JCI insight': 3.277,
    'Engineered Regeneration': 3.25,
    'FEMS Microbiology Reviews': 3.245,
    'Molecular Aspects of Medicine': 3.244,
    'Circulation: Heart Failure': 3.215,
    'npj Vaccines': 3.197,
    'Medical Image Analysis': 3.195,
    'Journal of Neurology, Neurosurgery and Psychiatry': 3.178,
    'Lancet Regional Health - Americas, The': 3.174,
    'Thorax': 3.133,
    'Environmental Science &amp; Technology': 3.123,
    'Health Psychology Review': 3.101,
    'Journal of Thrombosis and Haemostasis': 3.086,
    'Blood advances': 3.07,
    'American Journal of Obstetrics and Gynecology': 3.068,
    'Pharmacology and Therapeutics': 3.063,
    'Autism in Adulthood': 3.057,
    'Sleep Medicine Reviews': 3.053,
    'Emerging Infectious Diseases': 3.05,
    'Journal of Child Psychology and Psychiatry and Allied Disciplines': 3.025,
    'Journal of Internal Medicine': 2.992,
    'Circulation: Arrhythmia and Electrophysiology': 2.985,
    'Cancer Research': 2.984,
    'American Journal of Kidney Diseases': 2.969,
    'Circulation. Genomic and precision medicine': 2.966,
    'Liver Cancer': 2.955,
    'Annals of Surgery': 2.951,
    'Clinical Gastroenterology and Hepatology': 2.928,
    'Annual Review of Biomedical Engineering': 2.927,
    'JACC: Cardiovascular Interventions': 2.909,
    'JHEP Reports': 2.907,
    'American Journal of Hematology': 2.901,
    'eBioMedicine': 2.9,
    'European Journal of Cancer': 2.898,
    'Metabolism: Clinical and Experimental': 2.886,
    'Genomics, Proteomics and Bioinformatics': 2.869,
    'BMJ, The': 2.867,
    'British Journal of Cancer': 2.867,
    "Journal of Crohn's and Colitis": 2.866,
    'Pediatrics': 2.864,
    'Implementation Science': 2.861,
    'Journal of Anxiety Disorders': 2.85,
    'Child Development Perspectives': 2.844,
    'Milbank Quarterly': 2.828,
    'Emerging Microbes and Infections': 2.815,
    'International Journal of Epidemiology': 2.815,
    'Blood Reviews': 2.803,
    'Arteriosclerosis, Thrombosis, and Vascular Biology': 2.798,
    'Mucosal Immunology': 2.792,
    'Alimentary Pharmacology and Therapeutics': 2.786,
    'Critical Care Medicine': 2.785,
    'American Journal of Transplantation': 2.776,
    'JAMA Dermatology': 2.766,
    'Hypertension': 2.758,
    'Stroke': 2.746,
    'Genetics in Medicine': 2.745,
    'European Stroke Journal': 2.737,
    'Cancer': 2.736,
    'Allergy: European Journal of Allergy and Clinical Immunology': 2.734,
    'Annual Review of Vision Science': 2.727,
    'Vital and health statistics. Series 2, Data evaluation and methods research': 2.723,
    'Journal of Heart and Lung Transplantation': 2.71,
    'International Journal of Behavioral Nutrition and Physical Activity': 2.709,
    'Obesity Reviews': 2.699,
    'Current Opinion in Immunology': 2.698,
    'European Respiratory Review': 2.695,
    'Journal of Travel Medicine': 2.69,
    'Cancer Communications': 2.677,
    'Biochimica et Biophysica Acta - Reviews on Cancer': 2.657,
    'Seminars in Immunology': 2.652,
    "Alzheimer's Research and Therapy": 2.65,
    'Developmental Review': 2.649,
    'Journal of the National Cancer Center': 2.649,
    'American Journal of Public Health': 2.645,
    'Annual Review of Nutrition': 2.643,
    'Diabetes': 2.635,
    'JACC: Basic to Translational Science': 2.629,
    'Cytokine and Growth Factor Reviews': 2.621,
    'Reviews in Medical Virology': 2.618,
    'Alcohol research : current reviews': 2.617,
    'Annals of Intensive Care': 2.616,
    'Current Protocols in Microbiology': 2.602,
    'Movement Disorders': 2.602,
    'Gut Microbes': 2.6,
    'Modern Pathology': 2.598,
    'British Journal of Psychiatry': 2.597,
    'European urology oncology': 2.597,
    'International Journal of Surgery': 2.597,
    'Journal of Autoimmunity': 2.594,
    'Globalization and Health': 2.587,
    'Bone Research': 2.575,
    'Depression and Anxiety': 2.572,
    'Ultrasound in Obstetrics and Gynecology': 2.572,
    'Translational Neurodegeneration': 2.571,
    'JACC: Clinical Electrophysiology': 2.562,
    'Haematologica': 2.557,
    'Autoimmunity Reviews': 2.552,
    'Journal of Pathology': 2.541,
    'Circulation: Cardiovascular Quality and Outcomes': 2.538,
    'Neurology': 2.537,
    'Journal of Biomedical Science': 2.52,
    'npj Precision Oncology': 2.52,
    'Current Psychiatry Reports': 2.51,
    'Criminology': 2.497,
    'British Journal of Anaesthesia': 2.487,
    'Cardiovascular Research': 2.481,
    'Obstetrics and Gynecology': 2.48,
    'Journal of Occupational Health Psychology': 2.478,
    'American Journal of Sports Medicine': 2.477,
    'Anaesthesia': 2.473,
    'Bioresource Technology': 2.473,
    'Clinical Child and Family Psychology Review': 2.466,
    'Psychological Medicine': 2.465,
    'Seminars in Immunopathology': 2.462,
    'Cellular and Molecular Gastroenterology and Hepatology': 2.454,
    'Pain': 2.445,
    'Acta Psychiatrica Scandinavica': 2.444,
    'JACC: CardioOncology': 2.44,
    'Journal of the American Medical Informatics Association : JAMIA': 2.44,
    'Radiology: Artificial Intelligence': 2.432,
    'American Journal of Gastroenterology': 2.423,
    'Trauma, Violence, and Abuse': 2.422,
    'Investigative Radiology': 2.414,
    'Journal of Experimental and Clinical Cancer Research': 2.413,
    'Journal of Health and Social Behavior': 2.409,
    'Breast Cancer Research': 2.408,
    'Acta neuropathologica communications': 2.395,
    'Clinical Reviews in Allergy and Immunology': 2.391,
    'Schizophrenia Bulletin': 2.387,
    'Journal of Infectious Diseases': 2.386,
    'Neuropsychopharmacology': 2.385,
    'Bulletin of the World Health Organization': 2.384,
    'Theranostics': 2.381,
    'Biomarker Research': 2.379,
    'JNCI Cancer Spectrum': 2.377,
    'BMJ Global Health': 2.376,
    'Diabetes Technology and Therapeutics': 2.374,
    'British Journal of Health Psychology': 2.371,
    'PLoS Genetics': 2.363,
    'Cardiovascular Diabetology': 2.361,
    'Journal of Clinical Microbiology': 2.36,
    'JAMA Ophthalmology': 2.349,
    'Current Protocols in Immunology': 2.34,
    'Advances in Nutrition': 2.331,
    'Tobacco Control': 2.331,
    'Epidemiology and Psychiatric Sciences': 2.324,
    'Chest': 2.322,
    'Journals of Gerontology - Series B Psychological Sciences and Social Sciences': 2.311,
    'Trends in Endocrinology and Metabolism': 2.304,
    'Annals of the American Thoracic Society': 2.3,
    'Circulation: Cardiovascular Interventions': 2.296,
    'Clinical journal of the American Society of Nephrology : CJASN': 2.293,
    'Neurology: Neuroimmunology and NeuroInflammation': 2.288,
    'Annual Review of Analytical Chemistry': 2.263,
    'International Journal of Cancer': 2.259,
    'Heart Rhythm': 2.256,
    'European Heart Journal Cardiovascular Imaging': 2.255,
    'British Journal of Dermatology': 2.254,
    'Communications Biology': 2.251,
    'Environmental Health Perspectives': 2.251,
    'Biostatistics': 2.248,
    'Resuscitation': 2.242,
    'Clinical Epidemiology': 2.237,
    'Journal of Cardiovascular Magnetic Resonance': 2.233,
    'npj Breast Cancer': 2.209,
    'JCO Precision Oncology': 2.208,
    'BJOG: An International Journal of Obstetrics and Gynaecology': 2.203,
    'ESMO Open': 2.196,
    'Current Opinion in Microbiology': 2.195,
    'Biosensors and Bioelectronics': 2.194,
    'Cell Death and Disease': 2.188,
    'Journal of Consulting and Clinical Psychology': 2.188,
    'Brain Stimulation': 2.184,
    'American Journal of Preventive Medicine': 2.181,
    'ACS applied materials &amp; interfaces': 2.178,
    'Addiction': 2.177,
    'Journal of Adolescent Health': 2.175,
    'Lancet Regional Health - Western Pacific, The': 2.168,
    'Journal of Cachexia, Sarcopenia and Muscle': 2.159,
    'Rhinology': 2.159,
    'American Heart Journal': 2.155,
    'Journal of Clinical Immunology': 2.151,
    'Bone and Joint Journal': 2.149,
    'Translational Psychiatry': 2.148,
    'Cell Reports Methods': 2.145,
    'Comprehensive Physiology': 2.14,
    'Molecular and Cellular Proteomics': 2.14,
    'Psychiatry Research': 2.139,
    "npj Parkinson's Disease": 2.138,
    'Child Development': 2.135,
    'Photoacoustics': 2.134,
    'Virology: Research and Treatment': 2.13,
    'Environmental Research Letters': 2.119,
    'Gastric Cancer': 2.118,
    'Environmental Pollution': 2.11,
    'Cold Spring Harbor Perspectives in Medicine': 2.109,
    'Neuroscientist': 2.109,
    'British Journal of Surgery': 2.108,
    'Cancer and Metastasis Reviews': 2.107,
    'Journal of Epidemiology and Community Health': 2.105,
    'European Journal of Nuclear Medicine and Molecular Imaging': 2.1,
    'Drugs': 2.093,
    'npj Genomic Medicine': 2.093,
    'European heart journal. Quality of care &amp; clinical outcomes': 2.09,
    'International Journal of Radiation Oncology Biology Physics': 2.09,
    'Cancer Letters': 2.088,
    'Journal of Health Economics': 2.083,
    'Journal of Stroke': 2.079,
    'Journal of the American Heart Association': 2.079,
    'Journal of Urban Health': 2.076,
    'American Journal of Clinical Dermatology': 2.072,
    'Conservation Biology': 2.071,
    'American Journal of Surgical Pathology': 2.064,
    'Brain Pathology': 2.058,
    'Mayo Clinic Proceedings': 2.056,
    'Journal of the American Geriatrics Society': 2.054,
    'Diabetes, Obesity and Metabolism': 2.048,
    'Gastrointestinal Endoscopy': 2.046,
    'Journal of Gastroenterology': 2.046,
    'Health systems in transition': 2.044,
    'Military Medical Research': 2.042,
    'Journal of Sport and Health Science': 2.041,
    'Health Reports': 2.04,
    'Journal of Nuclear Medicine': 2.037,
    'Journal of Abnormal Psychology': 2.031,
    'Journal of Arthroplasty': 2.028,
    'Journal of Vascular Surgery': 2.027,
    'RMD Open': 2.024,
    'Journal of Experimental Psychology: General': 2.023,
    'Basic Research in Cardiology': 2.022,
    'Frontiers in Immunology': 2.022,
    'American Journal of Geriatric Psychiatry': 2.021,
    'Clinical and Molecular Hepatology': 2.014,
    'Clinical Epigenetics': 2.014,
    'Journal of NeuroInterventional Surgery': 2.01,
    'Arthroscopy - Journal of Arthroscopic and Related Surgery': 2.006,
    'International Journal of Infectious Diseases': 2.006,
    'Journal of Clinical Virology': 2.006,
    'OncoImmunology': 2.003,
    'Biological Psychiatry: Cognitive Neuroscience and Neuroimaging': 1.997,
    'Journal of Bone and Joint Surgery': 1.996,
    'European Psychiatry': 1.993,
    'Journal of Medical Internet Research': 1.992,
    'Journal of Affective Disorders': 1.988,
    'International Journal of Social Psychiatry': 1.984,
    'Ocular Surface': 1.983,
    'Human Genetics': 1.98,
    'Health Services Research': 1.979,
    'Social Science and Medicine': 1.978,
    'Current Protocols in Human Genetics': 1.975,
    'npj Regenerative Medicine': 1.967,
    'Epilepsia': 1.966,
    'American Journal of Clinical Nutrition': 1.96,
    'Epidemiology': 1.96,
    'Nature Reviews Urology': 1.96,
    'Journal of the American Society of Echocardiography': 1.959,
    'Journal of Medical Virology': 1.957,
    'Accident Analysis and Prevention': 1.956,
    'Gerontologist, The': 1.954,
    'International Journal of Stroke': 1.952,
    'Journal of Urology': 1.945,
    'Inflammation and Regeneration': 1.938,
    'Travel Medicine and Infectious Disease': 1.932,
    'Liver International': 1.929,
    'Journal of Cerebral Blood Flow and Metabolism': 1.925,
    'Cancer Epidemiology Biomarkers and Prevention': 1.923,
    'Human Genetics and Genomics Advances': 1.92,
    'Journal of Behavioral Addictions': 1.918,
    'Clinical and Translational Immunology': 1.908,
    'Experimental Hematology and Oncology': 1.908,
    'Journal of Molecular Cell Biology': 1.904,
    'Asian Journal of Social Health and Behavior': 1.9,
    'Europace': 1.899,
    'Proceedings of the Royal Society B: Biological Sciences': 1.898,
    'American Journal of Ophthalmology': 1.895,
    'Molecular Cancer Therapeutics': 1.89,
    'Annals of Clinical and Translational Neurology': 1.885,
    'Heart': 1.877,
    'General Hospital Psychiatry': 1.876,
    'Journal of Thoracic and Cardiovascular Surgery': 1.876,
    'Comprehensive Psychiatry': 1.869,
    'Diabetes/Metabolism Research and Reviews': 1.863,
    'Osteoarthritis and Cartilage': 1.863,
    'Critical Reviews in Food Science and Nutrition': 1.862,
    'Archives of Disease in Childhood: Fetal and Neonatal Edition': 1.861,
    'Oncologist': 1.859,
    'Translational Research': 1.857,
    'Critical Reviews in Clinical Laboratory Sciences': 1.851,
    'Diabetes and Metabolism': 1.849,
    "Alzheimer's and Dementia: Diagnosis, Assessment and Disease Monitoring": 1.847,
    'Preventive Medicine': 1.837,
    'JAMA Otolaryngology - Head and Neck Surgery': 1.835,
    'Reviews in Endocrine and Metabolic Disorders': 1.835,
    'Inflammatory Bowel Diseases': 1.834,
    'Radiotherapy and Oncology': 1.834,
    'Annals of Epidemiology': 1.833,
    'Journal of the International AIDS Society': 1.833,
    'Human Molecular Genetics': 1.831,
    'Obesity': 1.826,
    'Endoscopy': 1.822,
    'Australian and New Zealand Journal of Psychiatry': 1.821,
    'Current Opinion in Psychiatry': 1.82,
    'Journal of Allergy and Clinical Immunology: In Practice': 1.816,
    'Journal of Medical Genetics': 1.816,
    'Journal of General Internal Medicine': 1.814,
    'Transplantation and Cellular Therapy': 1.814,
    'Trends in Cardiovascular Medicine': 1.812,
    'American Journal of Epidemiology': 1.808,
    'American Journal of Respiratory Cell and Molecular Biology': 1.805,
    'Particle and Fibre Toxicology': 1.805,
    "Alzheimer's and Dementia: Translational Research and Clinical Interventions": 1.8,
    'Current Opinion in Behavioral Sciences': 1.799,
    'European Child and Adolescent Psychiatry': 1.799,
    'Biomaterials Research': 1.796,
    'BMJ Quality and Safety': 1.795,
    'Journal of the American Medical Directors Association': 1.794,
    'Current Environmental Health Reports': 1.791,
    'Knee Surgery, Sports Traumatology, Arthroscopy': 1.789,
    'Behaviour Research and Therapy': 1.787,
    'Database : the journal of biological databases and curation': 1.786,
    'Neuropathology and Applied Neurobiology': 1.785,
    'SSM - Population Health': 1.785,
    'Public Health Reviews': 1.78,
    'BMC Medical Research Methodology': 1.778,
    'Molecular Oncology': 1.778,
    'Journal of Clinical Endocrinology and Metabolism': 1.776,
    'Ambio': 1.769,
    'European Journal of Psychotraumatology': 1.768,
    'European Journal of Immunology': 1.766,
    'European Journal of Human Genetics': 1.764,
    'Canadian Journal of Psychiatry': 1.758,
    'Psychological Assessment': 1.757,
    'International Journal of Consumer Studies': 1.753,
    'Journal of Rural Health': 1.753,
    'Respiratory Research': 1.753,
    'Social Psychiatry and Psychiatric Epidemiology': 1.75,
    'mAbs': 1.748,
    'Journal of Cystic Fibrosis': 1.747,
    'Age and Ageing': 1.738,
    'Current Protocols in Molecular Biology': 1.738,
    'Seminars in Liver Disease': 1.736,
    'Stroke and Vascular Neurology': 1.735,
    'Medicine and Science in Sports and Exercise': 1.734,
    'British Journal of Ophthalmology': 1.733,
    'European Radiology': 1.733,
    'Contraception': 1.728,
    'Therapeutic Advances in Neurological Disorders': 1.728,
    'Chemosphere': 1.727,
    'Current HIV/AIDS Reports': 1.726,
    'Hepatology Communications': 1.722,
    'Child Abuse and Neglect': 1.721,
    'Rheumatology': 1.721,
    'Arthritis Care and Research': 1.718,
    'CMAJ': 1.716,
    'Dialogues in Clinical Neuroscience': 1.714,
    'Trends in Parasitology': 1.713,
    'World Journal of Emergency Surgery': 1.712,
    'Human Reproduction': 1.711,
    'Wiley Interdisciplinary Reviews: Systems Biology and Medicine': 1.71,
    'Zhonghua fu chan ke za zhi': 1.707,
    'Anesthesiology': 1.706,
    'Antioxidants and Redox Signaling': 1.706,
    'Clinical and Translational Medicine': 1.706,
    'Journal of Bone and Mineral Research': 1.705,
    'Journals of Gerontology - Series A Biological Sciences and Medical Sciences': 1.703,
    'Current Opinion in Neurology': 1.701,
    'Bipolar Disorders': 1.7,
    'Work, Aging and Retirement': 1.699,
    'Perspectives on Sexual and Reproductive Health': 1.698,
    'American journal of obstetrics &amp; gynecology MFM': 1.697,
    'Antibodies': 1.697,
    'Neuroepidemiology': 1.697,
    'Infectious Diseases of Poverty': 1.692,
    'Human Brain Mapping': 1.688,
    'Aging and Disease': 1.682,
    'Cell Proliferation': 1.681,
    'ImmunoTargets and Therapy': 1.681,
    'Journal of Environmental Management': 1.678,
    'Acta Pharmacologica Sinica': 1.677,
    'Prostate Cancer and Prostatic Diseases': 1.676,
    'Gynecologic Oncology': 1.674,
    'Foot and Ankle International': 1.672,
    'IEEE Journal of Biomedical and Health Informatics': 1.672,
    'Emotion': 1.67,
    'Molecular Cancer Research': 1.668,
    'Journal of Shoulder and Elbow Surgery': 1.666,
    'United European Gastroenterology Journal': 1.664,
    'Cancer Immunology, Immunotherapy': 1.663,
    'Exercise Immunology Review': 1.659,
    'Clinical Nutrition': 1.658,
    'Fluids and Barriers of the CNS': 1.657,
    'Vaccines': 1.655,
    'Health Education and Behavior': 1.65,
    'Antibody Therapeutics': 1.648,
    'Molecular Autism': 1.647,
    'BioDrugs': 1.646,
    'LGBT Health': 1.644,
    'Clinical Pharmacology and Therapeutics': 1.643,
    'European Heart Journal - Cardiovascular Pharmacotherapy': 1.641,
    'Pulmonology': 1.641,
    'Acta Biomaterialia': 1.638,
    'AIDS Patient Care and STDs': 1.638,
    'Critical Reviews in Biotechnology': 1.637,
    'Harvard Review of Psychiatry': 1.637,
    'Journal of Immunology': 1.636,
    'Disability and Health Journal': 1.635,
    'Diabetes Research and Clinical Practice': 1.634,
    'Medical Education': 1.629,
    'British Journal of Haematology': 1.628,
    'Radiology: Cardiothoracic Imaging': 1.628,
    'Free Radical Biology and Medicine': 1.625,
    'EuroIntervention': 1.624,
    'Food Chemistry': 1.624,
    'BJPsych Open': 1.622,
    'Journal of Leukocyte Biology': 1.622,
    'Ophthalmology Retina': 1.622,
    'Journal of the American Academy of Dermatology': 1.621,
    "journal of prevention of Alzheimer's disease, The": 1.616,
    'Exercise and Sport Sciences Reviews': 1.613,
    'Acta Orthopaedica': 1.612,
    'Schizophrenia': 1.61,
    'Lung Cancer': 1.609,
    'Thyroid': 1.609,
    'Bone and Joint Research': 1.607,
    'Multivariate Behavioral Research': 1.606,
    'JCO clinical cancer informatics': 1.602,
    'National Health Statistics Reports': 1.602,
    'Social Cognitive and Affective Neuroscience': 1.602,
    'Immunology': 1.601,
    'American Journal of Pathology': 1.6,
    'Histopathology': 1.596,
    'Survey of Ophthalmology': 1.595,
    'BMJ Open Respiratory Research': 1.594,
    'Seminars in Arthritis and Rheumatism': 1.594,
    'British Medical Bulletin': 1.593,
    'Journal of Headache and Pain': 1.593,
    'Arthritis Research and Therapy': 1.592,
    'European Review of Aging and Physical Activity': 1.591,
    'Wiley Interdisciplinary Reviews: Nanomedicine and Nanobiotechnology': 1.588,
    'European Journal of Preventive Cardiology': 1.582,
    'Sleep': 1.58,
    'Academic Medicine': 1.579,
    'Fertility and Sterility': 1.579,
    'Hepatology International': 1.577,
    'Frontiers of Hormone Research': 1.574,
    'American Journal of Physiology - Endocrinology and Metabolism': 1.572,
    'Brain Communications': 1.57,
    'GeroScience': 1.57,
    'Thrombosis and Haemostasis': 1.565,
    'Journal of Traumatic Stress': 1.564,
    'Justice Quarterly': 1.564,
    'Clinical Immunology': 1.563,
    'Drug and Alcohol Dependence': 1.562,
    'Spine Journal': 1.562,
    'Cephalalgia': 1.561,
    'Journal of Neurology': 1.556,
    'European Journal of Neurology': 1.554,
    'Journal of Psychiatric Research': 1.554,
    'European Urology Focus': 1.548,
    'International Journal for Equity in Health': 1.548,
    'Liver Transplantation': 1.548,
    'JMIR Public Health and Surveillance': 1.547,
    'Journal of the Pediatric Infectious Diseases Society': 1.547,
    'Journal of Translational Medicine': 1.544,
    'American Journal of Health Economics': 1.541,
    'Journal of the National Cancer Institute. Monographs': 1.541,
    'Human Mutation': 1.538,
    'Sports Medicine - Open': 1.538,
    'Clinical Chemistry': 1.536,
    'Biofabrication': 1.534,
    'Journal of the American College of Surgeons': 1.534,
    'Multiple Sclerosis Journal': 1.534,
    'Cancer Science': 1.533,
    'npj Aging and Mechanisms of Disease': 1.531,
    'Skeletal Muscle': 1.53,
    'Translational Stroke Research': 1.53,
    'European Heart Journal: Acute Cardiovascular Care': 1.527,
    'BMJ Open Science': 1.525,
    'Journal of Epidemiology and Global Health': 1.524,
    'Wiley Interdisciplinary Reviews: Cognitive Science': 1.523,
    'Genes and Diseases': 1.522,
    'Neurobiology of Aging': 1.521,
    'Nutrition Reviews': 1.521,
    'Nucleus': 1.516,
    'Therapeutic Advances in Medical Oncology': 1.514,
    'JMIR mHealth and uHealth': 1.513,
    'Nicotine and Tobacco Research': 1.512,
    'Critical Reviews in Oncology/Hematology': 1.51,
    'Journal of the European Academy of Dermatology and Venereology': 1.51,
    'Vaccine: X': 1.509,
    'Current Opinion in Environmental Science and Health': 1.508,
    'Journal of Cardiac Failure': 1.507,
    'Current Neurology and Neuroscience Reports': 1.506,
    'Progress in Cardiovascular Diseases': 1.506,
    'International Journal of Drug Policy': 1.504,
    'Contraception: X': 1.503,
    'European Journal of Endocrinology': 1.502,
    'Journal of Substance Abuse Treatment': 1.502,
    'Radiographics': 1.502,
    'Diabetes and Metabolism Journal': 1.5,
    'Journal of Health Politics, Policy and Law': 1.498,
    'Open Forum Infectious Diseases': 1.498,
    'Stem Cell Research and Therapy': 1.498,
    'Diabetes and Metabolic Syndrome: Clinical Research and Reviews': 1.495,
    'General Psychiatry': 1.495,
    'Journal of Community Health': 1.494,
    'Vaccine': 1.493,
    'Adolescent Research Review': 1.491,
    'International Journal of Neural Systems': 1.491,
    'Journal of Addiction Medicine': 1.491,
    'British Journal of Mathematical and Statistical Psychology': 1.49,
    'Current Opinion in Rheumatology': 1.489,
    'DMM Disease Models and Mechanisms': 1.489,
    'Pathogens and Immunity': 1.489,
    'Infection': 1.488,
    'Thrombosis Research': 1.487,
    'BJU International': 1.486,
    'CNS Drugs': 1.485,
    'Canadian Journal of Cardiology': 1.482,
    'Emerging Contaminants': 1.478,
    'International Forum of Allergy and Rhinology': 1.478,
    'Clinical Neuropsychologist': 1.477,
    'Cochrane Database of Systematic Reviews': 1.476,
    'Biology of Sport': 1.475,
    'Critical care and resuscitation : journal of the Australasian Academy of Critical Care Medicine.': 1.475,
    'Evolutionary Anthropology': 1.474,
    'ACS Pharmacology and Translational Science': 1.473,
    'Journal of Clinical Psychiatry': 1.473,
    'Statistics in Medicine': 1.471,
    'International Journal of Hygiene and Environmental Health': 1.47,
    'International Journal of Eating Disorders': 1.468,
    'Research on Child and Adolescent Psychopathology': 1.468,
    'Current Atherosclerosis Reports': 1.467,
    'Exposure and Health': 1.467,
    'JCO Global Oncology': 1.467,
    'American Journal of Physiology - Lung Cellular and Molecular Physiology': 1.466,
    'Cancer Biology and Medicine': 1.466,
    'Cyberpsychology, Behavior, and Social Networking': 1.466,
    'Preventing chronic disease': 1.464,
    'Seminars in Oncology': 1.464,
    'Academic Emergency Medicine': 1.462,
    'Journal of Innate Immunity': 1.462,
    'Clinical Research in Cardiology': 1.461,
    'Neurotherapeutics': 1.461,
    'Frontiers of Medicine': 1.46,
    'Nephrology Dialysis Transplantation': 1.46,
    'Targeted Oncology': 1.46,
    'Journal of Inherited Metabolic Disease': 1.459,
    'Digestive Endoscopy': 1.458,
    'Digital Biomarkers': 1.458,
    'Anatomical Sciences Education': 1.456,
    'Infectious Disease Clinics of North America': 1.453,
    'Journal of racial and ethnic health disparities': 1.453,
    'Journal of Intensive Care': 1.452,
    'Neurogastroenterology and Motility': 1.451,
    'Advances in Anatomic Pathology': 1.45,
    'Journal of Microbiology, Immunology and Infection': 1.45,
    'Intestinal Research': 1.449,
    'Epidemics': 1.448,
    'Health and Place': 1.448,
    'Apoptosis : an international journal on programmed cell death': 1.447,
    'Journal of Migration and Health': 1.445,
    'Artificial Intelligence in Medicine': 1.443,
    'Breast Cancer Research and Treatment': 1.442,
    'European Neuropsychopharmacology': 1.442,
    'Circulation: Cardiovascular Imaging': 1.44,
    "Archives of Women's Mental Health": 1.439,
    'International Journal of Obesity': 1.438,
    'Journal of Investigative Dermatology': 1.435,
    'Best Practice and Research in Clinical Endocrinology and Metabolism': 1.432,
    'BMJ Open Diabetes Research and Care': 1.43,
    'Scandinavian Journal of Work, Environment and Health': 1.43,
    'Safety Science': 1.429,
    'Schizophrenia Research': 1.429,
    'Journal of Athletic Training': 1.427,
    'Palliative Medicine': 1.425,
    'Annals of Behavioral Medicine': 1.424,
    'Journal of Nanobiotechnology': 1.421,
    'Pediatric Critical Care Medicine': 1.42,
    'Prevention Science': 1.42,
    'Bone and Joint Open': 1.419,
    'Antimicrobial Agents and Chemotherapy': 1.415,
    'Journal of Exposure Science and Environmental Epidemiology': 1.415,
    'Harm Reduction Journal': 1.414,
    'International Journal of Bipolar Disorders': 1.414,
    'Value in Health': 1.414,
    'American Journal of Physiology - Renal Physiology': 1.413,
    'Immunology and Cell Biology': 1.411,
    'JMIR Mental Health': 1.409,
    'Cellular oncology (Dordrecht)': 1.408,
    'Epigenetics': 1.408,
    'ACS Synthetic Biology': 1.406,
    'International Journal of Neuropsychopharmacology': 1.406,
    'Journal of Hospital Infection': 1.406,
    'Psychiatric Services': 1.405,
    'Theoretical And Applied Genetics': 1.403,
    'Allergology International': 1.402,
    'Computerized Medical Imaging and Graphics': 1.402,
    'Health Psychology': 1.402,
    'Child and Adolescent Psychiatry and Mental Health': 1.399,
    'Journal of Immunotherapy': 1.397,
    'Journal of Orthopaedic and Sports Physical Therapy': 1.396,
    'NeuroImage: Clinical': 1.395,
    'Borderline Personality Disorder and Emotion Dysregulation': 1.394,
    'Medical Clinics of North America': 1.394,
    'Society and Mental Health': 1.394,
    'AIDS and Behavior': 1.392,
    'Forensic Science International: Genetics': 1.392,
    'Journal of Environmental Sciences': 1.392,
    'Frontiers in Cardiovascular Medicine': 1.391,
    'Psychoneuroendocrinology': 1.391,
    'Stem cells translational medicine': 1.39,
    'PharmacoEconomics': 1.389,
    'Psychiatry and Clinical Neurosciences': 1.389,
    'Seminars in Radiation Oncology': 1.389,
    'Critical Reviews in Microbiology': 1.388,
    'Current Diabetes Reports': 1.387,
    'FASEB Journal': 1.386,
    'Current Protocols in Cytometry': 1.385,
    'JBJS Open Access': 1.385,
    'American Journal of Physiology - Gastrointestinal and Liver Physiology': 1.383,
    'Infectious Disease Modelling': 1.383,
    'International Immunology': 1.383,
    'Journal of Antimicrobial Chemotherapy': 1.382,
    'CNS Neuroscience and Therapeutics': 1.381,
    'Journal of Neurodevelopmental Disorders': 1.38,
    'Chemical Record': 1.379,
    'Journal of Magnetic Resonance Imaging': 1.378,
    'Atherosclerosis': 1.375,
    'Journal of Counseling Psychology': 1.373,
    'Transplantation': 1.372,
    'Clinical Colorectal Cancer': 1.371,
    'Medical Journal of Australia': 1.371,
    'Plant and Cell Physiology': 1.37,
    'ACS Chemical Biology': 1.369,
    'Immune Network': 1.369,
    'Amyloid : the international journal of experimental and clinical investigation : the official journal of the International Society of Amyloidosis': 1.368,
    'Global Health Research and Policy': 1.368,
    'Influenza and other Respiratory Viruses': 1.368,
    'Journal of Cancer Survivorship': 1.368,
    'Antimicrobial Resistance and Infection Control': 1.367,
    'Biomedicine and Pharmacotherapy': 1.366,
    'Anesthesia and Analgesia': 1.365,
    'Journal of Pain': 1.363,
    'Aggression and Violent Behavior': 1.36,
    'Clinical and Translational Gastroenterology': 1.359,
    'International Journal of Methods in Psychiatric Research': 1.357,
    'European Archives of Psychiatry and Clinical Neuroscience': 1.356,
    'Clinical Science': 1.353,
    'Health Research Policy and Systems': 1.353,
    'Plastic and Reconstructive Surgery': 1.353,
    'Xenotransplantation': 1.351,
    'Ultrasonics Sonochemistry': 1.35,
    'JCO Oncology Practice': 1.349,
    'Magnetic Resonance in Medicine': 1.349,
    'Biometrics': 1.348,
    'Ecotoxicology and Environmental Safety': 1.348,
    'Korean Journal of Radiology': 1.348,
    'Otolaryngology - Head and Neck Surgery': 1.348,
    'Psychology and Aging': 1.348,
    'Stanford Law Review': 1.348,
    'Wellcome Open Research': 1.348,
    'Psychophysiology': 1.345,
    'BMJ Evidence-Based Medicine': 1.344,
    'Journal of the Royal Society of Medicine': 1.344,
    'Pediatric Diabetes': 1.343,
    'Cancer Research and Treatment': 1.341,
    'European Journal of Vascular and Endovascular Surgery': 1.341,
    'Therapeutic Advances in Infectious Disease': 1.341,
    'Paediatric Respiratory Reviews': 1.34,
    'Molecular Therapy - Oncolytics': 1.339,
    'Stem Cells': 1.339,
    'Food Packaging and Shelf Life': 1.338,
    'Anxiety, Stress and Coping': 1.336,
    'International Journal of Stress Management': 1.336,
    'Respiratory Medicine': 1.334,
    'International Journal of Sports Physiology and Performance': 1.333,
    'Kidney International Supplements': 1.333,
    'Behavioral Medicine': 1.331,
    'Scandinavian Journal of Medicine and Science in Sports': 1.331,
    'Addictive Behaviors': 1.328,
    'Sports Health': 1.328,
    'Bone Marrow Transplantation': 1.327,
    'European Journal of Cell Biology': 1.327,
    'Asian Journal of Psychiatry': 1.326,
    'EFORT Open Reviews': 1.325,
    "Journal of Parkinson's Disease": 1.325,
    'Journal of Science and Medicine in Sport': 1.324,
    'Oxidative Medicine and Cellular Longevity': 1.324,
    'Annals of Family Medicine': 1.323,
    'Autism Research': 1.323,
    'Journal of the American Academy of Orthopaedic Surgeons, The': 1.323,
    'American Journal of Community Psychology': 1.32,
    'Research and Practice in Thrombosis and Haemostasis': 1.32,
    'Current Rheumatology Reports': 1.319,
    'Injury Prevention': 1.317,
    'Development and Psychopathology': 1.315,
    'Current Oncology Reports': 1.314,
    'Environmental Health: A Global Access Science Source': 1.313,
    'Cancers': 1.312,
    'Journal of Molecular and Cellular Cardiology': 1.312,
    'Interdisciplinary Topics in Gerontology and Geriatrics': 1.311,
    'Journal of Neurotrauma': 1.31,
    'eNeuro': 1.309,
    'Frontiers in Cellular and Infection Microbiology': 1.308,
    'Journal of NeuroImmune Pharmacology': 1.308,
    'American Journal of Industrial Medicine': 1.307,
    'BMC Public Health': 1.307,
    'Journal of Pain and Symptom Management': 1.306,
    'Neurorehabilitation and Neural Repair': 1.306,
    'Przeglad Gastroenterologiczny': 1.306,
    'ERJ Open Research': 1.304,
    'Cortex': 1.303,
    'Journal of Sleep Research': 1.302,
    'Regional Anesthesia and Pain Medicine': 1.302,
    'American Journal of Roentgenology': 1.301,
    'European Journal of Clinical Investigation': 1.3,
    'Journal of Strength and Conditioning Research': 1.3,
    'Statistical Methods in Medical Research': 1.3,
    'Environmental Sciences: Processes and Impacts': 1.299,
    'PLoS Neglected Tropical Diseases': 1.298,
    'International Journal of Antimicrobial Agents': 1.297,
    'Diagnostic and interventional imaging': 1.296,
    'MicroRNA  (Shariqah, United Arab Emirates)': 1.296,
    'Biological Research': 1.294,
    'Respirology': 1.294,
    'World Journal of Oncology': 1.294,
    'Clinical Autonomic Research': 1.293,
    'Journal of Adolescence': 1.293,
    'Medical Care': 1.293,
    'Expert Opinion on Therapeutic Patents': 1.292,
    'BMC Psychiatry': 1.291,
    'Global Public Health': 1.291,
    'Current Addiction Reports': 1.29,
    'Expert Opinion on Investigational Drugs': 1.29,
    'Helicobacter': 1.29,
    'Viruses': 1.29,
    'International Journal of Public Health': 1.289,
    'Journal of Pathology: Clinical Research': 1.288,
    'Family Medicine and Community Health': 1.286,
    'Journal of Global Health': 1.286,
    'American Journal of Physiology - Heart and Circulatory Physiology': 1.283,
    'Headache': 1.283,
    'Psychology and Psychotherapy: Theory, Research and Practice': 1.283,
    'Current Gastroenterology Reports': 1.28,
    'Ophthalmology. Glaucoma': 1.28,
    'Frontiers in Endocrinology': 1.278,
    'Journal of acquired immune deficiency syndromes (1999)': 1.278,
    'Journal of Endocrinology': 1.278,
    'Asia-Pacific Journal of Ophthalmology': 1.277,
    'Annals of Thoracic Surgery': 1.276,
    'Journal of Molecular Medicine': 1.276,
    'Ear and Hearing': 1.273,
    'Medical Care Research and Review': 1.272,
    'Journal of Personality Assessment': 1.271,
    'Oral Oncology': 1.271,
    'Expert Review of Anti-Infective Therapy': 1.27,
    'Breast': 1.269,
    'Journal of Nutrition, Health and Aging': 1.269,
    'Systematic Reviews': 1.269,
    'Archives of Pathology and Laboratory Medicine': 1.268,
    'Health Policy and Planning': 1.268,
    'International Journal of Mental Health and Addiction': 1.267,
    'Clinical Chemistry and Laboratory Medicine': 1.266,
    'Mental Health and Physical Activity': 1.266,
    'Brain, Behavior, and Immunity - Health': 1.264,
    'Journal of Cranio-Maxillo-Facial Surgery': 1.263,
    'Medical Decision Making': 1.262,
    'AIDS': 1.26,
    'Attachment and Human Development': 1.26,
    'IEEE Transactions on Neural Systems and Rehabilitation Engineering': 1.26,
    'Investigative Ophthalmology and Visual Science': 1.259,
    'Perspectives on Medical Education': 1.259,
    'Pain Reports': 1.258,
    'Microbial genomics': 1.257,
    'Journal of Clinical Lipidology': 1.256,
    'Nonlinear Analysis: Real World Applications': 1.256,
    'Endocrine-Related Cancer': 1.255,
    'Cytometry. Part A : the journal of the International Society for Analytical Cytology': 1.254,
    'JBI evidence implementation': 1.254,
    "Journal of Women's Health": 1.253,
    'International Psychogeriatrics': 1.25,
    'Public Health Reports': 1.25,
    'BMJ Open Sport and Exercise Medicine': 1.249,
    'Heart Failure Reviews': 1.249,
    'Hepatology Research': 1.249,
    'Plant Science': 1.249,
    'Scandinavian Journal of Public Health': 1.247,
    'Diabetic Medicine': 1.246,
    'Journal of Molecular Diagnostics': 1.245,
    'Molecular Medicine': 1.245,
    'Current Protocols': 1.244,
    'Medicine in Drug Discovery': 1.244,
    'Journal of clinical and experimental hematopathology : JCEH': 1.243,
    'Rheumatology and Therapy': 1.242,
    'Best Practice and Research in Clinical Rheumatology': 1.241,
    'Clinical Genetics': 1.241,
    'Policy and Internet': 1.24,
    'Clinical Pharmacokinetics': 1.238,
    'Malaria Journal': 1.237,
    'Sleep Medicine': 1.237,
    'Telemedicine Journal and e-Health': 1.237,
    'Current Opinion in HIV and AIDS': 1.236,
    'Science and Medicine in Football': 1.233,
    'Current Protocols in Neuroscience': 1.232,
    'International Journal of Geriatric Psychiatry': 1.232,
    'Journal of Rheumatology': 1.232,
    'Cancer cytopathology': 1.231,
    'Cancer Imaging': 1.231,
    'Public Health': 1.231,
    'International Journal of Mental Health Nursing': 1.23,
    'Acta Obstetricia et Gynecologica Scandinavica': 1.227,
    'GeoHealth': 1.227,
    'Therapeutic Advances in Gastroenterology': 1.226,
    'BMC Clinical Pathology': 1.224,
    'Eye and Vision': 1.223,
    'Journal of Telemedicine and Telecare': 1.223,
    'Computers in Biology and Medicine': 1.222,
    'Frontiers in Psychiatry': 1.222,
    'Qualitative Health Research': 1.222,
    'Current Hypertension Reports': 1.221,
    'Neurosurgery': 1.221,
    'Gerontology': 1.22,
    'Journal of Psychiatry and Neuroscience': 1.22,
    'American Journal of Bioethics': 1.219,
    'Journal of Sport Management': 1.219,
    'Surgery for Obesity and Related Diseases': 1.219,
    'Laboratory Investigation': 1.218,
    'Nutrition Research Reviews': 1.218,
    'Medical Teacher': 1.217,
    'Journal of Quantitative Criminology': 1.213,
    'Clinical Neurophysiology': 1.212,
    'Indoor Air': 1.211,
    'Journal of Materials Chemistry B': 1.211,
    'Clinical Psychology and Psychotherapy': 1.209,
    'Health Technology Assessment': 1.207,
    'Life Sciences': 1.207,
    'American Health and Drug Benefits': 1.206,
    'Retina': 1.206,
    'Revista de Psiquiatria y Salud Mental': 1.204,
    'Human Resources for Health': 1.203,
    'Pediatric obesity': 1.202,
    'Conflict and Health': 1.201,
    'Personality Disorders: Theory, Research, and Treatment': 1.201,
    'Advances in Health Sciences Education': 1.2,
    'Aesthetic Surgery Journal': 1.2,
    'Clinical and Experimental Immunology': 1.2,
    'Advanced Structural and Chemical Imaging': 1.199,
    'Quality of Life Research': 1.198,
    'International Journal of Medical Informatics': 1.197,
    'Spine': 1.197,
    'Journal of Chemical Physics': 1.196,
    'Stress and Health': 1.196,
    'Diabetology and Metabolic Syndrome': 1.195,
    'Journal of Hypertension': 1.194,
    'Journal of Neurosurgery: Spine': 1.192,
    'Best Practice and Research in Clinical Obstetrics and Gynaecology': 1.19,
    'Endocrinology and Metabolism Clinics of North America': 1.19,
    'Frontiers in Microbiology': 1.19,
    'Psychiatric Quarterly': 1.19,
    'Seminars in Thrombosis and Hemostasis': 1.19,
    'Journal of Cardiovascular Computed Tomography': 1.189,
    'Archives of Disease in Childhood': 1.188,
    'Cancer Medicine': 1.188,
    'Neurosurgical Focus': 1.188,
    'Journal of Public Health': 1.187,
    'Clinical Orthopaedics and Related Research': 1.186,
    'Cytotherapy': 1.186,
    'Sexually Transmitted Infections': 1.185,
    'European Journal of Cardio-thoracic Surgery': 1.183,
    'Journal of Infection and Public Health': 1.183,
    'Knee Surgery and Related Research': 1.183,
    'Temperature': 1.183,
    'Current Reviews in Musculoskeletal Medicine': 1.182,
    'Experimental Hematology': 1.182,
    'Molecules and Cells': 1.182,
    'ACR Open Rheumatology': 1.18,
    'Clinical Neurosurgery': 1.18,
    'International Journal for Parasitology': 1.179,
    'Transfusion Medicine Reviews': 1.179,
    'Journal of Neuro-Oncology': 1.178,
    'Plant Cell Reports': 1.178,
    'Biologics: Targets and Therapy': 1.176,
    'Eye': 1.176,
    'EJNMMI Physics': 1.174,
    'Journal of the National Medical Association': 1.173,
    'Kidney International Reports': 1.173,
    'Lung': 1.173,
    'Diagnosis': 1.172,
    'Hematology. American Society of Hematology. Education Program': 1.172,
    'JMIR Aging': 1.172,
    'European Journal of Clinical Microbiology and Infectious Diseases': 1.171,
    'Intelligence-Based Medicine': 1.171,
    'Journal of Veterinary Internal Medicine': 1.171,
    'Radiologia Medica': 1.171,
    'Current Allergy and Asthma Reports': 1.169,
    'Brain Structure and Function': 1.168,
    'Current Neuropharmacology': 1.168,
    'American Journal of Neuroradiology': 1.167,
    'Angle Orthodontist': 1.167,
    'Addiction science &amp; clinical practice': 1.166,
    'Clinical and Experimental Allergy': 1.166,
    'Infectious Diseases and Therapy': 1.166,
    'Pediatric Clinics of North America': 1.166,
    'Journal of Healthcare Informatics Research': 1.163,
    'Archives of Toxicology': 1.162,
    'British Journal of Clinical Psychology': 1.162,
    'Journal of the Academy of Nutrition and Dietetics': 1.162,
    'Aging and Mental Health': 1.16,
    'Maturitas': 1.16,
    'Surgery': 1.16,
    'Pancreatology': 1.159,
    'Transplantation Reviews': 1.158,
    'World Journal of Gastroenterology': 1.158,
    'Journal of Epidemiology': 1.157,
    'International Journal of Health Policy and Management': 1.155,
    'International Journal of Molecular Sciences': 1.154,
    'Journal of Prosthetic Dentistry': 1.154,
    'Zeitschrift fur Medizinische Physik': 1.154,
    'ACS Infectious Diseases': 1.153,
    'Journal of Cardiopulmonary Rehabilitation and Prevention': 1.153,
    'Physical Education and Sport Pedagogy': 1.15,
    'Translational Oncology': 1.15,
    'BJS open': 1.149,
    'Brain Topography': 1.149,
    'Occupational and Environmental Medicine': 1.149,
    'Chronic Stress': 1.148,
    'Developmental Medicine and Child Neurology': 1.146,
    'Epidemiologic Reviews': 1.146,
    'Infectious Diseases': 1.146,
    "Journal of Alzheimer's Disease": 1.146,
    'BMC International Health and Human Rights': 1.145,
    'British Journal of General Practice': 1.145,
    'Cancer Cell International': 1.145,
    'Haemophilia': 1.145,
    'HemaSphere': 1.145,
    'Paediatric and Perinatal Epidemiology': 1.145,
    'Teaching and Learning in Medicine': 1.145,
    'European Journal of Medicinal Chemistry': 1.143,
    'Frontiers in Neuroanatomy': 1.143,
    'International Journal of Health Geographics': 1.142,
    'Journal of Otolaryngology - Head and Neck Surgery': 1.142,
    'Traumatology': 1.142,
    'Journal of Behavioral Medicine': 1.141,
    'Journal of Sports Sciences': 1.141,
    'Physiologia Plantarum': 1.141,
    'Veterinary Quarterly': 1.141,
    'Child Psychiatry and Human Development': 1.14,
    'BioFactors': 1.139,
    'Frontiers in Oncology': 1.138,
    'International Journal of Mental Health Systems': 1.138,
    'Journal of Neurosurgery': 1.138,
    'BMC Cancer': 1.137,
    'Clinical Lung Cancer': 1.137,
    'Chronic Respiratory Disease': 1.136,
    'Clinics in Chest Medicine': 1.136,
    'Expert Review of Clinical Immunology': 1.136,
    'Journal of Nutritional Biochemistry': 1.136,
    'Nutrition Journal': 1.136,
    'Academic Pediatrics': 1.135,
    'Depression Research and Treatment': 1.134,
    'International Journal of Gynecological Cancer': 1.134,
    'Journal of NeuroEngineering and Rehabilitation': 1.134,
    'American Journal of Nephrology': 1.133,
    'Annals of Surgical Oncology': 1.133,
    'Esophagus': 1.133,
    'American Journal of Cardiology': 1.132,
    'Current Opinion in Ophthalmology': 1.132,
    'Plant Molecular Biology': 1.132,
    'Acta Diabetologica': 1.131,
    'Journal of Psychosomatic Research': 1.131,
    'International Journal of Transgender Health': 1.13,
    'Transgender Health': 1.13,
    'Sport, Education and Society': 1.129,
    'Acta Ophthalmologica': 1.128,
    'Tropical Diseases, Travel Medicine and Vaccines': 1.128,
    'BMC Geriatrics': 1.127,
    'Bone': 1.127,
    'Obesity Facts': 1.127,
    'Obesity Surgery': 1.127,
    'Pflugers Archiv European Journal of Physiology': 1.127,
    'Phytomedicine': 1.126,
    'Cancer Prevention Research': 1.125,
    'Clinical and Experimental Metastasis': 1.125,
    'Frontiers in Public Health': 1.125,
    'Journal of Nutrition': 1.125,
    'Surgical Endoscopy and Other Interventional Techniques': 1.124,
    'Journal of Pediatric Psychology': 1.123,
    'Addictive Behaviors Reports': 1.122,
    'International Journal of Cardiology': 1.122,
    "Women's Health Issues": 1.122,
    'Genes and Immunity': 1.121,
    'Health Equity': 1.12,
    'Journal of Psychopharmacology': 1.12,
    'Orphanet Journal of Rare Diseases': 1.12,
    'CPT: Pharmacometrics and Systems Pharmacology': 1.119,
    'Health systems and reform': 1.119,
    'Journal of Aging and Health': 1.119,
    'Revmatologiia (Bulgaria)': 1.119,
    'American Journal of Infection Control': 1.118,
    'Computer Methods and Programs in Biomedicine': 1.118,
    'Current Treatment Options in Oncology': 1.118,
    'Journal of Dermatological Science': 1.118,
    'Amyotrophic Lateral Sclerosis and Frontotemporal Degeneration': 1.116,
    'International Journal of Oncology': 1.116,
    'Journal of Studies on Alcohol and Drugs': 1.115,
    'Nutrition and Diabetes': 1.115,
    'Scandinavian Journal of Surgery': 1.115,
    'BMJ Nutrition, Prevention and Health': 1.114,
    'European Addiction Research': 1.114,
    'Journal of Endovascular Therapy': 1.114,
    'Pathology': 1.114,
    'Virchows Archiv': 1.114,
    'Archives of Orthopaedic and Trauma Surgery': 1.113,
    'Cancer Epidemiology': 1.113,
    'Orthopaedic Journal of Sports Medicine': 1.113,
    'JBMR Plus': 1.112,
    'International Journal of Nanomedicine': 1.111,
    'Journal of Medical Ethics': 1.111,
    'Psycho-Oncology': 1.111,
    'Journal of Trauma and Acute Care Surgery': 1.11,
    'Neurocritical Care': 1.11,
    'American Journal of Medical Genetics, Part B: Neuropsychiatric Genetics': 1.109,
    'Current Opinion in Infectious Diseases': 1.108,
    'Fish and Shellfish Immunology': 1.108,
    'Journal of Experimental Psychology: Human Perception and Performance': 1.108,
    'American Journal of Orthopsychiatry': 1.107,
    'Neurology: Genetics': 1.106,
    'Drug Safety': 1.105,
    'Psychosomatic Medicine': 1.105,
    'Clinical and Translational Radiation Oncology': 1.104,
    'Virulence': 1.104,
    'European Physical Education Review': 1.103,
    'Food and Function': 1.103,
    'Laryngoscope': 1.103,
    'Therapeutic Advances in Musculoskeletal Disease': 1.102,
    'Veterinary Clinics of North America - Food Animal Practice': 1.102,
    'Clinical and Experimental Gastroenterology': 1.101,
    'International Immunopharmacology': 1.1,
    'Poultry Science': 1.1,
    'CONTINUUM Lifelong Learning in Neurology': 1.098,
    'Neonatology': 1.098,
    'Chronic Obstructive Pulmonary Diseases': 1.097,
    'European Journal of Surgical Oncology': 1.097,
    'Tumour Virus Research': 1.097,
    'World Allergy Organization Journal': 1.097,
    'CMAJ open': 1.096,
    'Microbiology spectrum': 1.095,
    'Remedial and Special Education': 1.095,
    'European Journal of Haematology': 1.094,
    'European Journal of Nutrition': 1.094,
    'Journal of Hand Surgery': 1.093,
    'Maternal and Child Nutrition': 1.093,
    'Reproductive Health': 1.093,
    'European Journal of Sport Science': 1.092,
    'Therapeutic Advances in Respiratory Disease': 1.092,
    'Virology Journal': 1.092,
    'Scandinavian Journal of Trauma, Resuscitation and Emergency Medicine': 1.091,
    'Briefings in Functional Genomics': 1.089,
    'Clinical Neuropsychiatry': 1.089,
    'Journal of Human Genetics': 1.089,
    'Annals of Clinical Microbiology and Antimicrobials': 1.088,
    'CNS oncology': 1.088,
    'Epidemiology and Infection': 1.087,
    'Journal of Mental Health': 1.087,
    'Clinica Chimica Acta': 1.085,
    'Current Protocols in Cell Biology': 1.085,
    'Journal of Critical Care': 1.085,
    'Clinical and Translational Science': 1.084,
    'Journal of Biomedical Informatics': 1.083,
    'Journal of Trauma and Dissociation': 1.083,
    'Matrix Biology Plus': 1.083,
    'Journal of Healthcare Leadership': 1.082,
    'Respiration': 1.082,
    'HPB': 1.081,
    'Orthopaedics and Traumatology: Surgery and Research': 1.081,
    'CKJ: Clinical Kidney Journal': 1.079,
    'Insights into Imaging': 1.079,
    'Neuroendocrinology': 1.079,
    'Advances in Wound Care': 1.078,
    'BMB Reports': 1.078,
    'Journal of Orthopaedic Translation': 1.077,
    'Advances in Therapy': 1.076,
    'Alcoholism: Clinical and Experimental Research': 1.076,
    'Journal of Heredity': 1.076,
    'Pediatric Allergy and Immunology': 1.075,
    'Heredity': 1.074,
    'European Journal of Clinical Nutrition': 1.073,
    'Medical Physics': 1.072,
    'Biomedical Signal Processing and Control': 1.071,
    'Carcinogenesis': 1.071,
    'Health Policy and Technology': 1.071,
    'Journal of Obesity and Metabolic Syndrome': 1.071,
    'Neuroscience Bulletin': 1.071,
    'European Journal of Pain': 1.07,
    'Journal of Evidence-Based Medicine': 1.07,
    'Medical Education Online': 1.07,
    'Seminars in Hematology': 1.07,
    'NMR in Biomedicine': 1.069,
    'American Journal of Drug and Alcohol Abuse': 1.068,
    'Andrology': 1.068,
    'Current Osteoporosis Reports': 1.068,
    'European Journal of Ageing': 1.067,
    'Journal of diabetes science and technology': 1.067,
    'Journal of Gastroenterology and Hepatology (Australia)': 1.067,
    'Medical Reference Services Quarterly': 1.067,
    'Pharmacoepidemiology and Drug Safety': 1.067,
    'Physics and Imaging in Radiation Oncology': 1.067,
    'Archives of Medical Research': 1.066,
    'Biomedical Journal': 1.066,
    'Human Pathology': 1.065,
    'Academic Radiology': 1.064,
    'Annals of Physical and Rehabilitation Medicine': 1.064,
    'Frontiers in Pharmacology': 1.064,
    'Journal of Health Communication': 1.064,
    'Pathobiology': 1.063,
    'Substance Abuse': 1.063,
    'Archives of Physical Medicine and Rehabilitation': 1.062,
    'Health Policy': 1.062,
    'Osteoporosis International': 1.062,
    'Ethnicity and Disease': 1.061,
    'Journal of Applied Gerontology': 1.061,
    'Journal of Spine Surgery': 1.061,
    'Review Journal of Autism and Developmental Disorders': 1.061,
    'Genetic Epidemiology': 1.06,
    'BMJ Open': 1.059,
    'Microbes and Infection': 1.059,
    'Mycoses': 1.059,
    'Reproductive Biology and Endocrinology': 1.059,
    'Therapeutic Advances in Endocrinology and Metabolism': 1.058,
    'Gut and Liver': 1.057,
    'Translational Lung Cancer Research': 1.056,
    'BMC Infectious Diseases': 1.055,
    'Digestive Diseases and Sciences': 1.054,
    'Journal of Digital Imaging': 1.054,
    'Journal of Loss and Trauma': 1.053,
    'Pigment Cell and Melanoma Research': 1.053,
    'Psychology of Addictive Behaviors': 1.053,
    'Annals of Medicine': 1.052,
    'Neuro-Oncology Advances': 1.052,
    'Current Opinion in Hematology': 1.051,
    'Epilepsia Open': 1.051,
    'Hematological Oncology': 1.051,
    'Gastroenterology Clinics of North America': 1.05,
    'Journal of Neuropathology and Experimental Neurology': 1.05,
    'Journal of Transport and Health': 1.05,
    'Parkinsonism and Related Disorders': 1.05,
    'Addiction Biology': 1.049,
    'Mycorrhiza': 1.048,
    'Current Protocols in Pharmacology': 1.046,
    'ACS Chemical Neuroscience': 1.045,
    'Current Opinion in Critical Care': 1.045,
    'Ethnicity and Health': 1.045,
    'Gastroenterology and Hepatology': 1.045,
    'Medical Microbiology and Immunology': 1.045,
    'Circulation Journal': 1.044,
    'Annals of Gastroenterological Surgery': 1.043,
    'Global Spine Journal': 1.043,
    'Journal of Orthopaedic Trauma': 1.043,
    'Journal of Pediatrics': 1.043,
    'American Journal of Tropical Medicine and Hygiene': 1.042,
    'ASN Neuro': 1.042,
    'European Journal of Health Economics': 1.042,
    'Lipids in Health and Disease': 1.042,
    'Child and Adolescent Mental Health': 1.041,
    'Human Vaccines and Immunotherapeutics': 1.041,
    'International Review of Psychiatry': 1.04,
    'Pediatric Research': 1.04,
    'Advanced Therapeutics': 1.039,
    'Diabetes Therapy': 1.039,
    'Journal of Intensive Care Medicine': 1.039,
    'Molecular Human Reproduction': 1.039,
    'Research and Practice for Persons with Severe Disabilities': 1.039,
    'European Eating Disorders Review': 1.038,
    'International Journal for Parasitology: Drugs and Drug Resistance': 1.038,
    'Early Intervention in Psychiatry': 1.037,
    'Radiation Oncology': 1.037,
    'Sociology of Health and Illness': 1.037,
    'China CDC Weekly': 1.036,
    'European Journal of Pediatrics': 1.036,
    'Lupus Science and Medicine': 1.035,
    'BMC Pregnancy and Childbirth': 1.034,
    'Dermatology and Therapy': 1.034,
    'Pediatric Infectious Disease Journal': 1.034,
    'Rheumatology International': 1.034,
    'Virus Research': 1.034,
    'Criminal Justice and Behavior': 1.033,
    'Internet Interventions': 1.033,
    'Journal of Thoracic Imaging': 1.033,
    'Sexual Medicine Reviews': 1.033,
    'Women and Birth': 1.033,
    'Current Opinion in Lipidology': 1.032,
    'Health Economics (United Kingdom)': 1.032,
    'Molecular Plant-Microbe Interactions': 1.032,
    'Clinical Genitourinary Cancer': 1.031,
    'Familial Cancer': 1.031,
    'Journal of Cognition and Development': 1.031,
    'Cytokine: X': 1.03,
    'Molecular Diagnosis and Therapy': 1.03,
    'Child Maltreatment': 1.029,
    "International Journal of Alzheimer's Disease": 1.029,
    'International Journal of Molecular Medicine': 1.029,
    'European Journal of Public Health': 1.028,
    'Frontiers in Physiology': 1.028,
    'Journal of Diabetes': 1.028,
    'Genetics Selection Evolution': 1.027,
    'Journal of Lipid and Atherosclerosis': 1.027,
    'Journal of Sports Science and Medicine': 1.027,
    'Journal of Geriatric Oncology': 1.025,
    'Memory and Cognition': 1.025,
    'Psychology and Health': 1.025,
    'Clinics in Liver Disease': 1.024,
    'Administration and Policy in Mental Health and Mental Health Services Research': 1.022,
    'Behavioral Sleep Medicine': 1.022,
    'Pest Management Science': 1.022,
    'Current Treatment Options in Psychiatry': 1.02,
    'Eye and Brain': 1.02,
    'Ophthalmology and Therapy': 1.02,
    'Clinical Oncology': 1.019,
    'European Journal of Physical and Rehabilitation Medicine': 1.019,
    "Graefe's Archive for Clinical and Experimental Ophthalmology": 1.019,
    'Journal of Clinical Anesthesia': 1.019,
    'Molecular Genetics and Metabolism': 1.019,
    'Clinical and Translational Allergy': 1.018,
    'Dermatologic Clinics': 1.018,
    'Journal of Experimental Biology': 1.018,
    'Applied Health Economics and Health Policy': 1.017,
    'Behavioral and Brain Functions': 1.017,
    'Journal of Atherosclerosis and Thrombosis': 1.017,
    'Journal of Cancer Research and Clinical Oncology': 1.017,
    'Journal of Virus Eradication': 1.017,
    'Infancy': 1.016,
    'Journal of the American College of Radiology': 1.016,
    "Journal of Huntington's disease": 1.015,
    'One Health': 1.015,
    'Proceedings of the Japan Academy Series B: Physical and Biological Sciences': 1.015,
    'Prostate': 1.015,
    'Epidemiology and health': 1.014,
    'Journal of Biological Rhythms': 1.014,
    'World Journal of Urology': 1.014,
    'Clinical and Experimental Ophthalmology': 1.013,
    'Current Genetics': 1.013,
    'International Journal of Cardiology: Cardiovascular Risk and Prevention': 1.011,
    'Therapeutic Advances in Hematology': 1.011,
    'Cancer Microenvironment': 1.01,
    'Endoscopic Ultrasound': 1.01,
    'Seminars in Fetal and Neonatal Medicine': 1.009,
    'Archives of Gerontology and Geriatrics': 1.008,
    'BMJ Open Gastroenterology': 1.008,
    'Cancer Causes and Control': 1.008,
    'Journal of Clinical Pathology': 1.008,
    'Public Health Research and Practice': 1.008,
    'Annals of Emergency Medicine': 1.005,
    'Diseases of the Colon and Rectum': 1.005,
    'European Journal of General Practice': 1.005,
    'Journal of Reconstructive Microsurgery': 1.005,
    'OpenNano': 1.005,
    'Cardiology and Therapy': 1.004,
    'Drug Metabolism Reviews': 1.003,
    'Endocrine Practice': 1.003,
    'Brain Imaging and Behavior': 1.002,
    'Journal of Clinical Gastroenterology': 1.002,
    'Journal of Neuromuscular Diseases': 1.002,
    'Psychopathology': 1.001,
    'Nature': 20.957,
    'Science': 13.328,
    'The Innovation': 5.057,
    'Science advances': 4.598,
    'Proceedings of the National Academy of Sciences of the United States of America': 4.026,
    'National Science Review': 2.972,
    'Science Bulletin': 2.902,
    'Research': 2.293,
    'Tsinghua Science and Technology': 1.648,
    'iScience': 1.624,
    'Journal of Advanced Research': 1.603,
    'Proceedings of the Japan Academy Series B: Physical and Biological Sciences': 1.015,
    'Nature Neuroscience': 12.124,
    'Annual Review of Neuroscience': 8.389,
    'Lancet Psychiatry,The': 8.167,
    'Neuron': 7.736,
    'Nature Reviews Neuroscience': 6.97,
    'Nature Reviews Neurology': 6.536,
    'Nature Human Behaviour': 5.639,
    'Trends in Cognitive Sciences': 5.615,
    'EMBO Journal': 5.484,
    'Molecular Neurodegeneration': 5.158,
    'Progress in Retinal and Eye Research': 4.939,
    'Acta Neuropathologica': 4.897,
    'Trends in Neurosciences': 4.784,
    'Nature Aging': 4.336,
    'eLife': 4.251,
    'PLoS Biology': 4.005,
    'Annals of Neurology': 3.977,
    'Biological Psychiatry': 3.768,
    'Molecular Psychiatry': 3.541,
    "Alzheimer's and Dementia": 3.288,
    'Autism in Adulthood': 3.057,
    'Sleep Medicine Reviews': 3.053,
    'Brain, Behavior, and Immunity': 3.033,
    'Current Opinion in Neurobiology': 3.026,
    'Ageing Research Reviews': 3.007,
    'Current Biology': 2.806,
    'Stroke': 2.746,
    'Progress in Neurobiology': 2.664,
    "Alzheimer's Research and Therapy": 2.65,
    'Movement Disorders': 2.602,
    'Neuroscience and Biobehavioral Reviews': 2.599,
    'Translational Neurodegeneration': 2.571,
    'NeuroImage': 2.512,
    'Pain': 2.445,
    'Journal of Neuroinflammation': 2.439,
    'Acta neuropathologica communications': 2.395,
    'GLIA': 2.376,
    'Cellular and Molecular Life Sciences': 2.371,
    'Journal of Neuroscience': 2.35,
    'Neurology: Neuroimmunology and NeuroInflammation': 2.288,
    'Neural Networks': 2.221,
    'Cell Death and Disease': 2.188,
    'Brain Stimulation': 2.184,
    'Translational Psychiatry': 2.148,
    'Open Biology': 2.143,
    'Psychiatry Research': 2.139,
    "npj Parkinson's Disease": 2.138,
    'Frontiers in Neuroendocrinology': 2.132,
    'Neuroscientist': 2.109,
    'Brain Pathology': 2.058,
    'Journal of Abnormal Psychology': 2.031,
    'Journal of Experimental Psychology: General': 2.023,
    'Biological Psychiatry: Cognitive Neuroscience and Neuroimaging': 1.997,
    'Epilepsia': 1.966,
    'Developmental Science': 1.962,
    'International Journal of Stroke': 1.952,
    'Open Mind': 1.95,
    'Journal of Cerebral Blood Flow and Metabolism': 1.925,
    'Neurobiology of Disease': 1.893,
    'Annals of Clinical and Translational Neurology': 1.885,
    'PLoS Computational Biology': 1.872,
    'Network Neuroscience': 1.853,
    'Current Opinion in Behavioral Sciences': 1.799,
    'Developmental Cognitive Neuroscience': 1.792,
    'Neuropathology and Applied Neurobiology': 1.785,
    'Cerebral Cortex': 1.738,
    'British Journal of Ophthalmology': 1.733,
    'Therapeutic Advances in Neurological Disorders': 1.728,
    'Dialogues in Clinical Neuroscience': 1.714,
    'Current Opinion in Neurology': 1.701,
    'Bipolar Disorders': 1.7,
    'Cognition': 1.691,
    'Human Brain Mapping': 1.688,
    'Fluids and Barriers of the CNS': 1.657,
    'Molecular Autism': 1.647,
    'Neurobiology of Stress': 1.637,
    'Annals of the New York Academy of Sciences': 1.626,
    'Schizophrenia': 1.61,
    'Social Cognitive and Affective Neuroscience': 1.602,
    'Frontiers in Cellular Neuroscience': 1.591,
    'Brain Communications': 1.57,
    'Journal of Neurology': 1.556,
    'European Journal of Neurology': 1.554,
    'Journal of Psychiatric Research': 1.554,
    'Cell Death Discovery': 1.545,
    'Current Topics in Behavioral Neurosciences': 1.545,
    'Neural Computation': 1.544,
    'Progress in Neuro-Psychopharmacology and Biological Psychiatry': 1.543,
    'Multiple Sclerosis Journal': 1.534,
    'Translational Stroke Research': 1.53,
    'Wiley Interdisciplinary Reviews: Cognitive Science': 1.523,
    'Neurobiology of Aging': 1.521,
    'Journal of Research on Adolescence': 1.518,
    'Current Neurology and Neuroscience Reports': 1.506,
    'Frontiers in Molecular Neuroscience': 1.495,
    'General Psychiatry': 1.495,
    'DMM Disease Models and Mechanisms': 1.489,
    'Neurocomputing': 1.481,
    'Journal of Neurochemistry': 1.475,
    'npj Science of Learning': 1.468,
    'Journal of Contextual Behavioral Science': 1.465,
    'Neurogastroenterology and Motility': 1.451,
    'European Neuropsychopharmacology': 1.442,
    'Schizophrenia Research': 1.429,
    'International Journal of Bipolar Disorders': 1.414,
    'NeuroImage: Clinical': 1.395,
    'Borderline Personality Disorder and Emotion Dysregulation': 1.394,
    'Psychoneuroendocrinology': 1.391,
    'Psychiatry and Clinical Neurosciences': 1.389,
    'Experimental Neurology': 1.38,
    'Journal of Neurodevelopmental Disorders': 1.38,
    'Journal of Pain': 1.363,
    'European Archives of Psychiatry and Clinical Neuroscience': 1.356,
    'Psychophysiology': 1.345,
    'Neural Development': 1.344,
    "Journal of Parkinson's Disease": 1.325,
    'Molecular Neurobiology': 1.325,
    'Autism Research': 1.323,
    'Journal of Cognitive Neuroscience': 1.311,
    'eNeuro': 1.309,
    'Frontiers in Neural Circuits': 1.308,
    'Journal of NeuroImmune Pharmacology': 1.308,
    'Neuropharmacology': 1.307,
    'Neurorehabilitation and Neural Repair': 1.306,
    'Cortex': 1.303,
    'Journal of Sleep Research': 1.302,
    'Journal of Fluency Disorders': 1.296,
    'Molecular and Cellular Neurosciences': 1.295,
    'Clinical Autonomic Research': 1.293,
    'Headache': 1.283,
    'Molecular Brain': 1.273,
    'International Journal of Behavioral Development': 1.268,
    'IEEE Transactions on Neural Systems and Rehabilitation Engineering': 1.26,
    'Investigative Ophthalmology and Visual Science': 1.259,
    'Hippocampus': 1.246,
    'Journal of Comparative Neurology': 1.245,
    'Current Protocols': 1.244,
    'Topics in Cognitive Science': 1.242,
    'Frontiers in Synaptic Neuroscience': 1.238,
    'Current Protocols in Neuroscience': 1.232,
    'Journal of Psychiatry and Neuroscience': 1.22,
    'Clinical Neurophysiology': 1.212,
    'Frontiers in Aging Neuroscience': 1.211,
    'Journal of Neurosurgery: Spine': 1.192,
    'Journal of Neuroscience Research': 1.181,
    'Journal of Neuro-Oncology': 1.178,
    'Eye': 1.176,
    'Brain Structure and Function': 1.168,
    'Current Neuropharmacology': 1.168,
    'Frontiers in Neuroscience': 1.161,
    'Sleep Health': 1.16,
    'Translational Behavioral Medicine': 1.16,
    'Brain Topography': 1.149,
    'Chronic Stress': 1.148,
    'Developmental Medicine and Child Neurology': 1.146,
    "Journal of Alzheimer's Disease": 1.146,
    'Frontiers in Neuroanatomy': 1.143,
    'Journal of Neural Engineering': 1.135,
    'Neurobiology of Language': 1.132,
    'Amyotrophic Lateral Sclerosis and Frontotemporal Degeneration': 1.116,
    'American Journal of Medical Genetics, Part B: Neuropsychiatric Genetics': 1.109,
    'Journal of Experimental Psychology: Human Perception and Performance': 1.108,
    'Hormones and Behavior': 1.091,
    'Language, Cognition and Neuroscience': 1.089,
    'CNS oncology': 1.088,
    'Developmental Neurobiology': 1.087,
    'Cognitive, Affective and Behavioral Neuroscience': 1.085,
    'Clinical and Translational Science': 1.084,
    'Neuroendocrinology': 1.079,
    'Reviews in the Neurosciences': 1.074,
    'Neuroscience Bulletin': 1.071,
    'Frontiers in Systems Neuroscience': 1.07,
    'NeuroMolecular Medicine': 1.069,
    'Cognitive Systems Research': 1.068,
    'Journal of Neurophysiology': 1.067,
    'Review Journal of Autism and Developmental Disorders': 1.061,
    'Cognitive Science': 1.057,
    'Epilepsia Open': 1.051,
    'Journal of Neuropathology and Experimental Neurology': 1.05,
    'Parkinsonism and Related Disorders': 1.05,
    'ACS Chemical Neuroscience': 1.045,
    'European Journal of Neuroscience': 1.044,
    'ASN Neuro': 1.042,
    'Cognitive Computation': 1.037,
    'Early Intervention in Psychiatry': 1.037,
    "International Journal of Alzheimer's Disease": 1.029,
    'Behavioral Sleep Medicine': 1.022,
    'Eye and Brain': 1.02,
    "Graefe's Archive for Clinical and Experimental Ophthalmology": 1.019,
    'Behavioral and Brain Functions': 1.017,
    "Journal of Huntington's disease": 1.015,
    'Neuroscience': 1.006,
    'Cognitive Research: Principles and Implications': 1.004,
    'Brain Imaging and Behavior': 1.002,
    'Journal of Neuromuscular Diseases': 1.002,
    'Diabetes Care': 6.008,
    'Stroke': 2.746,
    'International Journal of Behavioral Nutrition and Physical Activity': 2.709,
    'Annual Review of Nutrition': 2.643,
    'Advances in Nutrition': 2.331,
    'Journals of Gerontology - Series B Psychological Sciences and Social Sciences': 2.311,
    'Resuscitation': 2.242,
    'American Journal of Clinical Nutrition': 1.96,
    'Gerontologist, The': 1.954,
    'Clinical and Translational Immunology': 1.908,
    'Obesity': 1.826,
    'Journal of the American Medical Directors Association': 1.794,
    'Public Health Reviews': 1.78,
    'International Journal of Nursing Studies': 1.774,
    'Journal of Aging and Social Policy': 1.721,
    'Clinical Nutrition': 1.658,
    'Nutrition Reviews': 1.521,
    'International Journal of Obesity': 1.438,
    'Journal of Cancer Survivorship': 1.368,
    'Evidence-based nursing': 1.366,
    'JCO Oncology Practice': 1.349,
    'Journal of Nursing Management': 1.341,
    'Journal of Pain and Symptom Management': 1.306,
    'Journal of Fluency Disorders': 1.296,
    'Nutrients': 1.291,
    'Journal of Nutrition, Health and Aging': 1.269,
    'Nursing Ethics': 1.261,
    'Journal of Clinical Lipidology': 1.256,
    'International Psychogeriatrics': 1.25,
    'Current Opinion in HIV and AIDS': 1.236,
    'American Journal of Bioethics': 1.219,
    'Nutrition Research Reviews': 1.218,
    'Pediatric obesity': 1.202,
    'Appetite': 1.187,
    'JMIR Aging': 1.172,
    'Journal of the Academy of Nutrition and Dietetics': 1.162,
    'Food Quality and Preference': 1.161,
    'Aging and Mental Health': 1.16,
    'International Journal of Health Policy and Management': 1.155,
    'Traumatology': 1.142,
    'Patient': 1.14,
    'Journal of Nutritional Biochemistry': 1.136,
    'Nutrition Journal': 1.136,
    'Obesity Surgery': 1.127,
    'Journal of Nutrition': 1.125,
    "Women's Health Issues": 1.122,
    'Journal of Aging and Health': 1.119,
    'BMJ Nutrition, Prevention and Health': 1.114,
    'Journal of Medical Ethics': 1.111,
    'European Journal of Nutrition': 1.094,
    'Maternal and Child Nutrition': 1.093,
    'Journal of Healthcare Leadership': 1.082,
    'European Journal of Clinical Nutrition': 1.073,
    'Journal of Applied Gerontology': 1.061,
    'Journal of the International Society of Sports Nutrition': 1.041,
    'Women and Birth': 1.033,
    'Current Opinion in Lipidology': 1.032,
    'Annals of K-Theory': 1.021,
    'International Nursing Review': 1.011,
    'Archives of Gerontology and Geriatrics': 1.008,
    'Nature Reviews Drug Discovery': 19.423,
    'Pharmacological Reviews': 5.338,
    'Drug Resistance Updates': 4.391,
    'Annual Review of Pharmacology and Toxicology': 3.902,
    'Nano Today': 3.868,
    'Molecular Therapy': 3.425,
    'Journal for ImmunoTherapy of Cancer': 3.403,
    'Protein and Cell': 3.367,
    'npj Vaccines': 3.197,
    'Pharmacology and Therapeutics': 3.063,
    'Advanced Drug Delivery Reviews': 2.982,
    'Trends in Pharmacological Sciences': 2.964,
    'Emerging Microbes and Infections': 2.815,
    'Medicinal Research Reviews': 2.748,
    'Cell Chemical Biology': 2.541,
    'Acta Pharmaceutica Sinica B': 2.518,
    'Pain': 2.445,
    'Neuropsychopharmacology': 2.385,
    'Theranostics': 2.381,
    'Cellular and Molecular Life Sciences': 2.371,
    'Natural Product Reports': 2.284,
    'Advanced healthcare materials': 2.114,
    'Environmental Pollution': 2.11,
    'British Journal of Pharmacology': 2.019,
    'Molecular Therapy - Nucleic Acids': 1.947,
    'Journal of Controlled Release': 1.844,
    'Journal of Medicinal Chemistry': 1.805,
    'Particle and Fibre Toxicology': 1.805,
    'Pharmacological Research': 1.784,
    'Therapeutic Advances in Neurological Disorders': 1.728,
    'Antiviral Research': 1.701,
    'Antibodies': 1.697,
    'Acta Pharmacologica Sinica': 1.677,
    'Vaccines': 1.655,
    'BioDrugs': 1.646,
    'Clinical Pharmacology and Therapeutics': 1.643,
    'Expert Opinion on Therapeutic Targets': 1.581,
    'Drug and Alcohol Dependence': 1.562,
    'Progress in Neuro-Psychopharmacology and Biological Psychiatry': 1.543,
    'Nucleic Acid Therapeutics': 1.49,
    'Bioengineering and Translational Medicine': 1.484,
    'Emerging Contaminants': 1.478,
    'ACS Pharmacology and Translational Science': 1.473,
    'Neurotherapeutics': 1.461,
    'Apoptosis : an international journal on programmed cell death': 1.447,
    'European Neuropsychopharmacology': 1.442,
    'Journal of Nanobiotechnology': 1.421,
    'Antimicrobial Agents and Chemotherapy': 1.415,
    'Journal of Exposure Science and Environmental Epidemiology': 1.415,
    'International Journal of Neuropsychopharmacology': 1.406,
    'Drug Discovery Today': 1.397,
    'Journal of Immunotherapy': 1.397,
    'Current Opinion in Systems Biology': 1.391,
    'PharmacoEconomics': 1.389,
    'npj Systems Biology and Applications': 1.388,
    'Journal of Toxicology and Environmental Health - Part B: Critical Reviews': 1.383,
    'Journal of Antimicrobial Chemotherapy': 1.382,
    'CNS Neuroscience and Therapeutics': 1.381,
    'Biomedicine and Pharmacotherapy': 1.366,
    'Expert Review of Vaccines': 1.346,
    'Drug Discovery Today: Technologies': 1.34,
    'Inflammation Research': 1.339,
    'Biochemical Pharmacology': 1.329,
    'Addictive Behaviors': 1.328,
    'Human Genomics': 1.308,
    'Journal of NeuroImmune Pharmacology': 1.308,
    'Neuropharmacology': 1.307,
    'PLoS Neglected Tropical Diseases': 1.298,
    'Expert Opinion on Therapeutic Patents': 1.292,
    'Expert Opinion on Investigational Drugs': 1.29,
    'Asian Journal of Pharmaceutical Sciences': 1.283,
    'Journal of Molecular Medicine': 1.276,
    'Current Protocols': 1.244,
    'Medicine in Drug Discovery': 1.244,
    'Journal of Pharmaceutical Analysis': 1.242,
    'Clinical Pharmacokinetics': 1.238,
    'Expert Opinion on Drug Discovery': 1.219,
    'Life Sciences': 1.207,
    'Bioconjugate Chemistry': 1.204,
    'Current Opinion in Pharmacology': 1.179,
    'Current Neuropharmacology': 1.168,
    'Archives of Pharmacal Research': 1.163,
    'Archives of Toxicology': 1.162,
    'European Journal of Medicinal Chemistry': 1.143,
    'Phytomedicine': 1.126,
    'Expert Opinion on Biological Therapy': 1.12,
    'Journal of Psychopharmacology': 1.12,
    'Journal of Studies on Alcohol and Drugs': 1.115,
    'International Journal of Nanomedicine': 1.111,
    'Drug Safety': 1.105,
    'International Immunopharmacology': 1.1,
    'Statistics in Biopharmaceutical Research': 1.091,
    'Clinical and Translational Science': 1.084,
    'Alcoholism: Clinical and Experimental Research': 1.076,
    'Phytotherapy Research': 1.073,
    'Frontiers in Pharmacology': 1.064,
    'Molecular Pharmacology': 1.061,
    'Psychopharmacology': 1.05,
    'Addiction Biology': 1.049,
    'Current Protocols in Pharmacology': 1.046,
    'Human Vaccines and Immunotherapeutics': 1.041,
    'Advanced Therapeutics': 1.039,
    'International Journal for Parasitology: Drugs and Drug Resistance': 1.038,
    'Molecular Diagnosis and Therapy': 1.03,
    'Cell Biology and Toxicology': 1.019,
    'Expert Opinion on Drug Delivery': 1.018,
    'Critical Reviews in Toxicology': 1.007,
    'OpenNano': 1.005,
    'Drug Metabolism Reviews': 1.003,
    'Reviews of Modern Physics': 20.343,
    'Annual Review of Astronomy and Astrophysics': 14.486,
    'Nature Materials': 13.874,
    'Nature Nanotechnology': 13.141,
    'Advances in Physics': 12.034,
    'Nature Photonics': 11.774,
    'Living Reviews in Relativity': 11.561,
    'Nature Electronics': 10.927,
    'Annual Review of Condensed Matter Physics': 10.644,
    'Astronomy and Astrophysics Review': 9.937,
    'Nature Reviews Physics': 9.34,
    'Advances in Optics and Photonics': 8.332,
    'Nature Physics': 7.955,
    'Living Reviews in Solar Physics': 7.235,
    'Materials Science and Engineering: R: Reports': 6.842,
    'Physical Review X': 6.267,
    'Annual Review of Fluid Mechanics': 6.237,
    'Materials Today': 6.194,
    'Annual Review of Earth and Planetary Sciences': 6.083,
    'Annual Review of Nuclear and Particle Science': 5.985,
    'Physics Reports': 5.691,
    'Progress in Polymer Science': 5.676,
    'Advanced Functional Materials': 5.565,
    'Light: Science and Applications': 5.406,
    'Nature Communications': 5.116,
    'Progress in Particle and Nuclear Physics': 4.855,
    'Advanced Photonics': 4.787,
    'ACS Nano': 4.728,
    'Reports on Progress in Physics': 4.467,
    'PhotoniX': 4.445,
    'Annals of PDE': 4.425,
    'PRX Quantum': 4.415,
    'Applied Physics Reviews': 4.143,
    'Optica': 4.096,
    'Advanced Science': 4.086,
    'Nano Letters': 3.54,
    'AVS Quantum Science': 3.413,
    'ISPRS Journal of Photogrammetry and Remote Sensing': 3.308,
    'npj Quantum Information': 3.305,
    'Nature Astronomy': 3.269,
    'Surface Science Reports': 3.204,
    'Laser and Photonics Reviews': 3.147,
    'Physical Review Letters': 3.118,
    'IEEE Wireless Communications Letters': 3.092,
    'Astrophysical Journal, Supplement Series': 3.086,
    'IUCrJ': 3.072,
    'Opto-Electronic Advances': 2.913,
    'New Astronomy Reviews': 2.825,
    'Reviews in Physics': 2.811,
    'Space Science Reviews': 2.768,
    'Materials Today Physics': 2.767,
    'Astrophysical Journal Letters': 2.726,
    'Quantum': 2.702,
    'npj 2D Materials and Applications': 2.665,
    'Cell Reports Physical Science': 2.598,
    'IEEE Geoscience and Remote Sensing Magazine': 2.539,
    'Advanced Optical Materials': 2.524,
    'Progress in Quantum Electronics': 2.513,
    'NPG Asia Materials': 2.501,
    'Nano Research': 2.486,
    'Progress in Nuclear Magnetic Resonance Spectroscopy': 2.423,
    'Quantum Science and Technology': 2.341,
    'Materials Today Nano': 2.338,
    'IEEE Transactions on Antennas and Propagation': 2.317,
    'Advances in Colloid and Interface Science': 2.241,
    'Solar RRL': 2.24,
    'npj Quantum Materials': 2.221,
    'Computer Methods in Applied Mechanics and Engineering': 2.218,
    'Publications of the Astronomical Society of the Pacific': 2.151,
    'Photoacoustics': 2.134,
    'Progress in Photovoltaics: Research and Applications': 2.132,
    'Photonics Research': 2.124,
    'ACS Photonics': 2.113,
    'International Journal of Radiation Oncology Biology Physics': 2.09,
    'Astronomical Journal': 2.025,
    'Nanophotonics': 2.013,
    'Astronomy and Astrophysics': 1.999,
    'Progress of Theoretical and Experimental Physics': 1.958,
    'APL Photonics': 1.949,
    'Astrophysical Journal': 1.925,
    'SciPost Physics': 1.897,
    'Advances in Physics: X': 1.895,
    'IEEE Transactions on Microwave Theory and Techniques': 1.892,
    'ACS Sensors': 1.875,
    'Physics of Life Reviews': 1.848,
    'Communications Physics': 1.844,
    'Physical Review Research': 1.824,
    'Selecta Mathematica, New Series': 1.784,
    'Scripta Materialia': 1.756,
    'Journal of Computational Physics': 1.753,
    'Monthly Notices of the Royal Astronomical Society': 1.734,
    'Physics Letters, Section B: Nuclear, Elementary Particle and High-Energy Physics': 1.704,
    'Monthly Notices of the Royal Astronomical Society: Letters': 1.696,
    'Journal of the Mechanics and Physics of Solids': 1.691,
    'Progress in Surface Science': 1.677,
    'Advanced Nonlinear Studies': 1.676,
    'Materials Science &amp; Engineering A: Structural Materials: Properties, Microstructure and Processing': 1.667,
    'International Journal of Thermofluids': 1.643,
    'Physical Review D': 1.639,
    'Progress in Solid State Chemistry': 1.633,
    '2D Materials': 1.631,
    'Critical Reviews in Solid State and Materials Sciences': 1.627,
    'ISA Transactions': 1.588,
    'Synthesis Lectures on Image, Video, and Multimedia Processing': 1.584,
    'Computing and Software for Big Science': 1.578,
    'Advanced Quantum Technologies': 1.577,
    'Publications of the Astronomical Society of Australia': 1.536,
    'International Journal of Mechanical Sciences': 1.532,
    'European Physical Journal C': 1.526,
    'Microsystems and Nanoengineering': 1.515,
    'Journal of Lightwave Technology': 1.514,
    'SciPost Physics Core': 1.494,
    'Physical Review Applied': 1.492,
    'Nonlinearity': 1.491,
    'Sensors and Actuators B: Chemical': 1.482,
    'Physical Review B': 1.468,
    'Nuclear Fusion': 1.447,
    'Nuclear Data Sheets': 1.432,
    'Physics of the Dark Universe': 1.431,
    'JPhys Materials': 1.423,
    'Communications in Mathematical Physics': 1.413,
    'Journal of Fluid Mechanics': 1.409,
    'IEEE Transactions on Instrumentation and Measurement': 1.397,
    'Mass Spectrometry Reviews': 1.395,
    'Chaos, Solitons and Fractals': 1.393,
    'Journal of Sound and Vibration': 1.376,
    'International Journal of Heat and Mass Transfer': 1.369,
    'Journal of Physics G: Nuclear and Particle Physics': 1.357,
    'Ultrasonics Sonochemistry': 1.35,
    'IEEE/ACM Transactions on Audio Speech and Language Processing': 1.348,
    'Tribology International': 1.348,
    'Capillarity': 1.346,
    'Contemporary Physics': 1.342,
    'Rare Metals': 1.326,
    'International Journal of Hydrogen Energy': 1.318,
    'Current Opinion in Colloid and Interface Science': 1.314,
    'Journal of Computational Physics: X': 1.314,
    'Computer Physics Communications': 1.312,
    'Physical Review C': 1.305,
    'Matter and Radiation at Extremes': 1.273,
    'Classical and Quantum Gravity': 1.264,
    'IEEE Transactions on Biometrics, Behavior, and Identity Science': 1.255,
    'Icarus': 1.241,
    'NDT and E International': 1.24,
    'Chinese Physics C': 1.231,
    'New Journal of Physics': 1.224,
    'IEEE Microwave and Wireless Components Letters': 1.222,
    'IEEE Journal of Selected Topics in Quantum Electronics': 1.221,
    'Fortschritte der Physik': 1.209,
    'Frontiers of Physics': 1.198,
    'Journal of Chemical Physics': 1.196,
    'Superconductor Science and Technology': 1.191,
    'Applied Surface Science': 1.187,
    'Nuclear Materials and Energy': 1.184,
    'Optics Letters': 1.179,
    'EJNMMI Physics': 1.174,
    'Astroparticle Physics': 1.167,
    'LEUKOS - Journal of Illuminating Engineering Society of North America': 1.142,
    'Theoretical and Applied Fracture Mechanics': 1.142,
    'Journal of the Energy Institute': 1.138,
    'Optics Express': 1.138,
    'IEEE Transactions on Semiconductor Manufacturing': 1.129,
    'MRS Bulletin': 1.128,
    'Publication of the Astronomical Society of Japan': 1.119,
    'Ultrasonics': 1.118,
    'Science China: Physics, Mechanics and Astronomy': 1.112,
    'Physical Review A': 1.11,
    'Materials Characterization': 1.109,
    'Astrodynamics': 1.106,
    'Measurement: Journal of the International Measurement Confederation': 1.106,
    'Physical Review Materials': 1.105,
    'EPJ Quantum Technology': 1.101,
    'IEEE Transactions on Quantum Engineering': 1.088,
    'Materials Science and Engineering C': 1.086,
    'Physics of Fluids': 1.083,
    'European Journal of Mechanics, A/Solids': 1.082,
    'Physics of the Earth and Planetary Interiors': 1.082,
    'Physics and Imaging in Radiation Oncology': 1.067,
    'Nanoscale Advances': 1.063,
    'Progress in Electromagnetics Research': 1.062,
    'Journal of High Energy Astrophysics': 1.06,
    'Communications in Computational Physics': 1.057,
    'JPhys Photonics': 1.056,
    'IEEE Journal of Photovoltaics': 1.05,
    'Optics and Lasers in Engineering': 1.047,
    'International Journal of Solids and Structures': 1.046,
    'International Journal of Thermal Sciences': 1.044,
    'Physica D: Nonlinear Phenomena': 1.044,
    'Applied Physics Letters': 1.043,
    'Wear': 1.043,
    'Journal of Mathematical Fluid Mechanics': 1.037,
    'Structural Dynamics': 1.037,
    'Zeitschrift fur Angewandte Mathematik und Physik': 1.023,
    'Technical Digest - International Electron Devices Meeting': 1.017,
    'Physical Review Physics Education Research': 1.015,
    'Proceedings of the Japan Academy Series B: Physical and Biological Sciences': 1.015,
    'High Power Laser Science and Engineering': 1.014,
    'Annales Henri Poincare': 1.007,
    'Journal of Language Aggression and Conflict': 1.007,
    'Physical Review Accelerators and Beams': 1.003,
    'Journal of Nuclear Materials': 1.002,
    'Journal of Plasma Physics': 1.001,
    'Annual Review of Psychology': 9.226,
    'Psychological Bulletin': 7.635,
    'Annual Review of Organizational Psychology and Organizational Behavior': 7.036,
    'Annual Review of Clinical Psychology': 6.744,
    'Psychological Science in the Public Interest': 6.295,
    'Journal of Applied Psychology': 6.13,
    'Personality and Social Psychology Review': 5.709,
    'Advances in Methods and Practices in Psychological Science': 5.649,
    'Nature Human Behaviour': 5.639,
    'Trends in Cognitive Sciences': 5.615,
    'Perspectives on Psychological Science': 5.529,
    'Psychotherapy and Psychosomatics': 4.738,
    'Clinical Psychology Review': 4.541,
    'Leadership Quarterly': 4.331,
    'Psychological Methods': 4.247,
    'Lancet Child and Adolescent Health, The': 4.179,
    'Qualitative Research in Sport, Exercise and Health': 4.045,
    'Journal of Organizational Behavior': 3.804,
    'Journal of Personality and Social Psychology': 3.799,
    'Personnel Psychology': 3.749,
    'Educational Psychologist': 3.668,
    'Journal of the American Academy of Child and Adolescent Psychiatry': 3.663,
    'Educational Psychology Review': 3.566,
    'Organizational Behavior and Human Decision Processes': 3.408,
    'American Psychologist': 3.363,
    'Contemporary Educational Psychology': 3.187,
    'Human Resource Management Review': 3.106,
    'Health Psychology Review': 3.101,
    'Autism in Adulthood': 3.057,
    'Current Directions in Psychological Science': 3.028,
    'Journal of Child Psychology and Psychiatry and Allied Disciplines': 3.025,
    'Journal of Consumer Psychology': 2.978,
    'Organizational Psychology Review': 2.97,
    'Journal of Vocational Behavior': 2.926,
    'European Journal of Personality': 2.868,
    'Psychological Science': 2.854,
    'Journal of Anxiety Disorders': 2.85,
    'Child Development Perspectives': 2.844,
    'European Review of Social Psychology': 2.806,
    'Psychological Review': 2.801,
    'Behavior Research Methods': 2.753,
    'Developmental Review': 2.649,
    'Technological Forecasting and Social Change': 2.644,
    'Qualitative Research in Psychology': 2.631,
    'Alcohol research : current reviews': 2.617,
    'Political Psychology': 2.61,
    'Neuroscience and Biobehavioral Reviews': 2.599,
    'Depression and Anxiety': 2.572,
    'Work and Stress': 2.52,
    'Journal of Business and Psychology': 2.517,
    'Journal of Educational Psychology': 2.511,
    'Journal of Occupational Health Psychology': 2.478,
    'Clinical Child and Family Psychology Review': 2.466,
    'Psychological Medicine': 2.465,
    'Computers in Human Behavior': 2.464,
    'Trauma, Violence, and Abuse': 2.422,
    'Accounting, Organizations and Society': 2.411,
    'Journal of Health and Social Behavior': 2.409,
    'Social Psychological and Personality Science': 2.404,
    'Learning and Instruction': 2.398,
    'British Journal of Health Psychology': 2.371,
    'Human Resource Management': 2.338,
    'International Journal of Clinical and Health Psychology': 2.324,
    'Journals of Gerontology - Series B Psychological Sciences and Social Sciences': 2.311,
    'Applied Psychology': 2.279,
    'Journal of the Learning Sciences': 2.267,
    'Social Issues and Policy Review': 2.245,
    'Personality and Social Psychology Bulletin': 2.238,
    'Journal of Personality': 2.192,
    'Journal of Consulting and Clinical Psychology': 2.188,
    'Journal of School Psychology': 2.16,
    'Child Development': 2.135,
    'Current Opinion in Psychology': 2.13,
    'Human Behavior and Emerging Technologies': 2.126,
    'British Journal of Social Psychology': 2.117,
    'Clinical Psychological Science': 2.115,
    'Emotion Review': 2.113,
    'Psychological Trauma: Theory, Research, Practice, and Policy': 2.109,
    'Journal of Memory and Language': 2.104,
    'Monographs of the Society for Research in Child Development': 2.104,
    'Psychology and Marketing': 2.083,
    'Decision Support Systems': 2.076,
    'Journal of Occupational and Organizational Psychology': 2.062,
    'Reading Research Quarterly': 2.058,
    'Applied Psychology: Health and Well-Being': 2.053,
    'Journal of Environmental Psychology': 2.045,
    'Journal of Abnormal Psychology': 2.031,
    'Journal of Youth and Adolescence': 2.03,
    'Journal of Experimental Psychology: General': 2.023,
    'Neuropsychology Review': 2.019,
    'Research on Language and Social Interaction': 2.013,
    'Journal of Affective Disorders': 1.988,
    'European Journal of Work and Organizational Psychology': 1.966,
    'Developmental Science': 1.962,
    'Open Mind': 1.95,
    'Educational and Psychological Measurement': 1.949,
    'Journal of Behavioral Addictions': 1.918,
    'European Journal of Psychology Applied to Legal Context': 1.91,
    'Asian Journal of Social Health and Behavior': 1.9,
    'Human Communication Research': 1.88,
    'Comprehensive Psychiatry': 1.869,
    'Cognitive Behaviour Therapy': 1.859,
    'Review of General Psychology': 1.829,
    'Applied Developmental Science': 1.818,
    'Journal of Clinical Child and Adolescent Psychology': 1.811,
    'European Child and Adolescent Psychiatry': 1.799,
    'Evolution and Human Behavior': 1.795,
    'Behaviour Research and Therapy': 1.787,
    'Group and Organization Management': 1.784,
    'Psychonomic Bulletin and Review': 1.783,
    'Autism': 1.767,
    'Psychological Assessment': 1.757,
    'International Journal of Consumer Studies': 1.753,
    'Social Psychiatry and Psychiatric Epidemiology': 1.75,
    'Cognition and Instruction': 1.731,
    'Developmental Psychology': 1.731,
    'Child Abuse and Neglect': 1.721,
    'International Review of Sport and Exercise Psychology': 1.711,
    'Journal of Positive Psychology': 1.701,
    'Cognition': 1.691,
    'Emotion': 1.67,
    'Social and Personality Psychology Compass': 1.646,
    'Journal of Black Psychology, The': 1.644,
    'British Journal of Psychology': 1.633,
    'Group Processes and Intergroup Relations': 1.631,
    'Early Childhood Research Quarterly': 1.63,
    'Scientific Studies of Reading': 1.619,
    'Journal of Professional Capital and Community': 1.618,
    'Schizophrenia': 1.61,
    'Journal of Family Theory and Review': 1.608,
    'Multivariate Behavioral Research': 1.606,
    'Social Cognitive and Affective Neuroscience': 1.602,
    'Journal of Economic Psychology': 1.601,
    'Clinical Psychology: Science and Practice': 1.599,
    'Journal of Experimental Social Psychology': 1.596,
    'Journal of Research in Crime and Delinquency': 1.583,
    'Journal of Criminal Justice': 1.573,
    'Assessment': 1.57,
    'Journal of Traumatic Stress': 1.564,
    'Cognitive Psychology': 1.56,
    'Media Psychology': 1.545,
    'Wiley Interdisciplinary Reviews: Cognitive Science': 1.523,
    'Journal of Research on Adolescence': 1.518,
    'Journal of Substance Abuse Treatment': 1.502,
    'Adolescent Research Review': 1.491,
    'British Journal of Mathematical and Statistical Psychology': 1.49,
    'European Journal of Social Psychology': 1.481,
    'Clinical Neuropsychologist': 1.477,
    'Research on Child and Adolescent Psychopathology': 1.468,
    'Cyberpsychology, Behavior, and Social Networking': 1.466,
    'Journal of Contextual Behavioral Science': 1.465,
    'Canadian Psychology': 1.464,
    'Personality and Individual Differences': 1.463,
    'Body Image': 1.459,
    'Psychosocial Intervention': 1.437,
    'Annals of Behavioral Medicine': 1.424,
    'Psychology of Women Quarterly': 1.417,
    'Public Understanding of Science': 1.408,
    'Health Psychology': 1.402,
    'Family Process': 1.401,
    'Journal of Cognition': 1.401,
    'British Journal of Educational Psychology': 1.399,
    'Psychology of Aesthetics, Creativity, and the Arts': 1.397,
    'Borderline Personality Disorder and Emotion Dysregulation': 1.394,
    'AIDS and Behavior': 1.392,
    'Journal of Counseling Psychology': 1.373,
    'Learning and Individual Differences': 1.367,
    'Aggression and Violent Behavior': 1.36,
    'Journal of Research in Personality': 1.356,
    'Behavior Therapy': 1.352,
    'Transportation Research Part F: Traffic Psychology and Behaviour': 1.352,
    'Psychology of Sport and Exercise': 1.351,
    'Psychology and Aging': 1.348,
    'Psychophysiology': 1.345,
    'Anxiety, Stress and Coping': 1.336,
    'International Journal of Stress Management': 1.336,
    'Behavioral Medicine': 1.331,
    'Addictive Behaviors': 1.328,
    'AERA Open': 1.327,
    'Asian Journal of Psychiatry': 1.326,
    'American Journal of Community Psychology': 1.32,
    'Development and Psychopathology': 1.315,
    'Sex Roles': 1.314,
    'Psychometrika': 1.308,
    'Exceptional Children': 1.306,
    'Journal of Managerial Psychology': 1.306,
    'Cortex': 1.303,
    'Youth Violence and Juvenile Justice': 1.3,
    'Journal of Fluency Disorders': 1.296,
    'Cultural Diversity and Ethnic Minority Psychology': 1.293,
    'Journal of Adolescence': 1.293,
    'Current Addiction Reports': 1.29,
    'Psychology and Psychotherapy: Theory, Research and Practice': 1.283,
    'Consumption Markets and Culture': 1.281,
    'Journal of Personality Assessment': 1.271,
    'International Journal of Behavioral Development': 1.268,
    'Mental Health and Physical Activity': 1.266,
    'European Journal of Psychology of Education': 1.264,
    'Policy Insights from the Behavioral and Brain Sciences': 1.263,
    'Attachment and Human Development': 1.26,
    'European Psychologist': 1.259,
    'Mindfulness': 1.259,
    'International Psychogeriatrics': 1.25,
    'Journal of Sex Research': 1.25,
    'Topics in Cognitive Science': 1.242,
    'Journal of Research in Reading': 1.24,
    'Educational Psychology': 1.227,
    'Psychotherapy Research': 1.225,
    'Annual Review of Applied Linguistics': 1.213,
    'Clinical Psychology and Psychotherapy': 1.209,
    'Journal of Applied Developmental Psychology': 1.205,
    'Social Networks': 1.203,
    'Personality Disorders: Theory, Research, and Treatment': 1.201,
    'Journal of the Association for Consumer Research': 1.2,
    'Counseling Psychologist': 1.199,
    'Journal of Autism and Developmental Disorders': 1.196,
    'Stress and Health': 1.196,
    'Cognitive Therapy and Research': 1.19,
    'Reading and Writing': 1.19,
    'Appetite': 1.187,
    'Learning Environments Research': 1.187,
    'International Review of Social Psychology': 1.181,
    'Scandinavian Journal of Management': 1.18,
    'Human-Computer Interaction': 1.178,
    'International Journal of Social Robotics': 1.177,
    'ELT Journal': 1.174,
    'Collabra: Psychology': 1.169,
    'Addiction science &amp; clinical practice': 1.166,
    'Journal of Experimental Psychology: Learning Memory and Cognition': 1.166,
    'British Journal of Clinical Psychology': 1.162,
    'Sleep Health': 1.16,
    'Translational Behavioral Medicine': 1.16,
    'Journal of Applied Behavioral Science, The': 1.156,
    'Chronic Stress': 1.148,
    'Methods, Data, Analyses': 1.148,
    "Journal of Alzheimer's Disease": 1.146,
    'Journal of Behavioral Medicine': 1.141,
    'Child Psychiatry and Human Development': 1.14,
    'Depression Research and Treatment': 1.134,
    'Journal of Psychosomatic Research': 1.131,
    'Small Group Research': 1.128,
    'Thinking and Reasoning': 1.128,
    'Journal of Applied Research in Memory and Cognition': 1.124,
    'Journal of Applied Behavior Analysis': 1.123,
    'Journal of Pediatric Psychology': 1.123,
    'Addictive Behaviors Reports': 1.122,
    'Psycho-Oncology': 1.111,
    'Journal of Experimental Psychology: Human Perception and Performance': 1.108,
    'American Journal of Orthopsychiatry': 1.107,
    'Methods in Psychology': 1.105,
    'Psychosomatic Medicine': 1.105,
    'Aggressive Behavior': 1.103,
    'Psicothema': 1.097,
    'Journal of Social and Personal Relationships': 1.091,
    'Language, Cognition and Neuroscience': 1.089,
    'American Behavioral Scientist': 1.088,
    'Psychological Inquiry': 1.088,
    'Palgrave Communications': 1.086,
    'Journal of Trauma and Dissociation': 1.083,
    'Journal of Experimental Child Psychology': 1.082,
    'Journal of Mathematical Behavior': 1.081,
    'Personnel Review': 1.078,
    'Human Development': 1.075,
    'Journal of Family Psychology': 1.069,
    'American Journal of Drug and Alcohol Abuse': 1.068,
    'Cognitive Systems Research': 1.068,
    'Games and Culture': 1.065,
    'Motivation Science': 1.063,
    'Psychology of Sexual Orientation and Gender Diversity': 1.061,
    'Intelligence': 1.059,
    'Cognitive Science': 1.057,
    'Journal of Attention Disorders': 1.055,
    'Journal of Loss and Trauma': 1.053,
    'Psychology of Addictive Behaviors': 1.053,
    'European Journal of Psychological Assessment': 1.052,
    'Journal of Experimental Education': 1.047,
    'Judgment and Decision Making': 1.046,
    'Early Education and Development': 1.043,
    'Journal of Interpersonal Violence': 1.043,
    'Social Psychology of Education': 1.042,
    'Discourse Studies': 1.04,
    'Research and Practice for Persons with Severe Disabilities': 1.039,
    'Archives of Sexual Behavior': 1.038,
    'European Eating Disorders Review': 1.038,
    'Research in Human Development': 1.038,
    'International Journal of Wellbeing': 1.034,
    'Behavioral Disorders': 1.033,
    'Criminal Justice and Behavior': 1.033,
    'Journal of Cognition and Development': 1.031,
    'Motivation and Emotion': 1.031,
    'Child Maltreatment': 1.029,
    'Memory and Cognition': 1.025,
    'Psychology and Health': 1.025,
    'Behavioral Sleep Medicine': 1.022,
    'Current Treatment Options in Psychiatry': 1.02,
    'Infancy': 1.016,
    'Psychology of Violence': 1.015,
    'Journal of Clinical Psychology': 1.014,
    'School Mental Health': 1.006,
    'Cognitive Research: Principles and Implications': 1.004,
    'Psychopathology': 1.001,
    'MMWR Recommendations and Reports': 23.962,
    'Morbidity and Mortality Weekly Report': 16.786,
    'Administrative Science Quarterly': 16.555,
    'National Vital Statistics Reports': 16.495,
    'MMWR Surveillance Summaries': 15.178,
    'Review of Economics and Statistics': 8.371,
    'Annual Review of Political Science': 8.346,
    'American Political Science Review': 7.734,
    'American Sociological Review': 7.412,
    'Nature Climate Change': 6.849,
    'Nature Sustainability': 6.568,
    'Journal of Business and Economic Statistics': 6.15,
    'Journal of Consumer Research': 6.024,
    'Review of Educational Research': 5.804,
    'Analytic Methods in Accident Research': 5.17,
    'Journal of Service Research': 4.981,
    'American Journal of Political Science': 4.963,
    'International Journal of Information Management': 4.906,
    'Political Analysis': 4.815,
    'International Organization': 4.798,
    'Leadership Quarterly': 4.331,
    'Strategic Organization': 4.284,
    'Annual Review of Sociology': 4.196,
    'Qualitative Research in Sport, Exercise and Health': 4.045,
    'Research Synthesis Methods': 3.991,
    'British Journal of Political Science': 3.893,
    'Journal of Organizational Behavior': 3.804,
    'Journal of Personality and Social Psychology': 3.799,
    'Comparative Political Studies': 3.766,
    'Communication Methods and Measures': 3.757,
    'Computers and Education': 3.682,
    'Lancet Healthy Longevity, The': 3.619,
    'Progress in Human Geography': 3.604,
    'European Journal of Political Research': 3.59,
    'Lancet Planetary Health, The': 3.586,
    'Educational Psychology Review': 3.566,
    'Tourism Management': 3.561,
    'Human Relations': 3.508,
    'Political Behavior': 3.456,
    'Annals of Tourism Research': 3.426,
    'Journal of Public Administration Research and Theory': 3.337,
    'Internet and Higher Education': 3.327,
    'Public Administration Review': 3.311,
    'ISPRS Journal of Photogrammetry and Remote Sensing': 3.308,
    'Educational Researcher': 3.304,
    'Educational Research Review': 3.269,
    'Information Systems Research': 3.257,
    'Journal of Travel Research': 3.249,
    'Transportation Research Part B: Methodological': 3.205,
    'Communications in Transportation Research': 3.188,
    'Contemporary Educational Psychology': 3.187,
    'Journal of Development Economics': 3.139,
    'Journal of Urban Economics': 3.076,
    'Political Communication': 3.061,
    'Journal of Politics': 3.048,
    'Structural Equation Modeling': 3.014,
    'Transportation Research, Part E: Logistics and Transportation Review': 3.005,
    'Journal of Population Economics': 2.986,
    'Review of Research in Education': 2.985,
    'Wiley Interdisciplinary Reviews: Climate Change': 2.972,
    'Journal of Sustainable Tourism': 2.966,
    'Business and Society': 2.965,
    'Global Environmental Change': 2.945,
    'Journal of Vocational Behavior': 2.926,
    'Transport Reviews': 2.898,
    'American Journal of Sociology': 2.884,
    'Transportation Research Part C: Emerging Technologies': 2.882,
    'Business Strategy and the Environment': 2.87,
    'Long Range Planning': 2.852,
    'Child Development Perspectives': 2.844,
    'Transportation Science': 2.827,
    'Communication Research': 2.779,
    'Sociological Science': 2.766,
    'Research and Politics': 2.704,
    'International Security': 2.694,
    'World Politics': 2.67,
    'Sociological Theory': 2.651,
    'Developmental Review': 2.649,
    'Digital Journalism': 2.634,
    'Political Psychology': 2.61,
    'Journal of Advertising': 2.606,
    'Journal of Communication': 2.605,
    'Tourism Geographies': 2.596,
    'Journal of Business Ethics': 2.59,
    'Political Science Research and Methods': 2.589,
    'Annual Review of Criminology': 2.58,
    'Journal of the American Planning Association': 2.572,
    'Linguistic Inquiry': 2.564,
    'eTransportation': 2.563,
    'World Bank Research Observer': 2.553,
    'Journal of Educational Psychology': 2.511,
    'Journal of Interactive Advertising': 2.505,
    'Antipode': 2.504,
    'World Development': 2.504,
    'Annals of the International Communication Association': 2.499,
    'Criminology': 2.497,
    'Journal of Applied Econometrics': 2.495,
    'Jahrbucher fur Nationalokonomie und Statistik': 2.494,
    'Artificial Intelligence Review': 2.49,
    'IEEE Transactions on Transportation Electrification': 2.487,
    'European Journal of Information Systems': 2.481,
    'International Journal of Information Management Data Insights': 2.479,
    'Clinical Child and Family Psychology Review': 2.466,
    'Modern Language Journal': 2.463,
    'Postdigital Science and Education': 2.453,
    'West European Politics': 2.451,
    'Journal of Peace Research': 2.444,
    'Trauma, Violence, and Abuse': 2.422,
    'Environmental Innovation and Societal Transitions': 2.418,
    'International Journal of Press/Politics': 2.412,
    'Accounting, Organizations and Society': 2.411,
    'Scientific data': 2.41,
    'Survey Research Methods': 2.41,
    'Learning and Instruction': 2.398,
    'Progress in Planning': 2.391,
    'Big Data and Society': 2.389,
    'Journal of Marriage and Family': 2.386,
    'Dialogues in Human Geography': 2.375,
    'Policy and Society': 2.37,
    'Quarterly Journal of Political Science': 2.364,
    'Socio-Economic Review': 2.346,
    'Journal of Teacher Education': 2.337,
    'Sociology of Education': 2.335,
    'Review of International Political Economy': 2.331,
    'Tobacco Control': 2.331,
    'Journal of Democracy': 2.322,
    'Government Information Quarterly': 2.321,
    'Journal of Second Language Writing': 2.314,
    'Journals of Gerontology - Series B Psychological Sciences and Social Sciences': 2.311,
    'IEEE Communications Standards Magazine': 2.306,
    'Sustainable Cities and Society': 2.305,
    'Review of Public Personnel Administration': 2.303,
    'Journal of Peasant Studies': 2.277,
    'Journal of the Learning Sciences': 2.267,
    'Sociological Methods and Research': 2.252,
    'Social Issues and Policy Review': 2.245,
    'Gender, Work and Organization': 2.237,
    'American Educational Research Journal': 2.234,
    'Perspectives on Public Management and Governance': 2.218,
    'Language Learning': 2.195,
    'Global Food Security': 2.173,
    'Energy Research and Social Science': 2.171,
    'Journal of Conflict Resolution': 2.164,
    'Journal of School Psychology': 2.16,
    'International Journal of Computer-Supported Collaborative Learning': 2.158,
    'Public Management Review': 2.157,
    'Natural Language and Linguistic Theory': 2.156,
    'International Journal of Urban and Regional Research': 2.149,
    'Perspectives on Politics': 2.148,
    'Journal of Experimental Political Science': 2.143,
    'Child Development': 2.135,
    'Corporate Social Responsibility and Environmental Management': 2.134,
    'Social Forces': 2.132,
    'Demography': 2.131,
    'Public Opinion Quarterly': 2.129,
    'Human Behavior and Emerging Technologies': 2.126,
    'Journal of Public Transportation': 2.118,
    'British Journal of Educational Technology': 2.116,
    'Science Education': 2.115,
    'Journal of Policy Analysis and Management': 2.111,
    'Higher Education for the Future': 2.107,
    'Information Processing and Management': 2.106,
    'Journal of Memory and Language': 2.104,
    'RSF': 2.101,
    'Current Opinion in Environmental Sustainability': 2.1,
    'Urban Studies': 2.1,
    'New Media and Society': 2.084,
    'International Journal of Advertising': 2.081,
    'Assessment and Evaluation in Higher Education': 2.079,
    'Studies in Second Language Acquisition': 2.077,
    'Journal of Urban Health': 2.076,
    'Landscape and Urban Planning': 2.065,
    'Current Issues in Tourism': 2.062,
    'European Societies': 2.06,
    'Reading Research Quarterly': 2.058,
    'Applied Economic Perspectives and Policy': 2.051,
    'International Journal of Educational Technology in Higher Education': 2.051,
    'Health Reports': 2.04,
    'Sustainable Development': 2.035,
    'Journal of Youth and Adolescence': 2.03,
    'Transportation Research, Part A: Policy and Practice': 2.029,
    'Transportation Research, Part D: Transport and Environment': 2.024,
    'Review of International Organizations': 2.017,
    'Environment and Planning D: Society and Space': 2.015,
    'Research on Language and Social Interaction': 2.013,
    'Information and Organization': 1.997,
    'Sociological Methodology': 1.996,
    'Work, Employment and Society': 1.988,
    'Transactions of the Association for Computational Linguistics': 1.985,
    'Social Science and Medicine': 1.978,
    'System': 1.972,
    'Policy Studies Journal': 1.97,
    'Biology of Sex Differences': 1.961,
    'Accident Analysis and Prevention': 1.956,
    'Ecosystem Services': 1.956,
    'Global Transitions': 1.953,
    'Journal of Economic Geography': 1.953,
    'Higher Education': 1.952,
    'Open Mind': 1.95,
    'Social Media and Society': 1.95,
    'Educational and Psychological Measurement': 1.949,
    'Weather and Climate Extremes': 1.941,
    'Academy of Management Learning and Education': 1.94,
    'Social Problems': 1.932,
    'Party Politics': 1.93,
    'Journal of European Public Policy': 1.922,
    'Planning Theory': 1.911,
    'European Journal of Psychology Applied to Legal Context': 1.91,
    'Journal of Archaeological Research': 1.907,
    'Asian Journal of Social Health and Behavior': 1.9,
    'Geography and Sustainability': 1.898,
    'Food Policy': 1.896,
    'European Journal of Teacher Education': 1.892,
    'Educational Administration Quarterly': 1.886,
    'Science Communication': 1.886,
    'Journal of Research in Science Teaching': 1.882,
    'Human Communication Research': 1.88,
    'Learning, Media and Technology': 1.879,
    'Distance Education': 1.878,
    'Telematics and Informatics': 1.878,
    'Tourism Review': 1.878,
    'International Journal of Bilingual Education and Bilingualism': 1.873,
    'IEEE Transactions on Information Theory': 1.87,
    'Resources Policy': 1.869,
    'Gender and Society': 1.866,
    'Sustainability: Science, Practice, and Policy': 1.856,
    'Journal of Transport Geography': 1.852,
    'Transport Policy': 1.852,
    'Environmental Politics': 1.851,
    'Vehicular Communications': 1.846,
    'Journal of Industrial Ecology': 1.843,
    'NJAS - Wageningen Journal of Life Sciences': 1.841,
    'Mathematical Finance': 1.837,
    'Economic Geography': 1.833,
    'Educational Evaluation and Policy Analysis': 1.829,
    'Artificial Intelligence': 1.819,
    'Applied Developmental Science': 1.818,
    'Information Communication and Society': 1.807,
    'Digital Communications and Networks': 1.796,
    'International Journal of Physical Distribution and Logistics Management': 1.795,
    'Political Research Quarterly': 1.792,
    'Democratization': 1.785,
    'SSM - Population Health': 1.785,
    'Energy and Built Environment': 1.782,
    'European Political Science Review': 1.776,
    'Entrepreneurship and Regional Development': 1.774,
    'Ambio': 1.769,
    'International Studies Quarterly': 1.768,
    'Food Security': 1.764,
    'Review of Economics of the Household': 1.762,
    'European Sociological Review': 1.758,
    'Regional Studies': 1.757,
    'Crime Science': 1.755,
    'Computer Assisted Language Learning': 1.754,
    'Social Psychiatry and Psychiatric Epidemiology': 1.75,
    'American Review of Public Administration': 1.745,
    'Oxford Bulletin of Economics and Statistics': 1.743,
    'Criminology and Public Policy': 1.737,
    'Journal of Mixed Methods Research': 1.736,
    'Cognition and Instruction': 1.731,
    'Developmental Psychology': 1.731,
    'Child Abuse and Neglect': 1.721,
    'Journal of Aging and Social Policy': 1.721,
    'Journal of Planning Literature': 1.72,
    'Cities': 1.718,
    'Studies in Higher Education': 1.716,
    'Applied Linguistics': 1.71,
    'Television and New Media': 1.708,
    'Interpreting': 1.704,
    'Quaternary Science Reviews': 1.701,
    'Computers and Education: Artificial Intelligence': 1.7,
    'Work, Aging and Retirement': 1.699,
    'Perspectives on Sexual and Reproductive Health': 1.698,
    'Cognition': 1.691,
    'Economy and Society': 1.69,
    'Urban Geography': 1.688,
    'Active Learning in Higher Education': 1.686,
    'Global Environmental Politics': 1.68,
    'Environmental Science and Policy': 1.678,
    'Policy Design and Practice': 1.678,
    'Journal of Educational Computing Research': 1.673,
    'Theory and Society': 1.673,
    'New Technology, Work and Employment': 1.669,
    'International Journal of STEM Education': 1.668,
    'Research in Social Stratification and Mobility': 1.666,
    'International Journal of Qualitative Methods, The': 1.664,
    'Social Science Computer Review': 1.662,
    'Land Use Policy': 1.656,
    'TESOL Quarterly': 1.655,
    'Annals of the American Association of Geographers': 1.654,
    'Sociology of Religion': 1.651,
    'Geography Compass': 1.65,
    'Internet Research': 1.65,
    'Journal of Black Psychology, The': 1.644,
    'Media, Culture and Society': 1.641,
    'European Journal of International Relations': 1.639,
    'Econometric Theory': 1.638,
    'Educational Studies in Mathematics': 1.636,
    'Group Processes and Intergroup Relations': 1.631,
    'Early Childhood Research Quarterly': 1.63,
    'Journal of Cheminformatics': 1.63,
    'Journal of Computer Assisted Learning': 1.629,
    'Medical Education': 1.629,
    'Transportation Research Interdisciplinary Perspectives': 1.629,
    'International Migration Review': 1.626,
    'Canadian Journal of Political Science': 1.621,
    'Scientific Studies of Reading': 1.619,
    'Journal of Professional Capital and Community': 1.618,
    'Teaching and Teacher Education': 1.616,
    'Journal of Law and Economics': 1.612,
    'Quantitative Science Studies': 1.612,
    'Language Testing': 1.609,
    'Journal of Family Theory and Review': 1.608,
    'Electoral Studies': 1.606,
    'Computers and Security': 1.605,
    'Journal of Economic Psychology': 1.601,
    'Journal of Experimental Social Psychology': 1.596,
    'New Political Economy': 1.595,
    'Journalism Studies': 1.592,
    'American Journal of Criminal Justice': 1.589,
    'Environment and Planning A': 1.588,
    'Building and Environment': 1.584,
    'Travel Behaviour and Society': 1.584,
    'Journal of Intellectual Capital': 1.582,
    'Academic Medicine': 1.579,
    'Socius': 1.574,
    'Journal of Criminal Justice': 1.573,
    'CBE Life Sciences Education': 1.57,
    'Environment and Urbanization': 1.57,
    'Urban Education': 1.57,
    'Studies in Science Education': 1.568,
    'Computers, Environment and Urban Systems': 1.567,
    'Political Studies': 1.566,
    'African Affairs': 1.565,
    'Justice Quarterly': 1.564,
    'Public Administration': 1.561,
    'Theory and Research in Social Education': 1.561,
    'Cognitive Psychology': 1.56,
    'Journal of Higher Education': 1.55,
    'Media Psychology': 1.545,
    'Artificial Intelligence and Law': 1.544,
    'Transactions of the Institute of British Geographers': 1.544,
    'Sustainability Science': 1.535,
    'Feminist Economics': 1.534,
    'Journal of Happiness Studies': 1.528,
    'Journal of Research on Adolescence': 1.518,
    'Educational Technology Research and Development': 1.516,
    'Critical Studies in Education': 1.513,
    'Annual Review of Linguistics': 1.511,
    'Journalism': 1.511,
    'Qualitative Research': 1.511,
    'Communication Theory': 1.505,
    'Language Teaching': 1.496,
    'Journal of Community Health': 1.494,
    'Adolescent Research Review': 1.491,
    'Technology in Society': 1.491,
    'Critical Perspectives on Accounting': 1.483,
    'Sociology': 1.482,
    'Journal of Social Issues': 1.477,
    'Journal of Learning Disabilities': 1.476,
    'Transportation': 1.475,
    'Evolutionary Anthropology': 1.474,
    'Sociology of Race and Ethnicity': 1.474,
    'Internet Policy Review': 1.473,
    'International Journal of Logistics Management': 1.472,
    'Landscape Ecology': 1.471,
    'npj Science of Learning': 1.468,
    'Cyberpsychology, Behavior, and Social Networking': 1.466,
    'Journal of Contextual Behavioral Science': 1.465,
    'Higher Education Research and Development': 1.462,
    'International Affairs': 1.46,
    'European Union Politics': 1.459,
    'Journal of Urban Technology': 1.457,
    'Journal of racial and ethnic health disparities': 1.453,
    'Journal of Race, Ethnicity and Politics': 1.452,
    'Regulation and Governance': 1.449,
    'Health and Place': 1.448,
    'Journal of Migration and Health': 1.445,
    'Journal of Computers in Education': 1.442,
    'Language Teaching Research': 1.44,
    'Journal of Ethnic and Migration Studies': 1.435,
    'Public Relations Review': 1.432,
    'Equity and Excellence in Education': 1.43,
    'Legislative Studies Quarterly': 1.43,
    'Safety Science': 1.429,
    'Natural Language Semantics': 1.426,
    'International Environmental Agreements: Politics, Law and Economics': 1.418,
    'Online Learning Journal': 1.417,
    'Psychology of Women Quarterly': 1.417,
    'Comunicar': 1.412,
    'Government and Opposition': 1.41,
    'Journal of Education Policy': 1.41,
    'Public Understanding of Science': 1.408,
    'World Bank Economic Review': 1.405,
    'Habitat International': 1.403,
    'ZDM - International Journal on Mathematics Education': 1.403,
    'International Journal of Social Research Methodology: Theory and Practice': 1.402,
    'Journal of Air Transport Management': 1.402,
    'Family Process': 1.401,
    'British Journal of Educational Psychology': 1.399,
    'Men and Masculinities': 1.397,
    'Career Development and Transition for Exceptional Individuals': 1.395,
    'Race Ethnicity and Education': 1.388,
    'Work and Occupations': 1.388,
    'International Journal of Educational Research Open': 1.387,
    'School Leadership and Management': 1.379,
    'Earth System Governance': 1.376,
    'Educational Policy': 1.375,
    'Journal of Information Technology': 1.375,
    'Journal of Personal Selling and Sales Management': 1.372,
    'Journal of Learning Analytics': 1.369,
    'Global Health Research and Policy': 1.368,
    'Learning and Individual Differences': 1.367,
    'Environmental Impact Assessment Review': 1.362,
    'Politics and Gender': 1.359,
    'Journal of Chemical Information and Modeling': 1.357,
    'Journal of Hospitality and Tourism Research': 1.357,
    'Advances in Life Course Research': 1.356,
    'European Urban and Regional Studies': 1.356,
    'Journal of Risk Research': 1.354,
    'Political Geography': 1.354,
    'Journal of Research on Technology in Education': 1.352,
    'Transportation Research Part F: Traffic Psychology and Behaviour': 1.352,
    'Geoforum': 1.351,
    'IEEE Transactions on Computational Social Systems': 1.351,
    'Journalism and Mass Communication Quarterly': 1.348,
    'Stanford Law Review': 1.348,
    'Journal of Law, Economics, and Organization': 1.345,
    'Review of Black Political Economy': 1.345,
    'Comparative Migration Studies': 1.34,
    'Journal of Computing in Higher Education': 1.339,
    'Journal of Human Evolution': 1.338,
    'Journal for Research in Mathematics Education': 1.337,
    'International Journal of Stress Management': 1.336,
    'American Politics Research': 1.334,
    'Mass Communication and Society': 1.333,
    'Catalan Journal of Linguistics': 1.332,
    'Contemporary Security Policy': 1.328,
    'AERA Open': 1.327,
    'Journal of English for Academic Purposes': 1.323,
    'American Journal of Community Psychology': 1.32,
    'Climate and Development': 1.318,
    'Journal of Rural Studies': 1.316,
    'International Journal of Geographical Information Science': 1.315,
    'Journal of Education for Teaching': 1.315,
    'Sex Roles': 1.314,
    'Gender and Education': 1.312,
    'Science and Education': 1.311,
    'Language, Culture and Curriculum': 1.31,
    'Journal of European Social Policy': 1.309,
    'Journal of Literacy Research': 1.308,
    'Exceptional Children': 1.306,
    'Prospects': 1.306,
    'Chinese Journal of Communication': 1.305,
    'Governance': 1.305,
    'Mobile Media and Communication': 1.303,
    'Youth Violence and Juvenile Justice': 1.3,
    'International Journal of Tourism Research': 1.298,
    'Journal of Fluency Disorders': 1.296,
    'Policy and Politics': 1.295,
    'Cultural Diversity and Ethnic Minority Psychology': 1.293,
    'Second Language Research': 1.29,
    'International Journal of Public Health': 1.289,
    'Annual Review of Anthropology': 1.284,
    'Consumption Markets and Culture': 1.281,
    'Journal of Science Education and Technology': 1.28,
    'American Antiquity': 1.278,
    'International Journal of Learner Corpus Research': 1.27,
    'Journal of Informetrics': 1.269,
    'International Journal of Behavioral Development': 1.268,
    'International Journal of Human Computer Studies': 1.268,
    'Economics of Education Review': 1.266,
    'Development and Change': 1.265,
    'European Journal of Psychology of Education': 1.264,
    'Policy Insights from the Behavioral and Brain Sciences': 1.263,
    'Technology, Pedagogy and Education': 1.261,
    'Journal of Accounting and Public Policy': 1.26,
    'Mindfulness': 1.259,
    'Perspectives on Medical Education': 1.259,
    'Policy Sciences': 1.256,
    'Comparative Education': 1.254,
    'Theory into Practice': 1.254,
    'Environment and Planning C: Politics and Space': 1.253,
    'Journal of Sex Research': 1.25,
    'Education and Information Technologies': 1.249,
    'Housing, Theory and Society': 1.249,
    'Business and Politics': 1.248,
    'Regional Science and Urban Economics': 1.247,
    'Research in Higher Education': 1.246,
    'Health Communication': 1.243,
    'Language': 1.243,
    'Journal of Enterprise Information Management': 1.242,
    'Topics in Cognitive Science': 1.242,
    'Journal of Research in Reading': 1.24,
    'Policy and Internet': 1.24,
    'Administration and Society': 1.238,
    'Computational Linguistics': 1.238,
    'Journal of Traffic and Transportation Engineering (English Edition)': 1.236,
    'British Journal of Sociology': 1.231,
    'Design Studies': 1.231,
    'User Modeling and User-Adapted Interaction': 1.231,
    'Journal of Broadcasting and Electronic Media': 1.229,
    'Transportation Geotechnics': 1.229,
    'Educational Psychology': 1.227,
    'Journal of World Prehistory': 1.225,
    'Journal of Diversity in Higher Education': 1.222,
    'Geoenvironmental Disasters': 1.221,
    'Education Finance and Policy': 1.219,
    'Journal of Archaeological Science': 1.218,
    'Race and Social Problems': 1.218,
    'Medical Teacher': 1.217,
    'Cambridge Journal of Regions, Economy and Society': 1.216,
    'Annual Review of Applied Linguistics': 1.213,
    'EURO Journal on Transportation and Logistics': 1.213,
    'Journal of Quantitative Criminology': 1.213,
    'Educational Assessment, Evaluation and Accountability': 1.211,
    'Journal of European Integration': 1.209,
    'Railway Engineering Science': 1.209,
    'Anthropological Theory': 1.208,
    'Bilingualism': 1.208,
    'Communication and Sport': 1.204,
    'Human Resources for Health': 1.203,
    'International Public Management Journal': 1.203,
    'Social Networks': 1.203,
    'Conflict and Health': 1.201,
    'Advances in Health Sciences Education': 1.2,
    'Computer Standards and Interfaces': 1.199,
    'Energy for Sustainable Development': 1.199,
    'Journal of Regional Science': 1.199,
    'Information Technology for Development': 1.196,
    'Social Movement Studies': 1.195,
    'Progress in Disaster Science': 1.194,
    'Theory, Culture and Society': 1.193,
    'Telecommunications Policy': 1.192,
    'Tijdschrift Voor Economische en Sociale Geografie': 1.192,
    'Innovation in Aging': 1.191,
    'Review of Higher Education': 1.191,
    'European Journal of Communication': 1.19,
    'Reading and Writing': 1.19,
    'Phonology': 1.188,
    'Learning Environments Research': 1.187,
    'English for Specific Purposes': 1.185,
    'Journal of Educational and Behavioral Statistics': 1.185,
    'Urban Climate': 1.185,
    'Interactive Technology and Smart Education': 1.184,
    'Journal of Development Studies': 1.179,
    'Earth Surface Processes and Landforms': 1.177,
    'Economic Development and Cultural Change': 1.177,
    'ELT Journal': 1.174,
    'Mind and Language': 1.174,
    'Poetics': 1.173,
    'ReCALL': 1.173,
    'JMIR Aging': 1.172,
    'Journal of Research in Music Education': 1.171,
    'Interactive Learning Environments': 1.17,
    'Nonprofit and Voluntary Sector Quarterly': 1.17,
    'Science Technology and Human Values': 1.17,
    'Population and Development Review': 1.169,
    'Journal of Specialised Translation': 1.168,
    'Teachers and Teaching: Theory and Practice': 1.167,
    'Journal of Experimental Psychology: Learning Memory and Cognition': 1.166,
    'Information Systems Management': 1.16,
    'Research in Science Education': 1.16,
    'Sleep Health': 1.16,
    'Economics of Transportation': 1.158,
    'Social Science Research': 1.156,
    'International Journal of Health Policy and Management': 1.155,
    'Political Studies Review': 1.154,
    'Foreign Language Annals': 1.153,
    'Journal of Linguistics': 1.153,
    'Thinking Skills and Creativity': 1.152,
    'Policy Reviews in Higher Education': 1.151,
    'Physical Education and Sport Pedagogy': 1.15,
    'Sustainable and Resilient Infrastructure': 1.15,
    'Journal of Engineering Education': 1.149,
    'Teacher Education and Special Education': 1.147,
    'Comparative Politics': 1.146,
    'International Journal of Sustainable Transportation': 1.146,
    'Land Degradation and Development': 1.145,
    'Teaching and Learning in Medicine': 1.145,
    'Corpus Linguistics and Linguistic Theory': 1.141,
    'Journal of Experimental Criminology': 1.141,
    'Journal of Studies in International Education': 1.141,
    'American Journal of Distance Education': 1.14,
    'IEEE Transactions on Learning Technologies': 1.14,
    'Studies in Comparative International Development': 1.14,
    'Applied Geography': 1.138,
    'Target': 1.138,
    'China Journal': 1.136,
    'International Theory': 1.133,
    'International Journal of Disaster Risk Reduction': 1.132,
    'Neurobiology of Language': 1.132,
    'Socio-Economic Planning Sciences': 1.131,
    'Syntax': 1.131,
    'International Journal of Transgender Health': 1.13,
    'Transgender Health': 1.13,
    'Sport, Education and Society': 1.129,
    'Signs': 1.128,
    'Urban Affairs Review': 1.128,
    'Linguistics and Philosophy': 1.127,
    'Obesity Facts': 1.127,
    'Eurasian Geography and Economics': 1.125,
    'Language Learning and Technology': 1.125,
    'Forest Policy and Economics': 1.124,
    'Mobilities': 1.124,
    'Journal of Applied Behavior Analysis': 1.123,
    'Security Dialogue': 1.123,
    'Environmental Education Research': 1.122,
    "Women's Health Issues": 1.122,
    'Health Equity': 1.12,
    'Transportmetrica A: Transport Science': 1.12,
    'Journal of Aging and Health': 1.119,
    'Journal of Mathematics Teacher Education': 1.119,
    'Annual Review of Law and Social Science': 1.117,
    'Chinese Sociological Review': 1.117,
    'Environmental Development': 1.117,
    'Journal of Studies on Alcohol and Drugs': 1.115,
    'BMJ Nutrition, Prevention and Health': 1.114,
    'European Addiction Research': 1.114,
    'Administrative Theory and Praxis': 1.112,
    'Conflict Management and Peace Science': 1.112,
    'Sociological Review': 1.112,
    'Journal of Medical Ethics': 1.111,
    'International Journal of Artificial Intelligence in Education': 1.11,
    'Aquaculture, Economics and Management': 1.109,
    'Journal of Economic Inequality': 1.109,
    'Public Performance &amp; Management Review': 1.109,
    'Translation, Cognition and Behavior': 1.109,
    'Technology, Knowledge and Learning': 1.108,
    'Measurement: Journal of the International Measurement Confederation': 1.106,
    'Australasian Journal of Educational Technology': 1.104,
    'European Physical Education Review': 1.103,
    'Research Papers in Education': 1.103,
    'Language Awareness': 1.102,
    'Population and Environment': 1.102,
    'Educational Review': 1.099,
    'Annals of Tourism Research Empirical Insights': 1.096,
    'Geographical Analysis': 1.096,
    'Journal of Leadership and Organizational Studies': 1.096,
    'Journal of Common Market Studies': 1.095,
    'Remedial and Special Education': 1.095,
    'Assessing Writing': 1.094,
    'Tourism Economics': 1.092,
    'Journal of Social and Personal Relationships': 1.091,
    'Environment and Planning E: Nature and Space': 1.089,
    'Language, Cognition and Neuroscience': 1.089,
    'American Behavioral Scientist': 1.088,
    'Journal of Planning Education and Research': 1.088,
    'Palgrave Communications': 1.086,
    'Journal of Pragmatics': 1.085,
    'Journal of Archaeological Method and Theory': 1.084,
    'Journal of Intelligent and Connected Vehicles': 1.083,
    'Population, Space and Place': 1.082,
    'Journal of Mathematical Behavior': 1.081,
    'Groundwater for Sustainable Development': 1.08,
    'Information Technology and People': 1.08,
    'Educational Management Administration and Leadership': 1.079,
    'Journal of Agrarian Change': 1.077,
    'Yale Law Journal': 1.077,
    'Ethnic and Racial Studies': 1.076,
    'International Studies Review': 1.076,
    'Journalism &amp; communication monographs': 1.076,
    'Journal of Benefit-Cost Analysis': 1.072,
    'Journal of Higher Education Policy and Management': 1.072,
    'International Journal of Child-Computer Interaction': 1.071,
    'Evolution: Education and Outreach': 1.07,
    'Medical Education Online': 1.07,
    'Metacognition and Learning': 1.07,
    'Review of International Studies': 1.07,
    'Studies in Second Language Learning and Teaching': 1.07,
    'Journal of Semantics': 1.069,
    'European Journal of Ageing': 1.067,
    'Medical Reference Services Quarterly': 1.067,
    'Perspectives: Studies in Translation Theory and Practice': 1.066,
    'Tourism Recreation Research': 1.066,
    'Games and Culture': 1.065,
    'Journal of Environmental Assessment Policy and Management': 1.064,
    'Journal of Health Communication': 1.064,
    'Psychology of Sexual Orientation and Gender Diversity': 1.061,
    'Australian Educational Researcher': 1.06,
    'Journal of Writing Research': 1.059,
    'Qualitative Inquiry': 1.059,
    'Current Issues in Language Planning': 1.058,
    'International Journal of Public Opinion Research': 1.058,
    'Linguistic Typology': 1.058,
    'European Planning Studies': 1.057,
    'Convergence': 1.056,
    'International Journal of Management Education': 1.056,
    'International Journal of Science and Mathematics Education': 1.055,
    'Sociology Compass': 1.055,
    'Canadian Public Policy/ Analyse de Politiques': 1.054,
    'Journal of Loss and Trauma': 1.053,
    'IEEE Transactions on Human-Machine Systems': 1.051,
    'Online Social Networks and Media': 1.051,
    'Public Personnel Management': 1.051,
    'Journal of Curriculum Studies': 1.05,
    'Journal of Transport and Health': 1.05,
    'Educational Technology and Society': 1.049,
    'Journal of Flood Risk Management': 1.049,
    'Journal of Experimental Education': 1.047,
    'American Journal of Cultural Sociology': 1.045,
    'Ethnicity and Health': 1.045,
    'International Journal of Research in Undergraduate Mathematics Education': 1.044,
    'Early Education and Development': 1.043,
    'Housing Studies': 1.043,
    'Social Psychology of Education': 1.042,
    'Economics and Human Biology': 1.041,
    'Journal of Advertising Research': 1.041,
    'Discourse Studies': 1.04,
    'European Journal of Cultural Studies': 1.04,
    'Climate Risk Management': 1.037,
    'Sociology of Health and Illness': 1.037,
    'International Review for the Sociology of Sport': 1.035,
    'Journal of New Approaches in Educational Research': 1.034,
    'Marine Policy': 1.034,
    'Behavioral Disorders': 1.033,
    'Criminal Justice and Behavior': 1.033,
    'Journal of Urban Management': 1.032,
    'Research in Transportation Economics': 1.03,
    'Child Maltreatment': 1.029,
    'European Journal of Political Economy': 1.029,
    'European Transport Research Review': 1.029,
    'Journal of Elections, Public Opinion and Parties': 1.027,
    'Environment and Planning B: Urban Analytics and City Science': 1.026,
    'Language Assessment Quarterly': 1.026,
    'Representation': 1.025,
    'Journal of Leisure Research': 1.021,
    'Journal of International Economic Law': 1.019,
    'Scientometrics': 1.019,
    'International Journal of Educational Research': 1.017,
    'Journal of Environment and Development': 1.017,
    'School Effectiveness and School Improvement': 1.017,
    'Foreign Policy Analysis': 1.016,
    'Past and Present': 1.016,
    'International Journal of Disaster Risk Science': 1.015,
    'Journal of the Association for Information Science and Technology': 1.015,
    'Physical Review Physics Education Research': 1.015,
    'Psychology of Violence': 1.015,
    'Smart Cities': 1.015,
    'Archives of Gerontology and Geriatrics': 1.008,
    'Journal of Language Aggression and Conflict': 1.007,
    'School Mental Health': 1.006,
    'City and Community': 1.005,
    'American Ethnologist': 1.004,
    'International Journal of Science Education': 1.003,
    'Research Evaluation': 1.003,
    'College and Research Libraries': 1.002,
    'Journal of School Violence': 1.001,
    'Annual Review of Animal Biosciences': 3.696,
    'GeroScience': 1.57,
    'Vaccine: X': 1.509,
    'Vaccine': 1.493,
    'Journal of Veterinary Internal Medicine': 1.171,
    'Animal Frontiers': 1.163,
    'Veterinary Quarterly': 1.141,
    'Animal Nutrition': 1.12,
    'Veterinary Clinics of North America - Food Animal Practice': 1.102,
};

export default journalScore;