import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Paper, AppBar, TextField, Button, Typography, Container, Divider, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

import paperSearchAnswer from '../../images/paperSearchAnswer.png';
import library from '../../images/library.png';
import fileViewer from '../../images/fileViewer.png';

// colors
// main button green: #2c8888 ; blue: #0d88c2 ; purple: #5908a9
// paper background green: #052424 ; blue: #011118 ; purple: #0c0117
// paper border green: #052424 ; blue: #03293a ; purple: #210239

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles({
  gradientText: {
    background: 'linear-gradient(90deg, #10a2ca 30%, #11bf7c 70%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  },
});

const LandingIntro = () => {
  //const classes = useStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    marginBottom: '10px',
    border: `1px solid #dddddd`,
    borderRadius: '10px',
    '&:not(:last-child)': {
      //borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    //color: 'lightGrey',
    borderRadius: '10px',
    //background: 'linear-gradient(90deg, #001013 30%, #00100b 70%)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderRadius: '10px',
    //background: 'linear-gradient(90deg, #001013 30%, #00100b 70%)',
    padding: theme.spacing(2),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  return (
    <Grid md={11.5} xl={10}>
      <Grid container justifyContent={isMobile ? "center" : "center"} flexDirection="column" marginTop={isMobile ? "30px" : "60px"} marginBottom='36px' padding='0 5% 0 5%'>
      {!isMobile && (
      <Grid>
        <Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'36px', fontWeight: 'bold'} : { fontSize:'60px', fontWeight: 'bold' }} >Supercharge</Typography>
        <Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'36px', fontWeight: 'bold'} : { fontSize:'60px', fontWeight: 'bold' }} >your research with AI</Typography>
      </Grid>
      )}
      {isMobile && (
      <Grid>
        <Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'36px', fontWeight: 'bold'} : { fontSize:'60px', fontWeight: 'bold' }} >Supercharge</Typography>
        <Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'36px', fontWeight: 'bold'} : { fontSize:'60px', fontWeight: 'bold' }} >your research</Typography>
        <Typography variant="h4" textAlign={isMobile ? "center" : "center"} sx={isMobile ? {fontSize:'36px', fontWeight: 'bold'} : { fontSize:'60px', fontWeight: 'bold' }} >with AI</Typography>
      </Grid>
      )}
      
      {isMobile && (
      <Grid>
        <Typography color="textSecondary" variant="h6" textAlign={isMobile ? "center" : "center"} sx={{ fontSize: '18px', marginTop:'20px' }}>Answer questions scientifically, analyze papers and generate research ideas.</Typography>
      </Grid>
      )}
      {!isMobile && (
      <Grid>
          <Typography variant="h6" color="textSecondary" textAlign={isMobile ? "center" : "center"} sx={{ fontSize: '22px', marginTop:'15px' }} >Answer questions scientifically, analyze papers</Typography>
          <Typography variant="h6" color="textSecondary" textAlign={isMobile ? "center" : "center"} sx={{ fontSize: '22px' }} >and generate research ideas.</Typography>
      </Grid>
      )}

      </Grid>

      <Grid>
        <Grid container display="flex" flexDirection="column" alignItems="center" marginTop='-10px'>
            <Button component={Link} to="/auth"
            sx={{ color: 'white', background: 'linear-gradient(90deg, #39a1be 0%, #39be8b 100%)', borderRadius: '10px', textTransform: 'none', fontSize: '18px', fontWeight: 'bold', "&:hover": { boxShadow: "#000000", background: 'linear-gradient(90deg, #288a9f 0%, #2ea77a 100%)' } }}
            variant="contained" size="large">
                Sign up
            </Button>
        </Grid>

        <Grid margin={isMobile ? '120px 10px 120px 10px' : '150px 60px 150px 60px'}>        
        </Grid>
                    
        <Grid margin={isMobile ? '0px 5% 40px 5%' : '0px 100px 40px 100px'}>
            <Typography variant="h4" sx={isMobile ? {fontSize:'26px', fontWeight: 'bold'} : {fontSize:'32px', fontWeight: 'bold' }}>
              Research made easy
            </Typography>
        </Grid>

        <Grid container display="flex" flexDirection="column" justifyContent="center" padding={isMobile ? '0px 5% 0px 5%' : '0px 100px 0px 100px'} spacing={2}>
            <Grid item display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Grid md={3.5} variant="outlined" elevation={0} sx={isMobile ? {} : {padding: '15px'}}>
                    <Typography className={classes.gradientText} style={isMobile ? {fontSize: '22px'} : {fontSize: '24px'}} sx={{ fontWeight: 'bold', marginBottom: '10px'}}>
                      {/*<ScienceOutlinedIcon sx={{color:'#b4e6e5', margin: '0 2px -5px 0'}}/>*/}
                      Answer any question scientifically
                    </Typography>
                    <Typography color="textSecondary">
                      Get answers and ideas based on existing scientific literature.
                    </Typography>
                </Grid>
                <Grid md={9} elevation={0} sx={isMobile ? {background: '#fafafa', padding: '10px 10px 5px 10px', borderRadius: '10px', margin:'20px 0px 50px 0px'} : { background: `#fafafa`, padding: '8px 8px 0px 8px', borderRadius: '10px', margin:'15px 0px 80px 20px' }} //#041d1d
                >
                  <img src={paperSearchAnswer} alt="paperSearchAnswer" style={{ width: '100%', height: 'auto', borderRadius: '10px' }}></img>
                </Grid>
            </Grid>
            <Grid item display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Grid md={3.5} variant="outlined" elevation={0} sx={isMobile ? {} : {padding: '15px'}}>
                    <Typography className={classes.gradientText} style={isMobile ? {fontSize: '22px'} : {fontSize: '24px'}} sx={{fontWeight: 'bold', marginBottom: '10px'}}>
                      {/*<DocumentScannerOutlinedIcon sx={{color:'#b4e6e5', margin: '0 2px -5px 0'}}/>*/}
                      Quickly analyze research papers
                    </Typography>
                    <Typography color="textSecondary">
                      Open papers, analyze them and interact with their content.
                    </Typography>
                </Grid>
                <Grid md={9} elevation={0}  sx={isMobile ? {background: '#fafafa', padding: '10px 10px 5px 10px', borderRadius: '10px', margin:'20px 0px 50px 0px'} : { background: `#fafafa`, padding: '8px 8px 0px 8px', borderRadius: '10px', margin:'15px 0px 80px 20px' }} //#041d1d
                >
                  <img src={fileViewer} alt="fileViewer" style={{ width: '100%', height: 'auto', borderRadius: '10px' }}></img>
                </Grid>
            </Grid>
            <Grid item display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Grid md={3.5} variant="outlined" elevation={0} sx={isMobile ? {} : {padding: '15px'}}>
                    <Typography className={classes.gradientText} style={isMobile ? {fontSize: '22px'} : {fontSize: '24px'}} sx={{fontWeight: 'bold', marginBottom: '10px'}}>
                      {/*<AppsOutlinedIcon sx={{color:'#b4e6e5', margin: '0 2px -5px 0'}}/>*/}
                      Curate your personal research library
                    </Typography>
                    <Typography color="textSecondary">
                      Create collections and easily manage your workspace.
                    </Typography>
                </Grid>
                <Grid md={9} elevation={0}  sx={isMobile ? {background: '#fafafa', padding: '10px 10px 5px 10px', borderRadius: '10px', margin:'20px 0px 0px 0px'} : { background: `#fafafa`, padding: '8px 8px 0px 8px', borderRadius: '10px', margin:'15px 0px 0px 20px' }} //#041d1d
                >
                  <img src={library} alt="library" style={{ width: '100%', height: 'auto', borderRadius: '10px' }}></img>
                </Grid>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '120px 10px 120px 10px' : '170px 60px 170px 60px'}>              
        </Grid>

        <Grid margin={isMobile ? '0px 5% 48px 5%' : '0px 100px 55px 100px'}>
          <Typography variant="h4" sx={isMobile ? {fontSize:'26px', fontWeight: 'bold'} : { fontSize:'32px', fontWeight: 'bold' }}>
            FAQ
          </Typography>
        </Grid>
        <Grid padding={isMobile ? '0px 5% 0px 5%' : '0px 115px 0px 115px'}>
          <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
            <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
              <Typography>What is Lifa?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lifa is a research-based, AI-powered text generation tool. It has a conversational interface and a contextual awareness.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>For what can I use Lifa?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can use Lifa to:
              </Typography>
              <Typography>
                (i) generate answers and research ideas backed up by the scientific literature;
              </Typography>
              <Typography>
                (ii) explore and analyze relevant papers;
              </Typography>
              <Typography>
                (iii) create and organize your personal research library.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>How does Lifa work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lifa combines artificial intelligence, semantic search technology, and the Semantic Scholar corpus of over 200 million research papers.
                It understands the meaning of your queries, identifies the most relevant papers or excerpts, and generates answers with cited sources.
              </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography>How accurate are the answers?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lifa’s answers are based on the existing scientific literature. Sources are always cited. Nevertheless, as with any new AI technology, we recommend verifying the generated results before using them. We do our best to continuously improve our technology.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
              <Typography>Can I use Lifa for free?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Today, Lifa is 100% free to use. In the future, we intend to release a paid version of the product.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid margin={isMobile ? '100px 10px 100px 10px' : '100px 60px 100px 60px'}>        
        </Grid>

        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center" spacing={isMobile? 1 : 3}
        sx={{ margin: '0px 0px 30px 0px' }}>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/about" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    About
                </Link>
            </Typography>
            </Grid>
            <Grid item>
            <Typography sx={{ color: 'Grey' }}>
                <Link 
                    to="/contact" 
                    style={{ color: 'Grey', textDecoration: 'none' }}
                >
                    Contact
                </Link>
            </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
                <Link 
                  to="/terms" 
                  style={{ color: 'Grey', textDecoration: 'none' }}
                >
                  Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey' }}>
              <Link 
                to="/privacy" 
                style={{ color: 'Grey', textDecoration: 'none' }}
              >
                Privacy
              </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'Grey'}}>
                <a href={`https://twitter.com/lifa_ai`} target="_blank" style={{color: 'Grey', "textDecoration": "none"}}>Twitter</a>
              </Typography>
            </Grid>
        </Grid>

        <Grid margin={isMobile ? '0px 10px 20px 10px' : '0px 60px 20px 60px'}>        
        </Grid>

      </Grid>

    </Grid>
  );
};

export default LandingIntro;