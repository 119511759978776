import React from 'react';
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useSelector } from 'react-redux';

import Post from './Post/Post'
//import useStyles from './styles'; // unused

const Posts = ({ posts, isLoading, setCurrentId, papers, scope }) => {
    //const { posts, isLoading } = useSelector((state) => state.posts); // props added to Home and UserProfile so we can show the posts of the concerned state
    //const classes = useStyles(); // unused

    if(!posts.length && !isLoading && (scope === "searches")) return 'No searches have been added to this collection.';
    if(!posts.length && !isLoading && (scope === "files")) return 'No PDF documents have been added to this collection.';
    
    return (
        isLoading ? <CircularProgress /> : (
            <Grid container alignItems="center" justifyContent="center" spacing={0}>
                {posts.map((post) => (
                    <Grid key={post._id} item xs={12} sm={12} md={12} lg={12}>
                        <Divider sx={{margin: '0px 5px 0px 5px'}} />
                        <Post post={post} setCurrentId={setCurrentId} papers={papers}/>
                    </Grid>
                ))}
            </Grid>
        )
    );
}
// !posts.length ? <CircularProgress /> : if 0 posts, circular progress turning - if >0, show the posts
//xs: size on mobile, sm: size on small-medium devices, md medium, lg large (12 divided by something)

export default Posts;