import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Box, Tooltip, IconButton, TextField, InputAdornment, Grid, LinearProgress, Paper, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import decode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';

import { addFileChatMessage } from '../../actions/posts';

import Filters from "../Filters/Filters";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledAppBar from "./bottombarStyles";

const theme = createTheme();
const PREFIX = 'Navbar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};


const Bottombar = ({ postType, handleSend, loading }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const userId = user?.result?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const [query, setQuery] = useState("");
    const [answerFilter, setAnswerFilter] = useState('direct');
    const [sourceFilter, setSourceFilter] = useState('papers');
    
    //useEffect(() => {
    //  console.log(messages)
    //}, [messages]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    const updateAnswerFilter = (newAnswerFilter) => {
      setAnswerFilter(newAnswerFilter);
    };

    const updateSourceFilter = (newSourceFilter) => {
      setSourceFilter(newSourceFilter);
    };

    const handleSendButtonClicked = () => {
      // Call the handleSend function from props with the query
      handleSend(query);
      // Clear the query input or perform any other necessary actions
      setQuery('');
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') { //keyCode 13 is just the enter key
        e.preventDefault();
        handleSendButtonClicked(e);
      }
    };

    const [disable, setDisable] = useState(true); // disable submit button if no input
    useEffect(() => {
        (query) ? setDisable(false) : setDisable(true);
    }, [query]);

    const handleSuggestedQuestionsButtonClicked = (suggestedQuestion) => {
      // Call the handleSend function from props with the query
      handleSend(suggestedQuestion);
      // Clear the query input or perform any other necessary actions
      //setQuery('');
    };
    
    return (
      <Box sx={{position: 'sticky', bottom: 0}}>

      {(isMobile || postType !== 'fileViaLink') && (
      <StyledAppBar className={classes.appBar} elevation={0} position="fixed" sx={isMobile ? {top: 'auto', bottom: 0,
       background: '#fefefe', backdropFilter:"blur(5px)"
       } : {top: 'auto', bottom: 0, 
       background: '#fefefe', backdropFilter:"blur(0px)"
       }}>
      <Grid sx={{width: '100%'}}>
        <Grid display='flex' flexDirection="column" alignItems='center' justifyContent='center' sx={{width: '100%'}}>
          <Grid container display='flex' flexDirection="row" justifyContent='center'>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What was the methodology?")} sx={{textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '8px'}}>
              Methodology
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What data was used?")} sx={{textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '8px'}}>
              Data
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What are the results?")} sx={{textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '8px'}}>
              Results
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What are the limitations?")} sx={{textTransform: 'none', borderRadius: '10px', marginRight: '0px', marginBottom: '8px'}}>
              Limitations
            </Button>
          </Grid>
          {(loading == true) && (
            <Box margin='0px 0px 0px 0px' style={ isMobile ? { width: '94%', minWidth: '94%', maxWidth: '94%' } : { width: '59%', minWidth: '59%', maxWidth: '59%', marginLeft: '180px' }}>
              <LinearProgress />
            </Box>
          )}
          <TextField autoFocus fullWidth={isMobile ? true : false} style={ isMobile ? {width: '95%', minWidth: '95%', maxWidth: '95%', marginBottom:'2.5%', marginTop:'2.5%'} : { width: '60%', minWidth: '60%', maxWidth: '60%', marginBottom:'0.75%', marginTop:'0.75%', marginLeft: '180px' }}
          name="goal" size="large" label={query ? '' : 'Ask a question'} InputLabelProps={{ shrink: false }} 
          value={query} multiline onChange={(e) => setQuery(e.target.value)} inputProps={{maxLength: 150}}
          sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35',
          borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
            //backgroundColor: '#02161a', //'rgba(30, 30, 30, 1)',
            borderRadius: '10px',
            //paddingRight:'20px',
            //paddingLeft:'-20px'
          },}}
          onKeyPress={handleKeyPress}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleSendButtonClicked} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#1c95aa', color: 'white', padding:'2px', marginRight:'0px'}}>
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}>
          </TextField>
        </Grid>
      </Grid>
      </StyledAppBar>
      )}
      {!isMobile && postType === 'fileViaLink' && (
      <Grid style={{padding:'10px 10px 10px 10px', borderRadius: '0px', backgroundColor: '#fefefe'}}//, backgroundColor: '#00090b'}}
      >
        <Grid display='flex' flexDirection="column" alignItems='center' justifyContent='center' sx={{width: '100%'}}>
          
          <Grid container display='flex' flexDirection="row">
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What was the methodology?")} sx={{ textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '15px'}}>
              Methodology
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What data was used?")} sx={{ textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '15px'}}>
              Data
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What are the results?")} sx={{ textTransform: 'none', borderRadius: '10px', marginRight: '10px', marginBottom: '15px'}}>
              Results
            </Button>
            <Button variant='outlined' onClick={() => handleSuggestedQuestionsButtonClicked("What are the limitations?")} sx={{ textTransform: 'none', borderRadius: '10px', marginRight: '0px', marginBottom: '15px'}}>
              Limitations
            </Button>
          </Grid>

          <TextField autoFocus fullWidth
          name="goal" size="large" label={query ? '' : 'Ask a question'} InputLabelProps={{ shrink: false }} 
          value={query} multiline onChange={(e) => setQuery(e.target.value)} inputProps={{maxLength: 150}}
          sx={{ '& .MuiOutlinedInput-root': {'& fieldset': { //borderColor: '#002b35',
          borderRadius:'20px'},  '&:hover fieldset': {borderColor: '#1c95aa'}}, '& .MuiInputBase-root': {
            //backgroundColor: '#02161a', //'rgba(30, 30, 30, 1)',
            borderRadius: '10px',
            //paddingRight:'20px',
            //paddingLeft:'-20px'
          },}}
          onKeyPress={handleKeyPress}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(loading !== true) && (
                  <IconButton edge="end" onClick={handleSendButtonClicked} disabled={disable} style={disable ? {padding:'2px', marginRight:'0px'} : {backgroundColor: '#1c95aa', color: 'white', padding:'2px', marginRight:'0px'}}>
                    <ArrowForwardIcon />
                  </IconButton>
                  )}
                  {(loading == true) && (
                  <Grid edge="end">
                    <CircularProgress />
                  </Grid>
                  )}
                </InputAdornment>
              ),
            }}>
          </TextField>
        </Grid>
      </Grid>
      )}
      </Box>
    );
};

export default Bottombar;