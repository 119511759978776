// create a standard file for things that are repeated to avoid too much duplicated code

import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrid from './inputStyles';

const theme = createTheme();
const PREFIX = 'Input';

const classes = {
  inputField: `${PREFIX}-inputField`
};


const Input = ({ name, handleChange, label, half, autoFocus, type, handleShowPassword }) => {
  return (
    <StyledGrid item={true} xs={12} sm={half ? 6 : 12}>
      <TextField className={classes.inputField}
        name={name}
        onChange={handleChange}
        variant="outlined"
        size="medium"
        required
        fullWidth
        label={label}
        autoFocus={autoFocus}
        type={type}
        InputProps={name === 'password' ? {
            endAdornment : (
                <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} size="small">
                        {type === "password" ? <Visibility sx={{ fontSize: '20px', color: "lightGrey"}} /> : <VisibilityOff sx={{ fontSize: '20px', color: "lightGrey"}} />}
                    </IconButton>
                </InputAdornment>
            ),
        } : null }
      />
    </StyledGrid>
  );
};

export default Input;
