import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Tabs, Tab, Box, Grid, Container, CircularProgress, Chip, InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FileBase from 'react-file-base64';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

import { editProfile } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './editProfileStyles';

const theme = createTheme();
const PREFIX = 'EditProfile';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`,
    buttonCancel: `${PREFIX}-buttonCancel`
};


const EditProfile = ({  }) => {
    // update post -> change the form
    const [loadingUpload, setLoadingUpload] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));

    const [profileData, setProfileData] = useState({
        name: user?.result?.name, profilePicture: user?.result?.profilePicture, bio: user?.result?.bio,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingUpload(true);
        history.push(`/profile/${user?.result?._id}`);
        setTimeout(() => {
            dispatch(editProfile( user?.result?._id, { ...profileData}));
        }, 1000);
      };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };

    const handleCancel = () => {
        history.goBack();
      };

      const [disable, setDisable] = useState(true); // disable submit button if no input
      
      useEffect(() => {
        (profileData.name) ? setDisable(false) : setDisable(true);
      }, [profileData.name]);

      if(!user?.result?.email) {
          return (
              <StyledPaper className={classes.paper} elevation={0}>
                  <Typography variant="h6" align="center">
                      Sign in to edit your own profile
                  </Typography>
              </StyledPaper>
          );
      };

    
    return (
        <Grid >
        <Container component="main" maxWidth="xs" >
        <StyledPaper className={classes.paper} elevation={0}>

            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <div className={`${classes.root} ${classes.form}`}>

                <Typography component="h1" variant="h5" marginTop="25px" marginBottom="10px">Edit profile</Typography>
                
                <TextField name="name" variant="standard" fullWidth required
                    value={profileData.name}
                    label={"Username"}
                    onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                />

                {/*<div className={classes.fileInput}>
                    <FileBase
                        type="file" multiple={false}
                        onDone={({base64}) => setProfileData({ ...profileData, profilePicture: base64})}
                    />
                </div>*/}

                <TextField name="bio" variant="standard" fullWidth
                    value={profileData.bio}
                    label={"Bio"}
                    multiline
                    maxRows={7}
                    inputProps={{maxLength: 200}}
                    onChange={(e) => setProfileData({ ...profileData, bio: e.target.value })}
                />
                
                </div>
                
                {(loadingUpload && (<CircularProgress />))}
                {(!loadingUpload && (
                <Button
                    className={classes.buttonCancel} onClick={handleCancel}
                    sx={{ color: 'white', backgroundColor: '#0e0e0e', borderColor: 'black'}}
                    variant="contained" size="small">
                        Cancel
                </Button>
                ))}
                {(!loadingUpload && (
                <Button
                    className={classes.buttonSubmit}
                    sx={{ color: 'black', backgroundColor: 'white', borderColor: 'black'}}
                    variant="contained" size="small" type="submit" disabled={disable}>
                        Edit
                </Button>
                ))}
            </form>
        </StyledPaper>
        </Container>
        </Grid>
    );
}
// e.preventDefault(); to not get the refresh in the browser at this moment
// if current id not nul, don't dispatch a create post -> update it
//Paper: white background
// ...postData to not override everything in onChange -> spread the postData, then creator

export default EditProfile;